import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { formatDate } from "@angular/common";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments/environment";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { GetInSiteValueDetectionsResponse } from "../entity/get-in-site-value-detections-response";
import { FloorInfo } from "../entity/floor-info";
import { Partition } from "../entity/partition";
import { Meter } from "../entity/meter";
import { LastMonthValueConfirmationUser } from "../entity/last-month-value-confirmation-user";
import { LastMonthValueConfirmation } from "../entity/last-month-value-confirmation";
import { LoginUserSite } from "../entity/login-user-site";
import { Const } from "../const/const";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MeterInfo } from "../entity/get-meter-info";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { partition } from "rxjs-compat/operator/partition";
import { GetReportListResponse } from "../entity/get-report-list-response";
import { ReportListInfo } from "../entity/report-list-info";

@Component({
  selector: "app-meter-card-list",
  templateUrl: "./meter-card-list.component.html",
  styleUrls: ["./meter-card-list.component.scss"],
})
export class MeterCardListComponent implements OnInit {
  selectedSite = new UntypedFormControl();
  selectedMonth = new UntypedFormControl(
    formatDate(new Date(), "yyyyMM", this.locale)
  );

  monthArray: string[];
  siteArray: LoginUserSite[];
  partitionsArray: Partition[];
  loginUser: LoginUser;
  formControl: UntypedFormGroup;
  nowFloorId: string;
  floorsInfo: FloorInfo[];
  currentFloorName = "";
  // タブクリック時に連携されるフロア名とAPI叩く用のフロアIDを紐付ける
  floorsNameIdMap = new Map();
  idToken: string;
  //debug
  isReviewed: Array<{ key: string; value: boolean }>;
  reviewed: boolean;
  //debug
  // APIのURL
  getLoginUserUrl: string;
  getValueDetectionsUrl: string;
  getFloorsUrl: string;
  postValueConfirmationUrl: string;

  // レポートリスト表示用
  reportList: ReportListInfo[];

  // レポート取得
  getReportListUrl: string;

  // APIのレスポンス
  getLoginUserResponse: GetLoginUserResponse;
  getValueDetectionsResponse: GetInSiteValueDetectionsResponse;
  getFloorsResponse: GetFloorsResponse;

  // フラグ類
  getUserFinished: boolean;
  getValueDetectionFinished: boolean;
  canEdit: boolean;
  bookMarkFlag: boolean; //ByChen
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;
  // 有効切れメーターリスト
  expMeterData: MeterInfo[] = [];
  partitionTenants: GetTenantsPartition[];

  //レポートが確定されたかどうか判別できるフラグ
  confirmedReportFlg = false;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService
  ) {
    localStorage.setItem("path", router.url);
  }
  //Working
  handleisReviewedChanged(
    data: Array<{ key: string; value: boolean }>,
    meter_id: string
  ) {
    const result = data.find((item) => item.key === meter_id);
    this.reviewed = result ? result.value : null;
    //this.partitionsArray
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.siteArray = [];
    this.partitionsArray = [];
    this.reportList = [];
    this.getLoginUserResponse = new GetLoginUserResponse();
    this.getFloorsResponse = new GetFloorsResponse();
    this.getValueDetectionsResponse = new GetInSiteValueDetectionsResponse();
    this.floorsNameIdMap = new Map();
    this.floorsInfo = [];
    this.monthArray = [];
    this.getUserFinished = false;
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    this.canEdit = false;
    this.bookMarkFlag = false;
    this.makeMonthArray();
    this.expMeterData = [];
    this.confirmedReportFlg = false;
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of Const.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.loadMeterData();
    this.getFloorsUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.doGetFloors();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        () => {
          alert("施設情報が取得できませんでした。");
        }
      );
  }

  // 今月の値から2019年9月までの月情報を「yyyymm」形式の文字列の配列にする処理
  makeMonthArray() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    this.monthArray.push(thisMonth);
    const endMonth = new Date(2019, 9, 1);
    for (let a: number; endMonth <= dateData; a++) {
      dateData.setMonth(dateData.getMonth() - 1);
      const formatedMonth = formatDate(dateData, "yyyyMM", this.locale);
      this.monthArray.push(formatedMonth);
    }
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth =
      String(yearMonth).substr(0, 4) +
      "年" +
      String(yearMonth).substr(4) +
      "月";
    return formatedMonth;
  }

  // 月変更された時の処理
  doChangeMonth() {
    this.getValueDetectionsResponse = new GetInSiteValueDetectionsResponse();
    this.partitionsArray = [];
    this.reportList = [];
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    this.getValueDetectionsUrl =
      // tslint:disable-next-line:max-line-length
      `${environment.apiUrl}/sites/${this.selectedSite.value}/floors/${this.nowFloorId}/partitions/meters/${this.selectedMonth.value}/value_detections/value_confirmations`;
    this.doGetValueDetections();
    this.doGetReportList();
  }

  // 施設選択
  async doChangeSite() {
    // ローディング表示
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    this.loadMeterData();
    // フロア情報取得
    this.getFloorsUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.doGetFloors();
    this.doGetReportList();
  }

  // idトークンとsite_idの情報からフロア情報の取得を行う
  doGetFloors() {
    this.floorsInfo = [];
    this.currentFloorName = "";
    this.httpClient
      .get(this.getFloorsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;
          this.nowFloorId = this.floorsInfo[0].id;
          for (const floor of this.floorsInfo) {
            this.floorsNameIdMap.set(floor.name, floor.id);
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            this.getValueDetectionFinished = true;
            this.selectedMonth.enable();
            this.selectedSite.enable();
            alert("フロア情報が取得できませんでした。");
          }
        }
      );
  }

  // 施設に紐づくメーター情報を取得するAPIを叩く処理
  // 最初はタブの初期値がB1階に設定された時に呼ばれる
  doGetValueDetections() {
    this.httpClient
      .get(this.getValueDetectionsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetInSiteValueDetectionsResponse) => {
          let partitionsCnt = 0;
          for (const floor of response.result["site"].floors) {
            for (const partition of floor.partitions) {
              partitionsCnt = partitionsCnt + 1;
              // テナント入れ替えない時
              if (partition.partitions_tenants.length === 1) {
                if (partition.partitions_tenants[0].tenant_log) {
                  this.partitionsArray.push(partition);
                }
                // テナント入れ替えある時
              } else if (partition.partitions_tenants.length >= 1) {
                partition.partitions_tenants.reverse();
                // 旧テナント（tenants[0]）のみの区画を生成
                const tenantArrayDumy = [];
                tenantArrayDumy.push(partition.partitions_tenants[0]);
                const oldTenantPartition = new Partition(
                  partition.id,
                  partition.name,
                  partition.created_at,
                  partition.updated_at,
                  partition.deleted_at,
                  tenantArrayDumy
                );
                const oldTenantMeters = this.makeMetersArray(
                  partition.meters,
                  oldTenantPartition.partitions_tenants[0].tenant_log.tenant_id,
                  true
                );
                // const oldTenantMeters = this.makeMetersArray(partition.meters, oldTenantPartition.partitions_tenants[0].tenant.id, true);
                oldTenantPartition.meters = oldTenantMeters;
                console.log(oldTenantPartition.meters);
                this.partitionsArray.push(oldTenantPartition);

                const partitionsExcept0 = partition.partitions_tenants;
                partitionsExcept0.shift();
                // 新テナント（tenant[1]以降のみの区画を生成
                this.makeNewTenantsPartiton(
                  partition,
                  partitionsExcept0,
                  oldTenantMeters
                );
              }
            }
          }
          if (partitionsCnt < 1) {
            alert("区画情報が取得できませんでした。");
          } else if (this.partitionsArray.length === 0) {
            alert("テナント情報が取得できませんでした。");
          }

          this.getValueDetectionFinished = true;
          this.selectedMonth.enable();
          this.selectedSite.enable();
        },
        (err) => {
          this.getValueDetectionFinished = true;
          this.selectedMonth.enable();
          this.selectedSite.enable();
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("検針値が取得できませんでした。");
          }
        }
      );
  }

  // テナント入れ替えのあった区画（partitionに複数区画が存在する）で２列目以降に表示する列情報を作成
  makeNewTenantsPartiton(
    partition: any,
    partitionsExcept0: any,
    oldTenantMeters: any
  ) {
    for (const partitonsTenant of partitionsExcept0) {
      const newTenantArrayDumy = [];
      newTenantArrayDumy.push(partitonsTenant);
      const newTenantPartition = new Partition(
        partition.id,
        partition.name,
        partition.created_at,
        partition.updated_at,
        partition.deleted_at,
        newTenantArrayDumy
      );
      // const newTenantMeters = this.makeMetersArray(partition.meters, newTenantPartition.partitions_tenants[0].tenant.id, false);
      const newTenantMeters = this.makeMetersArray(
        partition.meters,
        newTenantPartition.partitions_tenants[0].tenant_log.tenant_id,
        false
      );
      // 旧テナントの当月検針値を新テナントの前月検針値
      this.oldNewTenantsValueMatch(newTenantMeters, oldTenantMeters);
      newTenantPartition.meters = newTenantMeters;
      this.partitionsArray.push(newTenantPartition);
      oldTenantMeters = newTenantMeters;
    }
  }

  // 旧テナントの当月検針値を新テナントの当月検針値に入れる（＝新テナントのメーター情報を完成させる）処理
  // 引数に取るのはメーター情報だけ入れない
  oldNewTenantsValueMatch(newTenantMeters: Meter[], oldTenantMeters: Meter[]) {
    const user = new LastMonthValueConfirmationUser(
      this.loginUser.id,
      this.loginUser.name
    );
    let counter = 0;
    for (const oldTenantMeter of oldTenantMeters) {
      if (oldTenantMeter.value_detections.length !== 0) {
        if (oldTenantMeter.value_detections[0].value_confirmation) {
          const newLastMonthValueConfirmation = new LastMonthValueConfirmation(
            "dummyId",
            oldTenantMeter.value_detections[0].value_confirmation.confirmed_value,
            "1",
            oldTenantMeter.value_detections[0].value_confirmation.created_at,
            user
          );
          newTenantMeters[counter].last_month_value_confirmation =
            newLastMonthValueConfirmation;
        }
      }
      counter++;
    }
  }

  // テナントが複数いる区画において、引数のtenant_idにひもづくvalue_detectionだけを残したメーターオブジェクトを生成する
  makeMetersArray(allInfoMeters: Meter[], tenantId: string, isOld: boolean) {
    const metersArray = [];
    for (const meter of allInfoMeters) {
      const editedMeter = new Meter(
        meter.id,
        meter.name,
        meter.label,
        meter.number_of_digits,
        meter.decimal_point_position,
        meter.created_at,
        meter.updated_at,
        meter.unit,
        meter.is_reviewed
      );
      // 旧テナントであれば先月確定値をそのまま入れる
      // 新テナントは後の処理で旧テナントの確定値から持ってくる
      if (isOld) {
        editedMeter.last_month_value_confirmation =
          meter.last_month_value_confirmation;
      }
      const valueDetectionsArray = [];
      const count = 1;
      for (const valueDetection of meter.value_detections) {
        if (valueDetection.tenant_id === tenantId) {
          valueDetectionsArray.push(valueDetection);
        }
      }
      editedMeter.value_detections = valueDetectionsArray;
      metersArray.push(editedMeter);
    }
    return metersArray;
  }

  // タブがクリックされた時の処理
  // 引数からフロア名取得⇨MapでIDに変換⇨検針値取得API叩く
  doClickTab(MatTagChangevent: any) {
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    this.currentFloorName = MatTagChangevent.tab.textLabel;
    const newFloorId = this.floorsNameIdMap.get(MatTagChangevent.tab.textLabel);
    this.nowFloorId = newFloorId;
    this.partitionsArray = [];
    this.reportList = [];
    this.getValueDetectionsResponse = new GetInSiteValueDetectionsResponse();
    this.getValueDetectionsUrl =
      // tslint:disable-next-line:max-line-length
      `${environment.apiUrl}/sites/${this.selectedSite.value}/floors/${this.nowFloorId}/partitions/meters/${this.selectedMonth.value}/value_detections/value_confirmations`;
    this.doGetValueDetections();
    this.doGetReportList();
  }

  doGetReportList() {
    this.getReportListUrl = `${environment.apiUrl}/report_list/${this.selectedSite.value}`;
    this.httpClient
      .get(this.getReportListUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportListResponse) => {
          this.reportList = [];
          const reportListData = response.result.report_list;
          for (const oneReportData of reportListData) {
            const reportMonth = Number(oneReportData.report_month);
            let fixedAt = null;
            if (oneReportData.confirmed_report_created_at) {
              fixedAt = String(
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyyMM",
                  this.locale
                )
              );
            }
            const reportInfo = new ReportListInfo(reportMonth, fixedAt, "", "");
            this.reportList.push(reportInfo);
          }
          this.confirmedReportFlg = false;
          for (const report of this.reportList) {
            if (
              String(report.month) === String(this.selectedMonth.value) &&
              report.fixedTime
            ) {
              this.confirmedReportFlg = true;
              break;
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("レポート一覧が取得できませんでした。");
          }
        }
      );
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  get flagImgUrl() {
    return this.bookMarkFlag
      ? "../assets/filter_bookmark_on.png"
      : "../assets/filter_bookmark_off.png";
  }

  // レポート画面遷移処理
  onClickReport() {
    this.router.navigate(["meter-report"]);
  }
  onClickReport_bookmark() {
    this.bookMarkFlag = !this.bookMarkFlag;
    this.doSetValue();
  }

  onClickMasterEdit() {
    this.router.navigate(["master-edit"]);
  }

  onClickCardList() {
    this.router.navigate(["meter-card-list"]);
  }

  loadMeterData() {
    this.expMeterData = [];
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonObj = JSON.parse(JSON.stringify(res));
          if (jsonObj.status == 200) {
            let jsonFloors = jsonObj.result.site.floors;
            let site_id = jsonObj.result.site.id;
            this.getPartitionTenantData();
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              const partitions = floor.partitions;
              for (let index = 0; index < partitions.length; index++) {
                const partition = partitions[index];
                for (let index = 0; index < partition.meters.length; index++) {
                  const meterObj = partition.meters[index];
                  let meterModel = meterObj as MeterInfo;
                  meterModel.partName = partition.name;
                  if (
                    partition.partitions_tenants !== undefined &&
                    partition.partitions_tenants.length > 0
                  ) {
                    meterModel.tenantName =
                      partition.partitions_tenants[0].tenant_log.name;
                    meterModel.site_id = site_id;
                    meterModel.floor_id = floor.id;
                    meterModel.floor_name = floor.name;
                  }
                  if (
                    Number(this.loginUser.user_type) !== this.userTypeSysAdmin
                  ) {
                    if (
                      meterModel.expiration_date !== null &&
                      meterModel.expiration_date !== undefined &&
                      meterModel.expiration_date !== ""
                    ) {
                      var day = new Date();
                      day.setMonth(day.getMonth() + 24);
                      const dayOfNextYear = formatDate(
                        day,
                        "yyyyMMdd",
                        this.locale
                      );
                      const expiration = formatDate(
                        meterModel.expiration_date,
                        "yyyyMMdd",
                        this.locale
                      );
                      if (expiration < dayOfNextYear) {
                        meterModel.isExpireMeterFlag = true;

                        let expMeterModel = meterObj as MeterInfo;
                        expMeterModel = meterModel;
                        this.expMeterData.push(expMeterModel);
                      }
                    }
                  }
                }
              }
            }
            // ソート
            if (this.expMeterData.length > 0) {
              this.expMeterData.sort((n1, n2) => {
                if (n1.expiration_date > n2.expiration_date) {
                  return 1;
                }
                if (n1.expiration_date < n2.expiration_date) {
                  return -1;
                }
                return 0;
              });
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }

  getPartitionTenantData() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);

    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/tenants/${thisMonth}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.partitionTenants = response.result.site.partitions;
          this.partitionTenants = this.partitionTenants.filter(
            (item) => item.partitions_tenants.length != 0
          );
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
}
