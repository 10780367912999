import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageInfo } from '../entity/message-info';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: MessageInfo
  ) { }

  ngOnInit() {
  }

  onClickClose(): void {
    this.dialogRef.close('close');
  }
}
