export class SelectedPartition {
    constructor(partitionId: string, partitionName: string, meterLabels: string[]) {
        this.partitionId = partitionId;
        this.partitionName = partitionName;
        this.meterLabels = meterLabels;
    }
    partitionId: string;
    partitionName: string;
    meterLabels: string[];
}
