import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { MeterInfo } from '../entity/get-meter-info';
import { MeterExpirationWarningDialogComponent } from '../meter-expiration-warning-dialog/meter-expiration-warning-dialog.component'
import { Const } from '../const/const'

@Component({
  selector: 'app-meter-expiration-navigate',
  templateUrl: './meter-expiration-navigate.component.html',
  styleUrls: ['./meter-expiration-navigate.component.scss']
})

export class MeterExprationNavigate implements OnInit {
  componentName: string;
  idToken: string;
  allMeterData: MeterInfo[];
  displayFlag: boolean;
  @Input() expMetersInfo:  MeterInfo[];

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
  ) {
  }

  ngOnInit() {
    
    this.allMeterData = this.expMetersInfo
    if(this.allMeterData.length > 0) {
     this.displayFlag = true
    }
    this.getToken()
  }

  getToken() {
    this.auth.getIdToken()
      .subscribe(
        result => {
          if (result) {
            this.idToken = result
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.onClickLogout()
          }
        }
      )
  }

  ngAfterContentChecked() {
    this.displayFlag = false
    this.allMeterData = this.expMetersInfo
    if(this.allMeterData.length > 0) {
     this.displayFlag = true
    }
  }

  onClickMeterExpirationWarning() {
    const dialogRef = this.dialog.open(MeterExpirationWarningDialogComponent, {
      width: '1000px',
      data:{metersInfo: this.allMeterData}
    });
    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {}
    });
  }

  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }
}
