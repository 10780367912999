import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FooterNumberingExplainDialogComponent } from '../footer-numbering-explain-dialog/footer-numbering-explain-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnChanges {
  componentName: string;
  @Input() displayFlag: boolean
  @Output() numberingEvent = new EventEmitter();
  show = false

  constructor(
    public explainDialog: MatDialog,
  ) {
  }

  ngOnInit() {}

  onNumberingEventClick() {
    this.numberingEvent.emit()
  }

  ngOnChanges() {
    if (this.show != this.displayFlag) {
      this.show = this.displayFlag
    }
  }

  onExplain() {
    const dialogRef = this.explainDialog.open(FooterNumberingExplainDialogComponent, {
      width: '650px',
    });
  }
}
