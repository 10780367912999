import { LastMonthValueConfirmationUser } from './last-month-value-confirmation-user';

export class LastMonthValueConfirmation {
    constructor(
        id: string, confirmed_value: string, confirmation_channel: string, created_at: string, user: LastMonthValueConfirmationUser) {
        this.id = id;
        this.confirmed_value = confirmed_value;
        this.confirmation_channel = confirmation_channel;
        this.created_at = created_at;
        this.user = user;
    }
    id: string;
    confirmed_value: string;
    confirmation_channel: string;
    created_at: string;
    user: LastMonthValueConfirmationUser;
}
