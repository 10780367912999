import { LastMonthValueConfirmation } from './last-month-value-confirmation';
import { Unit } from './unit';
import { ValueDetection } from './value-detection';


export class Meter {
    constructor(
        id: string, name: string, label: string, number_of_digits: string,
        decimal_point_position: string, created_at: string, updated_at: string, unit: Unit, is_reviewed: boolean) {
        this.id = id;
        this.name = name;
        this.label = label;
        this.number_of_digits = number_of_digits;
        this.decimal_point_position = decimal_point_position;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.unit = unit;
        this.is_reviewed = is_reviewed;
    }
    id: string;
    name: string;
    label: string;
    number_of_digits: string;
    decimal_point_position: string;
    created_at: string;
    updated_at: string;
    unit: Unit;
    last_month_value_confirmation: LastMonthValueConfirmation;
    value_detections: ValueDetection[];
    is_reviewed: boolean;
}
