// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_component {
  width: 100%;
  height: 100%;
}

.icon_image {
  width: 300px;
  height: 200px;
}

.site-name {
  text-align: center;
}

table {
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.main-area {
  width: 400px;
  height: 400px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/site-select/site-select.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;AACJ","sourcesContent":[".login_component {\n    width: 100%;\n    height: 100%;\n}\n  \n.icon_image {\n    width: 300px;\n    height: 200px;\n}\n\n.site-name {\n    text-align: center;\n}\n\ntable {\n    width: 100%;\n    align-items: center;\n    cursor: pointer;\n}\n\n.main-area {\n    width: 400px;\n    height: 400px;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
