import { GetUserWithSitesLinksInfo } from './get-user-with-sites-links-info';

export class CsvUserInfo {
    userId: string;
    loginId: string;
    password: string;
    userName: string;
    companyId: string;
    userType: number;
    siteIdList: string[];
    dbuserData: GetUserWithSitesLinksInfo;
}
