import { Component, OnInit, Inject, LOCALE_ID} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { LoginUser } from '../entity/login-user';
import { MatDialog } from '@angular/material/dialog';
import { MeterType } from "../entity/get-meter-type";
import { MeterTypeAddDialogComponent } from '../meter-type-add-dialog/meter-type-add-dialog.component';
import { MeterTypeEditDialogComponent } from '../meter-type-edit-dialog/meter-type-edit-dialog.component';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { MeterTypeService } from '../services/meter-type.service';
import { Subject } from 'rxjs';
import { Const } from '../const/const';

@Component({
  selector: 'app-meter-type-management-list',
  templateUrl: './meter-type-management-list.component.html',
  styleUrls: ['./meter-type-management-list.component.scss']
})
export class MeterTypeManagementListComponent implements OnInit {

  loginUser: LoginUser;
  getLoginUserUrl: string;
  getLoginUserResponse: GetLoginUserResponse;
  getTenantDataUrl: string;

  idToken: string;

  // テーブル生成用
  displayedColumns: string[];
  meterTypeData: MeterType[];
  filterdMeterTypeData: MeterType[];

  searchKeyString: string="";
  searchKeyChanged: Subject<string> = new Subject<string>();

  // フラグ類
  getUserFinished: boolean;
  getMeterTypeFinished: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    public auth: AuthService,
    public typeService: MeterTypeService,
    public meterTypeDialog: MatDialog,
    ) {
      localStorage.setItem("path", router.url)
    }


    ngOnInit() {
      this.loginUser = new LoginUser()
      this.getLoginUserResponse = new GetLoginUserResponse();
      this.meterTypeData = []

      this.getUserFinished = false

      this.searchKeyChanged
      .debounceTime(300) // wait 300ms after the last event before emitting last event
      .subscribe(str => {this.searchKeyString = str; this.filterResult()});

      this.auth.getIdToken()
        .subscribe(
            result => {
              if (result) {
                this.idToken = result;
                if (Const.loginUser === null) {
                  this.doGetLoginUser()
                } else {
                  this.loginUser = Const.loginUser
                  this.mjitUserFlag = Const.mjitUser;
                  this.adminUserFlag = Const.adminUser;
                  this.doGetMeterTypeData()
                }
              } else {
                // idトークンがnullの場合はログイン画面へ遷移
                alert('セッションが切れています。再度ログインしてください。');
                this.onClickLogout()
              }
            }
        );
    }

  filterResult(){
    if(this.searchKeyString === ""){
      this.filterdMeterTypeData = this.meterTypeData
      return
    }

    this.filterdMeterTypeData = this.meterTypeData.filter(item => item.name.includes(this.searchKeyString))
  }

  search(text: string){
    this.searchKeyChanged.next(text);
  }
  
  
  doGetMeterTypeData(){

    this.getMeterTypeFinished = false

    const url = `${environment.apiUrl}/meter_types/meter_types_info_all`
    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res =>{
      let jsonStr = JSON.stringify(res)
      let jsonObj = JSON.parse(jsonStr)
      this.meterTypeData = jsonObj.result.metertypes

      this.filterdMeterTypeData = this.meterTypeData
      this.displayedColumns = ["typeName","createdBy","createdAt","updatedBy","updatedAt","editButton","deleteButton"]
      this.getMeterTypeFinished = true
    },
      err=>{
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
          this.onClickLogout();
        }else{
          alert("メータータイプが取得できませんでした。")
        }
    })
  }

    doGetLoginUser() {
      this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
  
      this.httpClient.get(this.getLoginUserUrl,
        {
          headers: new HttpHeaders({
            Authorization: this.idToken
          })
        }
      )
      .subscribe((response: GetLoginUserResponse) => {
        Const.loginUser = response.result.login_user;
        Const.mjitUser = Number(Const.loginUser.user_type) === 0
        Const.adminUser = Number(Const.loginUser.user_type) === 1
        if (Const.mjitUser) {
          Const.site_id = localStorage.getItem("siteId")
        } else {
          for (const site of response.result.login_user.sites) {
            if (site.id === localStorage.getItem("siteId")) {
              Const.site_id = site.id
            }
          }
  
          if (Const.site_id === null) {
            alert('前回選択した施設が見つかりませんでした。');
            Const.site_id = response.result.login_user.sites[0].id;
          }
        }
    
        this.loginUser = Const.loginUser
        this.mjitUserFlag = Const.mjitUser;
        this.adminUserFlag = Const.adminUser;
        this.doGetMeterTypeData()
      },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
        }else{
          alert('ログイン情報が取得できませんでした。再度ログインしてください。');
        }
        this.onClickLogout()
      });
    }
  
    // ログアウト
    onClickLogout() {
      Const.release()
      this.auth.signOut();
    }
  
    onClickAddMeterType() {

      const dialogRef = this.meterTypeDialog.open(MeterTypeAddDialogComponent, {
        width: '650px',
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === 'close') {
          // 最小限の更新になるように要改良
          this.doGetMeterTypeData()
        }
      });
    }

    onClickMeterTypeEdit(meterType: MeterType) {
      const dialogRef = this.meterTypeDialog.open(MeterTypeEditDialogComponent, {
        width: '650px',
        data: meterType
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === 'close') {
          // 最小限の更新になるように要改良
          this.doGetMeterTypeData()
        }
      });
    }
  
    onClickMeterTypeDelete(meterType: MeterType) {
      if(!navigator.onLine){
        alert("ネットワーク接続を確認してください")
        return
      }
      let contentMsg =  meterType.name + "を削除します。よろしいですか。"
      const messageInfo = new MeterConfirmDialogInfo('メータータイプ削除確認', contentMsg);
      const confirmDialog = this.meterTypeDialog.open(CommonConfirmDialogComponent,{
        width: '400px',
        data: messageInfo
      })

      confirmDialog.afterClosed().subscribe(result => {
        if(result === "Yes"){
          
          let params = new HttpParams();
          params.set("name","")

          const url = `${environment.apiUrl}/meter_types/${meterType.id}/0/meter_types_info`;
          this.httpClient.patch(url,params, {
            headers: new HttpHeaders({Authorization: this.idToken })
          }).subscribe(
            () => {
              this.doGetMeterTypeData()
          },err=>{
            if(err.error.code == "AccountLockError"){
              alert('アカウントがロックされました。管理者までお問合せください');
              this.onClickLogout()
            }else{
              alert("メータータイプ削除に失敗しました")
            }
          })
        }
      })
    }
  
}
