// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-button {
  background-color: lightcoral;
}

.report-area {
  margin: 5px;
}

button {
  margin: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.alert-area {
  padding-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-list-dialog/report-list-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;;AACA;EACI,WAAA;AAEJ;;AAAA;EACI,WAAA;EACA,iBAAA;EACA,oBAAA;AAGJ;;AAAA;EACI,oBAAA;AAGJ","sourcesContent":[".alert-button{\n    background-color: lightcoral;\n}\n.report-area{\n    margin:5px;\n}\nbutton {\n    margin: 5px;\n    padding-top: 25px;\n    padding-bottom: 25px;\n}\n\n.alert-area{\n    padding-bottom: 25px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
