// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: small;
  text-align: right;
}

table thead {
  align-self: center;
}

table thead,
table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

table thead {
  text-align: center;
}

th {
  text-align: left;
  padding-left: 20%;
  width: 40%;
}

td {
  text-align: left;
  height: 45px;
}

.user-button {
  border: none;
  background-color: white;
  color: rgb(57, 57, 230);
  font-size: small;
}

.actionButtonArea {
  margin-top: 20px;
  padding-bottom: 12px;
}

.confirm-button {
  background-color: #90CAFC;
}`, "",{"version":3,"sources":["webpack://./src/app/site-delete-dialog/site-delete-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGA;;EAEI,cAAA;EACA,mBAAA;EACA,WAAA;AAAJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,UAAA;AACJ;;AACA;EACI,gBAAA;EACA,YAAA;AAEJ;;AACA;EACI,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,gBAAA;EACA,oBAAA;AAEJ;;AAAA;EACI,yBAAA;AAGJ","sourcesContent":["table{\n    font-size: small;\n    text-align: right;    \n}\n\ntable thead{\n    align-self: center;\n}\n\n\ntable thead,\ntable tr{\n    display: table;\n    table-layout: fixed;\n    width: 100%;\n}\ntable thead{\n    text-align: center;\n}\n\nth{\n    text-align: left;\n    padding-left: 20%;\n    width: 40%;\n}\ntd{\n    text-align: left;\n    height: 45px\n}\n\n.user-button{\n    border: none;\n    background-color: white;\n    color: rgb(57, 57, 230);\n    font-size: small;\n}\n\n.actionButtonArea{\n    margin-top: 20px;\n    padding-bottom: 12px;\n}\n.confirm-button{\n    background-color: #90CAFC;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
