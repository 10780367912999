import { idName } from "./id-name";

export class GetUserWithSitesLinksInfo {
    id: string;
    loginId: string;
    passward: string;
    name: string;
    companyId: string;
    companyName: string;
    userType: number;
    userTypeName: string;
    sites: UserSite[];
    createdAt: string;
    updatedAt: string;
    created_by: idName;
    updated_by: idName;

    lockStatus: boolean = false
}

export class UserSite {
    siteId: string;
    siteName: string;
    siteAddress: string;
    companyName: string;
}
