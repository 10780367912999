// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: small;
  text-align: right;
}

table thead {
  align-self: center;
}

table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 250px;
  display: block;
  text-align: center;
}

table thead,
table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

table thead {
  text-align: center;
}

th {
  text-align: left;
  padding-left: 20%;
}

td {
  text-align: left;
  height: 45px;
}

mat-form-field {
  min-width: 200px;
}

input {
  font-size: medium;
}

:host ::ng-deep .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  background: inherit;
}

.user-button {
  border: none;
  background-color: white;
  color: rgb(57, 57, 230);
  font-size: small;
}

.actionButtonArea {
  margin-top: 20px;
  padding-bottom: 12px;
}

.confirm-button {
  background-color: #90CAFC;
}

.error {
  color: red;
}

.user-button:focus {
  box-shadow: 0 0 rgb(255, 255, 255) !important;
  transition: none !important;
  background-color: rgb(255, 255, 255) !important;
  -webkit-tap-highlight-color: rgb(255, 255, 255) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/site-add-edit-dialog/site-add-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EAEA,aAAA;EACA,cAAA;EAEA,kBAAA;AADJ;;AAIA;;EAEI,cAAA;EACA,mBAAA;EACA,WAAA;AADJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,iBAAA;AAAJ;;AAEA;EACI,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,oBAAA;AACJ;;AACA;EACI,yBAAA;AAEJ;;AACA;EACI,UAAA;AAEJ;;AACA;EACI,6CAAA;EACA,2BAAA;EACA,+CAAA;EACA,0DAAA;AAEJ","sourcesContent":["table{\n    font-size: small;\n    text-align: right;    \n}\n\ntable thead{\n    align-self: center;\n}\n\ntable tbody{\n    overflow-y: scroll;\n    overflow-x: hidden;\n\n    height: 250px;\n    display: block;\n\n    text-align: center;\n}\n\ntable thead,\ntable tr{\n    display: table;\n    table-layout: fixed;\n    width: 100%;\n}\ntable thead{\n    text-align: center;\n}\n\nth{\n    text-align: left;\n    padding-left: 20%;\n}\ntd{\n    text-align: left;\n    height: 45px\n}\n\nmat-form-field{\n    min-width: 200px;\n}\n\ninput{\n    font-size: medium;\n}\n\n:host ::ng-deep .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {\n    background: inherit;\n}\n\n.user-button{\n    border: none;\n    background-color: white;\n    color: rgb(57, 57, 230);\n    font-size: small;\n}\n\n.actionButtonArea{\n    margin-top: 20px;\n    padding-bottom: 12px;\n}\n.confirm-button{\n    background-color: #90CAFC;\n}\n\n.error{\n    color: red;\n}\n\n.user-button:focus{\n    box-shadow: 0 0 rgba(255, 255, 255, 1) !important;\n    transition:none !important;\n    background-color: rgba(255, 255, 255, 1) !important;\n    -webkit-tap-highlight-color:rgba(255, 255, 255, 1) !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
