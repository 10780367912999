import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Const } from '../const/const'

@Component({
  selector: 'app-log-out-button',
  templateUrl: './log-out-button.component.html',
  styleUrls: ['./log-out-button.component.scss']
})
export class LogOutButtonComponent implements OnInit {

  constructor(
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }

}
