import { LoginUserCompany } from './login-user-company';
import { LoginUserSite } from './login-user-site';

export class LoginUser {
    id: string;
    name: string;
    user_type: string;
    created_at: string;
    updated_at: string;
    company: LoginUserCompany;
    sites: LoginUserSite[];
}
