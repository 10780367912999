

export class ReportListInfo {
    constructor(month: number, fixedTime: string, lastUpdate: string, confirmedReportUser: string) {
        this.month = month;
        this.fixedTime = fixedTime;
        this.lastUpdate = lastUpdate;
        this.confirmedReportUser = confirmedReportUser;
    }
    month: number;
    fixedTime: string;
    lastUpdate: string;
    confirmedReportUser: string;
    isConfirmedAll = true;
}
