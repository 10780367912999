import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { CompanyModel } from '../site-management/site-management.component';

@Component({
  selector: 'app-company-add-edit-dialog',
  templateUrl: './company-add-edit-dialog.component.html',
  styleUrls: ['./company-add-edit-dialog.component.scss']
})

export class CompanyAddEditDialogComponent implements OnInit {
  idToken: string;
  companyName: string;

  @Input() public company:  CompanyModel;
  constructor(
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<CompanyAddEditDialogComponent>,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public isEditMode: boolean = false) {
  }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            this.onClickLogout()
            alert('セッションが切れています。再度ログインしてください。');
          }
        }
    );

    if(this.isEditMode){
      this.companyName = this.company.name
    }
  }

  onClickConfirm(){
    if(!navigator.onLine){
      alert("ネットワーク接続を確認してください")
      return
    }

    if(this.isEditMode){
      this.updateCompany()
    }else{
      this.addCompany()
    }
  }

  addCompany(){
    const messageInfo = new MeterConfirmDialogInfo('新規登録確認', '会社を新規登録します。よろしいですか。');
    const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent, {
      width: '400px',
      data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        // 会社の登録処理
        const postSiteUrl = `${environment.apiUrl}/company/company_info`;
        let params = new HttpParams();
        params = params.set('name', this.companyName);
        this.httpClient.post(postSiteUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe((response) => {
          this.dialogRef.close('close');
        },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
            this.dialogRef.close('lockedAccount');
          }else{
            alert('会社情報の登録に失敗しました。');
          }
        });
      }
    });
  }

  updateCompany(){
    const messageInfo = new MeterConfirmDialogInfo('会社更新確認', '会社を更新します。よろしいですか。');
    const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent, {
      width: '400px',
      data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        // 会社更新処理
        const url = `${environment.apiUrl}/company/company_info/1/${this.company.id}`;
        let params = new HttpParams();
        params = params.set('name', this.companyName);
        this.httpClient.patch(url, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken,
              "content-type": "application/x-www-form-urlencoded"
            })
          }
        )
        .subscribe((response) => {
          this.dialogRef.close('close');
        },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
            this.dialogRef.close('lockedAccount');
          }else{
            alert('会社情報の更新に失敗しました。');
          }
        });
      }
    });
  }

  checkIfFormDirty(){
    return this.companyName != this.company.name
  }
  
  checkIfFormReady(){
    return this.companyName != "" && this.companyName != null && this.companyName != undefined
  }

  comfirmButtonDisable(){
    if(this.isEditMode){
      return this.checkIfFormReady() && this.checkIfFormDirty()
    }
    return this.checkIfFormReady()
  }

  // ログアウト
  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }
}
