// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  padding: 50px;
}

th {
  text-align: left;
}

.confirm-button {
  background-color: #90CAFC;
}`, "",{"version":3,"sources":["webpack://./src/app/floor-delete-dialog/floor-delete-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;;AAAA;EACI,yBAAA;AAGJ","sourcesContent":["table {\n    padding: 50px ;\n}\nth {\n    text-align: left;\n}\n.confirm-button {\n    background-color: #90CAFC;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
