import { idName } from "./id-name";

export class TenantRow {
    tenantId: string;
    partition: string;
    partitionId: string;
    name: string;
    note: string;
    initialValue: string;
    closedValue: string;
    isCardDisplay: number;
    contractedAt: string;
    cancelledAt: string;
    created_by: idName;
    updated_by: idName;
    updatedAt: string;
}
