// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-bar {
  padding-top: 0;
}

.side-bar .selected {
  color: #90CAFC;
  background-color: whitesmoke;
}

.side-bar section {
  min-height: 150px;
}

.side-bar section .title {
  font-size: 14pt;
  font-weight: 300;
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.side-bar .blue {
  background-color: #90CAFC;
}

.side-bar .red {
  background-color: lightcoral;
}`, "",{"version":3,"sources":["webpack://./src/app/side-nav/side-nav.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;;AAGA;EACI,cAAA;EACA,4BAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AAAJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ","sourcesContent":["\n.side-bar{\n    padding-top: 0;\n}\n\n.side-bar .selected{\n    color: #90CAFC;\n    background-color: whitesmoke;\n}\n\n.side-bar section{\n    min-height: 150px;\n}\n\n.side-bar section .title{\n    font-size: 14pt;\n    font-weight: 300;\n    margin: 0;\n    padding: 0;\n    padding-left: 10px;\n}\n.side-bar .blue{\n    background-color: #90CAFC;\n}\n\n.side-bar .red{\n    background-color: lightcoral;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
