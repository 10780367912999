// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: medium;
  text-align: right;
}

table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 350px;
  display: block;
  padding-left: 20%;
  margin: 0 auto;
}

table tbody .small_row {
  padding-left: 10%;
}

#small_right_field {
  margin-left: -50px;
}

table tbody th {
  text-align: left;
}

table tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

table tbody::-webkit-scrollbar :vertical {
  width: 2px;
}

table tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.3);
}

table thead,
table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

table thead {
  text-align: center;
}

td {
  text-align: left;
  height: 45px;
}

input {
  font-size: medium;
}

.actionButtonArea {
  margin-top: 20px;
  padding-bottom: 12px;
}

.confirm-button {
  background-color: #90CAFC;
}

.error {
  color: red;
}

.numberExist {
  color: red;
  font-size: 12px;
}

.mainMeterNameHeader, .subMeterNameHeader {
  padding-left: 10%;
}

.actualValue {
  color: #333;
  font-size: small;
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-edit-dialog/meter-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EAEA,aAAA;EACA,cAAA;EAEA,iBAAA;EACA,cAAA;AADJ;;AAIA;EAEI,iBAAA;AAFJ;;AAIA;EACI,kBAAA;AADJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,wBAAA;AAAJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;EACA,oCAAA;AACJ;;AAEA;;EAEI,cAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,gBAAA;EACA,YAAA;AAEJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,oBAAA;AACJ;;AACA;EACI,yBAAA;AAEJ;;AACA;EACI,UAAA;AAEJ;;AACA;EACI,UAAA;EACA,eAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AACA;EACI,WAAA;EACA,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":["table{\n    font-size: medium;\n    text-align: right;\n}\n\ntable tbody{\n    overflow-y: scroll;\n    overflow-x: hidden;\n\n    height: 350px;\n    display: block;\n\n    padding-left: 20%;\n    margin: 0 auto;\n}\n\ntable tbody .small_row{\n\n    padding-left: 10%;\n}\n#small_right_field{\n    margin-left: -50px;\n}\ntable tbody th{\n    text-align: left;\n}\n\ntable tbody::-webkit-scrollbar{\n    -webkit-appearance: none;\n}\ntable tbody::-webkit-scrollbar :vertical {\n    width: 2px;\n}\n\ntable tbody::-webkit-scrollbar-thumb{\n    border-radius: 8px;\n    border: 2px solid white;\n    background-color: rgba(0,0,0,0.3);\n}\n\ntable thead,\ntable tr{\n    display: table;\n    table-layout: fixed;\n    width: 100%;\n}\ntable thead{\n    text-align: center;\n}\n\ntd{\n    text-align: left;\n    height: 45px\n}\n\n\ninput{\n    font-size: medium;\n}\n\n.actionButtonArea{\n    margin-top: 20px;\n    padding-bottom: 12px;\n}\n.confirm-button{\n    background-color: #90CAFC;\n}\n\n.error{\n    color: red;\n}\n\n.numberExist{\n    color: red;\n    font-size: 12px;\n}\n\n.mainMeterNameHeader, .subMeterNameHeader {\n    padding-left: 10%;\n}\n\n.actualValue {\n    color: #333;\n    font-size: small;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
