import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { ExistingTenant } from "../entity/existing-tenant";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { GetPartitionsResponse } from "../entity/get-partitions-response";
import { LoginUser } from "../entity/login-user";
import { MessageInfo } from "../entity/message-info";
import { SelectedPartition } from "../entity/selected-partition";
import { TenantConfirmDialogInfo } from "../entity/tenant-confirm-dialog-info";
import { ToTenantMakeObject } from "../entity/to-tenant-make-object";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { TenantConfirmDialogComponent } from "../tenant-confirm-dialog/tenant-confirm-dialog.component";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-tenant-make-dialog",
  templateUrl: "./tenant-make-dialog.component.html",
  styleUrls: ["./tenant-make-dialog.component.scss"],
})
export class TenantMakeDialogComponent implements OnInit {
  partitionList: SelectedPartition[];
  tenantList: ExistingTenant[];
  selectedPartition = new UntypedFormControl();
  selectedTenant = new UntypedFormControl();
  tenantName: string;
  tenantNote: string;
  contractedAt: Date;
  minDate: Date;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;
  getPartitionsListUrl: string;
  getPartitionsResponse: GetPartitionsResponse;
  isCardDisplayBoolean = true;
  isMakingNewTenant = true;
  isExistTenantNow = false;
  notExistTenantPartition = false;
  canChooseDate = false;
  idToken: string;
  site: LoginUserSite;

  initialValue = this.fb.group({
    items: this.fb.array([]),
  });
  isInitialValue = false;
  constructor(
    public dialogRef: MatDialogRef<TenantMakeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public allTenantObject: ToTenantMakeObject,
    public tenantConfirmDialog: MatDialog,
    dateAdapter: DateAdapter<NativeDateAdapter>,
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    dateAdapter.setLocale("ja");
  }

  ngOnInit() {
    this.site = this.data.site;
    this.partitionList = [];
    this.tenantList = [];
    for (const tenant of this.data.allTenantObject.allTenantList) {
      const existingTenant = new ExistingTenant(tenant.id, tenant.name);
      this.tenantList.push(existingTenant);
    }
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.doGetLoginUser();
        this.addForm();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  checkInitialValue() {
    for (const val of this.initialValues.value) {
      if (
        val.meterLabel === undefined ||
        val.meterLabel === null ||
        val.meterLabel === "" ||
        val.meterValue === undefined ||
        val.meterValue === null ||
        val.meterValue === ""
      ) {
        this.isInitialValue = false;
        return;
      }
    }
    this.isInitialValue = true;
  }

  // 追加分の入力フォーム
  get optionForm(): UntypedFormGroup {
    return this.fb.group({
      meterLabel: [],
      meterValue: [],
    });
  }

  // 可変枠の抽出
  get initialValues(): UntypedFormArray {
    return this.initialValue.get("items") as UntypedFormArray;
  }

  // 追加ボタン押下時
  addForm() {
    this.initialValues.push(this.optionForm);
    this.isInitialValue = false;
  }

  // 削除ボタン押下時
  removeForm(idx: number) {
    this.initialValues.removeAt(idx);
  }

  // ログインユーザーの取得処理（区画一覧の取得に施設IDが必要）
  // リファクタで呼び出し元（レポート画面）から施設IDは連携してくるようにする
  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          this.loginUser = response.result.login_user;
          this.getPartitionsList();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.dialogRef.close("lockedAccount");
          } else {
            alert(
              "ログイン名が取得できませんでした。再度ログインしてください。"
            );
            this.dialogRef.close("failed");
          }
          this.onClickLogout();
        }
      );
  }

  // 区画一覧取得処理
  getPartitionsList() {
    const url = `${environment.apiUrl}/sites/${Const.site_id}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          for (const floor of response["result"]["site"]["floors"]) {
            for (const partition of floor["partitions"]) {
              let meterLabels = [];

              for (const meter of partition["meters"]) {
                meterLabels.push(meter["label"]);
              }
              const partitionInfo = new SelectedPartition(
                partition.id,
                partition.name,
                meterLabels
              );
              this.partitionList.push(partitionInfo);
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("区画一覧が取得できませんでした。");
          }
        }
      );
  }

  formSetup() {
    this.initialValueSetup(this.selectedPartition.value);
    this.calenderRegulate();
  }

  initialValueSetup(partitionId: string) {
    let itemsTemp = this.fb.array([]);
    for (const partition of this.partitionList) {
      if (partition.partitionId === partitionId) {
        if (partition.meterLabels.length > 0) {
          for (const meterLabel of partition.meterLabels) {
            itemsTemp.push(
              this.fb.group({
                meterLabel: meterLabel,
                meterValue: [],
              })
            );
          }
          this.initialValue.setControl("items", itemsTemp);
        } else {
          itemsTemp.push(this.optionForm);
          this.initialValue.setControl("items", itemsTemp);
          this.isInitialValue = true;
        }
      }
    }
  }

  // 選択区画の変更に伴って、カレンダーの選択可能日付を編集する（minDateの書き換え）
  calenderRegulate() {
    this.checkExistTenantNow(this.selectedPartition.value);
    const lastCancellDate = this.getLastCancellDate(
      this.selectedPartition.value
    );
    if (lastCancellDate) {
      lastCancellDate.setDate(lastCancellDate.getDate() + 1);
      this.minDate = lastCancellDate;
    }
  }

  // 区画IDを引数に、現在テナントがいる区画かどうか判定する(いれば日付選択可能)
  // テナント一覧で表示されている各区画の最新テナント（一覧で一番下に表示）のうち、解約日が記入されているものだけ日付選択が可能になる
  checkExistTenantNow(partitionId: string) {
    const tenantRowsSamePartition = [];
    for (const tenantRow of this.data.allTenantObject.tenantData) {
      if (partitionId === tenantRow.partitionId) {
        tenantRowsSamePartition.push(tenantRow);
      }
    }
    if (tenantRowsSamePartition.length > 0) {
      if (
        !tenantRowsSamePartition[tenantRowsSamePartition.length - 1].cancelledAt
      ) {
        this.isExistTenantNow = true;
        this.canChooseDate = false;
        this.notExistTenantPartition = false;
      } else {
        this.isExistTenantNow = false;
        this.canChooseDate = true;
        this.notExistTenantPartition = false;
      }
    } else {
      this.isExistTenantNow = false;
      this.canChooseDate = true;
      this.notExistTenantPartition = false;
    }
  }

  // 区画IDを引数に、その区画の最新の解約日を取得する
  getLastCancellDate(partitionId: string) {
    let lastCancellDate: Date;
    for (const tenantRow of this.data.allTenantObject.tenantData) {
      if (partitionId === tenantRow.partitionId) {
        if (!lastCancellDate) {
          // 最初にここにくるテナントが未契約の場合（運用上はありえないはず）、契約日制御入っていなかったのを修正
          if (tenantRow.cancelledAt) {
            lastCancellDate = new Date(tenantRow.cancelledAt);
          }
        }
        if (lastCancellDate <= new Date(tenantRow.cancelledAt)) {
          lastCancellDate = new Date(tenantRow.cancelledAt);
        }
      }
    }
    return lastCancellDate;
  }

  // boolean型のカード表示フラグをnumber型に変換する
  changeIsCardDisplayFormat(isCardDisplayBoolean: boolean): number {
    if (isCardDisplayBoolean === true) {
      return 1;
    } else {
      return 0;
    }
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // ログインボタンの制御:TRUE→活性
  checkIfReadyToGo() {
    return (
      (this.isMakingNewTenant &&
        this.tenantName &&
        this.contractedAt &&
        this.isInitialValue) ||
      (!this.isMakingNewTenant &&
        this.selectedTenant.value &&
        this.contractedAt &&
        this.isInitialValue)
    );
  }

  // 新規作成（確定）ボタンが押された時の処理
  onClickConfirm() {
    if (
      !this.isExistTenantNow &&
      this.selectedPartition.value &&
      this.contractedAt
    ) {
      if (this.selectedTenant.value || this.tenantName) {
        const messageInfo = new TenantConfirmDialogInfo(
          "新規登録確認",
          "テナントを新規登録します。よろしいですか。"
        );
        const tenantConfirmDialogRef = this.tenantConfirmDialog.open(
          TenantConfirmDialogComponent,
          {
            width: "650px",
            data: messageInfo,
          }
        );
        tenantConfirmDialogRef.afterClosed().subscribe((result) => {
          if (result === "Yes") {
            const lastCancellDate = this.getLastCancellDate(
              this.selectedPartition.value
            );
            // 同日に契約開始・終了があった場合に登録できるよう、比較時のみ契約日を24時間分補正
            const contractedDate = new Date(this.contractedAt);
            contractedDate.setHours(contractedDate.getHours() + 24);
            if (contractedDate <= lastCancellDate) {
              const errorMessageInfo = new MessageInfo(
                "契約開始日が旧テナントの解約日前です。 再設定してください。",
                ""
              );
              const dialogRef = this.dialog.open(MessageDialogComponent, {
                width: "400px",
                data: errorMessageInfo,
              });
            } else {
              if (!this.tenantNote) {
                this.tenantNote = "";
              }
              let postTenantUrl = `${environment.apiUrl}/partitions/tenants`;
              let params = new HttpParams();
              params = params.set("partition_id", this.selectedPartition.value);
              params = params.set("note", this.tenantNote);
              params = params.set(
                "initial_value",
                JSON.stringify(this.initialValues.value)
              );
              params = params.set(
                "contracted_at",
                String(
                  formatDate(
                    this.contractedAt,
                    "yyyy-MM-dd hh:mm:ss",
                    this.locale
                  )
                )
              );
              params = params.set(
                "is_card_display",
                String(
                  this.changeIsCardDisplayFormat(this.isCardDisplayBoolean)
                )
              );
              // 新規テナント作成時の処理
              if (this.isMakingNewTenant) {
                params = params.set("tenant_name", this.tenantName);
              } else {
                // 既存テナント作成時の処理
                params = params.set("tenant_id", this.selectedTenant.value);
                postTenantUrl = `${environment.apiUrl}/partitions_tenants`;
              }
              this.httpClient
                .post(postTenantUrl, params, {
                  headers: new HttpHeaders({
                    Authorization: this.idToken,
                  }),
                })
                // レスポンスクラス未実装
                .subscribe(
                  (response) => {
                    this.dialogRef.close("close");
                  },
                  (err) => {
                    if (err.error.code == "AccountLockError") {
                      alert(
                        "アカウントがロックされました。管理者までお問合せください"
                      );
                      Const.release();
                      this.auth.signOut();
                      this.dialogRef.close("lockedAccount");
                    } else {
                      alert("テナントの登録に失敗しました。");
                    }
                  }
                );
            }
          }
        });
      }
    }
  }
}
