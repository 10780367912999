import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { CompanyInfo } from '../entity/get-company-info';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { SiteInfo } from '../entity/get-site-info';
import { GetUserWithSitesLinksInfo, UserSite } from '../entity/get-user-with-sites-links-info';
import { LoginUser } from '../entity/login-user';
import { UserConfirmDialogInfo } from '../entity/user-confirm-dialog-info';
import { UserChangePasswordDialogComponent } from '../user-change-password-dialog/user-change-password-dialog.component';
import { UserSiteLinksSelectDialogComponent } from '../user-site-links-select-dialog/user-site-links-select-dialog.component';

interface UserType {
  value: number;
  viewValue: string;
}

export class CheckSiteInfo {
  checked = false;
  siteId: string;
  siteName: string;
  siteAddress: string;
  companyName: string;
}

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {

  idToken: string;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;

  userTypeList: UserType[] = [
    {value: 1, viewValue: '管理者'},
    {value: 2, viewValue: '一般'},
  ];

  // 施設情報
  siteData: SiteInfo[];
  isReadySiteData = false;
  // ユーザー施設紐付情報
  userSiteLinksData: UserSite[] = new Array();
  isReadyUserSiteLinksData = false;
  // 会社情報
  companyData: CompanyInfo[] = new Array();
  isReadyCompanyData = false;
  // 施設選択情報
  selectSite: string;
  selectSiteInfoList: UserSite[] = new Array();
  siteInfoList: UserSite[] = new Array();
  checkSiteInfoList: CheckSiteInfo[] = new Array();
  // 入力項目
  userName: string;
  selectedCompnay = new UntypedFormControl({value: '', disabled: true});
  selectedUserType = new UntypedFormControl();
  errorMessage: string;
  hasError = false;
  isFormReady = false;
  isUserLocked = false;

  userTypeSysAdmin = '0';
  userTypeAdmin = '1';
  userTypeUser = '2';

  @Input() public company: CompanyInfo[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: GetUserWithSitesLinksInfo,
    private httpClient: HttpClient,
    private router: Router,
    public dialogRef: MatDialogRef<UserEditDialogComponent>,
    public userConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService) { }

  ngOnInit() {
    this.selectSite = '施設選択';
    if (this.user.sites.length > 1) {
      this.selectSite = this.user.sites[0].siteName + '他' + (this.user.sites.length - 1) + '件';
    } else if (this.user.sites.length === 1) {
      this.selectSite = this.user.sites[0].siteName;
    }
    this.selectSiteInfoList = [];
    this.checkSiteInfoList = [];
    this.companyData = [];
    this.selectedCompnay.setValue(this.user.companyId);
    this.selectedUserType.setValue(this.user.userType);
    this.userName = this.user.name;
    this.isUserLocked = this.user.lockStatus;
    this.auth.getIdToken()
      .subscribe(
          result => {
            if (result) {
              this.idToken = result;
              this.doGetLoginUser();
              this.loadCompany();
              this.loadUserSiteLinks();
            } else {
              // idトークンがnullの場合はログイン画面へ遷移
              alert('セッションが切れています。再度ログインしてください。');
              this.router.navigate(['login']);
            }
          }
      );
  }

  // ログインユーザーの取得処理（区画一覧の取得に施設IDが必要）
  // リファクタで呼び出し元（レポート画面）から施設IDは連携してくるようにする
  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
      .subscribe((response: GetLoginUserResponse) => {
        this.loginUser = response.result.login_user;
        if (response.result.login_user.user_type == this.userTypeSysAdmin) {
          this.userTypeList.push({value: 0, viewValue: 'MJIT'});
        }
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            this.onClickLogout();
          }else{
            alert('ログイン名が取得できませんでした。再度ログインしてください。');
            Const.release();
            this.auth.signOut();
            this.dialogRef.close('failed');
          }
          
        }
      );
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
    this.dialogRef.close('lockedAccount');
  }

  // 施設選択
  onClickSelectSite() {
    const dialogRef = this.dialog.open(UserSiteLinksSelectDialogComponent, {
      width: '1000px',
      data: this.user.sites // ユーザーに紐づいている施設
    });
    dialogRef.componentInstance.checkSiteInfoList = this.checkSiteInfoList;
    dialogRef.componentInstance.siteInfo = this.siteInfoList; // ユーザーが選択可能な施設の全量
    dialogRef.componentInstance.company = this.company;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        // 閉じるボタンの処理
        if (result === '') {
          return;
        }
        // 選択した施設を表示
        if (result.length === 1) {
          // 表示施設
          this.selectSite = result[0].siteName;
          this.selectSiteInfoList = result as UserSite[];
          this.checkSiteInfoList = result;
        }
        if (result.length > 1) {
          // 表示施設
          this.selectSite = result[0].siteName + '他(' + (result.length - 1) + ')';
          this.selectSiteInfoList = result as UserSite[];
          this.checkSiteInfoList = result;
        }
        if (result.length === 0) {
          this.checkSiteInfoList = [];
          this.selectSiteInfoList = [];
          // 表示施設 1:nの紐付きが前提の為元々の紐付きデータを表示する
          for (const site of this.user.sites) {
            const checkSiteInfoItem = new CheckSiteInfo();
            checkSiteInfoItem.checked = true;
            checkSiteInfoItem.siteId = site.siteId;
            checkSiteInfoItem.siteName = site.siteName;
            checkSiteInfoItem.siteAddress = site.siteAddress;
            checkSiteInfoItem.companyName = site.companyName;
            this.checkSiteInfoList.push(checkSiteInfoItem);
            this.selectSiteInfoList.push(checkSiteInfoItem);
          }
          if (this.selectSiteInfoList.length === 1) {
            // 表示施設
            this.selectSite = this.selectSiteInfoList[0].siteName;
          }
          if (this.selectSiteInfoList.length > 1) {
            // 表示施設
            this.selectSite = this.selectSiteInfoList[0].siteName + '他(' + (this.selectSiteInfoList.length - 1) + ')';
          }
        }
      }
    });
  }

  // 所管会社の取得（UserType:0はシステム管理者として登録されている会社を全件取得する。UserType:1また2は自身の所属会社のみ）
  loadCompany() {
    this.isReadyCompanyData = false;
    let companyId = 'all';
    if (this.user.userType === 1 || this.user.userType === 2) { // 1:Admin 2:一般ユーザ
      companyId = this.user.companyId;
    }
    // 会社を取得する（allの場合全会社）
    const url = `${environment.apiUrl}/company/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.companies;
      this.companyData.push(data);
      this.isReadyCompanyData = true;
      this.loadSite();
    },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
          this.onClickLogout();
        }else{
          alert('会社情報が取得できませんでした。');
        }
    });
  }

  // 施設の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。UserType:1また2は自身の所属会社の所管施設のみ）
  loadSite() {
    this.isReadySiteData = false;
    let companyId = 'all';
    if (this.user.userType === 1 || this.user.userType === 2 ) { // 1:Admin 2:一般ユーザ
      companyId = this.user.companyId;
    }
    // 施設を取得する（allの場合全施設）
    const url = `${environment.apiUrl}/sites/sites_info_all/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.sites;
      this.siteData = data;
      this.isReadySiteData = true;
      this.siteCompnayLinks();
    },
      () => {
      alert('施設情報が取得できませんでした。');
    });
  }

  siteCompnayLinks() {
    for (const site of this.siteData) {
      const siteInfo = new UserSite();
      const companyItem = this.companyData.filter(item => {
        return item.id === site.company_id;
      });
      if (this.user.userType !== 0 && this.user.companyId === companyItem[0].id ) {
        siteInfo.siteId = site.id;
        siteInfo.siteName = site.name;
        siteInfo.siteAddress = site.address;
        siteInfo.companyName = companyItem[0].name;
        this.siteInfoList.push(siteInfo);
      }
    }
  }

  // ユーザーの紐付情報を取得
  loadUserSiteLinks() {
    this.isReadyUserSiteLinksData = false;
    // 施設を取得する（allの場合全施設）
    const url = `${environment.apiUrl}/user_site_links/1/${this.user.id}/user_site_links_info`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.user_site_links;
      for (const dataItem of data) {
        const siteInfo = new UserSite();
        siteInfo.siteId = dataItem.site.id;
        siteInfo.siteName = dataItem.site.name;
        this.userSiteLinksData.push(siteInfo);
      }
      if (this.userSiteLinksData.length > 0) {
        this.selectSiteInfoList = this.userSiteLinksData;
      }
      this.isReadyUserSiteLinksData = true;
    });
  }

  // 更新ボタンの活性化制御
  checkIfReadyToGo() {
    this.isFormReady = this.userName !== undefined && this.userName !== '' &&
                        this.selectedUserType.value !== null;
    return !this.hasError && this.isFormReady;
  }

 // ユーザーのLockStatus 更新
  onClickUnlockUser(){
    // 確認ダイアログ
    const messageInfo = new UserConfirmDialogInfo('ロック解除確認', 'ユーザーロック解除します。よろしいですか。');
    const confirmDialog = this.userConfirmDialog.open(CommonConfirmDialogComponent, {
        width: '400px',
        data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        // APIコールして更新処理
        const userID = this.user.loginId;
        const url = `${environment.apiUrl}/account_lock/${userID}/0`;
        this.httpClient.patch(url, '',
          {
            headers: new HttpHeaders({
              'x-api-key': environment.apiKey,
              'Content-Type': 'application/json'
            })
          }
        )
        .subscribe((response) => {
          this.dialogRef.close('close');
        }, err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            this.onClickLogout()
          }
        });
      }
    });
  }

  // パスワード変更
  onClickChangePassword() {
    const dialogRef = this.dialog.open(UserChangePasswordDialogComponent, {
      width: '800px',
      data: this.user
    });
    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'lockedAccount') {
        this.onClickLogout()
      }
    });
  }

  // ユーザー更新
  onClickConfirm() {
    if (!navigator.onLine) {
      alert('ネットワーク接続を確認してください');
      return;
    }

    // 入力チェック
    this.errorMessage = this.checkValue();
    if (this.errorMessage.length > 0) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }

    // if (!checkUserTypeResult) {
    //   return;
    // }

    // 施設選択をしていない場合エラー
    if (this.selectSite === '施設選択') {
      alert('施設は１つ以上選択してください。');
      return;
    }

    // 自身を変更する際ユーザー権限を一般ユーザーにした場合のチェック
    if (this.loginUser.id === this.user.id && this.selectedUserType.value === 2) {
      // 権限変更確認ダイアログ
      const userTypeMessageInfo = new UserConfirmDialogInfo('権限変更確認', '自身のユーザー権限を一般に変更すると管理機能が使用できなくなります。よろしいですか。');
      const userTypeConfirmDialog = this.userConfirmDialog.open(CommonConfirmDialogComponent, {
          width: '400px',
          data: userTypeMessageInfo
      });
      userTypeConfirmDialog.afterClosed().subscribe(userTypeConfirmDialogResult => {
        if (userTypeConfirmDialogResult === 'No') {
          return;
        } else {
          // 確認ダイアログ
          const messageInfo = new UserConfirmDialogInfo('更新確認', 'ユーザーを更新します。よろしいですか。');
          const confirmDialog = this.userConfirmDialog.open(CommonConfirmDialogComponent, {
              width: '400px',
              data: messageInfo
          });

          confirmDialog.afterClosed().subscribe(result => {
            if (result === 'Yes') {
              // APIコールして更新処理
              const patchUserUrl = `${environment.apiUrl}/users/users_info/1/${this.user.id}`;
              let params = new HttpParams();
              params = params.set('name', this.userName);
              params = params.set('company_id', this.user.companyId);
              params = params.set('user_type', this.selectedUserType.value);
              this.httpClient.patch(patchUserUrl, params,
                {
                  headers: new HttpHeaders({
                    Authorization: this.idToken
                  })
                }
              )
              .subscribe((response) => {
                const jsonStr = JSON.stringify(response);
                const jsonObj = JSON.parse(jsonStr);
                const data = jsonObj.result.user;
                // ユーザー施設紐付けを行う
                this.updateUserSitelinks();
                this.dialogRef.close('close');
              },
              err => {
                if(err.error.code == "AccountLockError"){
                  alert('アカウントがロックされました。管理者までお問合せください');
                  this.onClickLogout()
                }else{
                  alert('ユーザー情報の更新に失敗しました。');
                }
              });
            }
          });
        }
      });
    } else {
      // 確認ダイアログ
      const messageInfo = new UserConfirmDialogInfo('更新確認', 'ユーザーを更新します。よろしいですか。');
      const confirmDialog = this.userConfirmDialog.open(CommonConfirmDialogComponent, {
          width: '400px',
          data: messageInfo
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === 'Yes') {
          // APIコールして更新処理
          const patchUserUrl = `${environment.apiUrl}/users/users_info/1/${this.user.id}`;
          let params = new HttpParams();
          params = params.set('name', this.userName);
          params = params.set('company_id', this.user.companyId);
          params = params.set('user_type', this.selectedUserType.value);
          this.httpClient.patch(patchUserUrl, params,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken
              })
            }
          )
          .subscribe((response) => {
            const jsonStr = JSON.stringify(response);
            const jsonObj = JSON.parse(jsonStr);
            const data = jsonObj.result.user;
            // ユーザー施設紐付けを行う
            this.updateUserSitelinks();
            this.dialogRef.close('close');
          },
          err => {
            if(err.error.code == "AccountLockError"){
              alert('アカウントがロックされました。管理者までお問合せください');
              this.onClickLogout()
            }else{
              alert('ユーザー情報の更新に失敗しました。');
            }
          });
        }
      });
    }
  }

  // 入力チェック
  checkValue(): string {
    let errorMessage = 'を入力してください';
    if (this.userName === undefined || this.userName === '') {
      errorMessage = 'ユーザー名' + errorMessage;
      return errorMessage;
    }
    if (this.selectedUserType.value === null) {
      errorMessage = 'ユーザー権限' + errorMessage;
      return errorMessage;
    }
    return '';
  }

  // 取得時のの紐付け情報と比較を行い、増減によりユーザーサイト紐付けの更新を行う
  updateUserSitelinks() {
    // 元々の紐付けと更新時の紐付けを比較
    const selectedList: UserSite[] = new Array();
    for (const selectSiteInfoListItem of this.selectSiteInfoList) {
      const siteInfo = new UserSite();
      siteInfo.siteId = selectSiteInfoListItem.siteId;
      siteInfo.siteName = selectSiteInfoListItem.siteName;
      selectedList.push(siteInfo);
    }
    let updateDelteList: UserSite[] = new Array();
    let updateAddList: UserSite[] = new Array();
    // selectSiteInfoList > 0 必ず成立なので、selectedList > 0
    // userSiteLinksData < selectedList部分 --> 差分検出してselectedListに差分がある場合 --> 差分追加
    // A | A,B and - | A
    updateAddList = selectedList.filter(i => JSON.stringify(this.userSiteLinksData).indexOf(JSON.stringify(i)) === -1);
    if (updateAddList.length > 0) {
      for (const updateAddItem of updateAddList) {
            const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
            let params = new HttpParams();
            params = params.set('user_id', this.user.id);
            params = params.set('site_id', updateAddItem.siteId);
            this.httpClient.post(
              posthUserUrl,
              params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken
                })
              }
            )
            .subscribe((response) => {
            }, err => {
              if(err.error.code == "AccountLockError"){
                alert('アカウントがロックされました。管理者までお問合せください');
                this.onClickLogout()
              }else{
                alert('施設の紐付けに失敗しました');
              }
            });
      }
    }
    // userSiteLinksData > selectedList部分 --> 差分検出してuserSiteLinksDataに差分がある場合 --> 差分削除
    // A,B | A
    updateDelteList = this.userSiteLinksData.filter(i => JSON.stringify(selectedList).indexOf(JSON.stringify(i)) === -1);
    if (updateDelteList.length > 0) {
      for (const updateDelteItem of updateDelteList) {
        const patchUserUrl = `${environment.apiUrl}/user_site_links/delete_user_site_links_info`;
        let updateDelteParams = new HttpParams();
        updateDelteParams = updateDelteParams.set('user_id', this.user.id);
        updateDelteParams = updateDelteParams.set('site_id', updateDelteItem.siteId);
        this.httpClient.patch(
          patchUserUrl,
          updateDelteParams,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe((response) => {
        }, err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            this.onClickLogout()
          }else{
            alert('施設の紐付削除に失敗しました');
          }
        });
      }
    }

    // userSiteLinksData === selectedList --> 何もしない
    // - | -
    // A | A
    
    return;
  }
}
