import { idName } from "./id-name";
import { Tenant } from "./tenant";

export class MeterInfo {
  id: string;
  name: string;
  label: string;
  site_id: string;
  partName: string;
  partition_id: string;
  floor_id: string;
  floor_name: string;
  meter_type_id: string;
  unit_id: string;

  created_at: string;
  updated_at: string;
  cancelled_at: string;
  tenantName: string;

  tenants: Tenant[];
  no: number = 0;

  analysis_flg: boolean;
  is_card_display: boolean;
  is_reviewed: boolean;
  is_reread: boolean;
  expiration_date: string;
  isExpireMeterFlag: boolean = false;

  number_of_digits: number = 0;
  decimal_point_position: number = 0;
  max_value: number = 0;
  min_value: number = 0;
  max_angle: number = 0;
  min_angle: number = 0;

  initial_value: number = 0;
  closed_value: number = 0;

  // -1：unkonwn
  // 0 : for update
  // 1 ： for add new
  // 2 ： error meter
  state: number = -1;
  errMsg: string = "";

  statusCode: number;
  responseMsg: string;

  createdBy: idName;
  updatedBy: idName;
}
