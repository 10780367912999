import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { GetReportDataResponse } from "src/app/entity/get-report-data-response";
import { ReportCard } from "src/app/entity/report-card";
import { ReportSite } from "src/app/entity/report-site";
import { environment } from "src/environments/environment";
import { MeterCardListCommonService } from "./meter-card-list-common.service";
import { GetReportListReportList } from "src/app/entity/get-report-list-report-list";
import { GetReportListResponse } from "src/app/entity/get-report-list-response";
import { formatDate } from "@angular/common";
import { ReportPartitionsTenant } from "src/app/entity/report-partitions-tenant";
import { ReportTenant } from "src/app/entity/report-tenant";
import { Meter } from "src/app/entity/meter";
import { ReportMeters } from "src/app/entity/report-meters";
import { LastMonthValueConfirmation } from "src/app/entity/last-month-value-confirmation";

/**
 * レポート未確定時のデータ取得クラス
 */
@Injectable()
export class MeterCardListPendingService {
  constructor(
    private httpClient: HttpClient,
    private meterCardListCommonService: MeterCardListCommonService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * 新規レポートデータからカード表示データを作成する
   * @param apiToken(string): APIトークン
   * @param floorId(string): 現在のフロアID
   * @param siteId(string): 現在のSiteID
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @returns 作成した区画リスト: Promise<ReportCard[]>
   */
  async createPartitionsArrayForReportData(
    apiToken: string,
    floorId: string,
    siteId: string,
    selectedMonth: string
  ): Promise<ReportCard[]> {
    let result: ReportCard[] = [];

    try {

      // レポートリストの取得
      const reportList = await this.getReportList(apiToken, siteId);

      // 前月のレポート確定日を特定
      const lastMonthConfirmDate = this.getLastMonthConfirmDate(reportList, selectedMonth);

      // APIのコール
      const reportSite = await this.getReportData(
        apiToken,
        siteId,
        selectedMonth,
        lastMonthConfirmDate
      );

      // 現在のフロア情報を取得
      const currentFloor = reportSite.floors.find(
        (value) => value.id == floorId
      );

      currentFloor.partitions.forEach((partition) => {
        // 対象でなければスキップ
        if (!this.meterCardListCommonService.shouldCreateCardData(partition)) {
          return; // continueする
        }

        partition.partitions_tenants.forEach((partitionsTenants) => {
          // 1行分を作成
          const meters = this.createReportCardRow(
            partitionsTenants.tenant.id,
            partition.meters,
            partition.partitions_tenants
          );

          const row = new ReportCard();
          row.partition_id = partition.id;
          row.partition_name = partition.name;
          row.tenant_id = partitionsTenants.tenant.id;
          row.tenant_name = partitionsTenants.tenant.name;
          row.meters = meters;

          result.push(row);
        });
      });
    } catch (error) {
      throw error;
    }

    console.log("pending:", result)
    return result;
  }

  /**
   * レポートリストから前月の確定日を取得
   * @param reportList(GetReportListReportList[]): レポートリスト
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @returns 前月の確定日: string(yyyyMMdd)
   */
  private getLastMonthConfirmDate(
    reportList: GetReportListReportList[],
    selectedMonth: string
  ): string {
    // 選択した年月の前月の日付型変数を用意
    // JSやTSのDateのMonth部分は月ではなく、月に該当するIndex（0始まり）なので−1した後に−1をsetする
    let lastMonthDate = new Date(Number(selectedMonth.substring(0, 4)), Number(selectedMonth.substring(4)) - 1, 1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    // 返却値、初期値は選択した年月の前月月初にする
    let result = formatDate(lastMonthDate, "yyyyMMdd", this.locale);

    //前の月の確定日を抽出
    if (reportList.length > 0) {
      const lastMonthReportInfo = reportList.find(
        (element) => element.report_month == formatDate(lastMonthDate, "yyyyMM", this.locale)
      );

      if (lastMonthReportInfo) {
        result =
          lastMonthReportInfo.confirmed_report_created_at == null ||
          lastMonthReportInfo.confirmed_report_created_at == undefined
            ? result
            : formatDate(lastMonthReportInfo.confirmed_report_created_at, "yyyyMMdd", this.locale);
      }
    }
    return result;
  }

  /**
   * レポートリストを取得
   * @param apiToken(string): APIトークン
   * @param siteId(string): 現在のSiteID
   */
  private async getReportList(apiToken: string, siteId: string): Promise<GetReportListReportList[]> {
    const url = `${environment.apiUrl}/report_list/${siteId}`;

    try {
      const response = await this.httpClient
        .get<GetReportListResponse>(url, {
          headers: new HttpHeaders({
            Authorization: apiToken,
          }),
        })
        .toPromise();

      return response.result.report_list;
    } catch (error) {
      throw error;
    }
  }

  /**
   * APIからデータを取得する
   * @param apiToken(string): APIトークン
   * @param siteId(string): 現在のSiteID
   * @param selectedMonth(string): 現在の年月（yyyyMM）
   * @param lastMonthConfirmDate(string): 前月のレポート確定日（yyyyMMdd）
   * @returns APIの結果のSite以下: Promise<ReportSite>
   */
  private async getReportData(
    apiToken: string,
    siteId: string,
    selectedMonth: string,
    lastMonthConfirmDate: string
  ): Promise<ReportSite> {
    const url = `${environment.apiUrl}/sites/${siteId}/partitions/meters/${selectedMonth}/value_confirmations/${lastMonthConfirmDate}`;

    try {
      const response = await this.httpClient
        .get<GetReportDataResponse>(url, {
          headers: new HttpHeaders({
            Authorization: apiToken,
          }),
        })
        .toPromise();

      return response.result.site;
    } catch (error) {
      throw error;
    }
  }

  /**
   * カード表示用データのメーター部分を作成する
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters[]): メーターリスト
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns カード表示用データのメーター部分: Meter[]
   */
  private createReportCardRow(tenantId: string, meters: ReportMeters[], partitionsTenants: ReportPartitionsTenant[]): Meter[] {
    // metersの型変換
    let result: Meter[] = [];
    meters.forEach((meter) => {

      // フラグ設定
      const isOldTenant = this.isOldTenant(tenantId, partitionsTenants);
      const hasOldTenant = this.hasOldTenant(tenantId, partitionsTenants);
      
      // テナント登録時のメーター値
      const tenantInitialValue = this.getMeterValueAtTenantRegistration(tenantId, meter, partitionsTenants);

      // 先月確定値の作成
      const lastMonthValueConfirmation = this.getLastMonthValueConfirmation(
        meter,
        tenantId,
        isOldTenant,
        hasOldTenant,
        tenantInitialValue
      );

      // 今月確定値の作成
      const valueDetections = this.meterCardListCommonService.getValueDetections(tenantId, meter);

      // 終了値
      const closedValue = this.judgeClosedValue(tenantId, meter, partitionsTenants);

      result.push(
        new Meter(
          meter.id,
          meter.name,
          meter.label,
          meter.number_of_digits,
          meter.decimal_point_position.toString(),
          closedValue,
          meter.is_reviewed == "1",
          meter.created_at,
          meter.updated_at,
          meter.unit,
          lastMonthValueConfirmation,
          closedValue == "" ? valueDetections : []  // 当月確定値は終了値が存在する場合表示させない
        )
      );
    });
    return result;
  }

  /**
   * 自分が旧テナントかどうかの判定
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントかどうか: boolean
   */
  private isOldTenant(currentTenantId: string, partitionsTenants: ReportPartitionsTenant[]): boolean{

    // テナントが1つしかない場合
    if (partitionsTenants.length == 0){
      return false;
    }

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach(partitionsTenant => {
      tenants.push(partitionsTenant.tenant);
    });

    // 上記テナントオブジェクトを作成日時の昇順でソート
    tenants.sort((a, b) => Number(new Date(a.created_at)) - Number(new Date(b.created_at)));

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(tenant => tenant.id == currentTenantId);

    // テナントの数−1であれば（インデックスが最大であれば）false、それ以外はtrue
    return currentTenantIndex != tenants.length - 1;
  }

  /**
   * 自分が旧テナントを持っているか
   * @param currentTenantId(string): 確認対象のテナントID
   * @param partitionsTenants(ReportPartitionsTenant[]): 元データのpartitions_tenants
   * @returns 旧テナントを持っているか: boolean
   */
  private hasOldTenant(currentTenantId: string, partitionsTenants: ReportPartitionsTenant[]): boolean{

    // テナントが1つしかない場合
    if (partitionsTenants.length == 0){
      return false;
    }

    // tenantだけを抽出
    let tenants: ReportTenant[] = [];
    partitionsTenants.forEach(partitionsTenant => {
      tenants.push(partitionsTenant.tenant);
    });

    // 上記テナントオブジェクトを作成日時の昇順でソート
    tenants.sort((a, b) => Number(new Date(a.created_at)) - Number(new Date(b.created_at)));

    // 現在のテナントIDのインデックスを特定
    const currentTenantIndex = tenants.findIndex(tenant => tenant.id == currentTenantId);

    // インデックスが0（一番古いデータ）でなければtrue
    return currentTenantIndex != 0;
  }

  /**
   * テナント登録時のメーター初期値を取得
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 取得した初期値
   */
  private getMeterValueAtTenantRegistration(tenantId: string, meter: ReportMeters, partitionsTenants: ReportPartitionsTenant[]): string {
    let result = "";

    partitionsTenants.some(partitionsTenant => {
      if (partitionsTenant.tenant && partitionsTenant.tenant.initial_value && tenantId == partitionsTenant.tenant.id) {
        // JSONにパース
        const initialValues = JSON.parse(partitionsTenant.tenant.initial_value);
        const initialTenantMeterInfo = initialValues.find(value => value.meterLabel == meter.label);

        // 上記で見つかったらそのメーター値を返却値とする
        if (initialTenantMeterInfo) {
          result = initialTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }

  /**
   * 前月確定値のオブジェクトを作成する
   * 1. 自分が旧テナントでない（isOldTenant=false）&旧テナントを持っている（hasOldTenant=true）：*テナント登録時の初期値*
   * 2. 上記以外：*前回確定値*
   * 3. 前回確定値がない場合：*メーターの初期値*
   * 
   * @param meter(ReportMeters): 対象のメーター
   * @param tenantId(string): 対象のテナントID
   * @param isOldTenant(boolean): 旧テナントかどうか
   * @param hasOldTenant(boolean): 旧テナントを持っているかどうか
   * @param valueAtTenantRegistration(string): テナント登録時のメーター初期値
   * 
   * @returns 前月確定値情報: LastMonthValueConfirmation
   */
  private getLastMonthValueConfirmation(
    meter: ReportMeters,
    tenantId: string,
    isOldTenant: boolean,
    hasOldTenant: boolean,
    valueAtTenantRegistration: string
  ): LastMonthValueConfirmation {
    // 前月確定値は降順でソート
    const lastMonthValueDetections = meter.last_month_value_detections;
    lastMonthValueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    // 返却値。初期値はメーター初期値 or テナント登録時の初期値で設定（他はダミー）
    let result :LastMonthValueConfirmation;
    if (!isOldTenant && hasOldTenant && valueAtTenantRegistration !== "") {
      result =new LastMonthValueConfirmation(
        "",
        valueAtTenantRegistration,
        "",
        ""
      );
    } else {
      result =new LastMonthValueConfirmation(
        "",
        meter.initial_value,
        "",
        ""
      );
    }

    lastMonthValueDetections.some(detection => {

      if(detection.tenant_id == tenantId){

        // 前回確定値があるかどうか
        if (detection.value_confirmation) {

          // テナント登録時の初期値に該当するかどうか
          if (!isOldTenant && hasOldTenant && valueAtTenantRegistration !== ""){
            result = new LastMonthValueConfirmation(
              detection.value_confirmation.id,
              valueAtTenantRegistration,
              detection.value_confirmation.confirmation_channel,
              detection.value_confirmation.created_at
            );
          } else {
            // 上記以外は通常の前回確定値
            result = new LastMonthValueConfirmation(
              detection.value_confirmation.id,
              detection.value_confirmation.confirmed_value,
              detection.value_confirmation.confirmation_channel,
              detection.value_confirmation.created_at
            );
          }

          return true;  // ループ終了
        } else {
          return false;
        }
      }
    });
    return result;
  }

  /**
   * 解約時の検針値を決定する
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters): メーター
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns 判定後の解約時の検針値: string
   * - 解約がなければ空文字
   */
  private judgeClosedValue(tenantId: string, meter: ReportMeters, partitionsTenants: ReportPartitionsTenant[]): string {
    let result = "";

    // メーター情報にclosed_valueがあればそちらを優先
    if (meter.closed_value){
      return meter.closed_value;
    }

    // 上記でなければ、テナントのclosed_valueを検索
    partitionsTenants.some(partitionsTenant => {
      if (partitionsTenant.tenant && partitionsTenant.tenant.closed_value && tenantId == partitionsTenant.tenant.id) {
        // JSONにパース
        const closedValues = JSON.parse(partitionsTenant.tenant.closed_value);
        const closedTenantMeterInfo = closedValues.find(value => value.meterLabel == meter.label);

        // 上記で見つかったらそのメーター値をclosed_valueとする
        if (closedTenantMeterInfo) {
          result = closedTenantMeterInfo.meterValue;
          return true;
        } else {
          return false;
        }
      }
    });

    return result;
  }
}
