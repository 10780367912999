import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { formatDate } from "@angular/common";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { TenantRow } from "../entity/tenant-row";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { TenantEditDialogComponent } from "../tenant-edit-dialog/tenant-edit-dialog.component";
import { TenantDeleteDialogComponent } from "../tenant-delete-dialog/tenant-delete-dialog.component";
import { TenantMakeDialogComponent } from "../tenant-make-dialog/tenant-make-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { GetAllTenantsTenant } from "../entity/get-all-tenants-tenant";
import { GetAllTenantsResponse } from "../entity/get-all-tenants-response";
import { ToTenantMakeObject } from "../entity/to-tenant-make-object";
import { UntypedFormControl } from "@angular/forms";
import { LoginUserSite } from "../entity/login-user-site";
import { Const } from "../const/const";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";

@Component({
  selector: "app-master-edit",
  templateUrl: "./master-edit.component.html",
  styleUrls: ["./master-edit.component.scss"],
})
export class MasterEditComponent implements OnInit {
  selectedSite = new UntypedFormControl();
  selectedMonth = new UntypedFormControl(
    formatDate(new Date(), "yyyyMM", this.locale)
  );
  loginUser: LoginUser;
  getLoginUserUrl: string;
  getLoginUserResponse: GetLoginUserResponse;
  getTenantDataUrl: string;
  monthArray: string[];
  siteArray: LoginUserSite[];
  idToken: string;
  site = new LoginUserSite();

  allTenantsList: GetAllTenantsTenant[];
  getAllTenantsUrl: string;

  // テーブル生成用
  displayedColumns: string[];
  reportPartitions: GetTenantsPartition[];
  tenantData: TenantRow[];

  // フラグ類
  getUserFinished: boolean;
  getTenantFinished: boolean;
  showMakeButtonFlag: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public auth: AuthService,
    public tenantEditDialog: MatDialog,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string
  ) {
    localStorage.setItem("path", router.url);
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.siteArray = [];
    this.tenantData = [];
    this.allTenantsList = [];
    this.getTenantFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    this.monthArray = [];
    this.showMakeButtonFlag = false;
    this.makeMonthArray();
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.doGetAllTenantList();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("施設情報が取得できませんでした。");
          }
        }
      );
  }

  // 今月の値から2019年9月までの月情報を「yyyymm」形式の文字列の配列にする処理
  makeMonthArray() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    this.monthArray.push(thisMonth);
    const endMonth = new Date(2019, 9, 1);
    for (let a: number; endMonth <= dateData; a++) {
      dateData.setMonth(dateData.getMonth() - 1);
      const formatedMonth = formatDate(dateData, "yyyyMM", this.locale);
      this.monthArray.push(formatedMonth);
    }
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth =
      String(yearMonth).substr(0, 4) +
      "年" +
      String(yearMonth).substr(4) +
      "月";
    return formatedMonth;
  }

  doChangeMonth() {
    this.ngOnInit();
  }

  doGetAllTenantList() {
    this.doGetTenantData();
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  onClickTenantEdit(tenantRow: TenantRow) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.tenantEditDialog.open(TenantEditDialogComponent, {
      width: "650px",
      data: { tenantRow, site: this.site },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // 最小限の更新になるように要改良
        this.ngOnInit();
      }
    });
    console.log(tenantRow);
  }

  // 施設選択
  doChangeSite() {
    this.getTenantFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    this.showMakeButtonFlag = false;
    this.doGetAllTenantList();
    this.allTenantsList = [];
  }

  onClickTenantDelete(tenantRow: TenantRow) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.tenantEditDialog.open(TenantDeleteDialogComponent, {
      width: "650px",
      data: { tenantRow, site: this.site },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "close") {
        // 最小限の更新になるように要改良
        this.ngOnInit();
      }
    });
  }

  onClickTenantMake(tenantData: TenantRow[]) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const allTenantObject = new ToTenantMakeObject(
      this.allTenantsList,
      tenantData
    );
    const dialogRef = this.tenantEditDialog.open(TenantMakeDialogComponent, {
      width: "800px",
      data: { allTenantObject, site: this.site },
    });
    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
        // 最小限の更新になるように要改良
        this.ngOnInit();
      }
    });
  }

  doGetTenantData() {
    this.getTenantDataUrl = `${environment.apiUrl}/sites/${Const.site_id}/partitions/tenants/${this.selectedMonth.value}`;
    this.httpClient
      .get(this.getTenantDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.reportPartitions = response.result.site.partitions;
          this.makeRowData(this.reportPartitions);
          for (const partition of this.reportPartitions) {
            for (const partitionsTenants of partition.partitions_tenants) {
              if (partitionsTenants.tenant_log) {
                const tenantLog = partitionsTenants.tenant_log;
                const tenant: GetAllTenantsTenant = {
                  id: tenantLog.tenant_id,
                  name: tenantLog.name,
                  note: tenantLog.note,
                  created_at: tenantLog.tenant_created_at,
                  updated_at: tenantLog.tenant_updated_at,
                  updated_by: null,
                };
                this.allTenantsList.push(tenant);
              }
            }
          }
          this.allTenantsList = this.removeDuplicates(
            this.allTenantsList,
            "id"
          );
          this.displayedColumns = [
            "partition",
            "name",
            "isCardDisplay",
            "contractedAt",
            "note",
            "cancelledAt",
            "updatedBy",
            "updatedAt",
            "editButton",
            "deleteButton",
          ];
          this.getTenantFinished = true;
          this.selectedMonth.enable();
          this.selectedSite.enable();
        },
        () => {
          this.getTenantFinished = true;
          this.selectedMonth.enable();
          this.selectedSite.enable();
          alert("テナントデータが取得できませんでした。");
        }
      );
  }
  removeDuplicates<T>(list: T[], prop: keyof T): T[] {
    const seen = new Set<any>();
    return list.filter((obj) => {
      const value = obj[prop];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  }
  makeRowData(partitionArray: GetTenantsPartition[]) {
    this.tenantData.splice(0);
    if (partitionArray.length === 0) {
      this.showMakeButtonFlag = false;
      alert("区画情報が存在しません。\n区画を登録してください");
      return;
    } else {
      this.showMakeButtonFlag = true;
    }
    for (const partition of partitionArray) {
      partition.partitions_tenants.reverse();
      // テナント未契約の区画はelseに
      if (partition.partitions_tenants.length > 0) {
        for (const partitionsTenant of partition.partitions_tenants) {
          if (partitionsTenant.tenant_log) {
            const tenantRow = new TenantRow();
            // tenantRow.tenantId = partitionsTenant.tenant.id;
            tenantRow.tenantId = partitionsTenant.tenant_log.tenant_id;
            if (partition.name) {
              tenantRow.partition = partition.name;
            }
            // if (partitionsTenant.tenant.name) {
            if (partitionsTenant.tenant_log.name) {
              // tenantRow.name = partitionsTenant.tenant.name;
              tenantRow.name = partitionsTenant.tenant_log.name;
            }
            // if (partitionsTenant.tenant.note) {
            if (partitionsTenant.tenant_log.note) {
              // tenantRow.note = partitionsTenant.tenant.note;
              tenantRow.note = partitionsTenant.tenant_log.note;
            }
            if (partitionsTenant.tenant.initial_value) {
              tenantRow.initialValue = partitionsTenant.tenant.initial_value;
            }
            if (partitionsTenant.tenant.closed_value) {
              tenantRow.closedValue = partitionsTenant.tenant.closed_value;
            }
            if (partitionsTenant.contracted_at) {
              tenantRow.contractedAt = this.changeDateFormat(
                partitionsTenant.contracted_at
              );
            }
            if (partitionsTenant.cancelled_at) {
              tenantRow.cancelledAt = partitionsTenant.cancelled_at;
            }
            if (partition.is_card_display) {
              tenantRow.isCardDisplay = partition.is_card_display;
            }
            if (partition.id) {
              tenantRow.partitionId = partition.id;
            }
            if (partitionsTenant.updated_by) {
              tenantRow.updated_by = partitionsTenant.updated_by;
            }
            if (partitionsTenant.tenant.updated_at) {
              tenantRow.updatedAt = partitionsTenant.tenant.updated_at;
            }
            if (
              Number(
                formatDate(tenantRow.contractedAt, "yyyyMM", this.locale)
              ) <= Number(this.selectedMonth.value)
            ) {
              if (!tenantRow.cancelledAt) {
                this.tenantData.push(tenantRow);
              } else {
                if (
                  Number(
                    formatDate(tenantRow.cancelledAt, "yyyyMM", this.locale)
                  ) >= Number(this.selectedMonth.value)
                ) {
                  this.tenantData.push(tenantRow);
                }
              }
            }
          }
        }
      }
    }
  }

  changeDateFormat(date: string) {
    const changedDate = new Date(date + "Z").toLocaleString();
    return changedDate;
  }

  // 「yyyy-mm-dd hh:mm:ss」形式に変換する　かつ　＋９時間（日本時間にする）
  timeStringFormat(time: number) {
    let newD = new Date(new Date(time).setHours(new Date(time).getHours() + 9));

    const yyyy = String(newD.getFullYear());
    const mm = String(newD.getMonth() + 1).padStart(2, "0");
    const dd = String(newD.getDate()).padStart(2, "0");
    const hh = String(newD.getHours()).padStart(2, "0");
    const ms = String(newD.getMinutes()).padStart(2, "0");
    const s = String(newD.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${ms}:${s}`;
  }
}
