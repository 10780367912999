import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { MeterType } from '../entity/get-meter-type';
import { MessageInfo } from '../entity/message-info';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-meter-type-edit-dialog',
  templateUrl: './meter-type-edit-dialog.component.html',
  styleUrls: ['./meter-type-edit-dialog.component.scss']
})
export class MeterTypeEditDialogComponent implements OnInit {

  meterTypeName: string
  idToken: string;
  maxLengthMeterTypeName = 128;
  errorMeterTypeNameMsg: string;

  constructor( public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public meterType: MeterType,
    public dialogRef: MatDialogRef<MeterTypeEditDialogComponent>,
    private httpClient: HttpClient,
    public auth: AuthService,

    ) { }

  ngOnInit() {
    this.meterTypeName = this.meterType.name
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
          }
        }
    );
  }

  onClickConfirm(){
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください")
      return
    }
    if (this.meterTypeName === undefined) {
      const errorMessageInfo = new MessageInfo("メータータイプ名を入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      const errorMessageInfo = new MessageInfo("メータータイプ名を" + this.maxLengthMeterTypeName + "文字以内で入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else {
      const messageInfo = new MeterConfirmDialogInfo('変更確認', '情報の変更を反映します。よろしいですか。');
      const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent,{
        width: '400px',
        data: messageInfo
      })
  
      confirmDialog.afterClosed().subscribe(result => {
        if(result === "Yes"){
          
          const url = `${environment.apiUrl}/meter_types/${this.meterType.id}/1/meter_types_info`;
          const token = this.idToken

          let params = new HttpParams();
          params = params.set('name', this.meterTypeName);
          this.httpClient.patch(url, params,
            {
              headers: new HttpHeaders({
                Authorization: token
              })
            }
          )
            .subscribe((response: any) => {
              this.dialogRef.close('close');
            },
              err => {
                if(err.error.code == "AccountLockError"){
                  alert('アカウントがロックされました。管理者までお問合せください');
                  Const.release()
                  this.dialogRef.close('lockedAccount');
                  this.auth.signOut()
                }else{
                  alert('メータータイプ名の変更に失敗しました。\n' + err.error.message);
                }
              }
            );
        }
      })
    }
  }

  checkIfFormDirty() {
    let hasNameChanged = true
    if (this.meterTypeName === "" || this.meterTypeName === null || this.meterTypeName === undefined) {
      hasNameChanged = false
    } else if (this.meterTypeName === this.meterType.name) {
      hasNameChanged = false
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      hasNameChanged = false
    }

    return hasNameChanged
  }

  changeValue() {
    this.errorMeterTypeNameMsg = ''
    if (this.meterTypeName === "" || this.meterTypeName === null || this.meterTypeName === undefined) {
      this.errorMeterTypeNameMsg = 'メータータイプ名を入力してください'
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      this.errorMeterTypeNameMsg = "メータータイプ名を" + this.maxLengthMeterTypeName + "文字以内で入力してください"
    }
  }
}
