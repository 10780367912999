// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  text-align: center;
}

h3 {
  text-align: center;
}

h4 {
  text-align: center;
}

th, td {
  border: solid 1px;
  padding: 10px;
  text-align: center;
}

table {
  border-collapse: collapse;
}

.blank-value {
  color: lightgrey;
}

.this-month {
  background-color: antiquewhite;
}

.alert-value {
  color: red;
}

.alert-value-blue {
  color: blue;
}`, "",{"version":3,"sources":["webpack://./src/app/value-compare-dialog/value-compare-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAGA;EACI,UAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ","sourcesContent":["h1{\n    text-align: center;\n}\n\nh3{\n    text-align: center;\n}\n\nh4{\n    text-align: center;\n}\n\nth,td {\n    border: solid 1px;\n    padding: 10px;\n    text-align: center;\n}\n\ntable {\n    border-collapse:  collapse;\n}\n\n.blank-value{\n    color:lightgrey;\n}\n\n.this-month {\n    background-color: antiquewhite;\n}\n\n\n.alert-value{\n    color: red;\n}\n\n.alert-value-blue{\n    color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
