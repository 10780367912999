import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { CompanyModel } from '../site-management/site-management.component';

@Component({
  selector: 'app-company-delete-dialog',
  templateUrl: './company-delete-dialog.component.html',
  styleUrls: ['./company-delete-dialog.component.scss']
})
export class CompanyDeleteDialogComponent implements OnInit {

  idToken: string;

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public company:  CompanyModel,
    public dialogRef: MatDialogRef<CompanyDeleteDialogComponent>,
    private httpClient: HttpClient,
    public auth: AuthService,
    ) { }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            alert('セッションが切れています。再度ログインしてください。');
            this.onClickLogout()
          }
        }
    );
  }

  // 会社削除
  onClickConfirm() {

      if(!navigator.onLine){
        alert("ネットワーク接続を確認してください")
        return
      }
      const messageInfo = new MeterConfirmDialogInfo('会社削除', '会社削除します。よろしいですか。');
      const meterConfirmDialogRef = this.confirmDialog.open(CommonConfirmDialogComponent, {
        width: '650px',
        data: messageInfo
      });

      meterConfirmDialogRef.afterClosed().subscribe(result => {
        if(result === "Yes"){
            
          let params = new HttpParams();
          params.set("name","")
          const url = `${environment.apiUrl}/company/company_info/0/${this.company.id}`;
          this.httpClient.patch(url, params,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken,
                "content-type": "application/x-www-form-urlencoded"
              })
            }
          ) .subscribe((response: any) => {
            this.dialogRef.close('close');
          },
            err => {
              if(err.error.code == "AccountLockError"){
                alert('アカウントがロックされました。管理者までお問合せください');
                Const.release()
                this.auth.signOut()
                this.dialogRef.close('lockedAccount');
              }else{
                alert('会社削除に失敗しました。');
              }
            }
          );
        }
      });

  }

  // ログアウト
  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }
}
