import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { MessageInfo } from '../entity/message-info';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { AuthService } from '../auth/auth.service';
import { Const } from '../const/const';

@Component({
  selector: 'app-meter-type-add-dialog',
  templateUrl: './meter-type-add-dialog.component.html',
  styleUrls: ['./meter-type-add-dialog.component.scss']
})
export class MeterTypeAddDialogComponent implements OnInit {

  meterTypeName: string
  idToken: string;
  errorMeterTypeNameMsg: string;
  maxLengthMeterTypeName = 128;

  constructor(    
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MeterTypeAddDialogComponent>,
    public auth: AuthService,
    private httpClient: HttpClient,
    ) {

  }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
          }
        }
    );
  }

  onClickConfirm(){

    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください")
      return
    }
    if (this.meterTypeName === undefined) {
      const errorMessageInfo = new MessageInfo("メータータイプ名を入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      const errorMessageInfo = new MessageInfo("メータータイプ名を" + this.maxLengthMeterTypeName + "文字以内で入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else {
      const messageInfo = new MeterConfirmDialogInfo('新規登録確認', 'メータータイプを新規登録します。よろしいですか。');
      const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent,{
        width: '400px',
        data: messageInfo
      })
  
      confirmDialog.afterClosed().subscribe(result => {
        if(result === "Yes"){
          const url = `${environment.apiUrl}/meter_types/meter_types_info`;
          const token = this.idToken

          let params = new HttpParams();
          params = params.set('name', this.meterTypeName);
          this.httpClient.post(url, params,
            {
              headers: new HttpHeaders({
                Authorization: token
              })
            }
          )
            .subscribe((response: any) => {
              this.dialogRef.close('close');
            },
              err => {
                if(err.error.code == "AccountLockError"){
                  alert('アカウントがロックされました。管理者までお問合せください');
                  Const.release()
                  this.auth.signOut()
                  this.dialogRef.close('lockedAccount');
                }else{
                  alert('メータータイプを新規登録に失敗しました。');
                }
              }
            );
        }
      })
    }
  }

  checkIfFormDirty() {
    let hasNameChanged = true
    if (this.meterTypeName === "" || this.meterTypeName === null || this.meterTypeName === undefined) {
      hasNameChanged = false
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      hasNameChanged = false
    }

    return hasNameChanged
  }

  changeValue() {
    this.errorMeterTypeNameMsg = ''
    if (this.meterTypeName === "" || this.meterTypeName === null || this.meterTypeName === undefined) {
      this.errorMeterTypeNameMsg = 'メータータイプ名を入力してください'
    } else if (this.meterTypeName.length > this.maxLengthMeterTypeName) {
      this.errorMeterTypeNameMsg = "メータータイプ名を" + this.maxLengthMeterTypeName + "文字以内で入力してください"
    }
  }
}
