// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  align-items: center;
  height: 100%;
  background-color: white;
  box-shadow: none;
}

.headerTitleArea {
  align-self: center;
}

.footerArea {
  align-self: end;
}`, "",{"version":3,"sources":["webpack://./src/app/user-site-links-dialog/user-site-links-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,eAAA;AAEJ","sourcesContent":["table{\n    width: 100%;\n    align-items: center;\n    height: 100%;\n    background-color:white;\n    box-shadow: none;\n}\n\n.headerTitleArea{\n    align-self: center;\n}\n.footerArea{\n    align-self: end;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
