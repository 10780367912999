import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { FloorInfo } from "../entity/floor-info";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUser } from "../entity/login-user";
import { LoginUserSite } from "../entity/login-user-site";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MessageInfo } from "../entity/message-info";
import { FloorAddDialogComponent } from "../floor-add-dialog/floor-add-dialog.component";
import { FloorDeleteDialogComponent } from "../floor-delete-dialog/floor-delete-dialog.component";
import { FloorEditDialogComponent } from "../floor-edit-dialog/floor-edit-dialog.component";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";

@Component({
  selector: "app-floor-management-list",
  templateUrl: "./floor-management-list.component.html",
  styleUrls: ["./floor-management-list.component.scss"],
})
export class FloorManagementListComponent implements OnInit {
  selectedSite = new UntypedFormControl();
  loginUser: LoginUser;
  siteArray: LoginUserSite[];
  idToken: string;
  site = new LoginUserSite();

  // テーブル生成用
  displayedColumns: string[];
  floorsInfo: FloorInfo[];

  // APIのURL
  getLoginUserUrl: string;
  getFloorsUrl: string;

  // APIのレスポンス
  getFloorsResponse: GetFloorsResponse;

  // フラグ類
  getUserFinished: boolean;
  getFloorFinished: boolean;
  NumberingFlag: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public floorDialog: MatDialog
  ) {
    localStorage.setItem("path", router.url);
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.siteArray = [];

    this.getFloorsResponse = new GetFloorsResponse();
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        if (site.id === Const.site_id) {
          this.site = site;
        }
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.getFloorsUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.doGetFloors();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            if (site.id === Const.site_id) {
              this.site = siteItem;
            }
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("施設情報が取得できませんでした。");
          }
        }
      );
  }

  doGetFloors() {
    this.getFloorFinished = false;
    this.selectedSite.disable();
    this.NumberingFlag = false;

    this.httpClient
      .get(this.getFloorsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;

          this.displayedColumns = [
            "floorName",
            "floorNumber",
            "createdBy",
            "createdAt",
            "updatedBy",
            "updatedAt",
            "editButton",
            "deleteButton",
          ];
          this.getFloorFinished = true;
          this.selectedSite.enable();
          this.NumberingFlag = true;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("フロア情報が存在しません。");
            this.floorsInfo = [];
            this.getFloorFinished = true;
            this.selectedSite.enable();
          }
        }
      );
  }

  doChangeSite() {
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }

    this.getFloorsUrl = `${environment.apiUrl}/sites/${Const.site_id}/floors`;
    this.doGetFloors();
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
            this.onClickLogout();
          }
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  onClickAddFloor() {
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.floorDialog.open(FloorAddDialogComponent, {
      width: "650px",
      data: {
        site: this.site,
        floorsInfo: this.floorsInfo,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onClickFloorEdit(floorInfo: FloorInfo) {
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.floorDialog.open(FloorEditDialogComponent, {
      width: "650px",
      data: {
        floorInfo: floorInfo,
        floorsInfo: this.floorsInfo,
        site: this.site,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onClickFloorDelete(floorInfo: FloorInfo) {
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.floorDialog.open(FloorDeleteDialogComponent, {
      width: "650px",
      data: {
        floorInfo: floorInfo,
        floorsInfo: this.floorsInfo,
        site: this.site,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onNumbering() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    this.getFloorFinished = false;
    this.selectedSite.disable();
    this.NumberingFlag = false;
    // APIコールして登録処理
    const patchNumberingFloorUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors/no`;
    const params = new HttpParams();
    this.httpClient
      .patch(patchNumberingFloorUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          const successMessageInfo = new MessageInfo(
            "自動採番を行いました",
            ""
          );
          const dialogRef = this.floorDialog.open(MessageDialogComponent, {
            width: "400px",
            data: successMessageInfo,
          });
          dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult === "close") {
              this.doGetFloors();
            }
          });
        },
        (err) => {
          this.getFloorFinished = true;
          this.selectedSite.enable();
          this.NumberingFlag = true;
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("自動採番に失敗しました");
            this.doGetFloors();
          }
        }
      );
  }
}
