import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { MessageInfo } from '../entity/message-info';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { Unit } from '../entity/unit';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-meter-unit-edit-dialog',
  templateUrl: './meter-unit-edit-dialog.component.html',
  styleUrls: ['./meter-unit-edit-dialog.component.scss']
})
export class MeterUnitEditDialogComponent implements OnInit {
  meterUnitName: string
  idToken: string;
  maxLengthMeterUnitName = 10;
  errorMeterUnitNameMsg: string;

  constructor(@Inject(MAT_DIALOG_DATA) public unit: Unit,

  public dialogRef: MatDialogRef<MeterUnitEditDialogComponent>,
  private httpClient: HttpClient,
  public confirmDialog: MatDialog,
  public dialog: MatDialog,
  public auth: AuthService,
  ) { }

  ngOnInit() {

  this.meterUnitName = this.unit.type
  this.auth.getIdToken()
      .subscribe(
          result => {
            if (result) {
              this.idToken = result;
            } else {
              // idトークンがnullの場合はログイン画面へ遷移
              alert('セッションが切れています。再度ログインしてください。');
            }
          }
      );
  }

  onClickConfirm(){
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください")
      return
    }
    if (this.meterUnitName === undefined) {
      const errorMessageInfo = new MessageInfo("メーター単位名を入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else if (this.meterUnitName.length > this.maxLengthMeterUnitName) {
      const errorMessageInfo = new MessageInfo("メーター単位名を" + this.maxLengthMeterUnitName +"文字以内で入力してください。", '');
      this.dialog.open(MessageDialogComponent, {
        width: '400px',
        data: errorMessageInfo
      });
    } else {
      const messageInfo = new MeterConfirmDialogInfo('変更確認', '情報の変更を反映します。よろしいですか。');
      const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent,{
        width: '400px',
        data: messageInfo
      })

      confirmDialog.afterClosed().subscribe(result => {
        if(result === "Yes"){
          
          const url = `${environment.apiUrl}/units/${this.unit.id}/1/units_info`;
          const token = this.idToken

          let params = new HttpParams();
          params = params.set('type', this.meterUnitName);
          this.httpClient.patch(url, params,
            {
              headers: new HttpHeaders({
                Authorization: token
              })
            }
          )
            .subscribe((response: any) => {
              this.dialogRef.close('close');
            },
              err => {
                if(err.error.code == "AccountLockError"){
                  alert('アカウントがロックされました。管理者までお問合せください');
                  Const.release()
                  this.auth.signOut()
                  this.dialogRef.close('lockedAccount');
                }else{
                  alert('単位名の変更に失敗しました。\n' + err.error.message);
                }
              }
            );
        }
      })
    }
  }

  checkIfFormDirty() {
    let hasNameChanged = true
    if (this.meterUnitName === null || this.meterUnitName === undefined || this.meterUnitName === "") {
      hasNameChanged = false
    } else if (this.meterUnitName === this.unit.type) {
      hasNameChanged = false
    } else if (this.meterUnitName.length > this.maxLengthMeterUnitName) {
      hasNameChanged = false
    }

    return hasNameChanged
  }

  changeValue() {
    this.errorMeterUnitNameMsg = ''
    if (this.meterUnitName === "" || this.meterUnitName === null || this.meterUnitName === undefined) {
      this.errorMeterUnitNameMsg = 'メーター単位名を入力してください'
    } else if (this.meterUnitName.length > this.maxLengthMeterUnitName) {
      this.errorMeterUnitNameMsg = "メーター単位名を" + this.maxLengthMeterUnitName + "文字以内で入力してください"
    }
  }
}
