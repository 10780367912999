import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { CompareDialogData } from "../entity/compare-dialog-data";
import { ConfirmedReportPartition } from "../entity/confirmed-report-partition";
import { CorrectDialogData } from "../entity/correct-dialog-data";
import { GetConfirmedReportResponse } from "../entity/get-confirmed-report-response";
import { GetInSiteMeterResponse } from "../entity/get-in-site-meters-response";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { MeterInfo } from "../entity/get-meter-info";
import { GetReportDataResponse } from "../entity/get-report-data-response";
import { GetReportListResponse } from "../entity/get-report-list-response";
import { SiteInfo } from "../entity/get-site-info";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { LoginUser } from "../entity/login-user";
import { LoginUserSite } from "../entity/login-user-site";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MessageInfo } from "../entity/message-info";
import { Meter } from "../entity/meter";
import { ReportExceptionMeters } from "../entity/report-exception-meters";
import { ReportListInfo } from "../entity/report-list-info";
import { ReportMeters } from "../entity/report-meters";
import { ReportPartitions } from "../entity/report-partitions";
import { ReportRow } from "../entity/report-row";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { ReportConfirmDialogComponent } from "../report-confirm-dialog/report-confirm-dialog.component";
import { ReportListDialogComponent } from "../report-list-dialog/report-list-dialog.component";
import { ValueCompareDialogComponent } from "../value-compare-dialog/value-compare-dialog.component";
import { ValueCorrectDialogComponent } from "../value-correct-dialog/value-correct-dialog.component";
import { GetReportConfirmedDataResponse } from "../entity/get-confirmed-report-data-response";
import { BigNumber } from "bignumber.js";

@Component({
  selector: "app-meter-report",
  templateUrl: "./meter-report.component.html",
  styleUrls: ["./meter-report.component.scss"],
})
export class MeterReportComponent implements OnInit, OnDestroy {
  selectedSite = new UntypedFormControl();
  selectedType = new UntypedFormControl("すべて");
  selectedMonth: number;
  idToken: string;
  typeArray: string[];

  // ログインユーザー取得API関連
  getLoginUserUrl: string;
  getLoginUserResponse: GetLoginUserResponse;
  loginUser: LoginUser;

  // レポートデータ取得API関連
  getReportDataUrl: string;
  reportAPIResponse: GetReportDataResponse;
  reportPartitions: ReportPartitions[];

  // 確定済レポート取得API関連
  getConfirmedReportDataUrl: string;
  confirmedReportPartitions: ConfirmedReportPartition[];

  // レポート確定
  postConfirmedReportUrl: string;

  // レポート取得
  getReportListUrl: string;

  // メーター情報１件取得API関連
  getOneMeterDataUrl: string;
  meter: Meter;

  // フラグ類
  getReportDataFinished: boolean;
  getUserFinished: boolean;
  canEdit: boolean;
  isConfirmedReport: boolean;
  isAlreadyConfirmed: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;

  // データソース格納用の配列
  reportData: ReportRow[];
  reportDataBackUp: ReportRow[];

  // 列の表示項目を決める配列
  displayedColumns: string[];

  // レポートリスト表示用
  reportList: ReportListInfo[];
  siteArray: LoginUserSite[];

  // テナント名称"(施説負担分)"のテナントID
  // テナントIDがこれの時はレポートに表示させない
  siteBurdenId: string;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;
  // 有効切れメーターリスト
  expMeterData: MeterInfo[] = [];
  partitionTenants: GetTenantsPartition[];
  confirmFlg = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    public auth: AuthService,
    public dialog: MatDialog,
    dateAdapter: DateAdapter<NativeDateAdapter>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    localStorage.setItem("path", router.url);
    dateAdapter.setLocale("ja");
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.getLoginUserResponse = new GetLoginUserResponse();
    this.reportData = [];
    this.reportDataBackUp = [];
    this.reportList = [];
    this.siteArray = [];
    this.typeArray = ["すべて", "電灯", "動力", "水道", "ガス"];
    this.getUserFinished = false;
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    this.canEdit = false;
    this.isConfirmedReport = false;
    this.displayedColumns = [
      "partition",
      "name",
      "info",
      "label",
      "type",
      "lastMonth",
      "thisMonth",
      "used",
    ];
    this.siteBurdenId = "4e2cc14e-68f2-45b9-ad2a-453b8558579f";
    this.expMeterData = [];
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
    this.confirmFlg = false;
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    this.selectedSite = new UntypedFormControl(Const.site_id);
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.selectedType = new UntypedFormControl("すべて");
    this.loadMeterData();
    this.doGetReportList("make");
  }

  doChangeType() {
    this.selectedType.disable();
    this.selectedSite.disable();
    this.getUserFinished = false;
    this.getReportDataFinished = false;
    this.canEdit = false;
    this.isConfirmedReport = false;
    this.loadMeterData();
    this.doGetReportList("filtered");
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("施設情報が取得できませんでした。");
            console.error("Get site data failed");
            console.error(err);
          }
        }
      );
  }

  doChangeSite() {
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    this.selectedType = new UntypedFormControl("すべて");
    this.loadMeterData();
    this.doGetReportList("make");
  }

  // レポート一覧の取得処理（初回画面描画時、レポート一覧更新時共通）
  // 引数が'make'=最初の画面描画時のみ画面上に描画するレポートが確定済or未確定を判別して後続処理を行う
  doGetReportList(option: string) {
    this.getReportListUrl = `${environment.apiUrl}/report_list/${this.selectedSite.value}`;
    this.httpClient
      .get(this.getReportListUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportListResponse) => {
          this.reportList = [];
          let confirmedUser = null;
          const reportListData = response.result.report_list;
          for (const oneReportData of reportListData) {
            const reportMonth = Number(oneReportData.report_month);
            let fixedAt = null;
            let editedAt = null;
            if (oneReportData.confirmed_report_created_at) {
              fixedAt = String(
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              );
            }
            if (oneReportData.value_confirmation_created_at) {
              editedAt = String(
                formatDate(
                  oneReportData.value_confirmation_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              );
            }
            if (oneReportData.value_confirmation_user_name) {
              confirmedUser = oneReportData.value_confirmation_user_name;
            } else {
              confirmedUser = "削除済みユーザー";
            }
            const reportInfo = new ReportListInfo(
              reportMonth,
              fixedAt,
              editedAt,
              confirmedUser
            );
            if (
              oneReportData.confirmed_report_created_at &&
              oneReportData.value_confirmation_created_at
            ) {
              if (
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                ) <
                formatDate(
                  oneReportData.value_confirmation_created_at,
                  "yyyy-MM-dd HH:mm:ss",
                  this.locale
                )
              ) {
                reportInfo.isConfirmedAll = false;
              }
            }
            this.reportList.push(reportInfo);
          }
          // 最初の呼び出し時だけ後続の処理
          if (option === "make") {
            this.selectedMonth = this.reportList[0].month;
            if (!this.reportList[0].fixedTime) {
              this.doGetReportData();
            } else {
              this.doGetConfirmedReportData();
            }
          } else if (option === "filtered") {
            if (!this.confirmFlg) {
              this.doGetReportData();
            } else {
              this.doGetConfirmedReportData();
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("レポート一覧が取得できませんでした。");
            this.getReportDataFinished = true;
            this.selectedSite.enable();
            this.selectedType.enable();
            this.reportData = [];
            this.reportDataBackUp = [];
          }
        }
      );
  }

  // 選択月とレポート一覧を引数に、選択月に確定済レポートがあるかどうか確認する
  checkAlreadyConfirmed(selectedMonth: number, reportList: ReportListInfo[]) {
    for (const reportInfo of reportList) {
      if (reportInfo.month === selectedMonth) {
        if (!reportInfo.fixedTime) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  // 【確定済】APIからデータ取得〜レポートデータ作成処理呼び出しまで
  // 確定済の月変更と確定処理後は必ずここを通る
  doGetConfirmedReportData() {
    this.reportData = [];
    this.reportDataBackUp = [];
    this.isConfirmedReport = true;
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    this.getConfirmedReportDataUrl = `${environment.apiUrl}/report_histories/${this.selectedSite.value}/${this.selectedMonth}/report_details`;
    this.httpClient
      .get(this.getConfirmedReportDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportConfirmedDataResponse) => {
          this.reportPartitions = response.result.confirmed_report.partitions;
          this.createReportDataAll(this.reportPartitions);
          this.getReportDataFinished = true;
          this.selectedSite.enable();
          this.selectedType.enable();
        },
        /* (response: GetConfirmedReportResponse) => {
          this.confirmedReportPartitions =
            response.result.confirmed_report.partitions;
          this.createConfirmedReportAll(this.confirmedReportPartitions);
          this.getReportDataFinished = true;
          this.selectedSite.enable();
          this.selectedType.enable();
        },*/
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("レポート情報が取得できませんでした。");
            this.getReportDataFinished = true;
            this.selectedSite.enable();
            this.selectedType.enable();
            this.reportData = [];
            this.reportDataBackUp = [];
          }
        }
      );
  }

  // 【確定済】レポート作成処理（全体包括）
  // 未確定レポートの「１区画分のデータ作成〜reportDataへの格納まで（createReportRow）」も統合
  // 画面表示用配列へのpushと施設負担分の削除もここで行う
  createConfirmedReportAll(partitions: ConfirmedReportPartition[]) {
    let count = 0;

    for (const partition of partitions) {
      if (partition.partitions_tenants.length > 0) {
        // TODO 情報収集順が未確定と逆順になるので、表示順を統一するための暫定対応
        partition.meters.reverse();
        for (const meter of partition.meters) {
          const row = new ReportRow();
          row.partition = partition.name;

          // 契約なしもしくは解約済み区画の判別
          if (!partition.tenant_log) {
            row.name = "テナント未契約";
            this.checkAndPushToReportData(
              row,
              partition.report_type,
              String(this.selectedType.value)
            );

            // 非表示(検針対象外)テナントの判別
          } else if (partition.is_card_display_on_partition === 0) {
            row.name = partition.tenant_log.name;
            row.tenantId = partition.tenant_log.tenant_id;
            this.checkAndPushToReportData(
              row,
              partition.report_type,
              String(this.selectedType.value)
            );
          } else {
            if (
              count > 0 &&
              this.checkChangedConfirmed(
                partitions[count - 1],
                partitions[count]
              )
            ) {
              // テナント入れ替えあり区画
              row.name = partitions[count].tenant_log.name;
              row.tenantId = partitions[count].tenant_log.tenant_id;
              this.reflectOldThisMonthToNewLastMonth(
                row,
                partitions[count - 1],
                partitions[count]
              );
              break;
            } else {
              // 通常メーターのみ区画（1行目のみここを通る）
              row.name = partition.tenant_log.name;
              row.tenantId = partition.tenant_log.tenant_id;
              row.label = meter.label;
              row.info = meter.name.substr(0, meter.name.indexOf("_"));
              row.decimal = meter.decimal_point_position;
              row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
              this.writeMetersDataToRowConfirmed(meter, row, row.tenantId);
              this.checkAndPushToReportData(
                row,
                partition.report_type,
                String(this.selectedType.value)
              );
            }
          }
        }
      }
      count++;
    }

    // TODO
    // 全区画の処理が終わった後に'マルヤマクラス負担分'扱いの行を削除
    let rowCount = 0;
    for (const rowData of this.reportData) {
      if (rowData.tenantId === this.siteBurdenId) {
        this.reportData.splice(rowCount, 1);
      }
      rowCount++;
    }
  }

  // 【確定済】特殊メーターいる区画の行データ作成
  // 通常メーターのみの行を生成したあと特殊メーターの行を生成
  createConfirmedReportRowWithExceptionMeter(
    partition: ConfirmedReportPartition
  ) {
    const rowsWithException = [];
    const row = new ReportRow();
    row.partition = partition.name;
    row.name = partition.tenant_log.name;
    row.tenantId = partition.tenant_log.tenant_id;
    this.writeMetersDataToRowConfirmed(partition, row, row.tenantId);
    rowsWithException.push(row);

    const exceptionRow = new ReportRow();
    exceptionRow.partition = partition.name;
    exceptionRow.name = partition.tenant_log.name;
    exceptionRow.tenantId = partition.tenant_log.tenant_id;
    this.writeMetersDataToRowConfirmed(
      partition,
      exceptionRow,
      exceptionRow.tenantId
    );
    exceptionRow.info = this.pickUpExceptionMeterInfo(partition);
    rowsWithException.push(exceptionRow);

    return rowsWithException;
  }

  // 【確定済】区画内の全てのメーターをチェックして、特殊メーターを見つけたら”_”の前の文字列を取得して返す
  pickUpExceptionMeterInfo(partition: ConfirmedReportPartition) {
    let info = "";
    for (const meter of partition.meters) {
      if (meter.name.indexOf("_") !== -1) {
        info = meter.name.substr(0, meter.name.indexOf("_"));
        break;
      } else {
        info = "";
      }
    }
    return info;
  }

  // 【確定済】入れ替え前後の区画情報を引数に、旧テナント今月値⇨新テナント前月値への情報連携を行った区画情報を生成する
  reflectOldThisMonthToNewLastMonth(
    row: any,
    oldPartition: ConfirmedReportPartition,
    newPartition: ConfirmedReportPartition
  ) {
    // TODO 情報収集順が未確定と逆順になるので、表示順を統一するための暫定対応
    oldPartition.meters.reverse();

    for (let count = 0; count < newPartition.meters.length; count++) {
      const newRow = row;

      newRow.label = newPartition.meters[count].label;
      newRow.info = newPartition.meters[count].name.substr(
        0,
        newPartition.meters[count].name.indexOf("_")
      );
      newRow.decimal = newPartition.meters[count].decimal_point_position;
      newRow.type = newPartition.meters[count].name.substr(
        newPartition.meters[count].name.indexOf("_") + 1,
        2
      );
      if (oldPartition.meters[count].value_confirmation) {
        newPartition.meters[count].last_month_value_confirmation =
          oldPartition.meters[count].value_confirmation;
      }
      this.writeMetersDataToRowConfirmed(
        newPartition.meters[count],
        newRow,
        newRow.tenantId
      );
      this.checkAndPushToReportData(
        newRow,
        newPartition.report_type,
        String(this.selectedType.value)
      );
    }
  }

  // 【確定済】連続する区画を引数に、区画IDが同じ（＝テナント入れ替えがあった区画かどうか）を確認する
  checkChangedConfirmed(
    baforePartition: ConfirmedReportPartition,
    nowPartition: ConfirmedReportPartition
  ) {
    if (baforePartition.id === nowPartition.id) {
      return true;
    } else {
      return false;
    }
  }

  // 【確定済】例外メーターがいる区画かどうか確認する
  checkExceptionMeterConfirmed(partition: ConfirmedReportPartition) {
    for (const meter of partition.meters) {
      if (
        meter.name !== "電灯" &&
        meter.name !== "動力" &&
        meter.name !== "水道" &&
        meter.name !== "ガス"
      ) {
        return true;
      }
    }
    return false;
  }

  // 【確定済】通常メーターのみ検知して行データに書き込みを行う(電灯動力水道ガスで共通)
  writeMetersDataToRowConfirmed(meter: any, row: any, tenantId: any) {
    // 通常メーターはメーター名の完全一致のみ通す、特殊メーターで渡された場合はメーター名の部分一致でも通す
    if (
      meter.name === "電灯" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "電灯"
    ) {
      // メーター記入状況の初期化
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_confirmation) {
        // メーターが存在してかつ検針済み(value_confirmationがいる)のとき書き込みを行う
        row.lastMonth = meter.last_month_value_confirmation.confirmed_value;
      }
      if (meter.value_confirmation) {
        // 引数として連携したテナントIDに一致するときだけ書き込みを行う
        row.thisMonth = meter.value_confirmation.confirmed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        // 前月と今月の値が揃っている場合のみ差分を計算する
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        // 検針値比較ダイアログ用
        // ２ヶ月前の値があれば先月使用量の計算&アラートフラグの更新処理
        if (meter.two_months_before_value_confirmation) {
          row.lastUsed = this.calcUsedValue(
            Number(meter.two_months_before_value_confirmation.confirmed_value),
            row.lastMonth,
            meter.max_value,
            meter.decimal_point_position
          );
          row.alert = this.judgingAlertValue(
            row.used,
            row.lastMonth,
            meter.two_months_before_value_confirmation.confirmed_value,
            meter.max_value,
            meter.decimal_point_position,
            0.5
          );
        }
      }
      // 動力の分岐
    } else if (
      meter.name === "動力" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "動力"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_confirmation) {
        row.lastMonth = meter.last_month_value_confirmation.confirmed_value;
      }
      if (meter.value_confirmation) {
        row.thisMonth = meter.value_confirmation.confirmed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_confirmation) {
          row.lastUsed = this.calcUsedValue(
            Number(meter.two_months_before_value_confirmation.confirmed_value),
            row.lastMonth,
            meter.max_value,
            meter.decimal_point_position
          );
          row.alert = this.judgingAlertValue(
            row.used,
            row.lastMonth,
            meter.two_months_before_value_confirmation.confirmed_value,
            meter.max_value,
            meter.decimal_point_position,
            0.5
          );
        }
      }
      // 水道の分岐
    } else if (
      meter.name === "水道" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "水道"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_confirmation) {
        row.lastMonth = meter.last_month_value_confirmation.confirmed_value;
      }
      if (meter.value_confirmation) {
        row.thisMonth = meter.value_confirmation.confirmed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_confirmation) {
          row.lastUsed = this.calcUsedValue(
            Number(meter.two_months_before_value_confirmation.confirmed_value),
            row.lastMonth,
            meter.max_value,
            meter.decimal_point_position
          );
          row.alert = this.judgingAlertValue(
            row.used,
            row.lastMonth,
            meter.two_months_before_value_confirmation.confirmed_value,
            meter.max_value,
            meter.decimal_point_position,
            0.5
          );
        }
      }
      // ガスの分岐
    } else if (
      meter.name === "ガス" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "ガス"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_confirmation) {
        row.lastMonth = meter.last_month_value_confirmation.confirmed_value;
      }
      if (meter.value_confirmation) {
        row.thisMonth = meter.value_confirmation.confirmed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_confirmation) {
          row.lastUsed = this.calcUsedValue(
            Number(meter.two_months_before_value_confirmation.confirmed_value),
            row.lastMonth,
            meter.max_value,
            meter.decimal_point_position
          );
          row.alert = this.judgingAlertValue(
            row.used,
            row.lastMonth,
            meter.two_months_before_value_confirmation.confirmed_value,
            meter.max_value,
            meter.decimal_point_position,
            0.5
          );
        }
      }
    }
  }

  // 【未確定】APIからデータ取得〜レポートデータ作成処理呼び出しまで
  // 月変更後はここを呼び出し
  doGetReportData() {
    this.isAlreadyConfirmed = this.checkAlreadyConfirmed(
      this.selectedMonth,
      this.reportList
    );
    this.reportData = [];
    this.reportDataBackUp = [];
    this.getReportDataFinished = false;
    this.selectedSite.disable();
    this.selectedType.disable();
    this.isConfirmedReport = false;
    this.getReportDataUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/meters/${this.selectedMonth}/value_confirmations`;
    this.httpClient
      .get(this.getReportDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportDataResponse) => {
          this.reportPartitions = response.result.site.partitions;
          this.createReportDataAll(this.reportPartitions);
          this.getReportDataFinished = true;
          this.selectedSite.enable();
          this.selectedType.enable();
        },
        (err) => {
          this.getReportDataFinished = true;
          this.selectedSite.enable();
          this.selectedType.enable();
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("レポート情報が取得できませんでした。");
          }
        }
      );
  }

  // 【未確定】レポート作成処理（全体包括）
  createReportDataAll(partitions: ReportPartitions[]) {
    for (const partition of partitions) {
      this.createReportRow(partition);
    }
    // TODO
    // 全区画の処理が終わった後に'マルヤマクラス負担分'扱いの行を削除
    let rowCount = 0;
    for (const rowData of this.reportData) {
      if (rowData.tenantId === this.siteBurdenId) {
        this.reportData.splice(rowCount, 1);
      }
      rowCount++;
    }
  }

  // 【未確定】１区画分のデータ作成〜reportDataへの格納まで
  // テナント入替or特殊メーター（倉庫など）がある場合は別処理へ
  // 画面表示用の配列へのpushは全てここで行う
  createReportRow(partition: ReportPartitions) {
    // テナント入替区画（個別処理へ）

    if (
      partition.is_card_display !== 0 &&
      partition.partitions_tenants.length > 1
    ) {
      const rowsWithChange = this.createReportRowWithChanged(partition);
      for (const rowData of rowsWithChange) {
        this.checkAndPushToReportData(
          rowData,
          partition.report_type,
          String(this.selectedType.value)
        );
      }
      return;
    }

    if (
      partition.partitions_tenants.length > 0 &&
      (!partition.partitions_tenants[0].cancelled_at ||
        Number(
          formatDate(
            partition.partitions_tenants[0].cancelled_at,
            "yyyyMM",
            this.locale
          )
        ) >= Number(this.selectedMonth)) &&
      Number(
        formatDate(
          partition.partitions_tenants[0].contracted_at,
          "yyyyMM",
          this.locale
        )
      ) <= Number(this.selectedMonth)
    ) {
      for (const meter of partition.meters) {
        const row = new ReportRow();
        row.partition = partition.name;
        // メーターの削除フラグがあり、last_month_value_detectionsがない場合はスキップ
        if (
          meter.is_deleted == "0" ||
          (meter.is_deleted == "1" &&
            meter.last_month_value_detections.length > 0)
        ) {
          // テナント未契約（テナント解約済みも含む）
          if (partition.partitions_tenants.length === 0) {
            row.name = "テナント未契約";
            // 非表示テナントとしての処理
          } else {
            row.name = partition.partitions_tenants[0].tenant_log.name;
            row.tenantId = partition.partitions_tenants[0].tenant_log.tenant_id;
            row.label = meter.label;
            row.info = meter.name.substr(0, meter.name.indexOf("_"));
            row.decimal = meter.decimal_point_position;
            row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
            this.writeMetersDataToRow(meter, row, row.tenantId);
          }
          this.checkAndPushToReportData(
            row,
            partition.report_type,
            String(this.selectedType.value)
          );
        }
      }
    }
  }

  // 【未確定】区画のレポート種別をみて格納先を判別〜格納処理まで行う
  checkAndPushToReportData(row: ReportRow, reportType: number, filter: String) {
    if (filter === "すべて") {
      this.reportData.push(row);
    } else {
      this.reportDataBackUp.push(row);
      if (row.type === filter) {
        this.reportData.push(row);
      }
    }
  }

  // 【未確定】テナント入替区画の行データ作成
  // 必要行数分rowsSamePartitionを生成して
  createReportRowWithChanged(partition: ReportPartitions): any {
    let rows: ReportRow[] = [];
    let lastMonthConfirmDate = "";

    //前の月の確定日を抽出
    if (this.reportList.length > 1) {
      for (var i = 0; i < this.reportList.length; i++) {
        if (Number(this.selectedMonth) === Number(this.reportList[i].month)) {
          if (i < this.reportList.length - 1) {
            lastMonthConfirmDate =
              this.reportList[i + 1].fixedTime === null ||
              this.reportList[i + 1].fixedTime === undefined
                ? ""
                : this.reportList[i + 1].fixedTime;
          }
          break;
        }
      }
    }

    for (const partitionsTenant of partition.partitions_tenants) {
      let rowsSamePartition: ReportRow[] = [];

      if (
        (!partitionsTenant.cancelled_at ||
          Number(
            formatDate(partitionsTenant.cancelled_at, "yyyyMM", this.locale)
          ) >= Number(this.selectedMonth)) &&
        Number(
          formatDate(partitionsTenant.contracted_at, "yyyyMM", this.locale)
        ) <= Number(this.selectedMonth)
      ) {
        for (const meter of partition.meters) {
          // メーターの削除フラグがあり、last_month_value_detectionsがない場合はスキップ
          if (
            meter.is_deleted == "0" ||
            (meter.is_deleted == "1" &&
              meter.last_month_value_detections.length > 0)
          ) {
            const row = new ReportRow();
            row.partition = partition.name;
            row.name = partitionsTenant.tenant_log.name;
            row.label = meter.label;
            row.info = meter.name.substr(0, meter.name.indexOf("_"));
            row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
            row.decimal = meter.decimal_point_position;
            row.tenantId = partitionsTenant.tenant_log.tenant_id;
            this.writeMetersDataToRow(meter, row, row.tenantId);
            this.createTenantReplaceReport(meter, partitionsTenant, row);
            rowsSamePartition.push(row);
          }
        }
      }
      //確定日後に解約したテナントの行を表示する
      else if (
        lastMonthConfirmDate !== "" &&
        Number(
          formatDate(partitionsTenant.cancelled_at, "yyyyMMdd", this.locale)
        ) >=
          Number(formatDate(lastMonthConfirmDate, "yyyyMMdd", this.locale)) &&
        Number(
          formatDate(partitionsTenant.cancelled_at, "yyyyMM", this.locale)
        ) < Number(this.selectedMonth)
      ) {
        for (const meter of partition.meters) {
          // メーターの削除フラグがあり、last_month_value_detectionsがない場合はスキップ
          if (
            meter.is_deleted == "0" ||
            (meter.is_deleted == "1" &&
              meter.last_month_value_detections.length > 0)
          ) {
            const row = new ReportRow();
            row.partition = partition.name;
            row.name = partitionsTenant.tenant_log.name;
            row.label = meter.label;
            row.info = meter.name.substr(0, meter.name.indexOf("_"));
            row.type = meter.name.substr(meter.name.indexOf("_") + 1, 2);
            row.decimal = meter.decimal_point_position;
            row.tenantId = partitionsTenant.tenant_log.tenant_id;
            this.writeMetersDataToRow(meter, row, row.tenantId);
            this.createTenantReplaceReport(meter, partitionsTenant, row);
            rowsSamePartition.push(row);
          }
        }
      }

      rows = rowsSamePartition.concat(rows);
    }
    return rows;
  }

  createTenantReplaceReport(meter: any, partitionTenant: any, row: any) {
    const initial_values = JSON.parse(partitionTenant.tenant.initial_value);
    let initial_value: string = null;
    if (initial_values !== null && initial_values !== undefined) {
      for (const val of initial_values) {
        if (val.meterLabel === meter.label) {
          initial_value = val.meterValue;
        }
      }
    }
    let closed_values = JSON.parse(partitionTenant.tenant.closed_value);
    let closed_value: string = null;
    if (closed_values !== null && closed_values !== undefined) {
      for (const val of closed_values) {
        if (val.meterLabel === meter.label) {
          closed_value = val.meterValue;
        }
      }
    }

    if (
      meter.name === "電灯" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "電灯"
    ) {
      if (row.thisMonth === "N/A") {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      } else if (
        partitionTenant.contracted_at > meter.created_at &&
        closed_value === null &&
        meter.closed_value === null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.contracted_at < meter.updated_at &&
        closed_value === null &&
        meter.closed_value !== null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.cancelled_at < meter.updated_at &&
        closed_value !== null &&
        meter.closed_value !== null
      ) {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      }
      row.used = this.calcUsedValue(
        row.lastMonth,
        row.thisMonth,
        row.maxVal,
        row.decimal
      );
    } else if (
      meter.name === "動力" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "動力"
    ) {
      if (row.thisMonth === "N/A") {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      } else if (
        partitionTenant.contracted_at > meter.created_at &&
        closed_value === null &&
        meter.closed_value === null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.contracted_at < meter.updated_at &&
        closed_value === null &&
        meter.closed_value !== null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.cancelled_at < meter.updated_at &&
        closed_value !== null &&
        meter.closed_value !== null
      ) {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      }
      row.used = this.calcUsedValue(
        row.lastMonth,
        row.thisMonth,
        row.maxVal,
        row.decimal
      );
    } else if (
      meter.name === "水道" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "水道"
    ) {
      if (row.thisMonth === "N/A") {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      } else if (
        partitionTenant.contracted_at > meter.created_at &&
        closed_value === null &&
        meter.closed_value === null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.contracted_at < meter.updated_at &&
        closed_value === null &&
        meter.closed_value !== null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.cancelled_at < meter.updated_at &&
        closed_value !== null &&
        meter.closed_value !== null
      ) {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      }
      row.used = this.calcUsedValue(
        row.lastMonth,
        row.thisMonth,
        row.maxVal,
        row.decimal
      );
    } else if (
      meter.name === "ガス" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "ガス"
    ) {
      if (row.thisMonth === "N/A") {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      } else if (
        partitionTenant.contracted_at > meter.created_at &&
        closed_value === null &&
        meter.closed_value === null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.contracted_at < meter.updated_at &&
        closed_value === null &&
        meter.closed_value !== null
      ) {
        row.lastMonth = initial_value === null ? "N/A" : initial_value;
      } else if (
        partitionTenant.cancelled_at < meter.updated_at &&
        closed_value !== null &&
        meter.closed_value !== null
      ) {
        row.thisMonth = closed_value === null ? "N/A" : closed_value;
      }
      row.used = this.calcUsedValue(
        row.lastMonth,
        row.thisMonth,
        row.maxVal,
        row.decimal
      );
    }
  }

  // 【未確定】特殊メーターいる区画の行データ作成
  // 通常メーターのみの行を生成したあと特殊メーターの行を生成
  createReportRowWithExceptionMeter(partition: ReportPartitions, num: number) {
    const rowsWithException = [];
    const row = new ReportRow();
    row.partition = partition.name;
    row.name =
      partition.partitions_tenants[num] !== undefined
        ? partition.partitions_tenants[num].tenant_log.name
        : "";
    row.tenantId =
      partition.partitions_tenants[num] !== undefined
        ? partition.partitions_tenants[num].tenant_log.tenant_id
        : "";
    this.writeMetersDataToRow(partition.meters, row, row.tenantId);
    rowsWithException.push(row);

    const exceptionRow = new ReportRow();
    exceptionRow.partition = partition.name;
    exceptionRow.name =
      partition.partitions_tenants[num] !== undefined
        ? partition.partitions_tenants[num].tenant_log.name
        : "";
    exceptionRow.tenantId =
      partition.partitions_tenants[num] !== undefined
        ? partition.partitions_tenants[num].tenant_log.tenant_id
        : "";
    const exceptionMeters = this.pickUpExceptionMeters(partition.meters);
    exceptionRow.info = exceptionMeters.info;
    exceptionRow.decimal = exceptionMeters.decimal;
    exceptionRow.type = exceptionMeters.type;
    this.writeMetersDataToRow(
      exceptionMeters.meters,
      exceptionRow,
      exceptionRow.tenantId
    );
    rowsWithException.push(exceptionRow);

    return rowsWithException;
  }

  // 【未確定】メーターの中に水道、動力、電灯以外がいないかチェックする
  checkExceptionMeter(meters: ReportMeters[]): boolean {
    for (const meter of meters) {
      if (
        meter.name !== "電灯" &&
        meter.name !== "動力" &&
        meter.name !== "水道" &&
        meter.name !== "ガス"
      ) {
        return true;
      }
    }
    return false;
  }

  // 【未確定】メーターの配列を引数に受け取って、特殊メーターだけを残して返す
  pickUpExceptionMeters(meters: ReportMeters[]): any {
    const exceptionMeters = new ReportExceptionMeters();
    exceptionMeters.meters = [];
    for (const meter of meters) {
      if (meter.name.indexOf("_") !== -1) {
        exceptionMeters.meters.push(meter);
        exceptionMeters.info = meter.name.substr(0, meter.name.indexOf("_"));
        exceptionMeters.type = meter.name.substr(
          meter.name.indexOf("_") + 1,
          2
        );
      }
    }
    return exceptionMeters;
  }

  // 【未確定】通常メーターのみ検知して行データに書き込みを行う(電灯動力水道ガスで共通)
  writeMetersDataToRow(meter: any, row: any, tenantId: any) {
    // 通常メーターはメーター名の完全一致のみ通す、特殊メーターで渡された場合はメーター名の部分一致でも通す
    if (
      meter.name === "電灯" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "電灯"
    ) {
      // メーター記入状況の初期化
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_detections.length !== 0) {
        // メーターが存在してかつ検針済み(value_detection配列が空じゃない)のとき、最初の要素から順にvalue_detectionをチェックする
        // メーターIDが引数のものと一致かつvalue_confirmationがnull出ない（＝確定値入力済み）の場合、書き込みを行う
        // １度書き込みを行ったらfor文は抜ける（created_atが最新のもの＝書き込み対象データが先の要素として出てきているはずのため）
        for (const valueDetection of meter.last_month_value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.lastMonth =
                valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (
        row.lastMonth === "N/A" &&
        meter.initial_value !== undefined &&
        meter.initial_value !== null
      ) {
        row.lastMonth = meter.initial_value;
      }
      if (meter.value_detections.length !== 0) {
        // 引数として連携したテナントIDに一致するときだけ書き込みを行う
        for (const valueDetection of meter.value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.thisMonth =
                valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (meter.closed_value !== undefined && meter.closed_value !== null) {
        row.thisMonth = meter.closed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        // 前月と今月の値が揃っている場合のみ差分を計算する
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        // 検針値比較ダイアログ用
        // ２ヶ月前の値があれば先月使用量の計算&アラートフラグの更新処理
        if (meter.two_months_before_value_detections.length !== 0) {
          for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
            if (twoMonthsBeforeValueDetection.tenant_id === tenantId) {
              if (twoMonthsBeforeValueDetection.value_confirmation) {
                row.lastUsed = this.calcUsedValue(
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  row.lastMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );
                row.alert = this.judgingAlertValue(
                  row.used,
                  row.lastMonth,
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  meter.max_value,
                  meter.decimal_point_position,
                  0.5
                );
                break;
              }
            }
          }
        }
      }
      // 動力の分岐
    } else if (
      meter.name === "動力" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "動力"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_detections.length !== 0) {
        for (const lastValueDetection of meter.last_month_value_detections) {
          if (lastValueDetection.tenant_id === tenantId) {
            if (lastValueDetection.value_confirmation) {
              row.lastMonth =
                lastValueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (
        row.lastMonth === "N/A" &&
        meter.initial_value !== undefined &&
        meter.initial_value !== null
      ) {
        row.lastMonth = meter.initial_value;
      }
      if (meter.value_detections.length !== 0) {
        for (const valueDetection of meter.value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.thisMonth =
                valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (meter.closed_value !== undefined && meter.closed_value !== null) {
        row.thisMonth = meter.closed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_detections.length !== 0) {
          for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
            if (twoMonthsBeforeValueDetection.tenant_id === tenantId) {
              if (twoMonthsBeforeValueDetection.value_confirmation) {
                row.lastUsed = this.calcUsedValue(
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  row.lastMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );
                row.alert = this.judgingAlertValue(
                  row.used,
                  row.lastMonth,
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  meter.max_value,
                  meter.decimal_point_position,
                  0.5
                );
                break;
              }
            }
          }
        }
      }
      // 水道の分岐
    } else if (
      meter.name === "水道" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "水道"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_detections.length !== 0) {
        for (const lastValueDetection of meter.last_month_value_detections) {
          if (lastValueDetection.tenant_id === tenantId) {
            if (lastValueDetection.value_confirmation) {
              row.lastMonth =
                lastValueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (
        row.lastMonth === "N/A" &&
        meter.initial_value !== undefined &&
        meter.initial_value !== null
      ) {
        row.lastMonth = meter.initial_value;
      }
      if (meter.value_detections.length !== 0) {
        for (const valueDetection of meter.value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.thisMonth =
                valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (meter.closed_value !== undefined && meter.closed_value !== null) {
        row.thisMonth = meter.closed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_detections.length !== 0) {
          for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
            if (twoMonthsBeforeValueDetection.tenant_id === tenantId) {
              if (twoMonthsBeforeValueDetection.value_confirmation) {
                row.lastUsed = this.calcUsedValue(
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  row.lastMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );
                row.alert = this.judgingAlertValue(
                  row.used,
                  row.lastMonth,
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  meter.max_value,
                  meter.decimal_point_position,
                  0.5
                );
                break;
              }
            }
          }
        }
      }
      // ガスの分岐
    } else if (
      meter.name === "ガス" ||
      meter.name.substr(meter.name.indexOf("_") + 1, 2) === "ガス"
    ) {
      row.id = meter.id;
      row.lastMonth = "N/A";
      row.used = "N/A";
      row.thisMonth = "N/A";
      row.maxVal = meter.max_value;
      if (meter.last_month_value_detections.length !== 0) {
        for (const lastValueDetection of meter.last_month_value_detections) {
          if (lastValueDetection.tenant_id === tenantId) {
            if (lastValueDetection.value_confirmation) {
              row.lastMonth =
                lastValueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (
        row.lastMonth === "N/A" &&
        meter.initial_value !== undefined &&
        meter.initial_value !== null
      ) {
        row.lastMonth = meter.initial_value;
      }
      if (meter.value_detections.length !== 0) {
        for (const valueDetection of meter.value_detections) {
          if (valueDetection.tenant_id === tenantId) {
            if (valueDetection.value_confirmation) {
              row.thisMonth =
                valueDetection.value_confirmation["confirmed_value"];
              break;
            }
          }
        }
      }
      if (meter.closed_value !== undefined && meter.closed_value !== null) {
        row.thisMonth = meter.closed_value;
      }
      if (row.lastMonth !== "N/A" && row.thisMonth !== "N/A") {
        row.used = this.calcUsedValue(
          row.lastMonth,
          row.thisMonth,
          meter.max_value,
          meter.decimal_point_position
        );
        if (meter.two_months_before_value_detections.length !== 0) {
          for (const twoMonthsBeforeValueDetection of meter.two_months_before_value_detections) {
            if (twoMonthsBeforeValueDetection.tenant_id === tenantId) {
              if (twoMonthsBeforeValueDetection.value_confirmation) {
                row.lastUsed = this.calcUsedValue(
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  row.lastMonth,
                  meter.max_value,
                  meter.decimal_point_position
                );
                row.alert = this.judgingAlertValue(
                  row.used,
                  row.lastMonth,
                  twoMonthsBeforeValueDetection.value_confirmation[
                    "confirmed_value"
                  ],
                  meter.max_value,
                  meter.decimal_point_position,
                  0.5
                );
                break;
              }
            }
          }
        }
      }
    }
  }

  // カード表示に戻る処理
  onClickCardList() {
    this.router.navigate(["meter-card-list"]);
  }

  // レポート確定ボタン押下時の処理
  onClickReportConfirm() {
    const dialogRef = this.dialog.open(ReportConfirmDialogComponent, {
      width: "600px",
      data: this.isAlreadyConfirmed,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "Confirm") {
        this.getReportDataFinished = false;
        this.selectedSite.disable();
        this.selectedType.disable();
        this.postConfirmedReportUrl = `${environment.apiUrl}/report_histories/report_details`;
        let params = new HttpParams();
        params = params.set("site_id", Const.site_id);
        params = params.set("report_month", String(this.selectedMonth));
        this.httpClient
          .post(this.postConfirmedReportUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response) => {
              this.doGetReportList("reload");
              this.doGetConfirmedReportData();
              const messageInfo = new MessageInfo(
                "レポートの確定が完了しました。確定レポートを表示します。",
                ""
              );
              const messageDialogRef = this.dialog.open(
                MessageDialogComponent,
                {
                  width: "500px",
                  data: messageInfo,
                }
              );
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                this.onClickLogout();
              } else {
                alert("レポートが確定できませんでした。");
              }
            }
          );
      }
    });
  }

  //指定した小数点まで切り捨てる
  confirmedValueFormat(val: string, point: number) {
    let formatedNum = "";
    if (val !== "*" && val !== "N/A") {
      let ten = new BigNumber(Math.pow(10, point));
      let tmpNum = new BigNumber(val);
      let result = tmpNum.times(ten);
      result = new BigNumber(Math.floor(Number(result)));
      let endRes = result.dividedBy(ten);
      formatedNum = endRes.toString();
    } else {
      formatedNum = val;
    }
    return formatedNum;
  }

  // レポート一覧をクリックされた時の処理
  onClickReportList() {
    const dialogRef = this.dialog.open(ReportListDialogComponent, {
      width: "1150px",
      data: this.reportList,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedMonth = result.month;
        // レポート一覧で押下されたボタンによって確定済or未確定データの表示を分ける
        this.selectedType = new UntypedFormControl("すべて");
        if (result.isConfirmed) {
          this.confirmFlg = true;
          this.doGetConfirmedReportData();
        } else {
          this.confirmFlg = false;
          this.doGetReportData();
        }
      }
    });
  }

  // 当月検針値（の横のカメラ）をクリックした時の処理
  onClickThisMonth(
    partition: string,
    tenantName: string,
    meterId: string,
    checkedMonth: string,
    tenantId: string,
    label: string
  ) {
    this.getOneMeterDataUrl = `${environment.apiUrl}/meters/${meterId}/tenants/${tenantId}/${checkedMonth}/value_detections`;
    this.httpClient
      .get(this.getOneMeterDataUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetInSiteMeterResponse) => {
          this.meter = response.result.meter;
          const correctDialogData = new CorrectDialogData(
            partition,
            tenantName,
            this.meter,
            this.loginUser,
            this.canEdit,
            "report",
            tenantId,
            checkedMonth,
            label
          );
          const dialogRef = this.dialog.open(ValueCorrectDialogComponent, {
            width: "400px",
            data: correctDialogData,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result === "dialog close") {
              this.doGetReportList("reload");
              this.doGetReportData();
            }
          });
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("メーター情報が取得できませんでした。");
          }
        }
      );
  }

  // 当月使用量をクリックした時の処理
  // 引数からダイアログ表示用データを生成して、ダイアログ表示コンポーネントに連携する
  onClickUsed(
    meterType: string,
    partition: string,
    name: string,
    info: string,
    decimal: number,
    thisMonthUsed: string,
    lastMonthUsed: string,
    alertFlag: number
  ) {
    let rateOfChangeStr: string;
    if (!lastMonthUsed) {
      lastMonthUsed = "N/A";
      rateOfChangeStr = "N/A";
    } else {
      let tmUsed = new BigNumber(thisMonthUsed);
      let lmUsed = new BigNumber(lastMonthUsed);

      const rateOfChangeTemp = tmUsed.minus(lmUsed);
      let rateOfChange = rateOfChangeTemp.dividedBy(lmUsed);
      rateOfChange = rateOfChange.times(100);

      rateOfChangeStr = this.rateChangeStr(Number(rateOfChange));
    }
    const compareDialogData = new CompareDialogData(
      partition,
      name,
      info,
      decimal,
      meterType,
      thisMonthUsed,
      lastMonthUsed,
      rateOfChangeStr,
      alertFlag
    );
    const dialogRef = this.dialog.open(ValueCompareDialogComponent, {
      width: "400px",
      data: compareDialogData,
    });
  }

  // CSVでのレポート出力処理（1クリックで２種類のレポート同時ダウンロード）
  onClickCSV() {
    let tmpReportData: ReportRow[];
    if (String(this.selectedType.value) !== "すべて") {
      tmpReportData = this.reportDataBackUp;
    } else {
      tmpReportData = this.reportData;
    }
    // 電灯・動力・水道、ガスのレポートCSV生成〜ダウンロード
    let csvData =
      "区画,テナント名称,メーター名称,メーター番号,メーター種類,前月確定値,当月確定値,当月使用量\r\n";
    for (const row of tmpReportData) {
      if (!row.info) {
        row.info = "";
      }
      if (!row.type) {
        row.type = "";
      }
      const rowData =
        row.partition.replace(",", "、") +
        ", " +
        row.name.replace(",", "、") +
        ", " +
        row.info +
        ", " +
        row.label +
        ", " +
        row.type +
        ", " +
        this.confirmedValueFormat(row.lastMonth, row.decimal) +
        ", " +
        this.confirmedValueFormat(row.thisMonth, row.decimal) +
        ", " +
        this.confirmedValueFormat(row.used, row.decimal) +
        "\r\n";
      const rowDataFormated = rowData.replace(/\*/g, "").replace(/N\/A/g, "");
      csvData = csvData + rowDataFormated;
    }
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvData], { type: "csv/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = String(this.selectedMonth) + "kenshin-report.csv";
    link.download = fileName;
    link.click();

    // ガスのレポートCSV生成〜ダウンロード
    /*    let gasCsvData =
      "コード,系統名称,,メーター番号,前月確定値(ガス),当月確定値(ガス),当月使用量(ガス)\r\n";
    for (const row of this.gasReportData) {
      if (!row.info) {
        row.info = "";
      }
      const rowData =
        row.partition.replace(",", "、") +
        ", " +
        row.name.replace(",", "、") +
        ", " +
        row.info +
        ", " +
        row.label +
        ", " +
        row.gasLastMonth +
        ", " +
        row.gasThisMonth +
        ", " +
        row.gasUsed +
        "\r\n";*/
    //   const rowDataFormated = rowData.replace(/\*/g, "").replace(/N\/A/g, "");
    /*   gasCsvData = gasCsvData + rowDataFormated;
    }
      const gasBlob = new Blob([bom, gasCsvData], { type: "csv/plain" });
      const gasLink = document.createElement("a");
      gasLink.href = window.URL.createObjectURL(gasBlob);
      const gasFileName = String(this.selectedMonth) + "kenshin-gas-report.csv";
      gasLink.download = gasFileName;
      gasLink.click();*/
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  // ログアウト処理
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth =
      String(yearMonth).substr(0, 4) +
      "年" +
      String(yearMonth).substr(4) +
      "月";
    return formatedMonth;
  }

  // 使用量比較画面で表示する時にに正負と％を補正
  // 変化0の時と、倍率無限の時の補正
  rateChangeStr(rate: number) {
    if (rate > 0 && rate !== Infinity) {
      return "+" + String(this.rounding(rate)) + "%";
    } else if (!rate) {
      const zero = "0%";
      return zero;
    } else if (rate < 0) {
      return String(this.rounding(rate)) + "%";
    } else {
      const infinity = "∞%";
      return infinity;
    }
  }

  // 小数点第３位で四捨五入かつ小数点第２位までは必ず表示するよう補正する処理
  rounding(val: number) {
    const returnNumber = Number(val).toFixed(2);
    return returnNumber;
  }

  roundingUsed(val: number, decimal: number) {
    const returnNumber = Number(val).toFixed(decimal);
    return returnNumber;
  }

  usedValueFormat(val: number, point: number): Number {
    let ten = new BigNumber(Math.pow(10, point));
    let tmpNum = new BigNumber(val);
    let result = tmpNum.times(ten);
    result = new BigNumber(Math.floor(Number(result)));
    let endRes = result.dividedBy(ten);
    return Number(endRes);
  }

  // メーター１周を考慮しつつ、当月使用量を計算する処理
  calcUsedValue(
    lastMonthValue: any,
    thisMonthValue: any,
    maxValue: string,
    decimalPoint: number
  ) {
    if (
      lastMonthValue !== "N/A" &&
      lastMonthValue !== "*" &&
      thisMonthValue !== "N/A" &&
      thisMonthValue !== "*"
    ) {
      // 今月の検針値が先月の検針値より小さい場合、メーター最大値を足して返す
      if (Number(lastMonthValue) > Number(thisMonthValue)) {
        let tempThisMonth = this.usedValueFormat(thisMonthValue, decimalPoint);
        let tempLastMonth = this.usedValueFormat(lastMonthValue, decimalPoint);
        let tempMaxV = this.usedValueFormat(Number(maxValue), decimalPoint);

        let thisMonth = new BigNumber(Number(tempThisMonth));
        let lastMonth = new BigNumber(Number(tempLastMonth));
        let maxV = new BigNumber(Number(tempMaxV));

        let usedValue = maxV.minus(lastMonth).plus(thisMonth);

        let endUsedValue = this.usedValueFormat(
          Number(usedValue),
          decimalPoint
        );
        return endUsedValue;
      } else {
        let tempThisMonth = this.usedValueFormat(thisMonthValue, decimalPoint);
        let tempLastMonth = this.usedValueFormat(lastMonthValue, decimalPoint);

        let thisMonth = new BigNumber(Number(tempThisMonth));
        let lastMonth = new BigNumber(Number(tempLastMonth));

        let usedValue = thisMonth.minus(lastMonth);

        let endUsedValue = this.usedValueFormat(
          Number(usedValue),
          decimalPoint
        );

        return endUsedValue;
      }
    } else {
      return "N/A";
    }
  }

  // 前月使用量（前月検針値-前々月検針値）と当月使用量（当月検針値-前月検針値）を比較して、異常基準値の割合（今回は50％）以上増減している場合フラグ変更
  // 過減少は1、過増加は2
  // 当月使用量（計算済）、前月検針値、前々月検針値、メーター最大値、異常基準値を引数にとる
  judgingAlertValue(
    thisMonthUsed: string,
    lastMonthValue: string,
    twoMonthsBeforeValue: string,
    maxValue: string,
    decimal: number,
    judgementValue: number
  ) {
    const lastMonthUsed = this.calcUsedValue(
      twoMonthsBeforeValue,
      lastMonthValue,
      maxValue,
      decimal
    );

    if (lastMonthUsed === "N/A") {
      const diff = Number(thisMonthUsed) - Number(lastMonthUsed);
      let diffPer = diff / Number(lastMonthUsed);
      if (diffPer < 0) {
        diffPer = diffPer * -1;
        if (diffPer >= judgementValue) {
          return 1;
        }
      } else {
        if (diffPer >= judgementValue) {
          return 2;
        }
      }
      return 0;
    } else {
      let tmUsed = new BigNumber(thisMonthUsed);
      let lmUsed = new BigNumber(Number(lastMonthUsed));

      const diff = new BigNumber(tmUsed.minus(lmUsed));
      let diffPer = new BigNumber(diff.dividedBy(lmUsed));

      if (Number(diffPer) < 0) {
        diffPer = diffPer.times(-1);
        if (Number(diffPer) >= judgementValue) {
          return 1;
        }
      } else {
        if (Number(diffPer) >= judgementValue) {
          return 2;
        }
      }
      return 0;
    }
  }

  ngOnDestroy() {}
  loadMeterData() {
    this.expMeterData = [];
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonObj = JSON.parse(JSON.stringify(res));
          if (jsonObj.status == 200) {
            let jsonFloors = jsonObj.result.site.floors;
            let site_id = jsonObj.result.site.id;
            this.getPartitionTenantData();
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              const partitions = floor.partitions;
              for (let index = 0; index < partitions.length; index++) {
                const partition = partitions[index];
                for (let index = 0; index < partition.meters.length; index++) {
                  const meterObj = partition.meters[index];
                  let meterModel = meterObj as MeterInfo;

                  meterModel.partName = partition.name;
                  if (
                    partition.partitions_tenants !== undefined &&
                    partition.partitions_tenants.length > 0
                  ) {
                    meterModel.tenantName =
                      partition.partitions_tenants[0].tenant_log.name;
                    meterModel.site_id = site_id;
                    meterModel.floor_id = floor.id;
                    meterModel.floor_name = floor.name;
                  }
                  if (
                    Number(this.loginUser.user_type) !== this.userTypeSysAdmin
                  ) {
                    if (
                      meterModel.expiration_date !== null &&
                      meterModel.expiration_date !== undefined &&
                      meterModel.expiration_date !== ""
                    ) {
                      var day = new Date();
                      day.setMonth(day.getMonth() + 24);
                      const dayOfNextYear = formatDate(
                        day,
                        "yyyyMMdd",
                        this.locale
                      );
                      const expiration = formatDate(
                        meterModel.expiration_date,
                        "yyyyMMdd",
                        this.locale
                      );
                      if (expiration < dayOfNextYear) {
                        meterModel.isExpireMeterFlag = true;

                        let expMeterModel = meterObj as MeterInfo;
                        expMeterModel = meterModel;
                        this.expMeterData.push(expMeterModel);
                      }
                    }
                  }
                }
              }
            }
            // ソート
            if (this.expMeterData.length > 0) {
              this.expMeterData.sort((n1, n2) => {
                if (n1.expiration_date > n2.expiration_date) {
                  return 1;
                }
                if (n1.expiration_date < n2.expiration_date) {
                  return -1;
                }
                return 0;
              });
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
  getPartitionTenantData() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/tenants/${thisMonth}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.partitionTenants = response.result.site.partitions;
          this.partitionTenants = this.partitionTenants.filter(
            (item) => item.partitions_tenants.length != 0
          );
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
}
