import { LoginUserSiteCompany } from './login-user-site-company';

export class LoginUserSite {
    id: string;
    name: string;
    address: string;
    created_at: string;
    updated_at: string;
    company: LoginUserSiteCompany;
}
