// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerTitleArea {
  align-self: center;
}

.tableArea {
  align-self: center;
}

.footerArea {
  align-self: end;
}

.meterRed {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-detail-dialog/meter-detail-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,UAAA;AAIJ","sourcesContent":[".headerTitleArea{\n    align-self: center;\n}\n.tableArea{\n    align-self: center;\n}\n.footerArea{\n    align-self: end;\n}\n.meterRed{\n    color: red\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
