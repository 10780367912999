import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { PatchTenantCancellResponse } from "../entity/patch-tenant-cancell-response";
import { TenantConfirmDialogInfo } from "../entity/tenant-confirm-dialog-info";
import { TenantRow } from "../entity/tenant-row";
import { TenantConfirmDialogComponent } from "../tenant-confirm-dialog/tenant-confirm-dialog.component";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-tenant-delete-dialog",
  templateUrl: "./tenant-delete-dialog.component.html",
  styleUrls: ["./tenant-delete-dialog.component.scss"],
})
export class TenantDeleteDialogComponent implements OnInit {
  date: Date;
  minDate: Date;
  messageInfo: TenantConfirmDialogInfo;
  idToken: string;
  site: LoginUserSite;

  closedValue = this.fb.group({
    items: this.fb.array([]),
  });
  isClosedValue = false;
  isMeter = true;
  constructor(
    public tenantConfirmDialog: MatDialog,
    public dialogRef: MatDialogRef<TenantDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public tenantRow: TenantRow,
    @Inject(LOCALE_ID) private locale: string,
    dateAdapter: DateAdapter<NativeDateAdapter>,
    private httpClient: HttpClient,
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    dateAdapter.setLocale("ja");
  }

  // ダイアログオープン時、解約日が入力されていれば初期値に設定
  // 契約日より前の日付では解約できないようにカレンダーを制御
  ngOnInit() {
    this.site = this.data.site;
    if (this.data.tenantRow.cancelledAt) {
      this.date = new Date(this.data.tenantRow.cancelledAt);
    }
    this.minDate = new Date(this.data.tenantRow.contractedAt);
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.closedValueSetup();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  checkClosedValue() {
    for (const val of this.closedValues.value) {
      if (
        val.meterLabel === undefined ||
        val.meterLabel === null ||
        val.meterLabel === "" ||
        val.meterValue === undefined ||
        val.meterValue === null ||
        val.meterValue === ""
      ) {
        this.isClosedValue = false;
        return;
      }
    }
    this.isClosedValue = true;
  }

  // 追加分の入力フォーム
  get optionForm(): UntypedFormGroup {
    return this.fb.group({
      meterLabel: [],
      meterValue: [],
    });
  }

  closedValueSetup() {
    if (this.data.tenantRow.closedValue) {
      let itemsTemp = this.fb.array([]);
      for (const val of JSON.parse(this.data.tenantRow.closedValue)) {
        itemsTemp.push(
          this.fb.group({
            meterName: val.meterName,
            meterLabel: val.meterLabel,
            meterValue: val.meterValue,
          })
        );
      }
      this.closedValue.setControl("items", itemsTemp);
      this.isClosedValue = true;
    } else {
      this.getMeterLabelList();
    }
  }

  getMeterLabelList() {
    const url = `${environment.apiUrl}/sites/${Const.site_id}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          let itemsTemp = this.fb.array([]);

          for (const floor of response["result"]["site"]["floors"]) {
            for (const partition of floor["partitions"]) {
              if (
                partition.id === this.data.tenantRow.partitionId &&
                partition["meters"].length > 0
              ) {
                const meters = partition["meters"];
                // old_meter_idの配列を用意
                const oldMeterIds = meters
                  .map((value) => {
                    return value["old_meter_id"];
                  })
                  .filter((item) => {
                    return item != null; // Nullも除く
                  });

                meters.forEach((value) => {
                  // 旧メーターは含めない（old_meter_idに該当したidは含めない）
                  if (!oldMeterIds.includes(value["id"])) {
                    itemsTemp.push(
                      this.fb.group({
                        meterName: value["name"],
                        meterLabel: value["label"],
                        meterValue: [],
                      })
                    );
                  }
                });
                break;
              }
            }
          }
          if (itemsTemp.value.length <= 0) {
            this.isClosedValue = true;
            this.isMeter = false;
            return;
          }

          this.closedValue.setControl("items", itemsTemp);
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("区画一覧が取得できませんでした。");
          }
        }
      );
  }

  // 可変枠の抽出
  get closedValues(): UntypedFormArray {
    return this.closedValue.get("items") as UntypedFormArray;
  }

  checkIfReadyToGo() {
    return this.isClosedValue && this.date;
  }

  // 解約ボタンを押した時の処理
  // 既存の解約日の有無でメッセージを変える
  onClickConfirm() {
    if (this.date) {
      if (this.data.tenantRow.cancelledAt) {
        this.messageInfo = new TenantConfirmDialogInfo(
          "解約確認",
          "解約情報を上書きします。よろしいですか。"
        );
      } else {
        this.messageInfo = new TenantConfirmDialogInfo(
          "解約確認",
          "テナントの解約を確定します。よろしいですか。"
        );
      }
      const tenantConfirmDialogRef = this.tenantConfirmDialog.open(
        TenantConfirmDialogComponent,
        {
          width: "650px",
          data: this.messageInfo,
        }
      );
      tenantConfirmDialogRef.afterClosed().subscribe((result) => {
        if (result === "Yes") {
          const patchCancellTenantUrl = `${environment.apiUrl}/partitions/${this.data.tenantRow.partitionId}/tenants/${this.data.tenantRow.tenantId}`;
          let params = new HttpParams();
          params = params.set(
            "cancelled_at",
            String(formatDate(this.date, "yyyy-MM-dd hh:mm:ss", this.locale))
          );
          params = params.set(
            "closed_value",
            JSON.stringify(this.closedValues.value)
          );
          this.httpClient
            .patch(patchCancellTenantUrl, params, {
              headers: new HttpHeaders({
                Authorization: this.idToken,
              }),
            })
            .subscribe(
              (response: PatchTenantCancellResponse) => {
                this.dialogRef.close("close");
              },
              (err) => {
                if (err.error.code == "AccountLockError") {
                  alert(
                    "アカウントがロックされました。管理者までお問合せください"
                  );
                  Const.release();
                  this.auth.signOut();
                  this.dialogRef.close("lockedAccount");
                } else {
                  alert("テナント解約に失敗しました。");
                }
              }
            );
        }
      });
    }
  }

  // 解約取消ボタン押下時の処理（パラメータに''を設定してpatch）
  onClickDeleteCancell() {
    this.messageInfo = new TenantConfirmDialogInfo(
      "解約取消",
      "解約を取り消します。よろしいですか。"
    );
    const tenantConfirmDialogRef = this.tenantConfirmDialog.open(
      TenantConfirmDialogComponent,
      {
        width: "650px",
        data: this.messageInfo,
      }
    );
    tenantConfirmDialogRef.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        const patchCancellTenantUrl = `${environment.apiUrl}/partitions/${this.data.tenantRow.partitionId}/tenants/${this.data.tenantRow.tenantId}`;
        let params = new HttpParams();
        params = params.set("cancelled_at", "");
        this.httpClient
          .patch(patchCancellTenantUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response: PatchTenantCancellResponse) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("lockedAccount");
              } else {
                alert("テナント解約取消に失敗しました。");
              }
            }
          );
      }
    });
  }
}
