import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { formatDate } from "@angular/common";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments/environment";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { FloorInfo } from "../entity/floor-info";
import { LoginUserSite } from "../entity/login-user-site";
import { Const } from "../const/const";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MeterInfo } from "../entity/get-meter-info";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { GetReportListResponse } from "../entity/get-report-list-response";
import { ReportListInfo } from "../entity/report-list-info";
import { GetReportConfirmedDataResponse } from "../entity/get-confirmed-report-data-response";
import { MeterCardListConfirmedSevice } from "./services/meter-card-list-confirmed.sevice";
import { ReportCard } from "../entity/report-card";
import { MeterCardListPendingService } from "./services/meter-card-list-pending.service";
import { MeterCardListCommonService } from "./services/meter-card-list-common.service";

@Component({
  selector: "app-meter-card-list",
  templateUrl: "./meter-card-list.component.html",
  styleUrls: ["./meter-card-list.component.scss"],
  providers:[MeterCardListConfirmedSevice, MeterCardListPendingService, MeterCardListCommonService] // サービスクラスへのDI
})
export class MeterCardListComponent implements OnInit {
  selectedSite = new UntypedFormControl();
  selectedMonth = new UntypedFormControl(
    formatDate(new Date(), "yyyyMM", this.locale)
  );

  monthArray: string[];
  siteArray: LoginUserSite[];

  // カード表示用のデータ
  reportCards: ReportCard[];
  
  loginUser: LoginUser;
  formControl: UntypedFormGroup;
  nowFloorId: string;
  floorsInfo: FloorInfo[];
  currentFloorName = "";
  // タブクリック時に連携されるフロア名とAPI叩く用のフロアIDを紐付ける
  floorsNameIdMap = new Map();
  idToken: string;
  //debug
  isReviewed: Array<{ key: string; value: boolean }>;
  reviewed: boolean;

  // レポートリスト表示用
  reportList: ReportListInfo[];

  // APIのレスポンス
  private getReportConfirmedDataResponse: GetReportConfirmedDataResponse

  // フラグ類
  getUserFinished: boolean;
  getValueDetectionFinished: boolean;
  canEdit: boolean;
  bookMarkFlag: boolean; //ByChen
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;
  // 有効切れメーターリスト
  expMeterData: MeterInfo[] = [];
  partitionTenants: GetTenantsPartition[];

  //レポートが確定されたかどうか判別できるフラグ
  confirmedReportFlg = false;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    // サービスクラス
    private meterCardListConfirmedSevice: MeterCardListConfirmedSevice,
    private meterCardListPendingService: MeterCardListPendingService
  ) {
    localStorage.setItem("path", router.url);
  }
  //Working
  handleisReviewedChanged(
    data: Array<{ key: string; value: boolean }>,
    meter_id: string
  ) {
    const result = data.find((item) => item.key === meter_id);
    this.reviewed = result ? result.value : null;
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.siteArray = [];
    this.reportList = [];
    this.floorsNameIdMap = new Map();
    this.floorsInfo = [];
    this.monthArray = [];
    this.getUserFinished = false;
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    this.canEdit = false;
    this.bookMarkFlag = false;
    this.makeMonthArray();
    this.expMeterData = [];
    this.confirmedReportFlg = false;
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of Const.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.loadMeterData();
    this.doGetFloors();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        () => {
          alert("施設情報が取得できませんでした。");
        }
      );
  }

  // 今月の値から2019年9月までの月情報を「yyyymm」形式の文字列の配列にする処理
  makeMonthArray() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);
    this.monthArray.push(thisMonth);
    const endMonth = new Date(2019, 9, 1);
    for (let a: number; endMonth <= dateData; a++) {
      dateData.setMonth(dateData.getMonth() - 1);
      const formatedMonth = formatDate(dateData, "yyyyMM", this.locale);
      this.monthArray.push(formatedMonth);
    }
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth =
      String(yearMonth).substr(0, 4) +
      "年" +
      String(yearMonth).substr(4) +
      "月";
    return formatedMonth;
  }

  // 月変更された時の処理
  doChangeMonth() {
    this.createCardDataJob();
  }

  // 施設選択
  async doChangeSite() {
    // ローディング表示
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    this.loadMeterData();
    // フロア情報取得
    this.doGetFloors();
    this.doGetReportList();
  }

  // idトークンとsite_idの情報からフロア情報の取得を行う
  doGetFloors() {
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.floorsInfo = [];
    this.currentFloorName = "";
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;
          this.nowFloorId = this.floorsInfo[0].id;
          for (const floor of this.floorsInfo) {
            this.floorsNameIdMap.set(floor.name, floor.id);
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            this.getValueDetectionFinished = true;
            this.selectedMonth.enable();
            this.selectedSite.enable();

            // 初期化
            this.reportCards = [];
            alert("フロア情報が取得できませんでした。");
          }
        }
      );
  }
  
  /**
   * カードデータ作成の一連処理
   */
  async createCardDataJob() {
    this.getValueDetectionFinished = false;
    this.selectedMonth.disable();
    this.selectedSite.disable();

    try {
      // 初期化
      this.reportCards = [];

      if (await this.judgeGetReportData()){
        // レポートデータから作成
        this.reportCards = this.meterCardListConfirmedSevice.createPartitionsArrayForReportData(
          this.getReportConfirmedDataResponse,
          this.nowFloorId
        )
        this.confirmedReportFlg = true;
      } else {
        this.reportCards = await this.meterCardListPendingService.createPartitionsArrayForReportData(
          this.idToken,
          this.nowFloorId,
          this.selectedSite.value,
          this.selectedMonth.value
        );
        this.confirmedReportFlg = false;
      }
    } catch (error) {
      console.log(error);
      if (error.status == "404") {
        alert("データが見つかりませんでした。");
      } else if (error.status == "401") {
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      } else {
        alert("データが取得できませんでした。");
      }
    } finally {
      this.getValueDetectionFinished = true;
      this.selectedMonth.enable();
      this.selectedSite.enable();
    }
  }

  /**
   * 確定レポートデータから取得するか、未確定データからの取得をするか判定する
   * @returns レポート取得かどうかの結果：Promise<boolean>
   * - true:確定レポートデータから取得/false:未確定
   */
  async judgeGetReportData(): Promise<boolean> {

    // レポートの年月がシステム日付で今月の場合は未確定
    const today = new Date();
    if (this.selectedMonth.value == formatDate(today, "yyyyMM", this.locale)) {
      return false;
    }

    const url = `${environment.apiUrl}/report_histories/${this.selectedSite.value}/${this.selectedMonth.value}/report_details`;

    try {
      this.getReportConfirmedDataResponse = await this.httpClient
        .get<GetReportConfirmedDataResponse>(url, {
          headers: new HttpHeaders({
            Authorization: this.idToken,
          }),
        })
        .toPromise();
      return true;
    } catch (error) {
      console.log(error);

      // 404エラー（確定レポートが見つからない）場合はfalseを返却、それ以外はthrow
      if (error.status == "404"){
        return false;
      } else {
        throw error;
      }
    }
  }

  // タブがクリックされた時の処理
  // 引数からフロア名取得⇨MapでIDに変換⇨検針値取得API叩く
  doClickTab(MatTagChangevent: any) {
    this.currentFloorName = MatTagChangevent.tab.textLabel;
    const newFloorId = this.floorsNameIdMap.get(MatTagChangevent.tab.textLabel);
    this.nowFloorId = newFloorId;

    this.createCardDataJob();
  }

  doGetReportList() {
    const url = `${environment.apiUrl}/report_list/${this.selectedSite.value}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetReportListResponse) => {
          this.reportList = [];
          const reportListData = response.result.report_list;
          for (const oneReportData of reportListData) {
            const reportMonth = Number(oneReportData.report_month);
            let fixedAt = null;
            if (oneReportData.confirmed_report_created_at) {
              fixedAt = String(
                formatDate(
                  oneReportData.confirmed_report_created_at,
                  "yyyyMM",
                  this.locale
                )
              );
            }
            const reportInfo = new ReportListInfo(reportMonth, fixedAt, "", "");
            this.reportList.push(reportInfo);
          }
          this.confirmedReportFlg = false;
          for (const report of this.reportList) {
            if (
              String(report.month) === String(this.selectedMonth.value) &&
              report.fixedTime
            ) {
              this.confirmedReportFlg = true;
              break;
            }
          }
        },
        (err) => {
          console.log(err);
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            // 初期化
            this.reportCards = [];
            alert("レポート一覧が取得できませんでした。");
          }
        }
      );
  }

  doGetLoginUser() {
    const url = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  get flagImgUrl() {
    return this.bookMarkFlag
      ? "../assets/filter_bookmark_on.png"
      : "../assets/filter_bookmark_off.png";
  }

  // レポート画面遷移処理
  onClickReport() {
    this.router.navigate(["meter-report"]);
  }
  onClickReport_bookmark() {
    this.bookMarkFlag = !this.bookMarkFlag;
    this.doSetValue();
  }

  onClickMasterEdit() {
    this.router.navigate(["master-edit"]);
  }

  onClickCardList() {
    this.router.navigate(["meter-card-list"]);
  }

  loadMeterData() {
    this.expMeterData = [];
    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonObj = JSON.parse(JSON.stringify(res));
          if (jsonObj.status == 200) {
            let jsonFloors = jsonObj.result.site.floors;
            let site_id = jsonObj.result.site.id;
            this.getPartitionTenantData();
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              const partitions = floor.partitions;
              for (let index = 0; index < partitions.length; index++) {
                const partition = partitions[index];
                for (let index = 0; index < partition.meters.length; index++) {
                  const meterObj = partition.meters[index];
                  let meterModel = meterObj as MeterInfo;
                  meterModel.partName = partition.name;
                  if (
                    partition.partitions_tenants !== undefined &&
                    partition.partitions_tenants.length > 0
                  ) {
                    meterModel.tenantName =
                      partition.partitions_tenants[0].tenant_log.name;
                    meterModel.site_id = site_id;
                    meterModel.floor_id = floor.id;
                    meterModel.floor_name = floor.name;
                  }
                  if (
                    Number(this.loginUser.user_type) !== this.userTypeSysAdmin
                  ) {
                    if (
                      meterModel.expiration_date !== null &&
                      meterModel.expiration_date !== undefined &&
                      meterModel.expiration_date !== ""
                    ) {
                      var day = new Date();
                      day.setMonth(day.getMonth() + 24);
                      const dayOfNextYear = formatDate(
                        day,
                        "yyyyMMdd",
                        this.locale
                      );
                      const expiration = formatDate(
                        meterModel.expiration_date,
                        "yyyyMMdd",
                        this.locale
                      );
                      if (expiration < dayOfNextYear) {
                        meterModel.isExpireMeterFlag = true;

                        let expMeterModel = meterObj as MeterInfo;
                        expMeterModel = meterModel;
                        this.expMeterData.push(expMeterModel);
                      }
                    }
                  }
                }
              }
            }
            // ソート
            if (this.expMeterData.length > 0) {
              this.expMeterData.sort((n1, n2) => {
                if (n1.expiration_date > n2.expiration_date) {
                  return 1;
                }
                if (n1.expiration_date < n2.expiration_date) {
                  return -1;
                }
                return 0;
              });
            }
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }

  getPartitionTenantData() {
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);

    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/tenants/${thisMonth}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.partitionTenants = response.result.site.partitions;
          this.partitionTenants = this.partitionTenants.filter(
            (item) => item.partitions_tenants.length != 0
          );
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }
}
