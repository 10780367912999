import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { Const } from "../const/const";
import { FloorInfo } from "../entity/floor-info";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { PartitionConfirmDialogInfo } from "../entity/partition-confirm-dialog-info";
import { LoginUserSite } from "../entity/login-user-site";

class Partition {
  id: string;
  name: string;
  no: number;
  is_card_display: number;
  report_type: number;
  floor_id: string;
  floor_name: string;
  floor_no: number;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: "app-partition-add-dialog",
  templateUrl: "./partition-add-dialog.component.html",
  styleUrls: ["./partition-add-dialog.component.scss"],
})
export class PartitionAddDialogComponent implements OnInit {
  loginUser: LoginUser;
  idToken: string;
  partitionName: string;
  selectedFloor = new UntypedFormControl();
  partitionNumber: number;
  partitioReportTypeFlag: boolean;
  partitioIsCardDisplayFlag: boolean;
  errorPartitionNameMsg: string;
  errorPartitionNumberMsg: string;
  partitionsInfo: Partition[];
  site: LoginUserSite;

  // APIのURL
  getLoginUserUrl: string;
  getFloorsUrl: string;

  // APIのレスポンス
  getFloorsResponse: GetFloorsResponse;
  floorsInfo: FloorInfo[];

  // フラグ類
  getUserFinished: boolean;
  getFloorFinished: boolean;

  constructor(
    public partitionConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PartitionAddDialogComponent>,
    public auth: AuthService,
    private router: Router,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.partitionsInfo = this.data.partitionsInfo;
    this.partitioReportTypeFlag = true;
    this.partitioIsCardDisplayFlag = true;
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
        this.doGetLoginUser();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }
  doGetLoginUser() {
    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          this.loginUser = response.result.login_user;
          this.getUserFinished = true;
          this.getFloorsUrl = `${environment.apiUrl}/sites/${this.data.siteId}/floors`;
          this.doGetFloors();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.dialogRef.close("lockedAccount");
          Const.release();
          this.auth.signOut();
        }
      );
  }

  doGetFloors() {
    this.getFloorFinished = false;

    this.httpClient
      .get(this.getFloorsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;
          this.selectedFloor = new UntypedFormControl(this.floorsInfo[0].id);
          this.getFloorFinished = true;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("lockedAccount");
          } else {
            alert("フロア情報が取得できませんでした。");
            this.floorsInfo = null;
            this.getFloorFinished = true;
          }
        }
      );
  }

  checkIfReadyToGo() {
    let hasNameChanged = false;

    if (
      this.partitionName == undefined ||
      this.partitionName == null ||
      this.partitionName == "" ||
      this.partitionName == "未登録" ||
      this.partitionNumber == undefined ||
      this.partitionNumber == null
    ) {
      hasNameChanged = true;
    } else {
      for (var i = 0; i < this.partitionsInfo.length; i++) {
        if (this.partitionNumber == this.partitionsInfo[i].no) {
          hasNameChanged = true;
          break;
        }
      }
    }

    return hasNameChanged;
  }

  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }

    const messageInfo = new PartitionConfirmDialogInfo(
      "登録確認",
      "区画を新規登録します。よろしいですか。"
    );
    const confirmDialog = this.partitionConfirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "400px",
        data: messageInfo,
      }
    );

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        // APIコールして登録処理
        const postPartitionUrl = `${environment.apiUrl}/partitions/partition_info`;
        let params = new HttpParams();
        params = params.set("name", this.partitionName);
        params = params.set("no", this.partitionNumber.toString());
        params = params.set("floor_id", this.selectedFloor.value);
        params = params.set(
          "report_type",
          `${this.partitioReportTypeFlag ? 1 : 0}`
        );
        params = params.set(
          "is_card_display",
          `${this.partitioIsCardDisplayFlag ? 1 : 0}`
        );
        this.httpClient
          .post(postPartitionUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                this.dialogRef.close("lockedAccount");
                Const.release();
                this.auth.signOut();
              } else {
                alert("区画情報の登録に失敗しました。");
              }
            }
          );
      }
    });
  }

  changePartitionValue() {
    this.errorPartitionNameMsg = "";
    this.errorPartitionNumberMsg = "";

    if (
      this.partitionName == undefined ||
      this.partitionName == null ||
      this.partitionName == "" ||
      this.partitionNumber == undefined ||
      this.partitionNumber == null
    ) {
      this.errorPartitionNameMsg = "区画名と表示順を入力してください";
    } else if (this.partitionName == "未登録") {
      this.errorPartitionNameMsg = "区画名が「未登録」の区画は登録できません";
    } else {
      for (var i = 0; i < this.partitionsInfo.length; i++) {
        if (this.partitionNumber == this.partitionsInfo[i].no) {
          this.errorPartitionNumberMsg = "入力した表示順はすでに存在します";
          break;
        }
      }
    }
  }
}
