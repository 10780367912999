import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { FloorInfo } from "../entity/floor-info";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { GetInSiteValueDetectionsResponse } from "../entity/get-in-site-value-detections-response";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { LoginUserSite } from "../entity/login-user-site";
import { Partition } from "../entity/partition";
import { MeterInfo } from "../entity/get-meter-info";
import { MatDialog } from "@angular/material/dialog";
import { MeterAddDialogComponent } from "../meter-add-dialog/meter-add-dialog.component";
import { MeterQrcodeDialogComponent } from "../meter-qrcode-dialog/meter-qrcode-dialog.component";
import { MeterEditDialogComponent } from "../meter-edit-dialog/meter-edit-dialog.component";
import { MeterDeleteDialogComponent } from "../meter-delete-dialog/meter-delete-dialog.component";
import "rxjs/add/operator/debounceTime";
import { Subject } from "rxjs";
import { MeterType } from "../entity/get-meter-type";
import { Unit } from "../entity/unit";
import { MeterCsvBatchDialogComponent } from "../meter-csv-batch-dialog/meter-csv-batch-dialog.component";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { GetTenantsResponse } from "../entity/get-tenants-response";
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { MatSpinner } from "@angular/material/progress-spinner";
import { MeterConfirmDialogInfo } from "../entity/meter-confirm-dialog-info";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { MessageInfo } from "../entity/message-info";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { Const } from "../const/const";
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";
import { MeterDetailDialogComponent } from "../meter-detail-dialog/meter-detail-dialog.component";
import { MeterReplaceDialogComponent } from "../meter-replace-dialog/meter-replace-dialog.component";

@Component({
  selector: "app-meter-management-list",
  templateUrl: "./meter-management-list.component.html",
  styleUrls: ["./meter-management-list.component.scss"],
})
export class MeterManagementListComponent implements OnInit {
  selectedSite = new UntypedFormControl();

  monthArray: string[];
  siteArray: LoginUserSite[];

  partitionsArray: Partition[];
  partitionTenants: GetTenantsPartition[];

  loginUser: LoginUser;
  formControl: UntypedFormGroup;
  siteId: string;

  floorsNameIdMap = new Map();
  nowFloorId: string;
  floorsInfo: FloorInfo[];
  currentFloorName: string = "";

  allMeterData: MeterInfo[] = [];
  expMeterData: MeterInfo[] = [];
  currentFloorMeterData: MeterInfo[];
  filtredMeterData: MeterInfo[];

  meterTypeData: MeterType[];
  unitData: Unit[];
  site = new LoginUserSite();

  // テーブル生成用
  displayedColumns: string[];

  idToken: string;
  selectedIndex: number;

  // APIのURL
  getLoginUserUrl: string;
  getValueDetectionsUrl: string;
  getFloorsUrl: string;
  postValueConfirmationUrl: string;
  // APIのレスポンス
  getLoginUserResponse: GetLoginUserResponse;
  getValueDetectionsResponse: GetInSiteValueDetectionsResponse;
  getFloorsResponse: GetFloorsResponse;

  // フラグ類
  getUserFinished: boolean;
  isLoadingData: boolean;
  isMeterTypeReady: boolean = false;
  isMeterUnitReady: boolean = false;
  isPartitionTenantReady: boolean = false;
  isCreateMeterReady: boolean = false;
  isMeterDataExsit: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  searchOptionKey: string = "";
  searchOptionsArray: string[] = ["区画", "テナント名称", "メーター名称"];

  searchKeyString: string = "";
  searchKeyChanged: Subject<string> = new Subject<string>();

  //for csv import
  csvMeterData: MeterInfo[] = [];
  csvAddMeterData: MeterInfo[] = [];
  csvUpdateMeterData: MeterInfo[] = [];
  csvErrorMeterData: MeterInfo[] = [];
  csvDeleteMeterData: MeterInfo[] = [];
  tsvFlg = false;
  csvImportAccountLockError = false;
  csvImportUnexpectedError = false;

  //loading overlay
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
    private overlay: Overlay
  ) {
    localStorage.setItem("path", router.url);
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.selectedSite.disable();
    this.partitionsArray = [];
    this.partitionTenants = [];
    this.selectedIndex = 0;
    this.expMeterData = [];

    this.siteArray = [];

    this.getLoginUserResponse = new GetLoginUserResponse();
    this.getFloorsResponse = new GetFloorsResponse();
    this.getValueDetectionsResponse = new GetInSiteValueDetectionsResponse();

    this.floorsInfo = [];
    this.getUserFinished = false;

    this.searchOptionKey = this.searchOptionsArray[0];
    this.searchKeyChanged
      .debounceTime(300) // wait 300ms after the last event before emitting last event
      .subscribe((str) => {
        this.searchKeyString = str;
        this.filterResult();
      });

    this.getToken();
  }

  getToken() {
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;
    this.getFloorsUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.doGetFloors();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("施設情報が取得できませんでした。");
          }
        }
      );
  }

  // idトークンとsite_idの情報からフロア情報の取得を行う
  doGetFloors() {
    this.expMeterData = [];
    this.floorsInfo = [];
    this.currentFloorName = "";
    this.isCreateMeterReady = false;
    this.isMeterDataExsit = false;
    this.isPartitionTenantReady = false;
    this.httpClient
      .get(this.getFloorsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;

          this.nowFloorId = this.floorsInfo[this.selectedIndex].id;
          this.currentFloorName = this.floorsInfo[this.selectedIndex].name;

          for (const floor of this.floorsInfo) {
            this.floorsNameIdMap.set(floor.name, floor.id);
          }

          this.loadMeterData();
        },
        (err) => {
          this.isLoadingData = true;
          this.selectedSite.enable();
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("フロア情報が取得できませんでした。");
          }
        }
      );
  }

  loadMeterData() {
    this.expMeterData = [];
    this.allMeterData = [];
    this.currentFloorMeterData = [];
    this.isLoadingData = false;
    this.selectedSite.disable();
    this.isMeterDataExsit = false;
    this.isCreateMeterReady = false;
    this.isPartitionTenantReady = false;

    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/meters_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonObj = JSON.parse(JSON.stringify(res));
          if (jsonObj.status == 200) {
            let jsonFloors = jsonObj.result.site.floors;
            let site_id = jsonObj.result.site.id;
            let countOfPartitions = 0;
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              countOfPartitions = countOfPartitions + floor.partitions.length;
            }
            if (countOfPartitions < 1) {
              alert("区画情報が取得できませんでした。");
              this.isLoadingData = true;
              this.selectedSite.enable();
              return;
            }
            this.getPartitionTenantData();
            for (let index = 0; index < jsonFloors.length; index++) {
              const floor = jsonFloors[index];
              const partitions = floor.partitions;
              for (let index = 0; index < partitions.length; index++) {
                const partition = partitions[index];
                for (let index = 0; index < partition.meters.length; index++) {
                  const meterObj = partition.meters[index];
                  let meterModel = meterObj as MeterInfo;

                  meterModel.partName = partition.name;
                  if (
                    partition.partitions_tenants !== undefined &&
                    partition.partitions_tenants.length > 0
                  ) {
                    meterModel.tenantName =
                      partition.partitions_tenants[0].tenant_log.name;
                    meterModel.cancelled_at =
                      partition.partitions_tenants[0].cancelled_at;
                    meterModel.site_id = site_id;
                    meterModel.floor_id = floor.id;
                    meterModel.floor_name = floor.name;
                    if (
                      meterModel.closed_value !== undefined &&
                      meterModel.closed_value !== null
                    ) {
                      continue;
                    }
                    this.allMeterData.push(meterModel);
                  }
                  if (
                    Number(this.loginUser.user_type) !== this.userTypeSysAdmin
                  ) {
                    if (
                      meterModel.expiration_date !== null &&
                      meterModel.expiration_date !== undefined &&
                      meterModel.expiration_date !== ""
                    ) {
                      var day = new Date();
                      day.setMonth(day.getMonth() + 24);
                      const dayOfNextYear = formatDate(
                        day,
                        "yyyyMMdd",
                        this.locale
                      );
                      const expiration = formatDate(
                        meterModel.expiration_date,
                        "yyyyMMdd",
                        this.locale
                      );
                      if (expiration < dayOfNextYear) {
                        meterModel.isExpireMeterFlag = true;

                        let expMeterModel = meterObj as MeterInfo;
                        expMeterModel = meterModel;
                        this.expMeterData.push(expMeterModel);
                      }
                    }
                  }
                }
              }
            }
            // ソート
            if (this.expMeterData.length > 0) {
              this.expMeterData.sort((n1, n2) => {
                if (n1.expiration_date > n2.expiration_date) {
                  return 1;
                }
                if (n1.expiration_date < n2.expiration_date) {
                  return -1;
                }
                return 0;
              });
            }

            this.currentFloorMeterData = this.allMeterData.filter(
              (item) => item.floor_id == this.nowFloorId
            );
            this.filtredMeterData = this.currentFloorMeterData;

            this.displayedColumns = [
              "detailButton",
              "partition",
              "tenantName",
              "meterName",
              "meterType",
              "meterLabel",
              "qrcodeButton",
              "editButton",
              "meterReplace",
              "deleteButton",
            ];
            this.isLoadingData = true;
            this.selectedSite.enable();
            this.isMeterDataExsit = this.allMeterData.length > 0;
          }
        },
        (err) => {
          this.isLoadingData = true;
          this.selectedSite.enable();
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("メーター情報が取得できませんでした。");
          }
        }
      );
  }

  loadMeterTypes() {
    const url = `${environment.apiUrl}/meter_types/meter_types_info_all`;
    this.meterTypeData = [];
    this.isMeterTypeReady = false;

    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonStr = JSON.stringify(res);
          let jsonObj = JSON.parse(jsonStr);
          let data = jsonObj.result.metertypes;

          this.meterTypeData = data;
          this.isMeterTypeReady = true;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("メータータイプが取得できませんでした。");
          }
        }
      );
  }

  loadMeterUnit() {
    this.unitData = [];
    this.isMeterUnitReady = false;
    const url = `${environment.apiUrl}/units/units_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonStr = JSON.stringify(res);
          let jsonObj = JSON.parse(jsonStr);
          let data = jsonObj.result.units;
          this.unitData = data;
          this.isMeterUnitReady = true;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("単位情報が取得できませんでした。");
          }
        }
      );
  }

  getPartitionTenantData() {
    this.isPartitionTenantReady = false;
    const dateData = new Date();
    const thisMonth = formatDate(dateData, "yyyyMM", this.locale);

    const url = `${environment.apiUrl}/sites/${this.selectedSite.value}/partitions/tenants/${thisMonth}`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetTenantsResponse) => {
          this.partitionTenants = response.result.site.partitions;

          // テナント未登録の場合、メーターの作成が不可にする対応
          this.partitionTenants = this.partitionTenants.filter(
            (item) => item.partitions_tenants.length != 0
          );
          this.isCreateMeterReady = this.partitionTenants.length > 0;
          this.isPartitionTenantReady = true;
          if (!this.isCreateMeterReady) {
            alert("テナント情報が取得できませんでした。");
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          }
        }
      );
  }

  doClickTab(MatTagChangevent: any) {
    this.nowFloorId = this.floorsNameIdMap.get(MatTagChangevent.tab.textLabel);
    this.currentFloorName = MatTagChangevent.tab.textLabel;
    this.selectedIndex = MatTagChangevent.index;

    this.currentFloorMeterData = this.allMeterData.filter(
      (item) => item.floor_id == this.nowFloorId
    );
    this.filtredMeterData = this.currentFloorMeterData;
  }

  onClickAddMeter() {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.dialog.open(MeterAddDialogComponent, {
      width: "800px",
      data: {
        partitionTenants: this.partitionTenants,
        metersInfo: this.allMeterData,
        site: this.site,
      },
    });
    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
        this.loadMeterData();
      }
    });
  }

  onClickMeterQrcode(meter: MeterInfo) {
    if (!this.isPartitionTenantReady) {
      alert("テナントデータが取得できませんでした。");
      return;
    }

    const dialogRef = this.dialog.open(MeterQrcodeDialogComponent, {
      width: "800px",
      data: meter,
    });
    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
      }
    });
  }

  onClickMeterEdit(meter: MeterInfo) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    if (!this.isPartitionTenantReady) {
      alert("テナントデータが取得できませんでした。");
      return;
    }

    const dialogRef = this.dialog.open(MeterEditDialogComponent, {
      width: "800px",
      data: { meter: meter, metersInfo: this.allMeterData, site: this.site },
    });
    dialogRef.componentInstance.partitionTenant = this.partitionTenants;

    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
        // 最小限の更新になるように要改良
        this.loadMeterData();
      }
    });
  }

  onClickMeterReplace(meter: MeterInfo) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    if (!this.isPartitionTenantReady) {
      alert("テナントデータが取得できませんでした。");
      return;
    }
    const dialogRef = this.dialog.open(MeterReplaceDialogComponent, {
      width: "800px",
      data: { meter: meter, metersInfo: this.allMeterData, site: this.site },
    });
    dialogRef.componentInstance.partitionTenant = this.partitionTenants;

    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
        // 最小限の更新になるように要改良
        this.loadMeterData();
      }
    });
  }

  onClickMeterDelete(meter: MeterInfo) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.dialog.open(MeterDeleteDialogComponent, {
      width: "650px",
      data: { meter: meter, site: this.site },
    });
    dialogRef.afterClosed().subscribe((makeResult) => {
      if (makeResult === "close") {
        this.loadMeterData();
      }
    });
  }

  // 詳細表示
  onClickDetailMeter(meter: MeterInfo) {
    this.dialog.open(MeterDetailDialogComponent, {
      width: "600px",
      data: meter,
    });
  }

  onClickExportMeterCSV() {
    const companyID = this.loginUser.company.id;
    /*
    company_id,site_id,floor_id,partition_id,unit_id,meter_type_id,
    meter_id,no,name,max_value,min_value,max_angle,min_angle,number_of_digits,decimal_point_position,analysis_flg,is_card_display,is_reviewed,is_reread
    */

    let csvData =
      // = '会社ID,施設ID,フロアID,区画ID,メーターID,単位ID, メータータイプID,表示順, メーター名,最大検針値,最小検針値,最大値角度,最小値角度,整数部分の桁数,少数点以下の桁数,メーター解析,カード表示,見直しフラグ表示,再読取,有効期限\r\n';
      "company_id" +
      "," +
      "site_id" +
      "," +
      "floor_id" +
      "," +
      "partition_id" +
      "," +
      "meter_id" +
      "," +
      "unit_id" +
      "," +
      "meter_type_id" +
      "," +
      "no" +
      "," +
      "name" +
      "," +
      "label" +
      "," +
      "max_value" +
      "," +
      "min_value" +
      "," +
      "max_angle" +
      "," +
      "min_angle" +
      "," +
      "number_of_digits" +
      "," +
      "decimal_point_position" +
      "," +
      "analysis_flg" +
      "," +
      "is_card_display" +
      "," +
      "is_reviewed" +
      "," +
      "is_reread" +
      "," +
      "expiration_date\r\n";

    for (const row of this.allMeterData) {
      if (row.expiration_date == null) {
        row.expiration_date = "";
      }

      const rowData =
        companyID +
        "," +
        row.site_id +
        "," +
        row.floor_id +
        "," +
        row.partition_id +
        "," +
        row.id +
        "," +
        row.unit_id +
        "," +
        row.meter_type_id +
        "," +
        row.no +
        "," +
        row.name +
        "," +
        row.label +
        "," +
        row.max_value * 10 ** row.decimal_point_position +
        "," +
        row.min_value * 10 ** row.decimal_point_position +
        "," +
        row.max_angle +
        "," +
        row.min_angle +
        "," +
        row.number_of_digits +
        "," +
        row.decimal_point_position +
        "," +
        row.analysis_flg +
        "," +
        row.is_card_display +
        "," +
        row.is_reviewed +
        "," +
        row.is_reread +
        "," +
        row.expiration_date +
        "\r\n";
      const rowDataFormated = rowData.replace(/\*/g, "").replace(/N\/A/g, "");
      csvData = csvData + rowDataFormated;
    }
    const strArray = Encoding.stringToCode(csvData);
    const sjisArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const uint8Array = new Uint8Array(sjisArray);
    const blob = new Blob([uint8Array], { type: "text/tab-separated-values" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    const siteName = this.siteArray.filter(
      (item) => item.id === this.selectedSite.value
    )[0].name;
    const fileName = siteName + "メーター一覧.csv";
    link.download = fileName;
    link.click();
  }

  onClickImportMeterCSV() {
    document.querySelector("input").click();
  }

  search(text: string) {
    this.searchKeyChanged.next(text);
  }

  filterResult() {
    if (this.searchKeyString === "") {
      this.filtredMeterData = this.currentFloorMeterData;
      return;
    }

    switch (this.searchOptionKey) {
      case this.searchOptionsArray[0]:
        this.filtredMeterData = this.currentFloorMeterData.filter((item) =>
          item.partName.includes(this.searchKeyString)
        );
        break;
      case this.searchOptionsArray[1]:
        this.filtredMeterData = this.currentFloorMeterData.filter((item) =>
          item.tenantName.includes(this.searchKeyString)
        );
        break;
      case this.searchOptionsArray[2]:
        this.filtredMeterData = this.currentFloorMeterData.filter((item) =>
          item.name.includes(this.searchKeyString)
        );
        break;
      default:
        break;
    }
  }

  onChangeOption() {
    this.searchKeyChanged.next(this.searchKeyString);
  }

  doChangeSite() {
    this.isLoadingData = false;
    this.selectedSite.disable();
    Const.site_id = this.selectedSite.value;
    localStorage.setItem("siteId", this.selectedSite.value);
    // フロア情報取得
    this.getFloorsUrl = `${environment.apiUrl}/sites/${Const.site_id}/floors`;
    this.floorsInfo = [];
    this.allMeterData = [];
    this.currentFloorMeterData = [];
    this.filtredMeterData = [];
    this.doGetFloors();
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  onClickMasterEdit() {
    this.router.navigate(["master-edit"]);
  }

  onClickCardList() {
    this.router.navigate(["meter-card-list"]);
  }

  public changeListener(event) {
    let files = event.target.files;
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let reader: FileReader = new FileReader();
      reader.readAsText(file, "Shift_JIS");
      reader.onload = async (_) => {
        let csv: string = reader.result as string;
        const results = Papa.parse(csv);
        if (results.meta.delimiter === "\t") {
          this.tsvFlg = true;
        }
        let messageContents =
          "メーター情報をインポートします。よろしいですか？";
        const rows = csv.split("\r\n");
        if (rows.length > 1000) {
          messageContents =
            messageContents +
            `\n※1000件を超えています。処理に時間がかかる可能性があります。`;
        }
        const messageInfo = new MeterConfirmDialogInfo(
          "CSVインポート",
          messageContents
        );
        const meterConfirmDialogRef = this.dialog.open(
          CommonConfirmDialogComponent,
          {
            width: "650px",
            data: messageInfo,
          }
        );

        meterConfirmDialogRef.afterClosed().subscribe((result) => {
          if (result === "Yes") {
            this.handleCSVStringToMeterArray(csv);
          }
        });
      };
    }
  }

  handleCSVStringToMeterArray(csvString: string) {
    this.overlayRef.attach(new ComponentPortal(MatSpinner));

    this.csvMeterData = [];
    this.csvAddMeterData = [];
    this.csvUpdateMeterData = [];
    this.csvAddMeterData = [];
    this.csvErrorMeterData = [];
    let splitString = ",";
    if (this.tsvFlg) {
      splitString = "\t";
    }

    let rows = csvString.split("\r\n");
    const headerRowTitles = rows[0].trim().split(splitString);
    if (headerRowTitles.length != 20) {
      alert("csvファイルのフォーマットが正しいか確認してください。");
      this.overlayRef.detach();
      return;
    }
    for (let i = 1; i < rows.length; i++) {
      const jsonStr = rows[i];
      const strArr = jsonStr.split(splitString);
      // strArr's cell count not equal 20, skip the row
      if (strArr.length != 20) {
        continue;
      }
      // 会社IDのチェック(管理者の場合で、かつ、同じ会社のレコードでない場合はスキップ)
      if (
        Const.adminUser &&
        String(strArr[0]).trim() !== this.loginUser.company.id
      ) {
        continue;
      }
      // construct meter from csv strArr
      let meter = this.buildMeterFromStringArray(strArr);
      // check each meter's value
      this.checkMeterInfoIsValid(meter);
      // add to list
      this.csvMeterData.push(meter);
    }
    // split meter array
    this.splitToArray();
  }

  buildMeterFromStringArray(strArr: string[]) {
    // not used for now
    // let company_id = String(strArr[0]).trim()
    const siteId = String(strArr[1]).trim();
    // let floor_id = String(strArr[2]).trim()

    let partition_id = String(strArr[3]).trim();
    let meter_id = String(strArr[4]).trim();
    let unit_id = String(strArr[5]).trim();
    let meter_type_id = String(strArr[6]).trim();
    let no = Number(strArr[7].trim());
    let name = String(strArr[8]).trim();
    let label = String(strArr[10]).trim();
    let max_value = Number(strArr[9].trim());
    let min_value = Number(strArr[10].trim());
    let max_angle = Number(strArr[11].trim());
    let min_angle = Number(strArr[12].trim());
    let number_of_digits = Number(strArr[13].trim());
    let decimal_point_position = Number(strArr[14].trim());
    let analysis_flg = Number(strArr[15].trim());
    let is_card_display = Number(strArr[16].trim());
    let is_reviewed = Number(strArr[17].trim());
    let is_reread = Number(strArr[18].trim());
    let expiration_date = String(strArr[19]).trim();

    let meter = new MeterInfo();
    meter.id = meter_id;
    meter.site_id = siteId;
    meter.name = name;
    meter.label = label;
    meter.no = no;
    meter.partition_id = partition_id;
    meter.meter_type_id = meter_type_id;
    meter.unit_id = unit_id;
    meter.max_value = max_value;
    meter.min_value = min_value;
    meter.max_angle = max_angle;
    meter.min_angle = min_angle;
    meter.decimal_point_position = decimal_point_position;
    meter.number_of_digits = number_of_digits;
    meter.analysis_flg = Boolean(analysis_flg);
    meter.is_card_display = Boolean(is_card_display);
    meter.is_reread = Boolean(is_reread);
    meter.is_reviewed = Boolean(is_reviewed);
    meter.expiration_date = expiration_date;
    return meter;
  }

  checkValueIsInvalid(val: any) {
    if (val == "" || val == "null" || val == "undefined") {
      return true;
    }
    return false;
  }

  checkValueIsInvalidMeterName(meter: MeterInfo) {
    for (const partition of this.partitionTenants) {
      if (partition.id === meter.partition_id) {
        // if (partition.report_type === 0) {
        if (
          meter.name === "電灯" ||
          meter.name.substr(meter.name.indexOf("_") + 1, 2) === "電灯"
        ) {
          return false;
        } else if (
          meter.name === "動力" ||
          meter.name.substr(meter.name.indexOf("_") + 1, 2) === "動力"
        ) {
          return false;
        } else if (
          meter.name === "水道" ||
          meter.name.substr(meter.name.indexOf("_") + 1, 2) === "水道"
        ) {
          return false;
        }
        // } else if (partition.report_type === 1) {
        else if (
          meter.name === "ガス" ||
          meter.name.substr(meter.name.indexOf("_") + 1, 2) === "ガス"
        ) {
          return false;
        }
        //}
      }
    }
    return true;
  }

  checkMeterInfoIsValid(meter: MeterInfo) {
    // -1: unknown,
    // 1: add new meter,
    // 2, error meter : lack of name, unit_id, meter_type_id, partition_id
    let errMsg = "";
    let state = -1;
    if (meter.site_id !== Const.site_id) {
      state = 2;
      errMsg += "siteId is disagreement,";
    }
    if (this.checkValueIsInvalid(meter.id)) {
      state = 1;
    }

    if (this.checkValueIsInvalid(meter.name)) {
      state = 2;
      errMsg += "name is empty,";
    }

    if (this.checkValueIsInvalid(meter.label)) {
      state = 2;
      errMsg += "label is empty,";
    }

    if (this.checkValueIsInvalidMeterName(meter)) {
      state = 2;
      errMsg += "name is bad,";
    }

    if (this.checkValueIsInvalid(meter.unit_id)) {
      state = 2;
      errMsg += "unit_id is empty,";
    }

    if (this.checkValueIsInvalid(meter.meter_type_id)) {
      state = 2;
      errMsg += "meter_type_id is empty,";
    }

    if (this.checkValueIsInvalid(meter.partition_id)) {
      state = 2;
      errMsg += "partition_id is empty,";
    }

    // テナント未登録ものエラー
    if (
      this.partitionTenants.filter((item) => item.id === meter.partition_id)
        .length < 1
    ) {
      state = 2;
      errMsg += "partition_id is invaid,";
    }

    if (Number.isNaN(meter.max_value)) {
      state = 2;
      errMsg += "max_value is empty,";
    }

    if (meter.max_value < 0) {
      state = 2;
      errMsg += "max_value is negative,";
    }

    if (Number.isNaN(meter.min_value)) {
      state = 2;
      errMsg += "min_value is empty,";
    }

    if (meter.min_value < 0) {
      state = 2;
      errMsg += "min_value is negative,";
    }

    if (Number.isNaN(meter.max_angle)) {
      state = 2;
      errMsg += "max_angle is empty,";
    }

    if (meter.max_angle < 0 || meter.max_angle > 360) {
      state = 2;
      errMsg += "max_angle is out of range [0~360],";
    }

    if (Number.isNaN(meter.min_angle)) {
      state = 2;
      errMsg += "min_angle is empty,";
    }

    if (meter.min_angle < 0 || meter.min_angle > 360) {
      state = 2;
      errMsg += "min_angle is out of range [0~360],";
    }

    if (Number.isNaN(meter.number_of_digits)) {
      state = 2;
      errMsg += "number_of_digits is empty,";
    }

    if (meter.number_of_digits < 0) {
      state = 2;
      errMsg += "number_of_digits is negative,";
    }

    if (Number.isNaN(meter.decimal_point_position)) {
      state = 2;
      errMsg += "decimal_point_position is empty,";
    }

    if (meter.decimal_point_position < 0) {
      state = 2;
      errMsg += "decimal_point_position is negative,";
    }

    if (meter.min_value > meter.max_value) {
      state = 2;
      errMsg += "meter min value is bigger than max value,";
    }

    if (meter.min_angle > meter.max_angle) {
      state = 2;
      errMsg += "meter min angle is bigger than max angle,";
    }

    if (this.checkValueIsInvalid(meter.expiration_date)) {
      state = 2;
      errMsg += "expiration_date is empty";
    }

    if (
      !(
        meter.expiration_date.match(/\d{4}-[0-1][0-9]-[0-3][0-9]+$/) ||
        meter.expiration_date.match(/\d{4}[/][0-1][0-9][/][0-3][0-9]+$/)
      )
    ) {
      state = 2;
      errMsg += "expiration_date format is invalid";
    }

    meter.state = state;
    meter.errMsg = errMsg;
  }

  splitToArray() {
    // fetch update meter which satisfy both condition,
    //    1:originData contains the same meter id
    //    2:csv meter's value has any difference compare to the same meter.id from  originData's meter value
    let csvUpdateMeterDataNoError = this.csvMeterData.filter(
      (csvMeter) => csvMeter.state != 2
    );
    let updateMeterData = csvUpdateMeterDataNoError.filter((csvMeter) =>
      this.allMeterData.some((originMeter) => {
        let isSameMeter = csvMeter.id === originMeter.id;
        let hasMeterChanged =
          csvMeter.name != originMeter.name.trim() ||
          csvMeter.partition_id != originMeter.partition_id ||
          csvMeter.unit_id != originMeter.unit_id ||
          csvMeter.meter_type_id != originMeter.meter_type_id ||
          csvMeter.no != originMeter.no ||
          csvMeter.label != originMeter.label ||
          csvMeter.max_value !=
            originMeter.max_value * 10 ** originMeter.decimal_point_position ||
          csvMeter.min_value !=
            originMeter.min_value * 10 ** originMeter.decimal_point_position ||
          csvMeter.max_angle != originMeter.max_angle ||
          csvMeter.min_angle != originMeter.min_angle ||
          csvMeter.number_of_digits != originMeter.number_of_digits ||
          csvMeter.decimal_point_position !=
            originMeter.decimal_point_position ||
          csvMeter.analysis_flg != originMeter.analysis_flg ||
          csvMeter.is_card_display != originMeter.is_card_display ||
          csvMeter.is_reviewed != originMeter.is_reviewed ||
          csvMeter.is_reread != originMeter.is_reread ||
          csvMeter.expiration_date != originMeter.expiration_date;

        return isSameMeter && hasMeterChanged;
      })
    );

    // remove duplicate meter
    this.csvUpdateMeterData = this.unique(updateMeterData, "id");
    this.csvAddMeterData = this.csvMeterData.filter(
      (meter) => meter.state == 1
    );
    this.csvErrorMeterData = this.csvMeterData.filter(
      (meter) => meter.state == 2
    );

    // this.originMeterData - this.csvMeterData
    let waitForDeletion = this.allMeterData.filter((originMeter) => {
      return !this.csvMeterData.some((importedMeter) => {
        return originMeter.id == importedMeter.id;
      });
    });
    this.csvDeleteMeterData = this.unique(waitForDeletion, "id");

    this.beginToUploadCSVMeter();
  }

  // all asnyc action
  async beginToUploadCSVMeter() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    // for add
    await this.addMeters();
    // for update
    if (!this.csvImportAccountLockError && !this.csvImportUnexpectedError) {
      await this.updateMeters();
    }
    // for delete
    if (!this.csvImportAccountLockError && !this.csvImportUnexpectedError) {
      await this.deleteMeters();
    }
    this.overlayRef.detach();
    if (this.csvImportAccountLockError) {
      alert("アカウントがロックされました。管理者までお問合せください");
      this.onClickLogout();
    } else {
      let updateSuccessCount = this.csvUpdateMeterData.filter(
        (meter) => meter.statusCode === 200
      ).length;
      let addSuccessCount = this.csvAddMeterData.filter(
        (meter) => meter.statusCode === 200
      ).length;
      let deleteSuccessCount = this.csvDeleteMeterData.filter(
        (meter) => meter.statusCode === 200
      ).length;

      let successCount =
        updateSuccessCount + addSuccessCount + deleteSuccessCount;
      let failCount =
        this.csvErrorMeterData.length +
        this.csvUpdateMeterData.length -
        updateSuccessCount +
        this.csvAddMeterData.length -
        addSuccessCount +
        this.csvDeleteMeterData.length -
        deleteSuccessCount;

      let resultString = `成功${successCount}件/失敗${failCount}件`;
      const dialogRef = this.dialog.open(MeterCsvBatchDialogComponent, {
        width: "600px",
        data: resultString,
      });

      dialogRef.afterClosed().subscribe((_) => {
        this.loadMeterData();
      });
    }
  }

  // group add
  async addMeters() {
    let addResults: any[] = [];
    for await (let meter of this.csvAddMeterData) {
      const now = Date.now();
      const today = formatDate(now, "yyyyMMdd", this.locale);
      if (formatDate(meter.expiration_date, "yyyyMMdd", this.locale) < today) {
        continue;
      }
      // 戻り値200：正常、403：予期せぬエラー＆アカウントロック、上記以外：その他異常、
      const res = await this.addCSVMeter(meter);
      if (res.statusCode === 403) {
        break;
      }
      addResults.push(res);
    }
    return addResults;
  }

  // group update
  async updateMeters() {
    let dupdateResults: any[] = [];
    for await (let meter of this.csvUpdateMeterData) {
      // 戻り値200：正常、403：予期せぬエラー＆アカウントロック、上記以外：その他異常、
      const res = await this.updateCSVMeter(meter);
      if (res.statusCode === 403) {
        break;
      }
      dupdateResults.push(res);
    }
    return dupdateResults;
  }

  // group delete
  async deleteMeters() {
    let deleteResults: any[] = [];
    for await (let meter of this.csvDeleteMeterData) {
      // 戻り値200：正常、403：予期せぬエラー＆アカウントロック、上記以外：その他異常、
      const res = await this.deleteCSVMeter(meter);
      if (res.statusCode === 403) {
        break;
      }
      deleteResults.push(res);
    }
    return deleteResults;
  }

  async addCSVMeter(meter: MeterInfo): Promise<MeterInfo> {
    let params = new HttpParams();
    params = params.set("name", meter.name);
    params = params.set("label", meter.label);
    params = params.set("partition_id", meter.partition_id);
    params = params.set("meter_type_id", meter.meter_type_id);
    params = params.set("analysis_flg", `${meter.analysis_flg ? 1 : 0}`);
    params = params.set("unit_id", meter.unit_id);
    params = params.set(
      "max_value",
      (meter.max_value / 10 ** meter.decimal_point_position).toString()
    );
    params = params.set(
      "min_value",
      (meter.min_value / 10 ** meter.decimal_point_position).toString()
    );
    params = params.set("max_angle", meter.max_angle.toString());
    params = params.set("min_angle", meter.min_angle.toString());
    params = params.set("number_of_digits", meter.number_of_digits.toString());
    params = params.set(
      "decimal_point_position",
      meter.decimal_point_position.toString()
    );
    params = params.set("is_card_display", `${meter.is_card_display ? 1 : 0}`);
    params = params.set("is_reviewed", `${meter.is_reviewed ? 1 : 0}`);
    params = params.set("is_reread", `${meter.is_reread ? 1 : 0}`);
    params = params.set("no", meter.no.toString());
    params = params.set(
      "expiration_date",
      formatDate(meter.expiration_date, "yyyyMMdd", this.locale)
    );
    let url = `${environment.apiUrl}/meters/meters_info`;
    return new Promise((resolve, _) => {
      try {
        this.httpClient
          .post(url, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (_) => {
              meter.statusCode = 200;
              meter.responseMsg = "OK";
              resolve(meter);
            },
            (err) => {
              meter.statusCode = err.status;
              meter.responseMsg = err.error.message;
              if (err.error.code == "AccountLockError") {
                this.csvImportAccountLockError = true;
                meter.statusCode = 403;
              }
              let index = this.csvMeterData.indexOf(meter);
              resolve(meter);
            }
          );
      } catch (error) {
        this.csvImportUnexpectedError = true;
        meter.statusCode = 403;
        meter.responseMsg = error.message;
        resolve(meter);
      }
    });
  }

  async updateCSVMeter(meter: MeterInfo): Promise<MeterInfo> {
    let url = `${environment.apiUrl}/meters/${meter.id}/1/meters_info`;
    let params = new HttpParams();
    params = params.set("name", meter.name);
    params = params.set("label", meter.label);
    params = params.set("partition_id", meter.partition_id);
    params = params.set("meter_type_id", meter.meter_type_id);
    params = params.set("analysis_flg", `${meter.analysis_flg ? 1 : 0}`);
    params = params.set("unit_id", meter.unit_id);
    params = params.set(
      "max_value",
      (meter.max_value / 10 ** meter.decimal_point_position).toString()
    );
    params = params.set(
      "min_value",
      (meter.min_value / 10 ** meter.decimal_point_position).toString()
    );
    params = params.set("max_angle", meter.max_angle.toString());
    params = params.set("min_angle", meter.min_angle.toString());
    params = params.set("number_of_digits", meter.number_of_digits.toString());
    params = params.set(
      "decimal_point_position",
      meter.decimal_point_position.toString()
    );
    params = params.set("is_card_display", `${meter.is_card_display ? 1 : 0}`);
    params = params.set("is_reviewed", `${meter.is_reviewed ? 1 : 0}`);
    params = params.set("is_reread", `${meter.is_reread ? 1 : 0}`);
    params = params.set("no", meter.no.toString());
    params = params.set(
      "expiration_date",
      formatDate(meter.expiration_date, "yyyyMMdd", this.locale)
    );

    return new Promise((resolve, _) => {
      try {
        this.httpClient
          .patch(url, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (res) => {
              meter.statusCode = 200;
              meter.responseMsg = "OK";
              resolve(meter);
            },
            (err) => {
              meter.statusCode = err.status;
              meter.responseMsg = err.error.message;
              if (err.error.code == "AccountLockError") {
                this.csvImportAccountLockError = true;
                meter.statusCode = 403;
              }
              let index = this.csvMeterData.indexOf(meter);
              resolve(meter);
            }
          );
      } catch (error) {
        this.csvImportUnexpectedError = true;
        meter.statusCode = 403;
        meter.responseMsg = error.message;
        resolve(meter);
      }
    });
  }

  async deleteCSVMeter(meter: MeterInfo): Promise<MeterInfo> {
    const url = `${environment.apiUrl}/meters/${meter.id}/0/meters_info`;
    const params = new HttpParams();
    return new Promise((resolve, _) => {
      try {
        this.httpClient
          .patch(url, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (_) => {
              meter.statusCode = 200;
              meter.responseMsg = "OK";
              resolve(meter);
            },
            (err) => {
              meter.statusCode = err.status;
              meter.responseMsg = err.error.message;
              if (err.error.code == "AccountLockError") {
                this.csvImportAccountLockError = true;
                meter.statusCode = 403;
              }

              let index = this.csvMeterData.indexOf(meter);
              resolve(meter);
            }
          );
      } catch (error) {
        this.csvImportUnexpectedError = true;
        meter.statusCode = 403;
        meter.responseMsg = error.message;
        resolve(meter);
      }
    });
  }

  // remove duplicate
  unique(arr: any[], val: string) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
  }

  onNumbering() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    this.isLoadingData = false;
    this.selectedSite.disable();
    this.isMeterDataExsit = false;
    this.isPartitionTenantReady = false;
    this.isCreateMeterReady = false;

    // APIコールして登録処理
    const patchNumberingMeterUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/meter_info_all/no`;
    const params = new HttpParams();
    this.httpClient
      .patch(patchNumberingMeterUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          const successMessageInfo = new MessageInfo(
            "自動採番を行いました",
            ""
          );
          const dialogRef = this.dialog.open(MessageDialogComponent, {
            width: "400px",
            data: successMessageInfo,
          });
          dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult === "close") {
              this.doGetFloors();
            }
          });
        },
        (err) => {
          this.isLoadingData = true;
          this.selectedSite.enable();
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("自動採番に失敗しました");
            this.doGetFloors();
          }
        }
      );
  }

  //メーター名称からメーター種類を取得
  typeExtract(name: string) {
    let tmp = "";
    tmp = name.substr(name.indexOf("_") + 1, 2);
    return tmp;
  }

  //メーター名称からメーター補助名を取得
  nameExtract(name: string) {
    let tmp = "";
    tmp = name.substring(0, name.indexOf("_"));
    return tmp;
  }
}
