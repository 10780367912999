import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CompareDialogData } from "../entity/compare-dialog-data";
import { BigNumber } from "bignumber.js";

@Component({
  selector: "app-value-compare-dialog",
  templateUrl: "./value-compare-dialog.component.html",
  styleUrls: ["./value-compare-dialog.component.scss"],
})
export class ValueCompareDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ValueCompareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompareDialogData
  ) {}

  ngOnInit() {}

  //指定した小数点まで切り捨てる
  confirmedValueFormat(val: string, point: number) {
    let formatedNum = "";

    let ten = new BigNumber(Math.pow(10, point));
    let tmpNum = new BigNumber(val);
    let result = tmpNum.times(ten);
    result = new BigNumber(Math.floor(Number(result)));
    let endRes = result.dividedBy(ten);
    formatedNum = endRes.toString();

    return formatedNum;
  }

  onClickClose(): void {
    this.dialogRef.close();
  }
}
