import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { LoginUser } from '../entity/login-user';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { UserConfirmDialogInfo } from '../entity/user-confirm-dialog-info';
import { GetUserWithSitesLinksInfo } from '../entity/get-user-with-sites-links-info';
import { Const } from '../const/const';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss']
})
export class UserDeleteDialogComponent implements OnInit {

  idToken: string;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public user: GetUserWithSitesLinksInfo,
    public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    ) { }

  ngOnInit() {

    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
            this.doGetLoginUser();
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
      .subscribe((response: GetLoginUserResponse) => {
        this.loginUser = response.result.login_user;
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
          }else{
            alert('ログイン名が取得できませんでした。再度ログインしてください。');
          }
          this.onClickLogout();
        }
      );
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // ユーザー削除
  onClickConfirm() {
      if (!navigator.onLine) {
        alert('ネットワーク接続を確認してください');
        return;
      }

      let message = 'ユーザーを削除します。よろしいですか？';
      if (this.loginUser.id === this.user.id) {
        message = '自身を削除するとログイン出来なくなりますがよろしいですか？';
      }
      const messageInfo = new UserConfirmDialogInfo('ユーザー削除', message);
      const userConfirmDialogRef = this.confirmDialog.open(CommonConfirmDialogComponent, {
        width: '600px',
        data: messageInfo
      });

      userConfirmDialogRef.afterClosed().subscribe(result => {
        if (result === 'Yes') {
          this.deleteUser();
        }
      });

  }

  // Cognitoユーザーのステータスを無効化し、ユーザーを論理削除する。
  deleteUser() {
    try {
      // Cognitoユーザーのステータスを無効に変更
      const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/disable_user`;
      let cognitoParams = new HttpParams();
      cognitoParams = cognitoParams.set('user_pool_id', environment.amplify.Auth.aws_user_pools_id);
      cognitoParams = cognitoParams.set('user_name', this.user.loginId);
      this.httpClient.post(postActivateCognitoUserUrl, cognitoParams,
        {
          headers: new HttpHeaders({
            Authorization: this.idToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      ).subscribe((response: any) => {
        // ユーザー削除
        const patchUserUrl = `${environment.apiUrl}/users/users_info/0/${this.user.id}`;
        const params = new HttpParams();
        this.httpClient.patch(patchUserUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        ).subscribe((responseb) => {},
        err => {
          alert('ユーザー情報の更新に失敗しました。');
        });
        this.dialogRef.close('close');
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
            this.dialogRef.close('lockedAccount');
          }else{
            alert('ユーザー削除に失敗しました。');
          }
        }
      );
    } catch (error) {
      alert("ユーザー削除に失敗しました。");
    }
  }

}
