import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { UserSite } from '../entity/get-user-with-sites-links-info';

export interface PeriodicElement {
  siteAddress: string;
  siteName: string;
  companyName: string;
}

@Component({
  selector: 'app-user-site-links-dialog',
  templateUrl: './user-site-links-dialog.component.html',
  styleUrls: ['./user-site-links-dialog.component.scss']
})

export class UserSiteLinksDialogComponent implements OnInit {

  idToken: string;

  displayedColumns: string[] = ['siteName', 'siteAddress', 'companyName'];

  @Input() public siteInfo: UserSite[];

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public sites: UserSite[],
    public dialogRef: MatDialogRef<UserSiteLinksDialogComponent>,
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    ) { }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
  }

  // 施設選択
  onClickSelectSite() {
      if (!navigator.onLine) {
        alert('ネットワーク接続を確認してください');
        return;
      }
  }

}
