import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-site-confirm-dialog',
  templateUrl: './site-complete-dialog.component.html',
  styleUrls: ['./site-complete-dialog.component.scss']
})

export class SiteCompleteDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit() {}

}