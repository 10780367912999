import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { Const } from "../const/const";
import { MeterInfo } from "../entity/get-meter-info";
import { MeterConfirmDialogInfo } from "../entity/meter-confirm-dialog-info";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-meter-delete-dialog",
  templateUrl: "./meter-delete-dialog.component.html",
  styleUrls: ["./meter-delete-dialog.component.scss"],
})
export class MeterDeleteDialogComponent implements OnInit {
  idToken: string;
  site: LoginUserSite;
  meter: MeterInfo;
  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    //@Inject(MAT_DIALOG_DATA) public meter: MeterInfo,
    //public meter: MeterInfo,
    public dialogRef: MatDialogRef<MeterDeleteDialogComponent>,
    private httpClient: HttpClient,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.meter = this.data.meter;
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  // メーター管理削除
  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    const messageInfo = new MeterConfirmDialogInfo(
      "メーター削除",
      "メーター情報を削除します。よろしいですか。"
    );
    const meterConfirmDialogRef = this.confirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "650px",
        data: messageInfo,
      }
    );

    meterConfirmDialogRef.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        const postTenantUrl = `${environment.apiUrl}/meters/${this.meter.id}/0/meters_info`;
        const params = new HttpParams();
        this.httpClient
          .patch(postTenantUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response: any) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("lockedAccount");
              } else {
                alert("メーター削除に失敗しました。");
              }
            }
          );
      }
    });
  }
}
