import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { MeterInfo } from '../entity/get-meter-info';

@Component({
  selector: 'app-meter-detail-dialog',
  templateUrl: './meter-detail-dialog.component.html',
  styleUrls: ['./meter-detail-dialog.component.scss']
})
export class MeterDetailDialogComponent implements OnInit {

  idToken: string;
  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public meter: MeterInfo,
    public dialogRef: MatDialogRef<MeterDetailDialogComponent>,
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    ) { }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
  }

}
