import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MeterInfo } from "../entity/get-meter-info";

@Component({
  selector: 'app-meter-expiration-warning-dialog',
  templateUrl: './meter-expiration-warning-dialog.component.html',
  styleUrls: ['./meter-expiration-warning-dialog.component.scss']
})
export class MeterExpirationWarningDialogComponent implements OnInit {
  idToken: string;
  allMeterData: MeterInfo[]
  displayedColumns: string[]
  meters: MeterInfo[]

  constructor(
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<MeterExpirationWarningDialogComponent>,
    public meterConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data,){}

  ngOnInit() {
    this.allMeterData = this.data.metersInfo
    this.displayedColumns = ['floorName', 'partitionName', 'tenantName', 'meterName', 'meterLabel', 'expiry'];
    this.getToken()
  }

  getToken() {
    this.auth.getIdToken()
      .subscribe(
        result => {
          if (result) {
            this.idToken = result
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
          }
        }
      )
  }
}
