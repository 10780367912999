// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: medium;
  text-align: right;
}

td {
  text-align: left;
  height: 45px;
}

.meter-name {
  text-align: left;
  height: 20px;
  width: 190px;
}

.meter-label {
  text-align: left;
  height: 20px;
  width: 135px;
}

textarea {
  font-size: medium;
}

input {
  font-size: medium;
}

.confirm-button {
  background-color: lightcoral;
}

.cancell-button {
  background-color: lightskyblue;
}

.meter-val {
  width: 127px;
}`, "",{"version":3,"sources":["webpack://./src/app/tenant-delete-dialog/tenant-delete-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;AACJ;;AACA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AACA;EACI,4BAAA;AAEJ;;AACA;EACI,8BAAA;AAEJ;;AACA;EACI,YAAA;AAEJ","sourcesContent":["table{\n    font-size: medium;\n    text-align: right\n}\n\ntd{\n    text-align: left;\n    height: 45px;\n}\n\n.meter-name{\n    text-align: left;\n    height: 20px;\n    width: 190px;\n}\n.meter-label{\n    text-align: left;\n    height: 20px;\n    width: 135px;\n}\n\ntextarea{\n    font-size: medium;\n}\n\ninput{\n    font-size: medium;\n}\n\n.confirm-button{\n    background-color: lightcoral;\n}\n\n.cancell-button{\n    background-color: lightskyblue;\n}\n\n.meter-val{\n    width: 127px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
