// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerArea {
  padding-top: 50px;
}

.confirm-button {
  background-color: #90CAFC;
}`, "",{"version":3,"sources":["webpack://./src/app/user-delete-dialog/user-delete-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AACA;EACI,yBAAA;AAEJ","sourcesContent":[".footerArea{\n    padding-top: 50px;\n}\n.confirm-button{\n    background-color: #90CAFC;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
