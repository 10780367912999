import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { CsvUserInfo } from '../entity/csv-user-info';
import { CompanyInfo } from '../entity/get-company-info';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { SiteInfo } from '../entity/get-site-info';
import { GetUserSiteLinksInfo } from '../entity/get-user-site-links-info';
import { GetUserWithSitesLinksInfo, UserSite } from '../entity/get-user-with-sites-links-info';
import { LoginUser } from '../entity/login-user';
import { LoginUserSite } from '../entity/login-user-site';
import { UserConfirmDialogInfo } from '../entity/user-confirm-dialog-info';
import { UserAddDialogComponent } from '../user-add-dialog/user-add-dialog.component';
import { UserCompleteDialogComponent } from '../user-complete-dialog/user-complete-dialog.component';
import { UserDeleteDialogComponent } from '../user-delete-dialog/user-delete-dialog.component';
import { UserDetailDialogComponent } from '../user-detail-dialog/user-detail-dialog.component';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { UserSiteLinksDialogComponent } from '../user-site-links-dialog/user-site-links-dialog.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent implements OnInit {
  selectedSite =  new UntypedFormControl();

  monthArray: string[];
  siteArray: LoginUserSite[];

  companyInfo: CompanyInfo[];
  userSiteLinksInfo: GetUserSiteLinksInfo[];

  loginUser: LoginUser;
  formControl: UntypedFormGroup;
  siteId: string;

  filtredUserData: GetUserWithSitesLinksInfo[];

  // ユーザー情報
  userData: GetUserWithSitesLinksInfo[] = new Array();
  // 会社情報
  companyData: CompanyInfo[] = new Array();
  isReadyCompanyData = false;
  // 施設情報
  siteData: SiteInfo[] = new Array();
  isReadySiteData = false;

  // ユーザー施設紐付情報
  userSiteLinksData: GetUserSiteLinksInfo[];
  isReadyUserSiteLinksData = false;

  // テーブル生成用
  displayedColumns: string[];

  idToken: string;

  // APIのURL
  getLoginUserUrl: string;
  getValueDetectionsUrl: string;
  getFloorsUrl: string;
  postValueConfirmationUrl: string;
  // APIのレスポンス
  getLoginUserResponse: GetLoginUserResponse;

  // フラグ類
  getUserFinished: boolean;
  isLoadingData: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;

  searchOptionKey = '';
  searchOptionsArray: string[] = ['ユーザー名', '所属会社', 'ユーザー権限'];

  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  searchKeyString = '';
  searchKeyChanged: Subject<string> = new Subject<string>();

  // for csv import
  csvUserData: CsvUserInfo[] = [];
  csvAllUserData: CsvUserInfo[] = [];
  csvAddUserData: CsvUserInfo[] = [];
  csvUpdateUserData: CsvUserInfo[] = [];
  csvErrorUserData: CsvUserInfo[] = [];
  csvDeleteUserData: CsvUserInfo[] = [];
  tsvFlg = false;
  csvImportAccountLockError = false
  csvImportUnexpectedError = false
  addResults: any[] = []
  updateResults: any[] = []
  deleteResults: any[] = []

  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
    private overlay: Overlay,
    ) {
      localStorage.setItem("path", router.url)
    }

  ngOnInit() {
    this.isLoadingData = false;
    this.loginUser = new LoginUser();
    this.siteArray = [];
    this.siteData.splice(0);
    this.getLoginUserResponse = new GetLoginUserResponse();
    this.getUserFinished = false;
    this.searchOptionKey = this.searchOptionsArray[0];
    this.searchKeyChanged
            .debounceTime(300) // wait 300ms after the last event before emitting last event
            .subscribe(str => {this.searchKeyString = str; this.filterResult(); });
    this.getToken();
  }

  getToken() {
    this.auth.getIdToken()
    .subscribe(
      result => {
        if (result) {
          this.idToken = result;
          this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
          this.doGetLoginUser();
        } else {
          // idトークンがnullの場合はログイン画面へ遷移
          alert('セッションが切れています。再度ログインしてください。');
          this.router.navigate(['login']);
        }
      }
    );
  }

   // idトークン利用してログインユーザー情報の取得を行う
   doGetLoginUser() {
    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
      .subscribe((response: GetLoginUserResponse) => {
        this.loginUser = response.result.login_user;
        if (Number(response.result.login_user.user_type) === this.userTypeUser) {
          return
        }
        Const.loginUser = response.result.login_user;
        if (Const.mjitUser) {
          Const.site_id = localStorage.getItem("siteId")
        } else {
          for (const site of response.result.login_user.sites) {
            if (site.id === localStorage.getItem("siteId")) {
              Const.site_id = site.id
            }
          }
        }

        if (Const.site_id === null) {
          alert('前回選択した施設が見つかりませんでした。');
          Const.site_id = response.result.login_user.sites[0].id;
        }

        Const.mjitUser = Number(response.result.login_user.user_type) === 0;
        this.mjitUserFlag = Const.mjitUser;
        Const.adminUser = Number(response.result.login_user.user_type) === 1;
        this.adminUserFlag = Const.adminUser;
        this.selectedSite =  new UntypedFormControl(Const.site_id);
        for (const site of response.result.login_user.sites) {
          this.siteArray.push(site);
        }
        this.loadCompany();
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
          }else{
            alert('ログイン情報が取得できませんでした。再度ログインしてください。');
          }
          this.onClickLogout();
        }
      );
  }

  // 紐付情報を含むユーザー情報取得
  loadUserData() {
    this.csvUserData = [];
    this.isReadyUserSiteLinksData = false;
    this.userData.splice(0);
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    // 会社IDに紐づくユーザーを取得する（allの場合全ユーザー）
    const url = `${environment.apiUrl}/users/user_site_links_info_all/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.users;
      for (const dataItem of data) {
        if (!Const.mjitUser && dataItem.user_type == 0) {
          continue
        }
        const userInfo = new GetUserWithSitesLinksInfo();
        const siteInfoList: UserSite[] = new Array();
        userInfo.id = dataItem.id;
        userInfo.loginId = dataItem.login_id;
        userInfo.name = dataItem.name;
        userInfo.companyId = dataItem.company.id;
        userInfo.companyName = dataItem.company.name;
        userInfo.userType = dataItem.user_type;
        userInfo.created_by = dataItem.created_by;
        userInfo.updated_by = dataItem.updated_by;
        switch (dataItem.user_type) {
          case 0:
              userInfo.userTypeName = 'システム管理者';
              break;
          case 1:
              userInfo.userTypeName = '管理者';
              break;
          case 2:
              userInfo.userTypeName = '一般';
              break;
          default:
            userInfo.userTypeName = '-';
          }

        for (const site of dataItem.sites) {
          const siteInfo = new UserSite();
          const siteItem = this.siteData.filter(item => {
            return item.id === site.id;
          });
          // 不正データ排除（別会社データが存在する場合エラー）
          if (siteItem.length === 0) {
            this.isLoadingData = true;
            throw new Error('bad data');
          }
          const companyItem = this.companyData.filter(item => {
            return item.id === siteItem[0].company_id;
          });
          // 不正データ排除（別会社データが存在する場合エラー）
          if (companyItem.length === 0) {
            this.isLoadingData = true;
            throw new Error('bad data');
          }
          siteInfo.siteId = siteItem[0].id;
          siteInfo.siteName = siteItem[0].name;
          siteInfo.siteAddress = siteItem[0].address;
          siteInfo.companyName = companyItem[0].name;
          siteInfoList.push(siteInfo);
        }
        userInfo.sites = siteInfoList;
        userInfo.createdAt = dataItem.created_at;
        userInfo.updatedAt = dataItem.updated_at;
        userInfo.lockStatus = dataItem.account_lock.lock_status;

        this.userData.push(userInfo);
        // CSV比較用データ生成
        const csvUserItem = new CsvUserInfo();
        csvUserItem.userId = userInfo.id;
        csvUserItem.loginId = userInfo.loginId;
        csvUserItem.password = '';
        csvUserItem.userName = userInfo.name;
        csvUserItem.companyId = userInfo.companyId;
        csvUserItem.userType = userInfo.userType;
        csvUserItem.siteIdList = [];
        for (const site of userInfo.sites) {
          csvUserItem.siteIdList.push(site.siteId);
        }
        this.csvUserData.push(csvUserItem);

        this.isReadyUserSiteLinksData = true;
        this.isLoadingData = true;
      }
      this.filtredUserData = this.userData;
      this.displayedColumns = ['detailButton', 'userName', 'loginId', 'companyName', 'userType','sitesList', 'editButton', 'deleteButton'];
    },
      () => {
      alert('ユーザー情報が取得できませんでした。');
      this.onClickLogout();
    });
  }

  // 所管会社の取得（UserType:0はシステム管理者として登録されている会社を全件取得する。UserType:1は自身の所属会社のみ）
  loadCompany() {
    this.isReadyCompanyData = false;
    this.companyData.splice(0);
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    // 会社を取得する（allの場合全会社）
    const url = `${environment.apiUrl}/company/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.companies;
      if (Array.isArray(data)) {
        this.companyData = data;
      } else {
        this.companyData.push(data);
      }
      this.isReadyCompanyData = true;
      this.loadSite();
    },
      () => {
      alert('会社情報が取得できませんでした。');
    });
  }

  // 施設詳細の取得
  loadSite() {
    this.isReadySiteData = false;
    this.siteData.splice(0);
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    // 会社を取得する（allの場合全会社）
    const url = `${environment.apiUrl}/sites/sites_info_all/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.sites;
      for (const dataItem of data) {
        const getSiteInfo = new SiteInfo();
        getSiteInfo.id = dataItem.id;
        getSiteInfo.name = dataItem.name;
        getSiteInfo.address = dataItem.address;
        getSiteInfo.company_id = dataItem.company_id;
        this.siteData.push(getSiteInfo);
      }
      this.isReadySiteData = false;
      this.loadUserData();
    },
      () => {
      alert('会社情報が取得できませんでした。');
    });
  }

  // 詳細表示
  onClickDetailUser(user: GetUserWithSitesLinksInfo) {
    for (const site of user.sites) {
      this.loadSite();
    }
    this.dialog.open(UserDetailDialogComponent, {
      width: '600px',
      data: user
    });
  }

  // 登録処理
  onClickAddUser() {
    const dialogRef = this.dialog.open(UserAddDialogComponent, {
      width: '800px',
    });
    dialogRef.componentInstance.company = this.companyData;
    dialogRef.componentInstance.siteInfo = this.siteData;
    dialogRef.componentInstance.userInfo = this.userData;

    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
        this.ngOnInit();
      }
    });
  }

  // 更新処理
  onClickUserEdit(user: GetUserWithSitesLinksInfo) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      width: '800px',
      data: user
    });
    dialogRef.componentInstance.company = this.companyData;

    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
        this.ngOnInit();
      }
    });
  }

  // 削除処理
  onClickUserDelete(user: GetUserWithSitesLinksInfo) {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      width: '800px',
      data: user
    });
    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
        this.ngOnInit();
      }
    });
  }

  // CSV出力
  onClickExportUserCSV() {
    const companyID = this.loginUser.company.id;
    // CSVの項目：user_id(db),login_id,password,user_name,company_id,user_type,site_id(n)
    // 操作タイプ、ユーザーID、ログインID、パスワード、ユーザー名、会社ID、ユーザー権限、施設ID（N件。紐づくもの全て）
    let csvData = 'user_id' + ',' +
    'login_id' + ',' +
    'password' + ',' +
    'user_name' + ',' +
    'company_id' + ',' +
    'user_type' + ',' +
    'site_id\r\n';

    for (const row of this.filtredUserData) {
      const siteIdList = [];
      for (const site of row.sites) {
        siteIdList.push(site.siteId);
      }
      const rowData =
        row.id + ',' +
        row.loginId + ',' +
        ',' + // passwordは空で出力
        row.name + ',' +
        row.companyId + ',' +
        row.userType + ',' +
        siteIdList +
        '\r\n';
      const rowDataFormated = rowData.replace(/\*/g, '').replace(/N\/A/g, '');
      csvData = csvData + rowDataFormated;
    }
    const strArray = Encoding.stringToCode(csvData);
    const sjisArray = Encoding.convert(strArray, 'SJIS', 'UNICODE');
    const uint8Array = new Uint8Array(sjisArray);
    const blob = new Blob([uint8Array], {type: 'text/tab-separated-values'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    let fileName = 'ユーザー一覧.csv';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      fileName = this.loginUser.company.name + 'ユーザー一覧.csv';
    }
    link.download = fileName;
    link.click();

  }

  // CSVインポート（ペンディング）
  onClickImportUserCSV() {
    document.querySelector('input').click();
    // 読み込み完了イベントをトリガーにchangeListenerが起動
  }

  public changeListener(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      const reader: FileReader = new FileReader();
      reader.readAsText(file, 'Shift_JIS');
      reader.onload = async (_) => {
        const csv: string = reader.result as string;
        const results = Papa.parse(csv);
        if (results.meta.delimiter === '\t') {
          this.tsvFlg = true;
        }
        let messageContents = 'ユーザー情報をインポートします。よろしいですか？';
        const rows = csv.split('\r\n');
        if (rows.length > 1000) {
          messageContents = messageContents + `\n※1000件を超えています。処理に時間がかかる可能性があります。`;
        }
        const messageInfo = new UserConfirmDialogInfo('CSVインポート', messageContents );
        const userConfirmDialogRef = this.dialog.open(CommonConfirmDialogComponent, {
          width: '650px',
          data: messageInfo
        });

        userConfirmDialogRef.afterClosed().subscribe(result => {
          if (result === 'Yes') {
            this.handleCSVStringToUserArray(csv);
          }
        });
      };
    }
  }

  handleCSVStringToUserArray(csvString: string) {
    this.overlayRef.attach(new ComponentPortal(MatSpinner));

    this.csvAllUserData = [];
    this.csvAddUserData = [];
    this.csvUpdateUserData = [];
    this.csvErrorUserData = [];
    let splitString = ',';
    if (this.tsvFlg) {
      splitString = '\t';
    }

    const rows = csvString.split('\r\n');
    const headerRowTitles = rows[0].trim().split(splitString);
    if (this.checkHeaderTitle(headerRowTitles)) {
      alert('csvファイルのフォーマットが正しいか確認してください。');
      this.overlayRef.detach();
      return;
    }

    // CSVの項目：user_id,login_id,password,user_name,company_id,user_type,site_id(n)
    for (let i = 1; i < rows.length; i++) {
      const csvuserInfo = new CsvUserInfo();
      const jsonStr = rows[i];
      const strArr = jsonStr.split(splitString);
      // 項目の足りないレコードはスキップ
      if (strArr.length < 7) {
        continue;
      }
      csvuserInfo.userId = strArr[0].trim();
      csvuserInfo.loginId = strArr[1].trim();
      csvuserInfo.password = strArr[2].trim();
      csvuserInfo.userName = strArr[3].trim();
      csvuserInfo.companyId = strArr[4].trim();
      csvuserInfo.userType = Number(strArr[5].trim());
      const csvSiteIdList = strArr.slice(-(strArr.length - 6));
      csvuserInfo.siteIdList = [];
      for (const csvSiteId of csvSiteIdList) {
        if (csvSiteId.length > 0) {
          csvuserInfo.siteIdList.push(csvSiteId);
        }
      }
      // idが空の場合登録
      if (csvuserInfo.userId === '') {
        // 項目チェックを行い不正データは振り分ける
        this.checkAddCSVData(csvuserInfo);
      }
      // idが存在する場合更新
      const userItem = this.userData.filter(item => {
        return item.id === csvuserInfo.userId;
      });
      if (userItem.length > 0) {
        // 項目チェックを行い不正データは振り分ける
        this.checkUpdateCSVData(csvuserInfo);
      }
      this.csvAllUserData.push(csvuserInfo);
    }
    // DBのユーザー - CSVのユーザーの差分が削除対象
    const difference = this.csvUserData.filter(item => {
        return JSON.stringify(this.csvAllUserData).indexOf(JSON.stringify(item)) === -1;
    });
    this.csvDeleteUserData = difference.filter(item => {
      return !this.csvAllUserData.some(csvData => {
        return csvData.userId === item.userId;
      });
    });
    // 重複削除
    this.csvAddUserData = this.csvAddUserData.filter((element, index, self) =>
      self.findIndex(e => e.loginId === element.loginId ) === index
    );
    this.csvUpdateUserData = this.csvUpdateUserData.filter((element, index, self) =>
      self.findIndex(e => e.userId === element.userId ) === index
    );
    this.beginToUploadCSVUser();
  }

  checkHeaderTitle(headerRowTitles): boolean {
    if (headerRowTitles[0] !== 'user_id') {
      return true;
    }
    if (headerRowTitles[1] !== 'login_id') {
      return true;
    }
    if (headerRowTitles[2] !== 'password') {
      return true;
    }
    if (headerRowTitles[3] !== 'user_name') {
      return true;
    }
    if (headerRowTitles[4] !== 'company_id') {
      return true;
    }
    if (headerRowTitles[5] !== 'user_type') {
      return true;
    }
    if (headerRowTitles[6] !== 'site_id') {
      return true;
    }
    return false;
  }

  checkAddCSVData(csvuserInfo: CsvUserInfo) {
    const loginIdPolicy = new RegExp(/^([a-zA-Z0-9!-/:-@¥[-`{-~]{8,32})+$/);
    // const passwordPolicy = new RegExp(/^([a-zA-Z0-9!-/:-@¥[-`{-~]{8,32})+$/);
    const passwordPolicy = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,99}$/);

    // ログインIDのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.loginId)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // ログインIDポリシーチェック
    if (csvuserInfo.loginId.match(loginIdPolicy) === null) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // ログインIDの重複チェック
    const isUserData = this.userData.filter(item => {
      return item.loginId === csvuserInfo.loginId;
    });
    if (isUserData.length > 0) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // パスワードのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.password)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // パスワードポリシーチェック
    if (csvuserInfo.password.match(passwordPolicy) === null) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // userNameのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.userName)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // companyIDのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.companyId)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // companyIDの妥当性チェック
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && this.loginUser.company.id !== csvuserInfo.companyId) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // userTypeのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.userType)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // userTypeの妥当性チェック
    // 操作者が管理者の場合、管理者OR一般
    const userTypeList = [1, 2];
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && !userTypeList.includes(csvuserInfo.userType)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // siteのCSV存在チェック
    if (csvuserInfo.siteIdList.length === 0) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // site情報がDB上に存在するか
    for (const siteId of csvuserInfo.siteIdList) {
      const siteInfo = this.siteData.filter(item => {
        return item.id === siteId;
      });
      if (siteInfo.length === 0) {
        this.csvErrorUserData.push(csvuserInfo);
        return;
      }
    }
    this.csvAddUserData.push(csvuserInfo);
  }

  checkUpdateCSVData(csvuserInfo: CsvUserInfo) {
    let updateFlg = false;
    const passwordPolicy = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,99}$/);
    // idは存在するのでこれを元にユーザー情報がDB上に存在するかチェック
    const userInfo = this.userData.filter(item => {
      return item.id === csvuserInfo.userId;
    });
    if (userInfo.length === 0) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // loginID同一チェック（CSV存在もここでチェック）
    if (userInfo[0].loginId !== csvuserInfo.loginId) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // パスワードチェック（値がセットされている場合更新）
    if (csvuserInfo.password.length > 0) {
      // パスワードポリシーチェック
      if (csvuserInfo.password.match(passwordPolicy) === null) {
        this.csvErrorUserData.push(csvuserInfo);
        return;
      } else {
        updateFlg = true;
      }
    }

    // userNameのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.userName)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    if (userInfo[0].name !== csvuserInfo.userName) {
      updateFlg = true;
    }

    // companyIDのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.companyId)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // companyIDの妥当性チェック
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && this.loginUser.company.id !== csvuserInfo.companyId) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }

    // userTypeのCSV存在チェック
    if (!this.checkValueIsInvalid(csvuserInfo.userType)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    // userTypeの妥当性チェック
    // 操作者が管理者の場合、管理者OR一般
    const userTypeList = [1, 2];
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && !userTypeList.includes(csvuserInfo.userType)) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    if (userInfo[0].userType !== csvuserInfo.userType) {
      updateFlg = true;
    }

    // siteのCSV存在チェック
    if (csvuserInfo.siteIdList.length === 0) {
      this.csvErrorUserData.push(csvuserInfo);
      return;
    }
    const dbUserSiteList: string[] = new Array();
    for (const site of userInfo[0].sites) {
      dbUserSiteList.push(site.siteId);
    }
    if (dbUserSiteList.length !== csvuserInfo.siteIdList.length) {
      updateFlg = true;
    }
    // site情報がDB上に存在するか
    for (const siteId of csvuserInfo.siteIdList) {
      const siteInfo = this.siteData.filter(item => {
        return item.id === siteId;
      });
      if (siteInfo.length === 0) {
        this.csvErrorUserData.push(csvuserInfo);
        return;
      }
      // CSVのuser_typeが管理者、一般の場合取得したsiteのcompanyIDがCSVのCompanyIDと同一かチェック
      if (userTypeList.includes(csvuserInfo.userType)) {
        if (siteInfo[0].company_id !== csvuserInfo.companyId) {
          this.csvErrorUserData.push(csvuserInfo);
          return;
        }
      }
    }
    if (updateFlg) {
      csvuserInfo.dbuserData = userInfo[0];
      this.csvUpdateUserData.push(csvuserInfo);
    }
  }

  checkValueIsInvalid(val: any) {
    return !(val === '' || val === null || val === undefined);
  }

  async beginToUploadCSVUser() {
    if (!navigator.onLine) {
      alert('ネットワーク接続を確認してください');
      return;
    }
    // for add
    this.addResults = await this.addUsers();
    const addSuccessCount = this.addResults.filter(item => item === 1).length;
    const addFailedCount = this.addResults.filter(item => item === 3).length;

    // for update
    if (!this.csvImportAccountLockError && !this.csvImportUnexpectedError){
      this.updateResults = await this.updateUsers();
    }
    const updateSuccessCount = this.updateResults.filter(item => item === 1).length;
    const updateFailedCount = this.updateResults.filter(item => item === 3).length;

    // for delete
    if (!this.csvImportAccountLockError && !this.csvImportUnexpectedError){
      this.deleteResults = await this.deleteUsers();
    }
    const deleteSuccessCount = this.deleteResults.filter(item => item === 1).length;
    const deleteFailedCount = this.deleteResults.filter(item => item === 3).length;

    this.overlayRef.detach();

    if(this.csvImportAccountLockError){
      alert('アカウントがロックされました。管理者までお問合せください');
      this.onClickLogout()
    } else {
      const successCount = updateSuccessCount + addSuccessCount + deleteSuccessCount;
      const failCount = addFailedCount + updateFailedCount + deleteFailedCount;

      const resultString = `インポートが完了しました。\n成功${successCount}件/失敗${failCount}件`;
      const dialogRef = this.dialog.open(UserCompleteDialogComponent, {
        width: '600px',
        data: resultString
      });

      dialogRef.afterClosed().subscribe(_ => {
        this.ngOnInit();
      });
    }
  }

  async addUsers() {
    const addResults: any[] = [];
    for await (const user of this.csvAddUserData) {
      // 戻り値１：正常、２：処理なし、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.createCognitoUser(user);
      if (res === 4){
        this.csvImportUnexpectedError = true
        break;
      }
      if  (res === 1 || res === 3){
        addResults.push(res);
      }
    }
    return addResults;
  }

  // Cognitoへの登録後DBにユーザーを作成する
  async createCognitoUser(user: CsvUserInfo): Promise<number> {
    return new Promise(async (resolve, _) => {
      try {
          await Auth.signUp({
            username: user.loginId,
            password: user.password,
          }).then(() => {
            // Cognitoユーザーの確認ステータスを確認済に変更
            const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/activate_cognito_user`;
            let params = new HttpParams();
            params = params.set('user_pool_id', environment.amplify.Auth.aws_user_pools_id);
            params = params.set('user_name', user.loginId);
            params = params.set('password', user.password);
            this.httpClient.post(postActivateCognitoUserUrl, params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken,
                  'Content-Type': 'application/x-www-form-urlencoded'
                })
              }
            )
            .subscribe(async (response) => {
              resolve(await this.createUser(user));
            }, err => {
              if (err.error.code = 'AccountLockError'){
                this.csvImportAccountLockError = true
                resolve(4)
              }else{
                resolve(3);
              }
            });
          });
      } catch (error) {
        resolve(4);
      }
    });
  }



  // 作成したユーザーのユーザーIDを取得しユーザー情報を登録する
  async createUser(user: CsvUserInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
          // Cognitoユーザーを取得
          const postActivateCognitoUserUrl =
          `${environment.apiUrl}/cognito/get_cognito_user/${environment.amplify.Auth.aws_user_pools_id}/${user.loginId}`;
          this.httpClient.get(postActivateCognitoUserUrl,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken
              })
            }
          )
          .subscribe((response) => {
            const jsonStr = JSON.stringify(response);
            const jsonObj = JSON.parse(jsonStr);
            const data = jsonObj.result.cognito;
            // APIコールして登録処理
            const postUserUrl = `${environment.apiUrl}/users/users_info`;
            let params = new HttpParams();
            params = params.set('id', data.UserAttributes[0].Value);
            params = params.set('login_id', user.loginId);
            params = params.set('name', user.userName);
            params = params.set('company_id', user.companyId);
            params = params.set('user_type', String(user.userType));
            this.httpClient.post(postUserUrl, params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken
                })
              }
            )
            .subscribe(async (res) => {
              const jsonUserStr = JSON.stringify(res);
              const jsonUserObj = JSON.parse(jsonUserStr);
              const userData = jsonUserObj.result.user;
              // ユーザー施設紐付けを行う
              resolve(await this.postUserSitelinks(userData.id, user));
            }, err => {
              if (err.error.code = 'AccountLockError'){
                this.csvImportAccountLockError = true
                resolve(4)
              }else{
                resolve(3)
              }
            });
          });
      } catch (error) {
        resolve(4)
      }
    });
  }

  // ユーザー施設紐付け情報の登録
  async postUserSitelinks(userID: string, user: CsvUserInfo): Promise<number> {
    const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
    return new Promise((resolve, _) => {
      try {
        for (const siteId of user.siteIdList) {
          let params = new HttpParams();
          params = params.set('user_id', userID);
          params = params.set('site_id', siteId);
          this.httpClient.post(
            posthUserUrl,
            params,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken
              })
            }
          )
          .subscribe((response) => {
            const jsonUserSitelinks = JSON.stringify(response);
            const jsonUserObj = JSON.parse(jsonUserSitelinks);
            resolve(1);
          }, err => {
            if (err.error.code = 'AccountLockError'){
              this.csvImportAccountLockError = true
              resolve(4)
            }else{
              resolve(3)
            }
          });
        }
      } catch (error) {
        resolve(4);
      }
    });
  }

  async updateUsers() {
    const dupdateResults: any[] = [];
    for await (const user of this.csvUpdateUserData) {
      // 戻り値１：正常、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.updateCSVUser(user);
      if (res === 1 || res === 3) {
        dupdateResults.push(res);
      }
      if (res === 4){
        this.csvImportUnexpectedError = true
        break
      }
    }
    return dupdateResults;
  }

  async updateCSVUser(user: CsvUserInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        // APIコールして更新処理
        const patchUserUrl = `${environment.apiUrl}/users/users_info/1/${user.userId}`;
        let params = new HttpParams();
        params = params.set('name', user.userName);
        params = params.set('company_id', user.companyId);
        params = params.set('user_type', String(user.userType));
        this.httpClient.patch(patchUserUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe(async (response) => {
          const jsonStr = JSON.stringify(response);
          const jsonObj = JSON.parse(jsonStr);
          const data = jsonObj.result.user;
          // パスワード変更
          if (user.password.length > 0) {
            const result = await this.changePassword(user);
            resolve(result)
            
          }
          // ユーザー施設紐付けを行う
          const resultUpdateUserSitelinks = await this.updateUserSitelinks(user);
          resolve(resultUpdateUserSitelinks);
        },
        err => {
          if(err.error.code == "AccountLockError"){
            this.csvImportAccountLockError = true
            resolve(4);
          }else{
            resolve(3);
          }
        });
      } catch (error) {
        resolve(4);
      }
    });
  }

  async changePassword(user: CsvUserInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
      // パスワード更新　パスワードの設定がある場合Cognitoのパスワード変更を行い、確認ステータスを確認済に変更
        const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/activate_cognito_user`;
        let cognitoParams = new HttpParams();
        cognitoParams = cognitoParams.set('user_pool_id', environment.amplify.Auth.aws_user_pools_id);
        cognitoParams = cognitoParams.set('user_name', user.loginId);
        cognitoParams = cognitoParams.set('password', user.password);
        this.httpClient.post(postActivateCognitoUserUrl, cognitoParams,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken,
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          }
        ).subscribe(async (result) => {
          resolve(1);
        },
        err => {
          if(err.error.code == "AccountLockError"){
            this.csvImportAccountLockError = true
            resolve(4);
          }else{
            resolve(3);
          }
        });
      }catch (error) {
        resolve(4);
      }
    });
  }

  // 取得時のの紐付け情報と比較を行い、増減によりユーザーサイト紐付けの更新を行う
  async updateUserSitelinks(user: CsvUserInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        // 元々の紐付けと更新時の紐付けを比較
        const dbUserSiteList: string[] = new Array();
        for (const site of user.dbuserData.sites) {
          dbUserSiteList.push(site.siteId);
        }
        let updateDelteList: string[] = new Array();
        let updateAddList: string[] = new Array();
        // dbUserSiteList > 0 && user.siteIdList > 0 --> 差分検出してuser.siteIdListに差分がある場合 --> 差分追加
        // A | A,B
        if (dbUserSiteList.length > 0 && user.siteIdList.length > 0) {
          updateAddList = user.siteIdList.filter(i => JSON.stringify(dbUserSiteList).indexOf(JSON.stringify(i)) === -1);
          if (updateAddList.length > 0) {
            for (const updateAddSiteId of updateAddList) {
                  const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
                  let params = new HttpParams();
                  params = params.set('user_id', user.userId);
                  params = params.set('site_id', updateAddSiteId);
                  this.httpClient.post(
                    posthUserUrl,
                    params,
                    {
                      headers: new HttpHeaders({
                        Authorization: this.idToken
                      })
                    }
                  )
                  .subscribe((response) => {
                    resolve(1);
                  }, err => {
                    if(err.error.code == "AccountLockError"){
                      this.csvImportAccountLockError = true
                      resolve(4);
                    }
                  });
            }
          }
          // dbUserSiteList > 0 && user.siteIdList > 0 --> 差分検出してdbUserSiteListに差分がある場合 --> 差分削除
          // A,B | A
          updateDelteList = dbUserSiteList.filter(i => JSON.stringify(user.siteIdList).indexOf(JSON.stringify(i)) === -1);
          if (updateDelteList.length > 0) {
            for (const updateDelteSiteId of updateDelteList) {
              const patchUserUrl = `${environment.apiUrl}/user_site_links/delete_user_site_links_info`;
              let updateDelteParams = new HttpParams();
              updateDelteParams = updateDelteParams.set('user_id', user.userId);
              updateDelteParams = updateDelteParams.set('site_id', updateDelteSiteId);
              this.httpClient.patch(
                patchUserUrl,
                updateDelteParams,
                {
                  headers: new HttpHeaders({
                    Authorization: this.idToken
                  })
                }
              )
              .subscribe((response) => {
                resolve(1);
              }, err => {
                if(err.error.code == "AccountLockError"){
                  this.csvImportAccountLockError = true
                  resolve(4);
                }
              });
            }
          }
        }

        // dbUserSiteList === 0 && user.siteIdList > 0 --> user.siteIdList追加
        // - | A
        if (dbUserSiteList.length === 0 && user.siteIdList.length > 0) {
          for (const siteId of user.siteIdList) {
            const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
            let params = new HttpParams();
            params = params.set('user_id', user.userId);
            params = params.set('site_id', siteId);
            this.httpClient.post(
              posthUserUrl,
              params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken
                })
              }
            )
            .subscribe((response) => {
              resolve(1);
            }, err => {
              if(err.error.code == "AccountLockError"){
                this.csvImportAccountLockError = true
                resolve(4);
              }else{
                resolve(3);
                alert('施設の紐付けに失敗しました');
              }
            });
          }
        }

        // dbUserSiteList === user.siteIdList --> チェック済みの為処理なし
        // - | -
        // A | A

        // dbUserSiteList > 0 &&  user.siteIdList === 0 --> チェック済みの為処理なし
        // A | -
        // A,B | -
      resolve(1);
      } catch (error) {
        resolve(4);
      }
    });
  }

  async deleteUsers() {
    const deleteResults: any[] = [];
    for await (const user of this.csvDeleteUserData) {
       // 戻り値１：正常、２：処理なし、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.deleteCSVUser(user);
      if (res === 4){
        this.csvImportUnexpectedError = true
        break;
      }
      if (res === 1 || res === 3){
        deleteResults.push(res);
      }
    }
    return deleteResults;
  }

  async deleteCSVUser(user: CsvUserInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        // Cognitoユーザーのステータスを無効に変更
        const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/disable_user`;
        let cognitoParams = new HttpParams();
        cognitoParams = cognitoParams.set('user_pool_id', environment.amplify.Auth.aws_user_pools_id);
        cognitoParams = cognitoParams.set('user_name', user.loginId);
        this.httpClient.post(postActivateCognitoUserUrl, cognitoParams,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken,
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          }
        ).subscribe((response: any) => {
          // ユーザー削除
          const patchUserUrl = `${environment.apiUrl}/users/users_info/0/${user.userId}`;
          const params = new HttpParams();
          this.httpClient.patch(patchUserUrl, params,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken
              })
            }
          ).subscribe((responseb) => {
            resolve(1);
          },
          err => {
            if(err.error.code == "AccountLockError"){
              this.csvImportAccountLockError = true
              resolve(4);
            }else{
              resolve(3);
            }
            
          });
        },
          err => {
            if(err.error.code == "AccountLockError"){
              this.csvImportAccountLockError = true
              resolve(4);
            }else{
              resolve(3);
            }
          }
        );
      } catch (error) {
          resolve(4);
      }
    });
  }

  onClickSelectSite(user: GetUserWithSitesLinksInfo) {
    const dialogRef = this.dialog.open(UserSiteLinksDialogComponent, {
      width: '1000px',
      data: user.sites
    });
    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
      }
    });
  }

  // 検索ボックス
  search(text: string) {
    this.searchKeyChanged.next(text);
  }

  filterResult() {
    if (this.searchKeyString === '') {
      this.filtredUserData = this.userData;
      return;
    }
    switch (this.searchOptionKey) {
      case this.searchOptionsArray[0]:
        this.filtredUserData = this.userData.filter(item => item.name.includes(this.searchKeyString));
        break;
      case this.searchOptionsArray[1]:
        this.filtredUserData = this.userData.filter(item => item.companyName.includes(this.searchKeyString));
        break;
      case this.searchOptionsArray[2]:
        this.filtredUserData = this.userData.filter(item => item.userTypeName.includes(this.searchKeyString));
        break;
      default:
        break;
    }
  }

  onChangeOption() {
    this.searchKeyChanged.next(this.searchKeyString);
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }
}
