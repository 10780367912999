import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { CsvSiteInfo } from '../entity/csv-site-info';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { SiteInfo } from '../entity/get-site-info';
import { idName } from '../entity/id-name';
import { LoginUser } from '../entity/login-user';
import { LoginUserSite } from '../entity/login-user-site';
import { LoginUserSiteCompany } from '../entity/login-user-site-company';
import { SiteConfirmDialogInfo } from '../entity/site-confirm-dialog-info';
import { SiteAddOrEditDialogComponent } from '../site-add-edit-dialog/site-add-edit-dialog.component';
import { SiteCompleteDialogComponent } from '../site-complete-dialog/site-complete-dialog.component';
import { SiteDeleteDialogComponent } from '../site-delete-dialog/site-delete-dialog.component';


export class CompanyModel {
  id: string;
  name: string;
  createdAt = new Date();
  updatedAt = new Date();
  createdBy = idName;
  updatedBy = idName;
}

export class UserForSiteModel {

  id: string;
  name: string;

  user_type: number;
  user_type_name: string;

  company_id: string;
  company_name: string;

  company: CompanyModel;
  created_at: string;
  updated_at: string;

  is_selected = false;
}

export class SiteWithUserInfo {
  id: string;
  name: string;
  address: string;
  company_id: string;
  company_name: string;
  users: UserWithSiteInfo[];
  createdAt: string;
  updatedAt: string;
}

export class UserWithSiteInfo {
  id: string;
  name: string;
  userType: number;

  company: CompanyModel;
  sites: SiteInfo[];

  createdAt: string;
  updatedAt: string;
}


@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss']
})

export class SiteManagementComponent implements OnInit {
  loginUser: LoginUser;
  filtredsiteData:  SiteInfo[] = [];
  sitesData: SiteInfo[] = []
  siteData: SiteInfo[] = [];
  userData: UserForSiteModel[] = [];
  companiesData: CompanyModel[] = [];
  companyData: CompanyModel[] = [];
  isSiteUserDataReady = false;
  siteUserData: SiteWithUserInfo[] = [];
  // テーブル生成用
  displayedColumns: string[];

  // APIのURL
  idToken: string;
  getLoginUserUrl: string;
  getAllSiteUrl: string;

  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  // フラグ類
  getUserFinished = false;
  isLoadingData = false;
  isCompanyDataReady = false;
  isUserDataReady = false;
  mjitUserFlag = false;
  adminUserFlag = false;

  searchOptionKey = '';
  searchOptionsArray: string[] = ['施設名', '所管会社'];

  searchKeyString = '';
  searchKeyChanged: Subject<string> = new Subject<string>();

  // for csv import
  csvSiteData: CsvSiteInfo[] = [];
  csvAllSiteData: CsvSiteInfo[] = [];
  csvAddPreSiteData: CsvSiteInfo[] = [];
  csvAddSiteData: CsvSiteInfo[] = [];
  csvAddSiteUserLinksData: CsvSiteInfo[] = [];
  csvUpdateSiteForSiteData: CsvSiteInfo[] = [];
  csvUpdatePreSiteForUserData: CsvSiteInfo[] = [];
  csvUpdateSiteForUserData: CsvSiteInfo[] = [];
  csvErrorSiteData: CsvSiteInfo[] = [];
  csvDeletePreSiteData: CsvSiteInfo[] = [];
  csvDeleteDupSiteData: CsvSiteInfo[] = [];
  csvDeleteSiteData: string[] = [];
  addFailedCount: number;
  addUserSiteLinksFailedCount: number;
  updateFailedCount: number;
  tsvFlg = false;
  csvImportAccountLockError = false
  csvImportUnexpectedError = false
  updateResults: any[] = []
  addResults: any[] = []
  updateUserSiteLinksResults: any[] = []
  deleteResults: any[] = []

  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
    private overlay: Overlay,
    ) {
      localStorage.setItem("path", router.url)
    }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.searchOptionKey = this.searchOptionsArray[0];
    this.searchKeyChanged
            .debounceTime(300) // wait 300ms after the last event before emitting last event
            .subscribe(str => {this.searchKeyString = str; this.filterResult(); });

    this.getToken();
  }

  getToken() {

    this.auth.getIdToken()
    .subscribe(
      result => {
        if (result) {
          this.idToken = result;
          this.doGetLoginUser()
        } else {
          // idトークンがnullの場合はログイン画面へ遷移
          alert('セッションが切れています。再度ログインしてください。');
          this.router.navigate(['login']);
        }
      }
    );
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。UserType:1は自身の所属会社所管の施設を取得）
  loadSiteData() {
    this.siteData.splice(0);
    this.isLoadingData = false;
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    const url = `${environment.apiUrl}/sites/sites_info_all/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken,
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      this.sitesData = jsonObj.result.sites;

      // システム管理者の場合、最新を取得しConstを更新
      if (Number(this.loginUser.user_type) === 0) {
        this.siteData = this.sitesData;
        Const.siteInfo.splice(0);
        for (const site of this.sitesData) {
          const siteItem = new LoginUserSite();
          siteItem.id = site.id;
          siteItem.name = site.name;
          siteItem.address = site.address;
          siteItem.updated_at = site.updated_at;
          siteItem.created_at = site.created_at;
          siteItem.company = new LoginUserSiteCompany();
          siteItem.company.id = site.company_id;
          siteItem.company.name = site.company_name;
          Const.siteInfo.push(siteItem);
        }
      } else if (Number(this.loginUser.user_type) === 1 || Number(this.loginUser.user_type) === 2) {
        this.sitesData.forEach(site => {
          if (site.company_id === this.loginUser.company.id) {
            this.siteData.push(site)
          }
        })
      }
      
      this.loadCompany()
    },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
          this.onClickLogout();
        }else{
          alert('施設情報が取得できませんでした。');
        }
    });
  }

  loadSiteUserData() {
      this.isSiteUserDataReady = false;
      this.siteUserData = [];
      let companyId = 'all';
      if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
        companyId = this.loginUser.company.id;
      }
      const url = `${environment.apiUrl}/sites/user_site_links_info_all/${companyId}`;

      this.httpClient.get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }).subscribe( res => {
        const jsonStr = JSON.stringify(res);
        const jsonObj = JSON.parse(jsonStr);
        const data = jsonObj.result.sites;
        this.siteUserData = data;
        this.siteUserData.forEach(site => {
          if (site.company_id !== '') {
            if (this.companyData.length > 1) {
              const companyArray = this.companyData.filter(item => item.id === site.company_id);
              if (companyArray.length > 0) {
                site.company_name = companyArray[0].name;
              } else {
                site.company_id = '00000000-0000-0000-0000-000000000000';
                site.company_name = '未登録';
              }
            } else {
              site.company_name = (this.companyData[0] as unknown as CompanyModel).name;
            }
          } else {
            site.company_id = '00000000-0000-0000-0000-000000000000';
            site.company_name = '未登録';
          }
        });
        this.isSiteUserDataReady = true;
      });
  }
  // 所管会社の取得（UserType:0はシステム管理者として登録されている会社を全件取得する。UserType:1は自身の所属会社のみ）
  loadCompany() {
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    this.companyData = [];
    this.companiesData = [];
    this.companyData.splice(0);
    this.isCompanyDataReady = false;
    const url = `${environment.apiUrl}/company/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.companies;

      if (Array.isArray(data)) {
        this.companiesData = data;
      } else {
        this.companiesData.push(data as unknown as CompanyModel);
      }

      if (Number(this.loginUser.user_type) === 0) {
        this.companyData = this.companiesData
      } else if (Number(this.loginUser.user_type) === 1 || Number(this.loginUser.user_type) === 2) {
        this.companiesData.forEach (company => {
          if (company.id === this.loginUser.company.id) {
            this.companyData.push(company)
          }
        })
      }

      this.siteData.forEach(site => {
        if (site.company_id !== '') {
          if (this.companyData.length > 1) {
            const companyArray = this.companyData.filter(item => item.id === site.company_id);
            if (companyArray.length > 0) {
              site.company_name = companyArray[0].name;
            }
          } else {
            site.company_name = (this.companyData[0] as unknown as CompanyModel).name;
          }
        } else {
          site.company_id = '00000000-0000-0000-0000-000000000000';
          site.company_name = '未登録';
        }
      });
      this.filtredsiteData = this.siteData;
      this.displayedColumns = ['siteName', 'siteAddress', 'companyName', 'createBy',  'createAt','updateBy', 'updateAt', 'editButton', 'deleteButton'];
      this.isCompanyDataReady = true;
      this.isLoadingData = true;
      this.loadUserData();
    },
      () => {
      alert('会社情報が取得できませんでした。');
    });
  }

  loadUserData() {
    this.userData = [];
    this.isUserDataReady = false;
    let companyId = 'all';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      companyId = this.loginUser.company.id;
    }
    const url = `${environment.apiUrl}/users/${companyId}`;

    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.users;

      this.userData = data;
      this.userData.forEach(item => {
        item.is_selected = false;

        if (item.company) {
          item.company_id = item.company.id;
          item.company_name = item.company.name;
        } else {
          item.company_id = '-1';
          item.company_name = '未登録';
        }
        switch (item.user_type) {
          case 0:
            item.user_type_name = 'システム管理者';
            break;
          case 1:
            item.user_type_name =  '管理者';
            break;
          case 2:
            item.user_type_name =  '一般';
            break;
          default:
            item.user_type_name =  '-';
            break;
        }
      });

      this.isUserDataReady = true;
      this.loadSiteUserData();
    },
      () => {
      alert('ユーザーが取得できませんでした。');
    });
  }


  // 施設登録
  onClickAddSite() {
    const dialogRef = this.dialog.open(SiteAddOrEditDialogComponent, {
      width: '600px',
      data: false
    });

    dialogRef.componentInstance.companyData = this.companyData;
    dialogRef.componentInstance.allUserData = this.userData;

    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
        this.ngOnInit();
      }
    });
  }

  // 施設更新
  onClickEditSite(site: SiteInfo) {

    const dialogRef = this.dialog.open(SiteAddOrEditDialogComponent, {
      width: '600px',
      data: true,
    });

    dialogRef.componentInstance.companyData = this.companyData;
    dialogRef.componentInstance.allUserData = this.userData;
    dialogRef.componentInstance.siteModel = site;

    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
          this.ngOnInit();
      }
    });
  }

  // 施設削除
  onClickDeleteSite(site: SiteInfo) {

    const dialogRef = this.dialog.open(SiteDeleteDialogComponent, {
      width: '600px',
      data: site
    });

    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
          this.ngOnInit();
      }
    });
  }

  // CSV出力
  onClickExportSiteCSV() {

    let csvData = 'site_id' + ',' +
    'name' + ',' +
    'address' + ',' +
    'company_id' + ',' +
    'company_name' + ',' +
    'user_id\r\n';
    for (const row of this.siteUserData) {
      if (row.users.length > 0) {
        for (const user of row.users) {
          const rowData = row.id + ',' +
          row.name + ',' +
          row.address + ',' +
          row.company_id + ',' +
          row.company_name + ',' +
          user.id + '\r\n';
          const rowDataFormated = rowData.replace(/\*/g, '').replace(/N\/A/g, '');
          csvData = csvData + rowDataFormated;
        }
      } else {
        const rowData = row.id + ',' +
        row.name + ',' +
        row.address + ',' +
        row.company_id + ',' +
        row.company_name + ',' +
        '*' + '\r\n';
        const rowDataFormated = rowData.replace(/\*/g, '').replace(/N\/A/g, '');
        csvData = csvData + rowDataFormated;
      }
    }
    const strArray = Encoding.stringToCode(csvData);
    const sjisArray = Encoding.convert(strArray, 'SJIS', 'UNICODE');
    const uint8Array = new Uint8Array(sjisArray);
    const blob = new Blob([uint8Array], {type: 'text/tab-separated-values'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    let fileName = '施設一覧.csv';
    if (Number(this.loginUser.user_type) === this.userTypeAdmin) {
      fileName = this.loginUser.company.name + '施設一覧.csv';
    }
    link.download = fileName;
    link.click();
  }

  onClickImportSiteCSV() {
    document.querySelector('input').click();
  }

  public changeListener(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      const reader: FileReader = new FileReader();
      reader.readAsText(file, 'Shift_JIS');
      reader.onload = async (_) => {
        const csv: string = reader.result as string;
        const results = Papa.parse(csv);
        if (results.meta.delimiter === '\t') {
          this.tsvFlg = true;
        }
        let messageContents = '施設情報をインポートします。よろしいですか？';
        const rows = csv.split('\r\n');
        if (rows.length > 1000) {
          messageContents = messageContents + `\n※1000件を超えています。処理に時間がかかる可能性があります。`;
        }
        const messageInfo = new SiteConfirmDialogInfo('CSVインポート', messageContents );
        const siteConfirmDialogRef = this.dialog.open(CommonConfirmDialogComponent, {
          width: '650px',
          data: messageInfo
        });

        siteConfirmDialogRef.afterClosed().subscribe(result => {
          if (result === 'Yes') {
            this.handleCSVStringToUserArray(csv);
          }
        });
      };
    }
  }

  handleCSVStringToUserArray(csvString: string) {
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    try {

      this.csvAllSiteData = [];
      this.csvAddPreSiteData = [];
      this.csvAddSiteData = [];
      this.csvAddSiteUserLinksData = [];
      this.csvUpdateSiteForSiteData = [];
      this.csvUpdatePreSiteForUserData = [];
      this.csvUpdateSiteForUserData = [];
      this.csvErrorSiteData = [];
      this.csvDeletePreSiteData = [];
      this.csvDeleteDupSiteData = [];
      this.csvDeleteSiteData = [];
      this.addFailedCount = 0;
      this.addUserSiteLinksFailedCount = 0;
      this.updateFailedCount = 0;

      let splitString = ',';
      if (this.tsvFlg) {
        splitString = '\t';
      }

      // CSVの項目：site_id,name,address,company_id,company_name, user_id
      const rows = csvString.split('\r\n');
      const headerRowTitles = rows[0].trim().split(splitString);
      if (headerRowTitles.length !== 6) {
        alert('csvファイルのフォーマットが正しいか確認してください。');
        this.overlayRef.detach();
        return;
      }

      // CSVの項目：site_id,name,address,companyId,companyName, userId
      for (let i = 1; i < rows.length; i++) {
        const csvSiteInfo = new CsvSiteInfo();
        const jsonStr = rows[i];
        const strArr = jsonStr.split(splitString);
        // 項目の足りないレコードはスキップ
        if (strArr.length < 6) {
          continue;
        }
        csvSiteInfo.siteId = strArr[0].trim();
        csvSiteInfo.siteName = strArr[1].trim();
        csvSiteInfo.siteAddress = strArr[2].trim();
        csvSiteInfo.companyId = strArr[3].trim();
        csvSiteInfo.companyName = strArr[4].trim();
        csvSiteInfo.userIdList = [];
        if (strArr[5].length > 0) {
          csvSiteInfo.userIdList.push(strArr[5].trim());
        }
        // idが空の場合登録
        if (csvSiteInfo.siteId === '') {
          // 項目チェックを行い不正データは振り分ける
          this.checkAddCSVData(csvSiteInfo);
        }
        // idが存在する場合更新
        const siteItem = this.siteData.filter(item => {
          return item.id === csvSiteInfo.siteId;
        });
        if (siteItem.length > 0) {
          // 項目チェックを行い不正データは振り分ける
          this.checkUpdateCSVData(csvSiteInfo);
        }
        this.csvAllSiteData.push(csvSiteInfo);
      }
      if (this.csvAddPreSiteData.length > 0) {
        // 同一施設毎にデータをまとめる
        this.checkAddSiteUserLinks();
      }
      if (this.csvUpdateSiteForSiteData.length > 0) {
        // 更新データ(施設)の重複削除
        this.csvUpdateSiteForSiteData = this.csvUpdateSiteForSiteData.filter((element, index, self) =>
          self.findIndex(e =>
            e.siteId === element.siteId
          ) === index
        );
      }
      if (this.csvUpdatePreSiteForUserData.length > 0) {
        this.checkUpdateSiteUserLinks();
      }
      if (this.csvDeletePreSiteData.length > 0) {
        this.checkDeleteSiteUserLinks();
      }
      // DBの施設 - CSVの施設の差分が削除対象
      const dbSiteIdList: string[] = new Array();
      const csvSiteIdList: string[] = new Array();
      for (const site of this.siteData) {
        dbSiteIdList.push(site.id);
      }
      for (const csvSite of this.csvDeleteDupSiteData) {
        csvSiteIdList.push(csvSite.siteId);
      }
      this.csvDeleteSiteData = dbSiteIdList.filter(item => {
        return JSON.stringify(csvSiteIdList).indexOf(JSON.stringify(item)) === -1;
      });
      this.beginToUploadCSVSite();
    } catch (error) {
      alert('csvファイルのフォーマットが正しいか確認してください。');
      this.overlayRef.detach();
    }
  }

  checkAddCSVData(csvSiteInfo: CsvSiteInfo) {
    // CSVの項目：siteId,name,address,companyId,companyName,userId
    // nameのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.siteName)) {
      this.addFailedCount++;
      return;
    }
    // addressのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.siteAddress)) {
      this.addFailedCount++;
      return;
    }
    // companyIDのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.companyId)) {
      this.addFailedCount++;
      return;
    }
    // companyIDの妥当性チェック
    // 管理者の場合同じ会社の施設のみ
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && this.loginUser.company.id !== csvSiteInfo.companyId) {
      this.addFailedCount++;
      return;
    }
    // システム管理者の場合、複数の会社を登録できるためcompanyIdがDBに存在するかチェック
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      const isCompnay =  this.companyData.filter(item => {
        return item.id === csvSiteInfo.companyId;
      });
      if (isCompnay.length === 0) {
        this.addFailedCount++;
        return;
      }
    }

    // userIdの存在チェック
    if (csvSiteInfo.userIdList.length > 0) {
      this.csvAddSiteUserLinksData.push(csvSiteInfo);
    }
    this.csvAddPreSiteData.push(csvSiteInfo);
  }

  checkAddSiteUserLinks() {
    // 同-施設のユーザー登録チェック
    let csvSameSiteData = new Array();
    for (const siteInfo of this.csvAddPreSiteData) {
      const siteInfoItem = this.csvAddPreSiteData.filter(item => {
        return item.siteName === siteInfo.siteName &&
        item.siteAddress === siteInfo.siteAddress &&
        item.companyId === siteInfo.companyId &&
        item.userIdList[0] !== siteInfo.userIdList[0];
      });
      if (siteInfoItem.length > 0) {
        siteInfoItem.push(siteInfo);
        csvSameSiteData = siteInfoItem;
        const sameSiteInfoData = new CsvSiteInfo();
        const userList = new Array();
        sameSiteInfoData.siteName = csvSameSiteData[0].siteName;
        sameSiteInfoData.siteAddress = csvSameSiteData[0].siteAddress;
        sameSiteInfoData.companyId = csvSameSiteData[0].companyId;
        sameSiteInfoData.companyName = csvSameSiteData[0].companyName;
        for (const sameSiteData of csvSameSiteData) {
          userList.push(sameSiteData.userIdList[0]);
        }
        sameSiteInfoData.userIdList = userList.sort();
        this.csvAddSiteData.push(sameSiteInfoData);
      } else {
        this.csvAddSiteData.push(siteInfo);
      }
    }
    // 登録データ(施設)の重複削除
    this.csvAddSiteData = this.csvAddSiteData.filter((element, index, self) =>
      self.findIndex(e =>
        e.siteName === element.siteName &&
        e.siteAddress === element.siteAddress &&
        e.companyId === element.companyId &&
        JSON.stringify(e.userIdList) === JSON.stringify(element.userIdList)
      ) === index
    );
  }

  checkUpdateSiteUserLinks() {
    // 同-施設のユーザー登録チェック
    const csvSameSiteData = new Array();
    for (const siteInfo of this.csvUpdatePreSiteForUserData) {
      const siteInfoItem = this.csvUpdatePreSiteForUserData.filter(item => {
        return item.siteId === siteInfo.siteId;
      });
      csvSameSiteData.push(siteInfoItem);
    }

    for (const sameSiteData of csvSameSiteData) {
      const sameSiteInfoData = new CsvSiteInfo();
      const userList = new Array();
      sameSiteInfoData.siteId = sameSiteData[0].siteId;
      sameSiteInfoData.siteName = sameSiteData[0].siteName;
      sameSiteInfoData.siteAddress = sameSiteData[0].siteAddress;
      sameSiteInfoData.companyId = sameSiteData[0].companyId;
      sameSiteInfoData.companyName = sameSiteData[0].companyName;
      if (sameSiteData.length > 1) {
        for (const sameSiteDataItem of sameSiteData) {
          userList.push(sameSiteDataItem.userIdList[0]);
        }
        sameSiteInfoData.userIdList = userList.sort();
      } else {
        sameSiteInfoData.userIdList = sameSiteData[0].userIdList.sort();
      }
      this.csvUpdateSiteForUserData.push(sameSiteInfoData);
    }
    if (this.csvUpdateSiteForUserData.length > 0) {
      // 更新データ(ユーザー)の重複削除
      this.csvUpdateSiteForUserData = this.csvUpdateSiteForUserData.filter((element, index, self) =>
        self.findIndex(e =>
          e.siteId === element.siteId
        ) === index
      );
      // DBデータ紐付け
      for (const csvUpdateSiteForUserDataItem of this.csvUpdateSiteForUserData) {
        const dbSiteData = this.siteUserData.filter(item => {
          return item.id === csvUpdateSiteForUserDataItem.siteId;
        });
        csvUpdateSiteForUserDataItem.dbSiteData = dbSiteData[0];
      }
    }
  }

  checkDeleteSiteUserLinks() {
    // 同-施設のユーザー登録チェック
    const csvSameSiteData = new Array();
    for (const siteInfo of this.csvDeletePreSiteData) {
      const siteInfoItem = this.csvDeletePreSiteData.filter(item => {
        return item.siteId === siteInfo.siteId;
      });
      csvSameSiteData.push(siteInfoItem);
    }

    for (const sameSiteData of csvSameSiteData) {
      const sameSiteInfoData = new CsvSiteInfo();
      const userList = new Array();
      sameSiteInfoData.siteId = sameSiteData[0].siteId;
      this.csvDeleteDupSiteData.push(sameSiteInfoData);
    }
    if (this.csvDeleteDupSiteData.length > 0) {
      // データの重複削除
      this.csvDeleteDupSiteData = this.csvDeleteDupSiteData.filter((element, index, self) =>
        self.findIndex(e =>
          e.siteId === element.siteId
        ) === index
      );
    }

  }

  checkUpdateCSVData(csvSiteInfo: CsvSiteInfo) {
    let updateFlg = false;
    // CSVの項目：siteId,name,address,companyId,companyName, userId
    // siteIdは存在するのでこれを元に施設情報がDB上に存在するかチェック
    const siteInfo = this.siteUserData.filter(item => {
      return item.id === csvSiteInfo.siteId;
    });
    if (siteInfo.length === 0) {
      this.updateFailedCount++;
      return;
    }
    this.csvDeletePreSiteData.push(csvSiteInfo);

    // nameのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.siteName)) {
      this.updateFailedCount++;
      return;
    }
    if (siteInfo[0].name !== csvSiteInfo.siteName) {
      updateFlg = true;
    }

    // addressのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.siteAddress)) {
      this.updateFailedCount++;
      return;
    }
    if (siteInfo[0].address !== csvSiteInfo.siteAddress) {
      updateFlg = true;
    }

    // companyIDのCSV存在チェック
    if (!this.checkValueIsInvalid(csvSiteInfo.companyId)) {
      this.updateFailedCount++;
      return;
    }
    // companyIDの妥当性チェック
    if (Number(this.loginUser.user_type) === this.userTypeAdmin && this.loginUser.company.id !== csvSiteInfo.companyId) {
      this.updateFailedCount++;
      return;
    }
    // システム管理者の場合、複数の会社を登録できるためcompanyIdがDBに存在するかチェック
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      const isCompnay =  this.companyData.filter(item => {
        return item.id === csvSiteInfo.companyId;
      });
      if (isCompnay.length === 0) {
        this.updateFailedCount++;
        return;
      }
    }

    // companyNameは更新しないのでここでは対象外

    if (updateFlg) {
      csvSiteInfo.dbSiteData = siteInfo[0];
      this.csvUpdateSiteForSiteData.push(csvSiteInfo);
    }

    // userIDの更新チェック　→ 施設単位でまとめて実施するのでここではチェック対象外
    // userIDがある　→ DBの紐付けみてなければ追加更新
    // userIDがない　→ 更新対象外
    // 施設ユーザー紐付けの削除更新は施設ID単位で見る必要があるのでまとめてから実施
    // DBの施設に紐づくユーザーリストに存在・CSVの同一施設のユーザーリストに非存在　＞論理削除
    // DBの施設に紐づくユーザーリストに非存在・CSVの同一施設のユーザーリストに存在　＞追加
    this.csvUpdatePreSiteForUserData.push(csvSiteInfo);
  }

  checkValueIsInvalid(val: any) {
    return !(val === '' || val === null || val === undefined);
  }

  async beginToUploadCSVSite() {
    if (!navigator.onLine) {
      alert('ネットワーク接続を確認してください');
      return;
    }

    // for add
    this.addResults = await this.addSites();
    const addSuccessCount = this.addResults.filter(item => item === 1).length;
    this.addFailedCount += this.addResults.filter(item => item === 3).length;

    // for update
    if (!this.csvImportUnexpectedError){
      this.updateResults = await this.updateSites();
    }
    const updateSuccessCount = this.updateResults.filter(item => item === 1).length;
    this.updateFailedCount += this.updateResults.filter(item => item === 3).length;
    if (!this.csvImportUnexpectedError){
      this.updateUserSiteLinksResults = await this.updateUserSiteLinks();
    }
    const updateUserSiteLinksSuccessCount = this.updateUserSiteLinksResults.filter(item => item === 1).length;
    const updateUserSiteLinksFailedCount = this.updateUserSiteLinksResults.filter(item => item === 3).length;

    // for delete
    if (!this.csvImportUnexpectedError){
      this.deleteResults = await this.deleteSites();
    }
    const deleteSuccessCount = this.deleteResults.filter(item => item === 1).length;
    const deleteFailedCount = this.deleteResults.filter(item => item === 3).length;
    this.overlayRef.detach();

    if(this.csvImportAccountLockError){
      alert('アカウントがロックされました。管理者までお問合せください');
      this.onClickLogout()
    } else {
      const successCount = updateSuccessCount + addSuccessCount + deleteSuccessCount;
      const failCount = this.addFailedCount + this.updateFailedCount + deleteFailedCount;
  
      const resultString = `インポートが完了しました。\n
        施設:成功${successCount}件/失敗${failCount}件\n
        ユーザー施設紐付:成功${updateUserSiteLinksSuccessCount}件/失敗${updateUserSiteLinksFailedCount}件`;
      const dialogRef = this.dialog.open(SiteCompleteDialogComponent, {
        width: '600px',
        data: resultString
      });
  
      dialogRef.afterClosed().subscribe(_ => {
        this.ngOnInit();
      });
    }    
  }

  async addSites() {
    const addResults: any[] = [];
    for await (const user of this.csvAddSiteData) {
      const res = await this.createSites(user);
      if (res === 1 || res === 3) {
        addResults.push(res);
      }
      if (res === 4){
        this.csvImportUnexpectedError = true
        break
      }
    }
    return addResults;
  }

  async createSites(site: CsvSiteInfo): Promise<number> {
    return new Promise(async (resolve, _) => {
      try {
        // 施設の登録処理
        const postSiteUrl = `${environment.apiUrl}/sites/sites_info`;
        let params = new HttpParams();
        params = params.set('name', site.siteName);
        params = params.set('address', site.siteAddress);
        params = params.set('company_id', site.companyId);
        this.httpClient.post(postSiteUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe(async (res) => {
          const jsonSiteStr = JSON.stringify(res);
          const jsonSiteObj = JSON.parse(jsonSiteStr);
          const siteData = jsonSiteObj.result.site;
          // ユーザー施設紐付情報の登録
          resolve(await this.postUserSitelinks(siteData.id, site));
        },
        err => {
          if (err.error.code = 'AccountLockError'){
            this.csvImportAccountLockError = true
            resolve(4);
          }else{
            resolve(3);
          }
        });
      } catch (error) {
          resolve(4);
      }
    });
  }

  // ユーザー施設紐付け情報の登録
  async postUserSitelinks(siteId: string, site: CsvSiteInfo): Promise<number> {
    const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
    return new Promise((resolve, _) => {
      try {
        for (const userId of site.userIdList) {
          if (userId === undefined) {
            this.addUserSiteLinksFailedCount++;
            continue;
          }
          if (userId.length > 0) {
            let params = new HttpParams();
            params = params.set('user_id', userId);
            params = params.set('site_id', siteId);
            this.httpClient.post(
              posthUserUrl,
              params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken
                })
              }
            )
            .subscribe((response) => {
              resolve(1)
            }, err => {
              if(err.error.code == "AccountLockError"){
                this.csvImportAccountLockError = true
                 resolve(4)
              }else{
                resolve(3);
              }
            });
          }
        }
        resolve(1)
      } catch (error) {
        resolve(4);
      }
    });
  }

  async updateSites() {
    const dupdateResults: any[] = [];
    for await (const site of this.csvUpdateSiteForSiteData) {
       // 戻り値１：正常、２：処理なし、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.updateCSVSite(site);
      if (res === 1 || res === 3) {
        dupdateResults.push(res);
      }
      if (res === 4){
        this.csvImportUnexpectedError = true
        break
      }
    }
    return dupdateResults;
  }

  async updateUserSiteLinks() {
    const dupdateResults: any[] = [];
    for await (const site of this.csvUpdateSiteForUserData) {
      // 戻り値１：正常、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.updateUserSitelinks(site);
      if (res === 1 || res === 3) {
        dupdateResults.push(res);
      }
      if (res === 4){
        this.csvImportUnexpectedError = true
        break
      }

    }
    return dupdateResults;
  }

  async updateCSVSite(site: CsvSiteInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        // 施設の更新処理
        const url = `${environment.apiUrl}/sites/${site.siteId}/1/sites_info`;
        let params = new HttpParams();
        params = params.set('name', site.siteName);
        params = params.set('address', site.siteAddress);
        params = params.set('company_id', site.companyId);
        this.httpClient.patch(url, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe(async (response) => {
          resolve(1);
        },
        err => {
          if(err.error.code == "AccountLockError"){
            this.csvImportAccountLockError = true
            resolve(4);
          }else{
            resolve(3)
          }
        });
      } catch (error) {
        resolve(4);
      }
    });
  }

  // 取得時の紐付け情報と比較を行い、増減によりユーザー施設紐付けの更新を行う
  async updateUserSitelinks(site: CsvSiteInfo): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        let result = 2
        // 元々の紐付けと更新時の紐付けを比較
        const dbUserSiteList: string[] = new Array();
        for (const userItem of site.dbSiteData.users) {
          dbUserSiteList.push(userItem.id);
        }
        let updateDelteList: string[] = new Array();
        let updateAddList: string[] = new Array();
        // dbUserSiteList > 0 && user.siteIdList > 0 --> 差分検出してuser.siteIdListに差分がある場合 --> 差分追加
        // A | A,B
        if (dbUserSiteList.length > 0 && site.userIdList.length > 0) {
          updateAddList = site.userIdList.filter(i => JSON.stringify(dbUserSiteList).indexOf(JSON.stringify(i)) === -1);
          if (updateAddList.length > 0) {
            for (const updateAddUserId of updateAddList) {
              const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
              let params = new HttpParams();
              params = params.set('user_id', updateAddUserId);
              params = params.set('site_id', site.siteId);
              this.httpClient.post(
                posthUserUrl,
                params,
                {
                  headers: new HttpHeaders({
                    Authorization: this.idToken
                  })
                }
              )
              .subscribe((response) => {
                result = 1
              }, err => {
                if(err.error.code == "AccountLockError"){
                  this.csvImportAccountLockError = true
                  result = 4
                }else{
                  result = 3
                }
              });
              if(this.csvImportAccountLockError){
                break
              }
            }
            resolve(1);
            
          }
          // dbUserSiteList > 0 && user.siteIdList > 0 --> 差分検出してdbUserSiteListに差分がある場合 --> 差分削除
          // A,B | A
          updateDelteList = dbUserSiteList.filter(i => JSON.stringify(site.userIdList).indexOf(JSON.stringify(i)) === -1);
          if (updateDelteList.length > 0) {
            for (const updateDelteUserId of updateDelteList) {
              const patchUserUrl = `${environment.apiUrl}/user_site_links/delete_user_site_links_info`;
              let updateDelteParams = new HttpParams();
              updateDelteParams = updateDelteParams.set('user_id', updateDelteUserId);
              updateDelteParams = updateDelteParams.set('site_id', site.siteId);
              this.httpClient.patch(
                patchUserUrl,
                updateDelteParams,
                {
                  headers: new HttpHeaders({
                    Authorization: this.idToken
                  })
                }
              )
              .subscribe((response) => {
                result = 1
              }, err => {
                if(err.error.code == "AccountLockError"){
                  this.csvImportAccountLockError = true
                  result = 4
                }else{
                  result = 3
                }
              });
            }
            resolve(1);
          }
        }

        // dbUserSiteList === 0 && user.siteIdList > 0 --> user.siteIdList追加
        // - | A
        if (dbUserSiteList.length === 0 && site.userIdList.length > 0) {
          for (const userId of site.userIdList) {
            const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
            let params = new HttpParams();
            params = params.set('user_id', userId);
            params = params.set('site_id', site.siteId);
            this.httpClient.post(
              posthUserUrl,
              params,
              {
                headers: new HttpHeaders({
                  Authorization: this.idToken
                })
              }
            )
            .subscribe((response) => {
              result = 1
            }, err => {
              if(err.error.code == "AccountLockError"){
                this.csvImportAccountLockError = true
                result = 4
              }else{
                result = 3
              }
            });
          }
          resolve(1);
        }

        // dbUserSiteList === user.siteIdList --> チェック済みの為処理なし
        // - | -
        // A | A

        // dbUserSiteList > 0 &&  user.siteIdList === 0 --> チェック済みの為処理なし
        // A | -
        // A,B | -
        resolve(result);
      } catch (error) {
        resolve(4);
      }
    });
  }

  async deleteSites() {
    const deleteResults: any[] = [];
    for await (const siteId of this.csvDeleteSiteData) {
     // 戻り値１：正常、２：処理なし、３：異常、４：予期せぬエラー＆アカウントロック
      const res = await this.deleteCSVSite(siteId);
      if (res === 1 || res === 3) {
        deleteResults.push(res);
      }
      if (res === 4){
        this.csvImportUnexpectedError = true
        break
      }
    }
    return deleteResults;
  }

  async deleteCSVSite(siteId: string): Promise<number> {
    return new Promise((resolve, _) => {
      try {
        // 施設削除
        const patchSiteUrl = `${environment.apiUrl}/sites/${siteId}/0/sites_info`;
        const params = new HttpParams();
        this.httpClient.patch(patchSiteUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        ).subscribe((response) => {
          resolve(1);
        },
        err => {
          if(err.error.code == "AccountLockError"){
            this.csvImportAccountLockError = true
            resolve(4)
          }else{
            resolve(3)
          }
        });
      } catch (error) {
        resolve(4);
      }
    });
  }

  search(text: string) {
    this.searchKeyChanged.next(text);
  }

  filterResult() {
    if (this.searchKeyString === '') {
      this.filtredsiteData = this.siteData;
      return;
    }

    switch (this.searchOptionKey) {
      case this.searchOptionsArray[0]:
        this.filtredsiteData = this.siteData.filter(item => item.name.includes(this.searchKeyString));
        break;
      case this.searchOptionsArray[1]:
        this.filtredsiteData = this.siteData.filter(item => item.company_name.includes(this.searchKeyString));
        break;
      default:
        break;
    }
  }

  onChangeOption() {
    this.searchKeyChanged.next(this.searchKeyString);
  }

  onClickLogout() {
    Const.release()
    this.auth.signOut();
    this.dialog.closeAll();
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
    .subscribe((response: GetLoginUserResponse) => {
      Const.loginUser = response.result.login_user;
      Const.mjitUser = Number(Const.loginUser.user_type) === 0
      Const.adminUser = Number(Const.loginUser.user_type) === 1
      if (Const.mjitUser) {
        Const.site_id = localStorage.getItem("siteId")
      } else {
        for (const site of response.result.login_user.sites) {
          if (site.id === localStorage.getItem("siteId")) {
            Const.site_id = site.id
          }
        }
      }

      if (Const.site_id === null) {
        alert('前回選択した施設が見つかりませんでした。');
        Const.site_id = response.result.login_user.sites[0].id;
      }

      this.loginUser = Const.loginUser;
      this.mjitUserFlag = Const.mjitUser;
      this.adminUserFlag = Const.adminUser;    
      this.loadSiteData()
    },
    err => {
      if(err.error.code == "AccountLockError"){
        alert('アカウントがロックされました。管理者までお問合せください');
      }else{
        alert('ログイン情報が取得できませんでした。再度ログインしてください。');
      }
      this.onClickLogout();
    });
  }
}
