import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-confirm-dialog',
  templateUrl: './user-complete-dialog.component.html',
  styleUrls: ['./user-complete-dialog.component.scss']
})

export class UserCompleteDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit() {}

}
