import { LoginUser } from "./login-user";
import { Meter } from "./meter";

export class CorrectDialogData {
  constructor(
    partition: string,
    tenantName: string,
    meter: Meter,
    loginUser: LoginUser,
    canEdit: boolean,
    usingPlace: string,
    tenantId: string,
    checkedMonth: string,
    label: string
  ) {
    this.partition = partition;
    this.tenantName = tenantName;
    this.meter = meter;
    this.loginUser = loginUser;
    this.canEdit = canEdit;
    this.usingPlace = usingPlace;
    this.tenantId = tenantId;
    this.checkedMonth = checkedMonth;
    this.label = label;
  }
  partition: string;
  tenantName: string;
  meter: Meter;
  loginUser: LoginUser;
  canEdit: boolean;
  usingPlace: string;
  tenantId: string;
  checkedMonth: string;
  label: string;
}
