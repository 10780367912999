import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserForSiteModel } from '../site-management/site-management.component';

@Component({
  selector: 'app-site-user-selection-dialog',
  templateUrl: './site-user-selection-dialog.component.html',
  styleUrls: ['./site-user-selection-dialog.component.scss']
})

export class SiteUserSelectionDialogComponent implements OnInit {

  userList: UserForSiteModel[] = []
  originUserList: UserForSiteModel[] = []

  // テーブル生成用
  displayedColumns: string[];

  @Input() public allUserData: UserForSiteModel[];


  constructor( @Inject(MAT_DIALOG_DATA) public isEdit: boolean = false,
  public dialogRef: MatDialogRef<SiteUserSelectionDialogComponent>,
  ) {
    dialogRef.disableClose = true;  
  }

  ngOnInit() {
     // deep clone 
    let deepCloneUserArray = JSON.parse(JSON.stringify(this.allUserData));
    this.originUserList = deepCloneUserArray

    // for selection
    this.userList = JSON.parse(JSON.stringify(this.allUserData))
    this.displayedColumns = ["userName", "companyName","userPermission"]
  }

  isAllSelected(){
    let allSelectedCount = this.userList.filter(user => user.is_selected).length
    return allSelectedCount == this.userList.length && this.userList.length != 0
  }

  toggleAllRows(){
    let toggleValue = true
    let isAllNotSelected = this.userList.length == this.userList.filter(user => !user.is_selected).length
    let isAllSelected = this.isAllSelected()

    if(isAllSelected){
      toggleValue = false
    }else if(isAllNotSelected){
      toggleValue = true
    }

    this.userList.forEach(user => {
      user.is_selected = toggleValue
    })
  }

  confirmButtonEnabled(){
    let enable = false
    this.originUserList.forEach(item => {
      this.userList.forEach(user => {
        if(user.is_selected != item.is_selected){
          enable = true
        }
      })
    })
    return enable
  }

  onClickConfirm(){
    this.allUserData = this.userList
    this.dialogRef.close('yes');
  }

  onClickClose(){
    this.allUserData = this.originUserList
    this.dialogRef.close('close');
  }

}
