import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { GetUserWithSitesLinksInfo } from '../entity/get-user-with-sites-links-info';
import { LoginUser } from '../entity/login-user';
import { UserConfirmDialogInfo } from '../entity/user-confirm-dialog-info';
import { UserCompleteDialogComponent } from '../user-complete-dialog/user-complete-dialog.component';

interface UserType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-user-change-password-dialog',
  templateUrl: './user-change-password-dialog.component.html',
  styleUrls: ['./user-change-password-dialog.component.scss']
})
export class UserChangePasswordDialogComponent implements OnInit {
  idToken: string;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;

  // 入力項目
  loginId: string;
  password: string;
  confirmPassword: string;
  userName: string;

  errorMessage: string;
  hasError = false;
  isFormReady = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: GetUserWithSitesLinksInfo,
    private httpClient: HttpClient,
    private router: Router,
    public dialogRef: MatDialogRef<UserChangePasswordDialogComponent>,
    public userConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    ) {}

  ngOnInit() {
    this.hasError = false;
    this.errorMessage = '';
    this.auth.getIdToken()
      .subscribe(
          result => {
            if (result) {
              this.idToken = result;
              this.doGetLoginUser();
            } else {
              // idトークンがnullの場合はログイン画面へ遷移
              alert('セッションが切れています。再度ログインしてください。');
              this.router.navigate(['login']);
            }
          }
      );
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
      .subscribe((response: GetLoginUserResponse) => {
        this.loginUser = response.result.login_user;
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release();
            this.auth.signOut();
            this.dialogRef.close('lockedAccount');
          }else{
            alert('ログイン名が取得できませんでした。再度ログインしてください。');
            Const.release();
            this.auth.signOut();
            this.dialogRef.close('failed');
          }
        }
      );
  }

  // パスワード変更ボタンの活性化制御
  checkIfReadyToGo() {
    this.isFormReady = this.password !== undefined && this.password !== '' &&
                       this.confirmPassword !== undefined && this.confirmPassword !== '';
    return this.isFormReady;
  }

  // パスワード変更
  onClickChangePassword() {
    if (!navigator.onLine) {
      alert('ネットワーク接続を確認してください');
      return;
    }
    // 入力チェック
    this.errorMessage = this.checkValue();
    if (this.errorMessage.length > 0) {
      return;
    }

    // 確認ダイアログ
    const messageInfo = new UserConfirmDialogInfo('パスワード変更確認', 'パスワードを変更します。よろしいですか。');
    const confirmDialog = this.userConfirmDialog.open(CommonConfirmDialogComponent, {
        width: '400px',
        data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(async result => {
      if (result === 'Yes') {
        this.changePassword();
      }
    });
  }

  // 入力チェック
  checkValue(): string {
    let errorMessage = 'を入力してください';
    const passwordPolicy = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,99}$/);

    if (this.password === undefined || this.password === '') {
      errorMessage = 'パスワード' + errorMessage;
      return errorMessage;
    }
    // パスワードポリシーチェック
    if (this.password.match(passwordPolicy) === null) {
      return errorMessage = 'パスワードは、半角英数の大文字と小文字、記号を含む12文字以上99文字以内で入力してください';
    }
    // 空白チェック
    if (this.password.match(/\s+/g) !== null) {
      return errorMessage = 'パスワードは、半角英数の大文字と小文字、記号を含む12文字以上99文字以内で入力してください';
    }

    if (this.confirmPassword === undefined || this.confirmPassword === '') {
      errorMessage = 'パスワード（確認）' + errorMessage;
      return errorMessage;
    }
    if (this.password !== this.confirmPassword) {
      errorMessage = 'パスワードとパスワード（確認）が異なっています';
      return errorMessage;
    }

    return '';
  }

  // Cognitoへの登録
  async changePassword() {
    try {
      // Cognitoユーザーのパスワードを変更し確認ステータスを確認済に変更
      const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/activate_cognito_user`;
      let params = new HttpParams();
      params = params.set('user_pool_id', environment.amplify.Auth.aws_user_pools_id);
      params = params.set('user_name', this.user.loginId);
      params = params.set('password', this.password);
      this.httpClient.post(postActivateCognitoUserUrl, params,
        {
          headers: new HttpHeaders({
            Authorization: this.idToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      ).subscribe(async (response) => {
        const dialogRef = this.dialog.open(UserCompleteDialogComponent, {
          width: '600px',
          data: 'パスワードを変更しました。'
        });
        this.dialogRef.close('close');
      },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
          this.dialogRef.close('lockedAccount');
        }else{
          alert('パスワード変更に失敗しました。');
        }
      });
    } catch (error) {
      alert("パスワード変更に失敗しました。");
    }
  }
}
