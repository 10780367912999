import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSpinner } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { QRCodeModule } from "angularx-qrcode";
import { NgxPrintModule } from "ngx-print";
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { CardComponent } from "./card/card.component";
import { CommonConfirmDialogComponent } from "./common-confirm-dialog/common-confirm-dialog.component";
import { CompanyAddEditDialogComponent } from "./company-add-edit-dialog/company-add-edit-dialog.component";
import { CompanyDeleteDialogComponent } from "./company-delete-dialog/company-delete-dialog.component";
import { CompanyManagementComponent } from "./company-management/company-management.component";
import { ConfirmAlertDialogComponent } from "./confirm-alert-dialog/confirm-alert-dialog.component";
import { FloorAddDialogComponent } from "./floor-add-dialog/floor-add-dialog.component";
import { FloorDeleteDialogComponent } from "./floor-delete-dialog/floor-delete-dialog.component";
import { FloorEditDialogComponent } from "./floor-edit-dialog/floor-edit-dialog.component";
import { FloorManagementListComponent } from "./floor-management-list/floor-management-list.component";
import { FooterConfirmDialogComponent } from "./footer-numbering-confirm-dialog/footer-numbering-confirm-dialog.component";
import { FooterNumberingExplainDialogComponent } from "./footer-numbering-explain-dialog/footer-numbering-explain-dialog.component";
import { FooterComponent } from "./footer/footer.component";
import { LogOutButtonComponent } from "./log-out-button/log-out-button.component";
import { MasterEditComponent } from "./master-edit/master-edit.component";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { MeterAddDialogComponent } from "./meter-add-dialog/meter-add-dialog.component";
import { MeterCardListComponent } from "./meter-card-list/meter-card-list.component";
import { MeterConfirmDialogComponent } from "./meter-confirm-dialog/meter-confirm-dialog.component";
import { MeterCsvBatchDialogComponent } from "./meter-csv-batch-dialog/meter-csv-batch-dialog.component";
import { MeterDeleteDialogComponent } from "./meter-delete-dialog/meter-delete-dialog.component";
import { MeterDetailDialogComponent } from "./meter-detail-dialog/meter-detail-dialog.component";
import { MeterEditDialogComponent } from "./meter-edit-dialog/meter-edit-dialog.component";
import { MeterExprationNavigate } from "./meter-expiration-navigate/meter-expiration-navigate.component";
import { MeterExpirationWarningDialogComponent } from "./meter-expiration-warning-dialog/meter-expiration-warning-dialog.component";
import { MeterManagementListComponent } from "./meter-management-list/meter-management-list.component";
import { MeterQrcodeDialogComponent } from "./meter-qrcode-dialog/meter-qrcode-dialog.component";
import { MeterReplaceDialogComponent } from "./meter-replace-dialog/meter-replace-dialog.component";
import { MeterReportComponent } from "./meter-report/meter-report.component";
import { MeterTypeAddDialogComponent } from "./meter-type-add-dialog/meter-type-add-dialog.component";
import { MeterTypeEditDialogComponent } from "./meter-type-edit-dialog/meter-type-edit-dialog.component";
import { MeterTypeManagementListComponent } from "./meter-type-management-list/meter-type-management-list.component";
import { MeterUnitAddDialogComponent } from "./meter-unit-add-dialog/meter-unit-add-dialog.component";
import { MeterUnitEditDialogComponent } from "./meter-unit-edit-dialog/meter-unit-edit-dialog.component";
import { MeterUnitComponent } from "./meter-unit/meter-unit.component";
import { PartitionManagementListComponent } from "./partition-management-list/partition-management-list.component";
import { PartitionAddDialogComponent } from "./partittion-add-dialog/partition-add-dialog.component";
import { PartitionDeleteDialogComponent } from "./partittion-delete-dialog/partition-delete-dialog.component";
import { PartitionDetailDialogComponent } from "./partittion-detail-dialog/partition-detail-dialog.component";
import { PartitionEditDialogComponent } from "./partittion-edit-dialog/partition-edit-dialog.component";
import { ReportConfirmDialogComponent } from "./report-confirm-dialog/report-confirm-dialog.component";
import { ReportListDialogComponent } from "./report-list-dialog/report-list-dialog.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { SiteAddOrEditDialogComponent } from "./site-add-edit-dialog/site-add-edit-dialog.component";
import { SiteCompleteDialogComponent } from "./site-complete-dialog/site-complete-dialog.component";
import { SiteDeleteDialogComponent } from "./site-delete-dialog/site-delete-dialog.component";
import { SiteManagementComponent } from "./site-management/site-management.component";
import { SiteSelectComponent } from "./site-select/site-select.component";
import { SiteUserSelectionDialogComponent } from "./site-user-selection-dialog/site-user-selection-dialog.component";
import { TenantConfirmDialogComponent } from "./tenant-confirm-dialog/tenant-confirm-dialog.component";
import { TenantDeleteDialogComponent } from "./tenant-delete-dialog/tenant-delete-dialog.component";
import { TenantEditDialogComponent } from "./tenant-edit-dialog/tenant-edit-dialog.component";
import { TenantMakeDialogComponent } from "./tenant-make-dialog/tenant-make-dialog.component";
import { UserAddDialogComponent } from "./user-add-dialog/user-add-dialog.component";
import { UserChangePasswordDialogComponent } from "./user-change-password-dialog/user-change-password-dialog.component";
import { UserCompleteDialogComponent } from "./user-complete-dialog/user-complete-dialog.component";
import { UserConfirmDialogComponent } from "./user-confirm-dialog/user-confirm-dialog.component";
import { UserDeleteDialogComponent } from "./user-delete-dialog/user-delete-dialog.component";
import { UserDetailDialogComponent } from "./user-detail-dialog/user-detail-dialog.component";
import { UserEditDialogComponent } from "./user-edit-dialog/user-edit-dialog.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { UserSiteLinksDialogComponent } from "./user-site-links-dialog/user-site-links-dialog.component";
import { UserSiteLinksSelectDialogComponent } from "./user-site-links-select-dialog/user-site-links-select-dialog.component";
import { ValueCompareDialogComponent } from "./value-compare-dialog/value-compare-dialog.component";
import { ValueCorrectDialogComponent } from "./value-correct-dialog/value-correct-dialog.component";

import { ForbiddenCharactersDirective } from "./common-confirm-dialog/forbidden-characters.directive";

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenCharactersDirective,
    LoginComponent,
    MeterCardListComponent,
    CardComponent,
    MeterReportComponent,
    FooterComponent,
    ValueCompareDialogComponent,
    ValueCorrectDialogComponent,
    MasterEditComponent,
    MessageDialogComponent,
    SideNavComponent,
    LogOutButtonComponent,
    ReportListDialogComponent,
    ReportConfirmDialogComponent,
    TenantEditDialogComponent,
    TenantDeleteDialogComponent,
    TenantMakeDialogComponent,
    TenantConfirmDialogComponent,
    ConfirmAlertDialogComponent,
    FloorManagementListComponent,
    FloorAddDialogComponent,
    FloorEditDialogComponent,
    FloorDeleteDialogComponent,
    MeterManagementListComponent,
    MeterConfirmDialogComponent,
    MeterTypeManagementListComponent,
    MeterUnitComponent,
    MeterAddDialogComponent,
    MeterEditDialogComponent,
    MeterDeleteDialogComponent,
    MeterQrcodeDialogComponent,
    CommonConfirmDialogComponent,
    MeterTypeAddDialogComponent,
    MeterTypeEditDialogComponent,
    MeterUnitEditDialogComponent,
    MeterUnitAddDialogComponent,
    UserManagementComponent,
    UserAddDialogComponent,
    UserEditDialogComponent,
    UserDeleteDialogComponent,
    UserDetailDialogComponent,
    UserConfirmDialogComponent,
    UserCompleteDialogComponent,
    UserSiteLinksDialogComponent,
    UserSiteLinksSelectDialogComponent,
    UserChangePasswordDialogComponent,
    MeterCsvBatchDialogComponent,
    PartitionManagementListComponent,
    PartitionDetailDialogComponent,
    PartitionAddDialogComponent,
    PartitionEditDialogComponent,
    PartitionDeleteDialogComponent,
    SiteManagementComponent,
    SiteAddOrEditDialogComponent,
    SiteDeleteDialogComponent,
    SiteUserSelectionDialogComponent,
    SiteCompleteDialogComponent,
    CompanyManagementComponent,
    CompanyAddEditDialogComponent,
    CompanyDeleteDialogComponent,
    FooterNumberingExplainDialogComponent,
    FooterConfirmDialogComponent,
    SiteSelectComponent,
    MeterExpirationWarningDialogComponent,
    MeterExprationNavigate,
    MeterDetailDialogComponent,
    MeterReplaceDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSlideToggleModule,
    QRCodeModule,
    NgxPrintModule,
    OverlayModule,
  ],
  providers: [
    //UserAddDialogComponent,
    AuthService,
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
