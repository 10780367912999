import { Injectable } from "@angular/core";
import { GetReportConfirmedDataResponse } from "src/app/entity/get-confirmed-report-data-response";
import { ReportCard } from "src/app/entity/report-card";
import { MeterCardListCommonService } from "./meter-card-list-common.service";
import { Meter } from "src/app/entity/meter";
import { ReportMeters } from "src/app/entity/report-meters";
import { ReportPartitionsTenant } from "src/app/entity/report-partitions-tenant";
import { LastMonthValueConfirmation } from "src/app/entity/last-month-value-confirmation";

/**
 * 確定レポートからのデータ取得
 */
@Injectable()
export class MeterCardListConfirmedSevice {
  constructor(private meterCardListCommonService: MeterCardListCommonService) {}

  /**
   * レポートデータからカードリストのデータを生成する
   * @param apiResponse(GetReportConfirmedDataResponse): APIの結果レスポンス
   * @param floorId(string): 現在のフロアID
   * @returns 作成した区画リスト: ReportCard[]
   */
  createPartitionsArrayForReportData(
    apiResponse: GetReportConfirmedDataResponse,
    floorId: string
  ): ReportCard[] {
    let result: ReportCard[] = [];

    const floors = apiResponse.result.confirmed_report.floors;

    // 今のフロア情報を取得
    const currentFloor = floors.find(value => value.id == floorId);

    currentFloor.partitions.forEach(partition => {
      // 対象でなければスキップ
      if (!this.meterCardListCommonService.shouldCreateCardData(partition)){
        return; // continueする
      }

      partition.partitions_tenants.forEach(partitionsTenants => {
        // テナント情報がなければスキップ
        if (!partitionsTenants.tenant) {
          return;
        }

        // 1行分を作成
        const meters = this.createReportCardRow(partitionsTenants.tenant.id, partition.meters, partition.partitions_tenants);
  
          const row = new ReportCard();
          row.partition_id = partition.id;
          row.partition_name = partition.name;
          row.tenant_id = partitionsTenants.tenant.id;
          // テナント名はtenant_logsから取得する
          if (partitionsTenants.tenant_log){
            row.tenant_name = partitionsTenants.tenant_log.name;
          } else {
            row.tenant_name = partitionsTenants.tenant.name;
          }
          row.meters = meters;

          result.push(row);
      });
    });

    console.log("confirmed:",result)
    return result;
  }

  /**
   * カード表示用データのメーター部分を作成する
   * @param tenantId(string): テナントID
   * @param meters(ReportMeters[]): メーターリスト
   * @param partitionsTenants(ReportPartitionsTenant[]): API結果のpartitions_tenants以下
   * @returns カード表示用データのメーター部分: Meter[]
   */
  private createReportCardRow(tenantId: string, meters: ReportMeters[], partitionsTenants: ReportPartitionsTenant[]): Meter[] {
    let result: Meter[] = [];

    meters.forEach(meter => {

      // 前月確定値
      const lastMonthValueConfirmation = this.getLastMonthValueConfirmation(tenantId, meter);    
      
      // 今月確定値
      const valueDetections = this.meterCardListCommonService.getValueDetections(tenantId, meter);

      result.push( new Meter(
          meter.id,
          meter.name,
          meter.label,
          meter.number_of_digits,
          meter.decimal_point_position.toString(),
          "", // 確定レポートの場合、解約値は関係なくデータがあるかないかになる。
          meter.is_reviewed == "1",
          meter.created_at,
          meter.updated_at,
          meter.unit,
          lastMonthValueConfirmation,
          valueDetections
        ));
    });

    return result;
  }

  /**
   * 前月確定値のデータ作成
   * @param tenantId(string): 対象のテナントID
   * @param meter(ReportMeters): 対象のメーター
   * @retrun 前回確定値: LastMonthValueConfirmation
   */
  private getLastMonthValueConfirmation(tenantId: string, meter: ReportMeters): LastMonthValueConfirmation {
    let result: LastMonthValueConfirmation;

    // 前月確定値は降順でソート
    const lastMonthValueDetections = meter.last_month_value_detections;
    lastMonthValueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    lastMonthValueDetections.some(detection => {
      if (detection.tenant_id == tenantId && detection.value_confirmation) {
        result = new LastMonthValueConfirmation(
          detection.value_confirmation.id,
          detection.value_confirmation.confirmed_value,
          detection.value_confirmation.confirmation_channel,
          detection.value_confirmation.created_at
        );
        return true;
      } else {
        return false;
      }
    });

    return result;
  }

}
