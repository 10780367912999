// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-raised-button {
  height: 50%;
  text-align: center;
  line-height: 50%;
}

.footer {
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
}

.info-button {
  background-color: transparent;
  border: transparent;
}

.mat-icon {
  color: white;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.manualText {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".mat-mdc-raised-button {\n    height: 50%;\n    text-align: center;\n    line-height: 50%;\n}\n\n.footer {\n    height: 32px;\n    margin-top: auto;\n    margin-bottom: auto;\n}\n\n.info-button {\n    background-color: transparent;\n    border: transparent;\n}\n\n.mat-icon {\n    color: white;\n    cursor: pointer;\n    margin-top: auto;\n    margin-bottom: auto;\n}\n\n.manualText{\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
