import { ValueConfirmationUser } from './value-confirmation-user';

export class ValueConfirmation {
    // 検針値入力の処理から後付けで作成
    constructor(confirmed_value: string, user: ValueConfirmationUser, created_at: string) {
      this.confirmed_value = confirmed_value;
      this.user = user;
      this.created_at = created_at;
    }
    id: string;
    confirmed_value: string;
    confirmation_channel: string;
    created_at: string;
    user: ValueConfirmationUser;
}
