// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  padding: 50px;
  text-align: center;
}

th,
td {
  text-align: left;
  font-weight: normal;
  font-size: 100%;
}

.error {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/partittion-edit-dialog/partition-edit-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,kBAAA;AAAJ;;AAGA;;EAEI,gBAAA;EACA,mBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ","sourcesContent":["\ntable {\n    padding: 50px ;\n    text-align: center;\n}\n\nth,\ntd {\n    text-align: left;\n    font-weight: normal;\n    font-size: 100%;\n}\n\n.error {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
