import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, OnInit, Inject, Input } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments/environment";
import { LoginUser } from "../entity/login-user";
import { Router } from "@angular/router";
import { FloorInfo } from "../entity/floor-info";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { FloorConfirmDialogInfo } from "../entity/floor-confirm-dialog-info";
import { Const } from "../const/const";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-floor-edit-dialog",
  templateUrl: "./floor-edit-dialog.component.html",
  styleUrls: ["./floor-edit-dialog.component.scss"],
})
export class FloorEditDialogComponent implements OnInit {
  idToken: string;
  loginUser: LoginUser;
  floorName: string;
  floorNumber: number;
  floorInfo: FloorInfo;
  floorsInfo: FloorInfo[];
  errorFloorNameMsg: string;
  errorFloorNumberMsg: string;
  site: LoginUserSite;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public auth: AuthService,
    public dialog: MatDialog,
    public floorConfirmDialog: MatDialog,
    public dialogRef: MatDialogRef<FloorEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.floorInfo = this.data.floorInfo;
    this.floorsInfo = [];
    for (var i = 0; i < this.data.floorsInfo.length; i++) {
      if (this.data.floorsInfo[i].id != this.floorInfo.id) {
        this.floorsInfo.push(this.data.floorsInfo[i]);
      }
    }
    this.floorName = this.floorInfo.name;
    this.floorNumber = Number(this.floorInfo.no);
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }

  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }

    const messageInfo = new FloorConfirmDialogInfo(
      "変更確認",
      "情報の変更を反映します。よろしいですか。"
    );
    const confirmDialog = this.floorConfirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "400px",
        data: messageInfo,
      }
    );

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        // APIコールして登録処理
        const patchFloorUrl = `${environment.apiUrl}/floors/floor_info/1/${this.floorInfo.id}`;
        let params = new HttpParams();
        params = params.set("name", this.floorName);
        params = params.set("no", this.floorNumber.toString());
        params = params.set("site_id", this.floorInfo.site_id);
        this.httpClient
          .patch(patchFloorUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("lockedAccount");
              } else {
                alert("フロア情報の更新に失敗しました。");
              }
            }
          );
      }
    });
  }

  checkIfFormDirty() {
    let hasNameChanged = false;

    if (
      this.floorName == undefined ||
      this.floorName == null ||
      this.floorName == "" ||
      this.floorName == "未登録" ||
      this.floorNumber == undefined ||
      this.floorNumber == null ||
      (this.floorName == this.floorInfo.name &&
        this.floorNumber.toString() == this.floorInfo.no)
    ) {
      return (hasNameChanged = true);
    }

    for (var i = 0; i < this.floorsInfo.length; i++) {
      if (
        this.floorName == this.floorsInfo[i].name ||
        this.floorNumber.toString() == this.floorsInfo[i].no
      ) {
        hasNameChanged = true;
        break;
      }
    }

    return hasNameChanged;
  }

  changeValue() {
    this.errorFloorNameMsg = "";
    this.errorFloorNumberMsg = "";

    if (
      this.floorName == undefined ||
      this.floorName == null ||
      this.floorName == "" ||
      this.floorNumber === undefined ||
      this.floorNumber == null
    ) {
      this.errorFloorNameMsg = "フロア名と表示順を入力してください";
    } else if (this.floorName == "未登録") {
      this.errorFloorNameMsg = "フロア名が「未登録」のフロアは登録できません";
    } else {
      for (var i = 0; i < this.floorsInfo.length; i++) {
        if (this.floorName == this.floorsInfo[i].name) {
          this.errorFloorNameMsg = "入力したフロア名はすでに存在します";
        }

        if (this.floorNumber.toString() == this.floorsInfo[i].no) {
          this.errorFloorNumberMsg = "入力した表示順はすでに存在します";
        }

        if (this.errorFloorNameMsg != "" && this.errorFloorNumberMsg != "") {
          break;
        }
      }
    }
  }
}
