import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { UserSiteLinksDialogComponent } from '../user-site-links-dialog/user-site-links-dialog.component';
import { GetUserSiteLinksInfo } from '../entity/get-user-site-links-info';
import { GetUserWithSitesLinksInfo, UserSite } from '../entity/get-user-with-sites-links-info';
import { SiteInfo } from '../entity/get-site-info';
import { CompanyInfo } from '../entity/get-company-info';

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent implements OnInit {

  idToken: string;
    // 施設情報
    siteData: SiteInfo[];
    // 会社情報
    companyData: CompanyInfo[] = new Array();
    siteInfoList: UserSite[] = new Array();

  @Input() public userSiteLinksInfo: GetUserSiteLinksInfo[];

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public user: GetUserWithSitesLinksInfo,
    public dialogRef: MatDialogRef<UserDetailDialogComponent>,
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    ) { }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
  }

  onClickSelectSite() {
    const dialogRef = this.dialog.open(UserSiteLinksDialogComponent, {
      width: '1000px',
      data: this.user.sites
    });
  }

}
