// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: center;
  font-size: large;
}

table {
  font-size: small;
  text-align: center;
  max-height: 300px;
  overflow: auto;
  width: 620px;
}

.table-container {
  max-height: 300px;
  overflow: auto;
}

table tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

table tbody::-webkit-scrollbar :vertical {
  width: 2px;
}

table tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.3);
}

th {
  text-align: left;
}

td {
  text-align: left;
}

.user-name-header {
  width: 40%;
}

.company-name-header {
  width: 40%;
}

.permission-header {
  width: 20%;
}

input {
  font-size: medium;
}

.user-button {
  border: none;
  background-color: white;
  color: rgb(57, 57, 230);
  font-size: small;
}

.actionButtonArea {
  margin-top: 20px;
  padding-bottom: 12px;
}

.confirm-button {
  background-color: #90CAFC;
}`, "",{"version":3,"sources":["webpack://./src/app/site-user-selection-dialog/site-user-selection-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,wBAAA;AAAJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;EACA,oCAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AACA;EACI,UAAA;AAEJ;;AAAA;EACI,UAAA;AAGJ;;AADA;EACI,UAAA;AAIJ;;AADA;EACI,iBAAA;AAIJ;;AADA;EACI,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,gBAAA;AAIJ;;AADA;EACI,gBAAA;EACA,oBAAA;AAIJ;;AADA;EACI,yBAAA;AAIJ","sourcesContent":["\n.title{\n    text-align: center;\n    font-size: large;\n}\n\ntable{\n    font-size: small;\n    text-align: center;\n    max-height: 300px;\n    overflow: auto;\n    width: 620px;\n}\n\n.table-container{\n    max-height: 300px;\n    overflow: auto;\n}\n\ntable tbody::-webkit-scrollbar{\n    -webkit-appearance: none;\n}\ntable tbody::-webkit-scrollbar :vertical {\n    width: 2px;\n}\n\ntable tbody::-webkit-scrollbar-thumb{\n    border-radius: 8px;\n    border: 2px solid white;\n    background-color: rgba(0,0,0,0.3);\n}\n\nth{\n    text-align: left;\n}\n\ntd{\n    text-align: left;\n}\n.user-name-header{\n    width: 40%;\n}\n.company-name-header{\n    width: 40%;\n}\n.permission-header{\n    width: 20%;\n}\n\ninput{\n    font-size: medium;\n}\n\n.user-button{\n    border: none;\n    background-color: white;\n    color: rgb(57, 57, 230);\n    font-size: small;\n}\n\n.actionButtonArea{\n    margin-top: 20px;\n    padding-bottom: 12px;\n}\n\n.confirm-button{\n    background-color: #90CAFC;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
