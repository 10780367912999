import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, Input, LOCALE_ID, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { DateAdapter, NativeDateAdapter } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { Const } from "../const/const";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { MeterInfo } from "../entity/get-meter-info";
import { MeterType } from "../entity/get-meter-type";
import { GetPartitionsResponse } from "../entity/get-partitions-response";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { LoginUser } from "../entity/login-user";
import { MessageInfo } from "../entity/message-info";
import { MeterConfirmDialogInfo } from "../entity/meter-confirm-dialog-info";
import { Unit } from "../entity/unit";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-meter-replace-dialog",
  templateUrl: "./meter-replace-dialog.component.html",
  styleUrls: ["./meter-replace-dialog.component.scss"],
})
export class MeterReplaceDialogComponent implements OnInit {
  site: LoginUserSite;
  idToken: string;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;
  getPartitionsListUrl: string;
  getPartitionsResponse: GetPartitionsResponse;

  partitionTenantList: GetTenantsPartition[];
  currentPartition: GetTenantsPartition;
  selectedPartition = new UntypedFormControl({ value: "", disabled: true });
  selectedTenantName: string = "";

  meterTypeList: MeterType[];
  selectedType = new UntypedFormControl();

  meterUnitList: Unit[];
  meter: MeterInfo;
  meterList: MeterInfo[];

  meterId: string;
  meterNumber: number;
  meterName: string;
  meterLabel: string;
  maxValue: number; // 小数点あり
  maxValueDisplay: number; // 小数点ない
  minValue: number; // 小数点あり
  minValueDisplay: number; // 小数点ない
  numberOfDigit: number;
  decimalPointPosition: number;
  isShowActualValue: boolean = false;
  meterInitialValue: number;

  oldMaxValue: number;
  oldMinValue: number;
  oldDecimalPointPosition: number;
  meterClosedValue: number;

  meterIsReViewFlag: boolean;
  meterAIReadFlag: boolean;

  meterExpirationDate: string;

  hasAnyUpdate: boolean = false;
  errorMessage: string;
  hasError: boolean = false;
  isFormReady: boolean = false;
  isUseAngle: boolean = false;

  selectedMeterName = new UntypedFormControl({ value: "", disabled: true });
  meterNameList: string[];
  subMeterName: string;
  minDate: Date;

  meterValueInterval: number = 1;
  oldMeterValueInterval: number = 1;

  isExistNumber: boolean = false;
  isValidNumber: boolean = false;
  meterLabelList: string[];

  @Input() public partitionTenant: GetTenantsPartition[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<MeterReplaceDialogComponent>,
    public meterConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    dateAdapter: DateAdapter<NativeDateAdapter>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    dateAdapter.setLocale("ja");
  }

  ngOnInit() {
    this.site = this.data.site;
    this.partitionTenantList = [];
    this.meterTypeList = [];
    this.meterUnitList = [];
    this.meterList = this.data.metersInfo;
    this.meterLabelList = [];

    this.partitionTenantList = this.partitionTenant;
    this.meter = this.data.meter;

    this.meterLabelList = this.meterList.map((meter) => {
      return meter.label;
    });

    this.meterIsReViewFlag = this.meter.is_reviewed;
    this.meterAIReadFlag = this.meter.is_reread;
    this.meterExpirationDate = this.meter.expiration_date;

    this.meterNumber = this.meter.no;
    this.meterName = this.meter.name;
    if (this.meter.name.indexOf("_") !== -1) {
      this.selectedMeterName.setValue(
        this.meter.name.substr(this.meter.name.indexOf("_") + 1, 2)
      );
      this.subMeterName = this.meter.name.substr(
        0,
        this.meter.name.indexOf("_")
      );
    } else {
      this.selectedMeterName.setValue(this.meter.name);
    }
    this.maxValue = this.meter.max_value;
    this.minValue = 0;
    this.numberOfDigit = this.meter.number_of_digits;
    this.decimalPointPosition = this.meter.decimal_point_position;

    this.selectedPartition = new UntypedFormControl({
      value: this.partitionTenantList[0],
      disabled: true,
    });
    this.selectedPartition.setValue(this.meter.partition_id);
    if (this.meter.cancelled_at) {
      this.selectedTenantName = "(解約済みテナント)";
    } else {
      this.selectedTenantName = this.meter.tenantName;
    }

    this.currentPartition = this.partitionTenantList.filter(
      (item) => item.id == this.selectedPartition.value
    )[0];
    this.setMeterNameList();

    this.minDate = new Date();

    this.oldMaxValue = this.meter.max_value;
    this.oldMinValue = 0;
    this.oldDecimalPointPosition = this.meter.decimal_point_position;
    this.setOldDecimalInterval();

    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.doGetLoginUser();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  // ログインユーザーの取得処理（区画一覧の取得に施設IDが必要）
  // リファクタで呼び出し元（レポート画面）から施設IDは連携してくるようにする
  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          this.loginUser = response.result.login_user;

          this.loadMeterTypes();
          this.loadMeterUnit();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.dialogRef.close("lockedAccount");
          } else {
            alert(
              "ログイン名が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  loadMeterTypes() {
    const url = `${environment.apiUrl}/meter_types/meter_types_info_all`;
    this.meterTypeList = [];
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonStr = JSON.stringify(res);
          let jsonObj = JSON.parse(jsonStr);
          let data = jsonObj.result.metertypes;

          this.meterTypeList = data;
          this.selectedType.setValue(this.meter.meter_type_id);
          this.onChangeMeterType();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.dialogRef.close("lockedAccount");
            this.onClickLogout();
          } else {
            alert("メータータイプが取得できませんでした。");
          }
        }
      );
  }

  loadMeterUnit() {
    this.meterUnitList = [];
    const url = `${environment.apiUrl}/units/units_info_all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          let jsonStr = JSON.stringify(res);
          let jsonObj = JSON.parse(jsonStr);
          let data = jsonObj.result.units;
          this.meterUnitList = data;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.dialogRef.close("lockedAccount");
            this.onClickLogout();
          } else {
            alert("単位情報が取得できませんでした。");
          }
        }
      );
  }

  onChangeMeterType() {
    let useAngleTypeId = this.meterTypeList.filter(
      (type) => type.name === "アナログ（針）"
    )[0].id;
    this.isUseAngle = this.selectedType.value == useAngleTypeId;
    this.minValue = 0;
    let tmp1 = 0;
    if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
      let tmp = this.numberOfDigit + this.decimalPointPosition;
      for (let i = 0; i < tmp; i++) {
        tmp1 += 9 * 10 ** i;
      }
    } else if (
      this.numberOfDigit > 0 &&
      (this.decimalPointPosition == 0 || this.decimalPointPosition == undefined)
    ) {
      let tmp = this.numberOfDigit;
      for (let i = 0; i < tmp; i++) {
        tmp1 += 9 * 10 ** i;
      }
    } else {
      tmp1 = 0;
    }
    this.maxValue = tmp1;
    this.isShowActualValue = false;
    if (this.isUseAngle) {
      this.decimalPointPosition = 0;
      this.numberOfDigit = 0;
    } else {
      this.isShowActualValue =
        this.decimalPointPosition !== undefined &&
        this.numberOfDigit !== undefined;
      if (this.decimalPointPosition != undefined) {
        let tmp1 = 0;
        if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
          let tmp = this.numberOfDigit + this.decimalPointPosition;
          for (let i = 0; i < tmp; i++) {
            tmp1 += 9 * 10 ** i;
          }
        } else if (
          this.numberOfDigit > 0 &&
          (this.decimalPointPosition == 0 ||
            this.decimalPointPosition == undefined)
        ) {
          let tmp = this.numberOfDigit;
          for (let i = 0; i < tmp; i++) {
            tmp1 += 9 * 10 ** i;
          }
        } else {
          tmp1 = 0;
        }
        this.maxValue = tmp1 / 10 ** this.decimalPointPosition;
      }
    }
  }

  onChangeSubMeterName() {
    this.errorMessage = "";
    if (this.subMeterName == undefined || this.subMeterName == null) {
      return;
    }

    if (this.subMeterName.indexOf(" ") != -1) {
      this.subMeterName = String(this.subMeterName).trim();
    } else if (this.subMeterName.indexOf("　") != -1) {
      this.subMeterName = String(this.subMeterName).trim();
    }

    if (this.subMeterName.indexOf("_") != -1) {
      this.subMeterName = this.subMeterName.replace(/_/g, "");
    }
    this.changeMeterName();
  }

  onChangeMeterLabel() {
    this.errorMessage = "";
    if (this.meterLabel == undefined || this.meterLabel == null) {
      return;
    }

    if (this.meterLabel.indexOf(" ") != -1) {
      this.meterLabel = String(this.meterLabel).trim();
    } else if (this.meterLabel.indexOf("　") != -1) {
      this.meterLabel = String(this.meterLabel).trim();
    }

    this.isExistNumber = false;
    if (this.meterLabelList.indexOf(this.meterLabel) > -1) {
      this.isExistNumber = true;
    }

    this.isValidNumber = false;
    if (this.meterLabel == "") {
      this.meterLabel = undefined;
    }

    if (this.meterLabel == undefined || this.meterLabel == null) {
      this.isValidNumber = true;
    }
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // メーター取り替えを行う
  onClickMeterReplace() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }

    // 入力チェック
    const errorMessage = this.checkValue();
    if (errorMessage.length !== 0) {
      // エラーメッセージを表示して終了
      const errorMessageInfo = new MessageInfo(errorMessage, "");
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: "400px",
        data: errorMessageInfo,
      });
    } else {
      const messageInfo = new MeterConfirmDialogInfo(
        "取り替え確認",
        "メーターの取り替えを行います。よろしいですか。"
      );
      const confirmDialog = this.meterConfirmDialog.open(
        CommonConfirmDialogComponent,
        {
          width: "400px",
          data: messageInfo,
        }
      );

      confirmDialog.afterClosed().subscribe((result) => {
        if (result === "Yes") {
          /*
          no:200
          name:updated test
          meter_type_id:9cd589c0-088f-4245-8644-adf1b650ba47
          analysis_flg:1
          unit_id:42731a4b-a2f4-45b7-b218-5084549c0882
          max_value:1000
          min_value:0
          max_angle:90
          min_angle:0
          number_of_digits:2
          decimal_point_position:2
          is_card_display:1
          is_reviewed:1
          is_reread:1
          expiration_date:20231231
          */
          // APIコールして登録処理
          const url = `${environment.apiUrl}/meters/${this.meter.id}/replace`;
          let params = new HttpParams();
          params = params.set("no", this.meterNumber.toString());
          params = params.set("name", this.meterName);
          params = params.set("label", this.meterLabel);
          params = params.set("meter_type_id", this.selectedType.value);
          params = params.set("analysis_flg", "1");
          let tmp = "";
          if (
            this.selectedMeterName.value === "水道" ||
            this.selectedMeterName.value === "ガス"
          ) {
            for (let unit of this.meterUnitList) {
              if (unit.type === "㎥") {
                tmp = unit.id;
                break;
              }
            }
          } else {
            for (let unit of this.meterUnitList) {
              if (unit.type === "kWh") {
                tmp = unit.id;
                break;
              }
            }
          }
          params = params.set("unit_id", tmp);
          console.log(tmp);
          if (this.isUseAngle) {
            params = params.set("max_angle", "0");
            params = params.set("min_angle", "0");
            params = params.set("number_of_digits", "0");
            params = params.set("decimal_point_position", "0");
            params = params.set("min_value", "0");
            let tmp1 = 0;
            if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
              let tmp = this.numberOfDigit + this.decimalPointPosition;
              for (let i = 0; i < tmp; i++) {
                tmp1 += 9 * 10 ** i;
              }
            } else if (
              this.numberOfDigit > 0 &&
              (this.decimalPointPosition == 0 ||
                this.decimalPointPosition == undefined)
            ) {
              let tmp = this.numberOfDigit;
              for (let i = 0; i < tmp; i++) {
                tmp1 += 9 * 10 ** i;
              }
            } else {
              tmp1 = 0;
            }
            params = params.set("max_value", tmp1.toString());
          } else {
            params = params.set("max_angle", "0");
            params = params.set("min_angle", "0");
            params = params.set(
              "number_of_digits",
              this.numberOfDigit.toString()
            );
            params = params.set(
              "decimal_point_position",
              this.decimalPointPosition.toString()
            );
            params = params.set("min_value", "0");
            let tmp1 = 0;
            if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
              let tmp = this.numberOfDigit + this.decimalPointPosition;
              for (let i = 0; i < tmp; i++) {
                tmp1 += 9 * 10 ** i;
              }
            } else if (
              this.numberOfDigit > 0 &&
              (this.decimalPointPosition == 0 ||
                this.decimalPointPosition == undefined)
            ) {
              let tmp = this.numberOfDigit;
              for (let i = 0; i < tmp; i++) {
                tmp1 += 9 * 10 ** i;
              }
            } else {
              tmp1 = 0;
            }
            params = params.set(
              "max_value",
              (tmp1 / 10 ** this.decimalPointPosition).toString()
            );
          }
          params = params.set(
            "initial_value",
            this.meterInitialValue.toString()
          );

          params = params.set("is_card_display", "1");
          params = params.set(
            "is_reviewed",
            `${this.meterIsReViewFlag ? 1 : 0}`
          );
          params = params.set("is_reread", `${this.meterAIReadFlag ? 1 : 0}`);
          params = params.set(
            "expiration_date",
            formatDate(this.meterExpirationDate, "yyyyMMdd", this.locale)
          );

          params = params.set("closed_value", this.meterClosedValue.toString());
          this.httpClient
            .patch(url, params, {
              headers: new HttpHeaders({
                Authorization: this.idToken,
              }),
            })
            .subscribe(
              (response) => {
                this.dialogRef.close("close");
              },
              (err) => {
                if (err.error.code == "AccountLockError") {
                  alert(
                    "アカウントがロックされました。管理者までお問合せください"
                  );
                  this.dialogRef.close("lockedAccount");
                  Const.release();
                  this.auth.signOut();
                } else {
                  alert("メーターの取り替えに失敗しました。");
                }
              }
            );
        }
      });
    }
  }

  // 未入力項目有無チェック
  checkValue(): string {
    let errorMessage = "を入力してください。";

    if (this.selectedType.value === null) {
      errorMessage = "メータータイプ名" + errorMessage;
      return errorMessage;
    }

    if (!this.isUseAngle) {
      if (this.numberOfDigit === undefined || this.numberOfDigit == null) {
        errorMessage = "整数部分の桁数" + errorMessage;
        return errorMessage;
      }
      if (
        this.decimalPointPosition === undefined ||
        this.decimalPointPosition == null
      ) {
        errorMessage = "小数点以下の桁数" + errorMessage;
        return errorMessage;
      }
      if (
        this.meterExpirationDate === undefined ||
        this.meterExpirationDate == null
      ) {
        errorMessage = "有効期限" + errorMessage;
        return errorMessage;
      }
    }

    if (this.meterInitialValue === undefined) {
      errorMessage = "初期値" + errorMessage;
      return errorMessage;
    }
    if (this.meterClosedValue === undefined) {
      errorMessage = "旧メーター終了値" + errorMessage;
      return errorMessage;
    }
    return "";
  }
  numberOfDigitchange(value: number) {
    this.isShowActualValue = false;
    if (this.decimalPointPosition == undefined) {
      let tmp1 = 0;
      if (value > 0 && this.decimalPointPosition > 0) {
        let tmp = value + this.decimalPointPosition;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else if (value > 0 && this.decimalPointPosition == undefined) {
        let tmp = value;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else {
        tmp1 = 0;
      }
      this.maxValue = tmp1;
    } else {
      let tmp1 = 0;
      if (value > 0 && this.decimalPointPosition > 0) {
        let tmp = Number(value) + Number(this.decimalPointPosition);
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else if (value > 0 && this.decimalPointPosition == 0) {
        let tmp = value;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else {
        tmp1 = 0;
      }
      this.maxValue = tmp1 / 10 ** this.decimalPointPosition;
      if (this.numberOfDigit != undefined) {
        this.isShowActualValue = true;
      }

      this.setDecimalInterval();
    }
  }

  decimalPointPositionchange(value: number) {
    this.isShowActualValue = false;
    if (this.decimalPointPosition == undefined) {
      let tmp1 = 0;
      if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
        let tmp = this.numberOfDigit + this.decimalPointPosition;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else if (
        this.numberOfDigit > 0 &&
        this.decimalPointPosition == undefined
      ) {
        let tmp = this.numberOfDigit;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else {
        tmp1 = 0;
      }
      this.maxValue = tmp1;
      this.minValue = 0;
    } else {
      let tmp1 = 0;
      if (this.numberOfDigit > 0 && this.decimalPointPosition > 0) {
        let tmp = this.numberOfDigit + this.decimalPointPosition;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else if (this.numberOfDigit > 0 && this.decimalPointPosition == 0) {
        let tmp = this.numberOfDigit;
        for (let i = 0; i < tmp; i++) {
          tmp1 += 9 * 10 ** i;
        }
      } else {
        tmp1 = 0;
      }
      this.maxValue = tmp1 / 10 ** value;

      if (this.numberOfDigit != undefined) {
        this.isShowActualValue = true;
      }
    }

    this.setDecimalInterval();
  }

  minDateCheck(value: string) {
    this.hasError = false;
    this.errorMessage = "";

    if (value === null || value === "") {
      this.hasError = true;
      this.errorMessage = "有効期限を入力してください";
      return;
    }

    const today = new Date();
    const strToday = today
      .toISOString()
      .split("T")[0]
      .replace("-", "")
      .replace("-", "");
    if (formatDate(value, "yyyyMMdd", this.locale) < strToday) {
      this.hasError = true;
      this.errorMessage = "有効期限には本日以降の日付を入力してください";
      return;
    }
  }

  checkNumber(value: number) {
    this.hasError = false;
    this.errorMessage = "";

    if (this.meterNumber === undefined || this.meterNumber == null) {
      this.hasError = true;
      this.errorMessage = "表示順を入力してください";
      return;
    }
    if (value == this.meter.no) {
      return;
    }
    for (var i = 0; i < this.meterList.length; i++) {
      if (value == this.meterList[i].no) {
        this.hasError = true;
        this.errorMessage = "入力した表示順はすでに存在します";
        break;
      }
    }
  }

  setMeterNameList() {
    this.errorMessage = "";
    this.meterNameList = ["電灯", "動力", "水道", "ガス"];
    this.changeMeterName();
  }

  changeMeterName() {
    this.hasError = false;
    if (!this.meterNameList.includes(this.selectedMeterName.value)) {
      this.selectedMeterName = new UntypedFormControl();
      this.meterName = null;
      this.hasError = true;
      this.errorMessage =
        "メーター名称のメーター種類が無効な値です。再度入力してください";
      return;
    }

    if (
      this.selectedMeterName.value != undefined ||
      this.selectedMeterName.value != null
    ) {
      this.hasError = false;
      if (
        this.subMeterName == undefined ||
        this.subMeterName == "" ||
        this.subMeterName == null
      ) {
        this.meterName = this.selectedMeterName.value;
      } else {
        this.meterName = this.subMeterName + "_" + this.selectedMeterName.value;
      }
    } else {
      this.hasError = true;
      this.errorMessage = "メーター名称のメーター種類を入力してください";
    }
  }

  /**
   * @param {number} value 初期値
   */
  initialValuechange(value: number) {
    this.hasError = false;
    this.errorMessage = "";
    if (this.maxValue < value) {
      this.hasError = true;
      this.errorMessage = "初期値は最大検針値を超えることはできません";
    }
    if (value < this.minValue) {
      this.hasError = true;
      this.errorMessage = "初期値は最小検針値を下回ることはできません";
    }

    if (this.decimalPointPosition != undefined) {
      const diff =
        value - parseFloat(Number(value).toFixed(this.decimalPointPosition));

      if (diff != 0) {
        this.hasError = true;
        this.errorMessage = "初期値の小数点を正しく入力してください";
      }
    }
  }

  /**
   *
   */
  setDecimalInterval() {
    const interval: number = 0.1;

    if (
      this.decimalPointPosition != undefined ||
      this.decimalPointPosition != null
    ) {
      this.meterValueInterval = interval ** this.decimalPointPosition;
    }

    if (this.meterInitialValue != undefined) {
      this.initialValuechange(this.meterInitialValue);
    }
  }

  /**
   * @param {number} value 初期値
   */
  closedValuechange(value: number) {
    this.hasError = false;
    this.errorMessage = "";
    if (this.oldMaxValue < value) {
      this.hasError = true;
      this.errorMessage =
        "終了値は旧メーターの最大検針値を超えることはできません";
    }
    if (value < this.oldMinValue) {
      this.hasError = true;
      this.errorMessage =
        "終了値は旧メーターの最小検針値を下回ることはできません";
    }

    if (this.oldDecimalPointPosition != undefined) {
      const diff =
        value - parseFloat(Number(value).toFixed(this.oldDecimalPointPosition));

      if (diff != 0) {
        this.hasError = true;
        this.errorMessage = "終了値の小数点を正しく入力してください";
      }
    }
  }

  /**
   *
   */
  setOldDecimalInterval() {
    const interval: number = 0.1;

    if (
      this.decimalPointPosition != undefined ||
      this.decimalPointPosition != null
    ) {
      this.oldMeterValueInterval = interval ** this.decimalPointPosition;
    }
  }

  checkIfReadyToGo() {
    this.isFormReady =
      this.meterInitialValue != undefined &&
      this.meterInitialValue != null &&
      this.meterExpirationDate != null &&
      this.meterClosedValue != undefined &&
      this.meterClosedValue != null &&
      this.meterLabel != undefined &&
      this.meterLabel != "" &&
      this.meterLabel != null;

    if (!this.isUseAngle) {
      this.isFormReady =
        this.isFormReady &&
        this.numberOfDigit != undefined &&
        this.decimalPointPosition != undefined &&
        this.numberOfDigit != null &&
        this.decimalPointPosition != null;
    }

    return !this.hasError && this.isFormReady && !this.isExistNumber;
  }
}
