import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';

@Component({
  selector: 'app-meter-confirm-dialog',
  templateUrl: './meter-confirm-dialog.component.html',
  styleUrls: ['./meter-confirm-dialog.component.scss']
})
export class MeterConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MeterConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: MeterConfirmDialogInfo
  ) { }

  ngOnInit() {
  }

  onClickYes() {
    this.dialogRef.close('Yes');
  }
}
