// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// stg環境の設定情報
export const environment = {
  production: false,
  amplify: {
    Auth: {
      aws_cognito_region: 'ap-northeast-1',
      aws_user_pools_id: 'ap-northeast-1_Ucdb81Nsk',
      aws_user_pools_web_client_id: '53345r0l7dflt67btof0gcnsa0',
    }
  },
  apiUrl: 'https://api.rakusugi.com',
  apiKey: 'UF2sMx38LM1txNBBifdhA9yQYjpeMhh98yKvckOJ',
  apiEnv: 'stg',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
