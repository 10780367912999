import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Const } from "../const/const";
import { PatchNoteToTenantsResponse } from "../entity/patch-note-to-tenants-response";
import { TenantConfirmDialogInfo } from "../entity/tenant-confirm-dialog-info";
import { TenantRow } from "../entity/tenant-row";
import { TenantConfirmDialogComponent } from "../tenant-confirm-dialog/tenant-confirm-dialog.component";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-tenant-edit-dialog",
  templateUrl: "./tenant-edit-dialog.component.html",
  styleUrls: ["./tenant-edit-dialog.component.scss"],
})
export class TenantEditDialogComponent implements OnInit {
  site: LoginUserSite;
  editedNote: string;
  editedTenantName: string;
  isCardDisplayBoolean: boolean;
  idToken: string;
  initialValue = this.fb.group({
    items: this.fb.array([]),
  });
  isInitialValue = false;

  constructor(
    public dialogRef: MatDialogRef<TenantEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public tenantRow: TenantRow,
    private httpClient: HttpClient,
    public tenantConfirmDialog: MatDialog,
    public auth: AuthService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.editedNote = this.data.tenantRow.note;
    this.editedTenantName = this.data.tenantRow.name;
    if (this.data.tenantRow.initialValue) {
      for (const val of JSON.parse(this.data.tenantRow.initialValue)) {
        this.initialValues.push(
          this.fb.group({
            meterLabel: val.meterLabel,
            meterValue: val.meterValue,
          })
        );
      }
      this.isInitialValue = false;
    } else {
      this.addForm();
    }
    this.isCardDisplayBoolean = this.changeIsCardDisplayFormat(
      this.data.tenantRow.isCardDisplay
    );
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
      }
    });
  }

  // number型のカード表示フラグをboolean型に変換する
  changeIsCardDisplayFormat(isCardDisplay: number): boolean {
    if (isCardDisplay === 1) {
      return true;
    } else {
      return false;
    }
  }

  changeIsCardDisplayFormatNum(isCardDisplayBoolean: boolean): number {
    if (isCardDisplayBoolean === true) {
      return 1;
    } else {
      return 0;
    }
  }

  checkInitialValue() {
    for (const val of this.initialValues.value) {
      if (
        val.meterLabel === undefined ||
        val.meterLabel === null ||
        val.meterLabel === "" ||
        val.meterValue === undefined ||
        val.meterValue === null ||
        val.meterValue === ""
      ) {
        this.isInitialValue = false;
        return;
      }
    }
    this.isInitialValue =
      this.data.tenantRow.initialValue !==
      JSON.stringify(this.initialValues.value);
  }

  // 追加分の入力フォーム
  get optionForm(): UntypedFormGroup {
    return this.fb.group({
      meterLabel: [],
      meterValue: [],
    });
  }

  // 可変枠の抽出
  get initialValues(): UntypedFormArray {
    return this.initialValue.get("items") as UntypedFormArray;
  }

  // 追加ボタン押下時
  addForm() {
    this.initialValues.push(this.optionForm);
    this.isInitialValue = false;
  }

  // 削除ボタン押下時
  removeForm(idx: number) {
    this.initialValues.removeAt(idx);
    this.checkInitialValue();
  }

  // 更新ボタンの制御:TRUE→活性
  checkIfReadyToGo() {
    return (
      this.editedTenantName &&
      (this.editedTenantName !== this.data.tenantRow.name ||
        this.isInitialValue ||
        this.editedNote !== this.tenantRow.note ||
        this.isCardDisplayBoolean !==
          this.changeIsCardDisplayFormat(this.data.tenantRow.isCardDisplay))
    );
  }

  // 確定ボタン押された時の処理
  // 確定確認ダイアログの呼び出し⇨「はい」なら変更があった箇所だけ書き込み処理
  onClickConfirmButton() {
    if (
      this.tenantRow.note !== this.editedNote ||
      this.data.tenantRow.name !== this.editedTenantName ||
      this.isInitialValue ||
      this.changeIsCardDisplayFormat(this.data.tenantRow.isCardDisplay) !==
        this.isCardDisplayBoolean
    ) {
      const messageInfo = new TenantConfirmDialogInfo(
        "変更確認",
        "テナント情報の変更を反映します。よろしいですか。"
      );
      const tenantConfirmDialogRef = this.tenantConfirmDialog.open(
        TenantConfirmDialogComponent,
        {
          width: "650px",
          data: messageInfo,
        }
      );
      tenantConfirmDialogRef.afterClosed().subscribe(
        (result) => {
          if (result === "Yes") {
            // 情報変更があった箇所だけ更新APIを叩く
            if (
              this.data.tenantRow.name !== this.editedTenantName ||
              this.isInitialValue
            ) {
              this.doPatchTenantName();
            }
            if (this.tenantRow.note !== this.editedNote) {
              this.doPatchTenantNote();
            }
            if (
              this.changeIsCardDisplayFormat(
                this.data.tenantRow.isCardDisplay
              ) !== this.isCardDisplayBoolean
            ) {
              this.doPatchIsCardDisplayFlag();
            }
            this.dialogRef.close("close");
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("lockedAccount");
          } else {
            alert("テナント更新に失敗しました。");
          }
        }
      );
    }
  }

  // テナントメモ更新のAPIを叩く処理
  doPatchTenantNote() {
    const patchNoteToTenantsUrl = `${environment.apiUrl}/tenants/${this.data.tenantRow.tenantId}/note`;
    let params = new HttpParams();
    params = params.set("note", this.editedNote);
    this.httpClient
      .patch(patchNoteToTenantsUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: PatchNoteToTenantsResponse) => {
          this.dialogRef.close("close");
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("lockedAccount");
          } else {
            alert("テナントメモの登録に失敗しました。");
          }
        }
      );
  }

  // テナント名更新API叩く処理
  doPatchTenantName() {
    const patchNameToTenantsUrl = `${environment.apiUrl}/tenants/${this.data.tenantRow.tenantId}/info`;
    let params = new HttpParams();
    params = params.set("name", this.editedTenantName);
    params = params.set(
      "initial_value",
      JSON.stringify(this.initialValues.value)
    );
    this.httpClient
      .patch(patchNameToTenantsUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      // レスポンスのクラス未実装
      .subscribe(
        (response: any) => {
          this.dialogRef.close("close");
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("lockedAccount");
          } else {
            alert("テナント名の変更に失敗しました。");
          }
        }
      );
  }

  // カード・アプリ表示有無の変更API叩く処理
  doPatchIsCardDisplayFlag() {
    const patchIsCardDisplayFlagUrl = `${environment.apiUrl}/partitions/${this.data.tenantRow.partitionId}/is_card_display`;
    let params = new HttpParams();
    params = params.set(
      "is_card_display",
      String(this.changeIsCardDisplayFormatNum(this.isCardDisplayBoolean))
    );
    this.httpClient
      .patch(patchIsCardDisplayFlagUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      // レスポンスのクラス未実装
      .subscribe(
        (response: any) => {
          this.dialogRef.close("close");
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("lockedAccount");
          } else {
            alert("カード表示の変更に失敗しました。");
          }
        }
      );
  }
}
