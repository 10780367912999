import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CompanyAddEditDialogComponent } from '../company-add-edit-dialog/company-add-edit-dialog.component';
import { CompanyDeleteDialogComponent } from '../company-delete-dialog/company-delete-dialog.component';
import { Const } from '../const/const';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { SiteInfo } from '../entity/get-site-info';
import { LoginUser } from '../entity/login-user';
import { LoginUserSite } from '../entity/login-user-site';
import { LoginUserSiteCompany } from '../entity/login-user-site-company';
import { CompanyModel } from '../site-management/site-management.component';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  loginUser: LoginUser;
  idToken: string;
  getLoginUserUrl: string;

  // テーブル生成用
  displayedColumns: string[];
  companyData: CompanyModel[];
  filterdCompanyData: CompanyModel[];

  searchKeyString: string="";
  searchKeyChanged: Subject<string> = new Subject<string>();

  // フラグ類
  getCompanyDataFinished: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public auth: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    ) {
      localStorage.setItem("path", router.url)
    }

  ngOnInit() {
    this.loginUser = new LoginUser()
    this.companyData = []
    this.searchKeyChanged
    .debounceTime(300) // wait 300ms after the last event before emitting last event
    .subscribe(str => {this.searchKeyString = str; this.filterResult()});
    this.auth.getIdToken()
      .subscribe(
          result => {
            if (result) {
              this.idToken = result;

              if (Const.loginUser === null) {
                this.doGetLoginUser()
              }

              this.loginUser = Const.loginUser
              this.mjitUserFlag = Const.mjitUser
              this.adminUserFlag = Const.adminUser
              this.getCompanyData()
            } else {
              // idトークンがnullの場合はログイン画面へ遷移
              alert('セッションが切れています。再度ログインしてください。');
              this.onClickLogout()
            }
          }
      );
  }

  getCompanyData(){
    this.getCompanyDataFinished = false
    this.filterdCompanyData = []
    const url = `${environment.apiUrl}/company/all`
    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res =>{
      let jsonStr = JSON.stringify(res)
      let jsonObj = JSON.parse(jsonStr)
      this.companyData = jsonObj.result.companies
      this.filterdCompanyData = this.companyData
      this.displayedColumns = ["companyName","createdBy","createdAt","updatedBy","updatedAt","editButton","deleteButton"]
      this.getCompanyDataFinished = true
    }, err => {
      if(err.error.code == "AccountLockError"){
        alert('アカウントがロックされました。管理者までお問合せください');
        this.onClickLogout()
      }else{
        alert("会社情報が取得できませんでした。")
      }
    })
  }
  
  onClickAddCompany() {
    const dialogRef = this.dialog.open(CompanyAddEditDialogComponent, {
      width: '650px',
      data: false
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === 'close') {
        this.getCompanyData()
      }
    });
  }

  onClickCompanyEdit(company: CompanyModel) {
    const dialogRef = this.dialog.open(CompanyAddEditDialogComponent, {
      width: '650px',
      data: true
    });
    dialogRef.componentInstance.company = company
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === 'close') {
        this.getCompanyData()
      }
    });
  }

  onClickCompanyDelete(company: CompanyModel) {
    const dialogRef = this.dialog.open(CompanyDeleteDialogComponent, {
      width: '650px',
      data: company
    });
    dialogRef.afterClosed().subscribe(makeResult => {
      if (makeResult === 'close') {
        this.getCompanyData()
        this.getAllSiteData()
      }
    });
  }
    // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
    getAllSiteData() {
      Const.siteInfo.splice(0);
      const url = `${environment.apiUrl}/sites/sites_info_all/all`;
      this.httpClient.get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        })
      }).subscribe( res => {
        const jsonStr = JSON.stringify(res);
        const jsonObj = JSON.parse(jsonStr);
        for (const site of jsonObj.result.sites as SiteInfo[]) {
          const siteItem = new LoginUserSite();
          siteItem.id = site.id;
          siteItem.name = site.name;
          siteItem.address = site.address;
          siteItem.updated_at = site.updated_at;
          siteItem.created_at = site.created_at;
          siteItem.company = new LoginUserSiteCompany();
          siteItem.company.id = site.company_id;
          siteItem.company.name = site.company_name;
          Const.siteInfo.push(siteItem);
        }
      },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
          }else{
            alert('施設情報が取得できませんでした。');
          }
      });
    }

  filterResult(){
    if(this.searchKeyString === ""){
      this.filterdCompanyData = this.companyData
      return
    }
    this.filterdCompanyData = this.companyData.filter(item => item.name.includes(this.searchKeyString))
  }

  search(text: string){
    this.searchKeyChanged.next(text);
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
    .subscribe((response: GetLoginUserResponse) => {
      Const.loginUser = response.result.login_user;
      Const.mjitUser = Number(Const.loginUser.user_type) === 0
      Const.adminUser = Number(Const.loginUser.user_type) === 1
      if (Const.mjitUser) {
        Const.site_id = localStorage.getItem("siteId")
      } else {
        for (const site of response.result.login_user.sites) {
          if (site.id === localStorage.getItem("siteId")) {
            Const.site_id = site.id
          }
        }
      }

      if (Const.site_id === null) {
        alert('前回選択した施設が見つかりませんでした。');
        Const.site_id = response.result.login_user.sites[0].id;
      }

      this.loginUser = Const.loginUser
      this.mjitUserFlag = Const.mjitUser
      this.adminUserFlag = Const.adminUser
      this.getCompanyData()
    },
    err => {
      if(err.error.code == "AccountLockError"){
        alert('アカウントがロックされました。管理者までお問合せください');
      }else{
        alert('ログイン情報が取得できませんでした。再度ログインしてください。');
      }
      this.onClickLogout();
    });
  }

  // ログアウト
  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }
}
