// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-button {
  background-color: #90CAFC;
}

.container {
  display: flex;
  align-items: flex-start;
}

.rectangle {
  width: 130px;
  height: 150px;
  border: 2px solid black;
  flex-direction: column;
  justify-content: space-between;
  align-items: cneter;
  text-align: center;
}

.rectangle img {
  max-width: 20%;
  height: auto;
}

.rectangle p {
  margin: 2px 0 0 0;
  vertical-align: bottom;
}

table {
  font-size: 10px;
}

th {
  width: 120px;
}

td {
  width: 300px;
}

p {
  font-size: 10px;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-qrcode-dialog/meter-qrcode-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AACA;EACI,aAAA;EACA,uBAAA;AAEJ;;AACA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,cAAA;EACA,YAAA;AAGJ;;AAAA;EACI,iBAAA;EACA,sBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EAEI,YAAA;AAEJ;;AAEA;EAEI,YAAA;AAAJ;;AAIA;EACI,eAAA;EACA,sBAAA;AADJ","sourcesContent":[".confirm-button {\n    background-color: #90CAFC;\n}\n.container{\n    display:flex;\n    align-items:flex-start\n}\n\n.rectangle{\n    width:130px;\n    height: 150px;\n    border: 2px solid black;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: cneter;\n    text-align:center;\n}\n.rectangle img{\n    max-width: 20%;\n    height: auto;\n\n}\n.rectangle p{\n    margin: 2px 0 0 0;\n    vertical-align: bottom;\n} \n\ntable{\n    font-size: 10px;\n\n}\nth{\n\n    width: 120px ;\n\n}\n\ntd{\n\n    width: 300px ;\n\n}\n\np{\n    font-size: 10px;\n    vertical-align: bottom;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
