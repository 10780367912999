export class ReportRow {
  partition = "*";
  name = "*";
  tenantId: string;
  info: string; // 基本空文字だが、新旧メーターいる場合のみ記入される
  type: string;
  label: string;
  id: string;
  lastMonth = "*";
  thisMonth = "*";
  used = "*";
  lastUsed: string;
  alert = 0;
  maxVal: string;
  decimal = 0;
}
