export class CompareDialogData {
  constructor(
    partition: string,
    tenantName: string,
    info: string,
    decimal: number,
    meterType: string,
    thisMonthUsed: string,
    lastMonthUsed: string,
    rateOfChange: string,
    alertFlag: number
  ) {
    this.partition = partition;
    this.tenantName = tenantName;
    this.info = info;
    this.decimal = decimal;
    this.meterType = meterType;
    this.thisMonthUsed = thisMonthUsed;
    this.lastMonthUsed = lastMonthUsed;
    this.rateOfChange = rateOfChange;
    this.alertFlag = alertFlag;
  }
  partition: string;
  tenantName: string;
  info: string;
  decimal: number;
  meterType: string;
  thisMonthUsed: string;
  lastMonthUsed: string;
  rateOfChange: string;
  alertFlag: number;
}
