import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { Const } from '../const/const';
import { SiteInfo } from '../entity/get-site-info';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';

@Component({
  selector: 'app-site-delete-dialog',
  templateUrl: './site-delete-dialog.component.html',
  styleUrls: ['./site-delete-dialog.component.scss']
})
export class SiteDeleteDialogComponent implements OnInit {

  idToken: string;

  constructor(
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<SiteDeleteDialogComponent>,
    public dialog: MatDialog,
    public confirmDialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public site: SiteInfo,
    ) { }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            Const.release();
            this.auth.signOut();
          }
        }
    );
  }

  // メーター管理削除
  onClickConfirm() {
      if(!navigator.onLine){
        alert("ネットワーク接続を確認してください")
        return
      }

      const messageInfo = new MeterConfirmDialogInfo('施設削除', '施設情報を削除します。よろしいですか。');
      const meterConfirmDialogRef = this.confirmDialog.open(CommonConfirmDialogComponent, {
        width: '650px',
        data: messageInfo
      });

      meterConfirmDialogRef.afterClosed().subscribe(result => {
        if(result === "Yes"){

          const deleteSiteUrl = `${environment.apiUrl}/sites/${this.site.id}/0/sites_info`;
          let params = new HttpParams();
          params = params.set('name', "");
          params = params.set('address', "");
          params = params.set('company_id', "");

          this.httpClient.patch(deleteSiteUrl, params,
            {
              headers: new HttpHeaders({
                Authorization: this.idToken
              })
            }
          ) .subscribe((response: any) => {
            this.dialogRef.close('close');
          },
            err => {
              if(err.error.code == "AccountLockError"){
                alert('アカウントがロックされました。管理者までお問合せください');
                Const.release()
                this.auth.signOut()
                this.dialogRef.close('lockedAccount');
              }else{
                alert('施設削除に失敗しました。');
              }
            }
          );
        }
      });

  }

}
