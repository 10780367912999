import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { FloorInfo } from "../entity/floor-info";
import { FloorConfirmDialogInfo } from "../entity/floor-confirm-dialog-info";
import { Router } from "@angular/router";
import { Const } from "../const/const";
import { LoginUserSite } from "../entity/login-user-site";

@Component({
  selector: "app-floor-delete-dialog",
  templateUrl: "./floor-delete-dialog.component.html",
  styleUrls: ["./floor-delete-dialog.component.scss"],
})
export class FloorDeleteDialogComponent implements OnInit {
  idToken: string;
  site: LoginUserSite;
  floorInfo: FloorInfo;
  floorsInfo: FloorInfo[];

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    //@Inject(MAT_DIALOG_DATA) public floor: FloorInfo,
    public dialogRef: MatDialogRef<FloorDeleteDialogComponent>,
    private httpClient: HttpClient,
    public auth: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.floorsInfo = this.data.floorsInfo;
    this.floorInfo = this.data.floorInfo;
    this.floorsInfo = [];
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }

  // メーター管理削除
  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    const messageInfo = new FloorConfirmDialogInfo(
      "フロア削除",
      "フロア情報を削除します。よろしいですか？"
    );
    const floorConfirmDialogRef = this.confirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "650px",
        data: messageInfo,
      }
    );

    floorConfirmDialogRef.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        const postTenantUrl = `${environment.apiUrl}/floors/floor_info/0/${this.floorInfo.id}`;
        const params = new HttpParams();
        this.httpClient
          .patch(postTenantUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response: any) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("lockedAccount");
              } else {
                alert("フロア削除に失敗しました。");
              }
            }
          );
      }
    });
  }
}
