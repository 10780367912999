import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TenantConfirmDialogInfo } from '../entity/tenant-confirm-dialog-info';

@Component({
  selector: 'app-confirm-alert-dialog',
  templateUrl: './confirm-alert-dialog.component.html',
  styleUrls: ['./confirm-alert-dialog.component.scss']
})
export class ConfirmAlertDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: TenantConfirmDialogInfo
  ) { }

  ngOnInit() {
  }

  onClickYes() {
    this.dialogRef.close('Yes');
  }

  onClickNo() {
    this.dialogRef.close('No');
  }

}
