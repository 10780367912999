import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';

@Component({
  selector: 'app-common-confirm-dialog',
  templateUrl: './common-confirm-dialog.component.html',
  styleUrls: ['./common-confirm-dialog.component.scss']
})

export class CommonConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CommonConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: MeterConfirmDialogInfo
    ) {

  }

  ngOnInit() {

  }

  onClickYes() {
    this.dialogRef.close('Yes');
  }

  onClickNo() {
    this.dialogRef.close('No');
  }

}
