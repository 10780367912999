import { GetAllTenantsTenant } from '../entity/get-all-tenants-tenant';
import { TenantRow } from '../entity/tenant-row';

export class ToTenantMakeObject {
    constructor(allTenantList: GetAllTenantsTenant[], tenantData: TenantRow[]) {
        this.allTenantList = allTenantList;
        this.tenantData = tenantData;
    }
    allTenantList: GetAllTenantsTenant[];
    tenantData: TenantRow[];
}
