import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Amplify, Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { GetInSiteMeterResponse } from '../entity/get-in-site-meters-response';

// 参考　https://qiita.com/daikiojm/items/18f718df07c28965b7b3#authservice
@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  private login_id: string;

  // ここにidトークン持たせるのではなく、認証処理だけ別で切りだしたサービス（loginはそこを呼び出す）を定義
  // そこに持たせつ（Ingectable）

  constructor(
    private router: Router,
    private httpClient: HttpClient,
  ) {
    Amplify.configure(environment.amplify.Auth);
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  // ログイン
  public signIn(login_id: string, password: string): Observable<any> {
    this.login_id = login_id;
    return fromPromise(Auth.signIn(login_id, password))
      .pipe(
        tap(() => this.loggedIn.next(true))
      );
  }

  // ログイン状態の確認
  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  // idトークンの取得
  public getIdToken(): Observable<any> {
    return fromPromise(Auth.currentSession())
    .pipe(
      map(result => {
        const idToken = result.getIdToken().getJwtToken();
        return idToken;
      }
      ),
      catchError(error => {
        return null;
      })
    );
  }
  
  // ログアウト
  public signOut() {
    this.loginLogging('0');
    fromPromise(Auth.signOut())
      .subscribe(
        result => {
          localStorage.clear()
          this.loggedIn.next(false);
          this.router.navigate(['/login']);
          localStorage.clear();
        }
      );
  }
  // ログイン・ログアウト時ログを残す
  public loginLogging(login_type: string) {
    const apiURL = `${environment.apiUrl}/login/${this.login_id}/${login_type}`;
    this.getIdToken()
      .subscribe(
        result => {
          if (result) {
            this.httpClient.get(apiURL,
              {
                headers: new HttpHeaders({
                  Authorization: result
                })
              }
            ).subscribe((response: GetInSiteMeterResponse) => {
            });
          }
        }
      )
  }
}
