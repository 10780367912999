// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.example-container {
  height: 480px;
  background-color: white;
  box-shadow: none;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/user-site-links-select-dialog/user-site-links-select-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":["table{\n    width: 100%;\n\n}\n.example-container {\n    height: 480px;\n    background-color:white;\n    box-shadow: none;\n    overflow: scroll;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
