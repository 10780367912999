import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CompanyInfo } from '../entity/get-company-info';
import { UserSite } from '../entity/get-user-with-sites-links-info';

export interface PeriodicElement {
  checked: boolean;
  siteId: string;
  siteName: string;
  siteAddress: string;
  companyName: string;
}

export class CheckSiteInfo {
  checked = false;
  siteId: string;
  siteName: string;
  siteAddress: string;
  companyName: string;
}

@Component({
  selector: 'app-user-site-links-select-dialog',
  templateUrl: './user-site-links-select-dialog.component.html',
  styleUrls: ['./user-site-links-select-dialog.component.scss']
})

export class UserSiteLinksSelectDialogComponent implements OnInit {

  idToken: string;
  sitesWithLinksList: CheckSiteInfo[] = new Array();

  displayedColumns: string[] = ['select', 'siteName', 'siteAddress', 'companyName'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @Input() public siteInfo: UserSite[];
  @Input() public company: CompanyInfo[];
  @Input() public checkSiteInfoList: CheckSiteInfo[];

  constructor(
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public sites: UserSite[],
    public dialogRef: MatDialogRef<UserSiteLinksSelectDialogComponent>,
    private httpClient: HttpClient,
    private router: Router,
    public auth: AuthService,
    ) { }

  ngOnInit() {
    this.sitesWithLinksList.splice(0);
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
    this.checkSelectSite();
  }

  checkSelectSite() {
    if (this.checkSiteInfoList.length > 0) {
      for (const site of this.siteInfo) {
        const siteItem = this.checkSiteInfoList.filter(item => item.siteId === site.siteId);
        const checkSiteInfo = new CheckSiteInfo();
        checkSiteInfo.siteId = site.siteId;
        checkSiteInfo.siteName = site.siteName;
        checkSiteInfo.siteAddress = site.siteAddress;
        checkSiteInfo.companyName = site.companyName;
        if (siteItem.length > 0) {
          checkSiteInfo.checked = true;
          this.selection.toggle(checkSiteInfo);
        }
        this.sitesWithLinksList.push(checkSiteInfo);
      }
    } else {
      for (const site of this.siteInfo) {
        const siteItem = this.sites.filter(item => item.siteId === site.siteId);
        const checkSiteInfo = new CheckSiteInfo();
        checkSiteInfo.siteId = site.siteId;
        checkSiteInfo.siteName = site.siteName;
        checkSiteInfo.siteAddress = site.siteAddress;
        checkSiteInfo.companyName = site.companyName;
        if (siteItem.length > 0) {
          checkSiteInfo.checked = true;
          this.selection.toggle(checkSiteInfo);
        }
        this.sitesWithLinksList.push(checkSiteInfo);
      }
    }
  }

  // 施設選択
  onClickSelectSite() {
      if (!navigator.onLine) {
        alert('ネットワーク接続を確認してください');
        return;
      }
      this.dialogRef.close(this.selection.selected);
  }

  isAllSelected() {
   const numSelected = this.selection.selected.length;
   const numRows = this.sitesWithLinksList.length;
   return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
      this.selection.clear() :
       this.sitesWithLinksList.forEach(row => this.selection.select(row));
  }

}
