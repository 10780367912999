import { idName } from "./id-name";

export class SiteInfo {
    id: string;
    name: string;
    address: string;
    company_id: string;
    created_at: string;
    updated_at: string;
    created_by: idName;
    updated_by: idName;
    company_name:string;
}