import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LoginUser } from '../entity/login-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginUserSite } from '../entity/login-user-site';
import { Const } from '../const/const'
import { environment } from 'src/environments/environment';
import { GetLoginUserResponse } from '../entity/get-login-user-response';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss']
})

export class SiteSelectComponent implements OnInit {
  loginUser: LoginUser;
  idToken: string;
  getLoginUserUrl: string;
  siteArray: LoginUserSite[];
  displayedColumns: string[];
  getUserFinished = false;
  show = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private httpClient: HttpClient,
  ) {
    localStorage.setItem("path", router.url)
  }

  ngOnInit() {
    this.siteArray = []

    if (Const.loginUser == null) {
      this.getToken()
    } else {
      if (Const.loginUser.user_type === 0) {
        this.router.navigate(['company-management']);
      }
      this.loginUser = Const.loginUser
      this.siteArray = Const.loginUser.sites
      this.displayedColumns = ['site'];
      this.getUserFinished = true;
    }
  }

  getToken() {
    this.auth.getIdToken()
    .subscribe(
      result => {
        if (result) {
          this.idToken = result;
          this.doGetLoginUser()
        } else {
          // idトークンがnullの場合はログイン画面へ遷移
          alert('セッションが切れています。再度ログインしてください。');
          this.router.navigate(['login']);
        }
      }
    );
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient.get(this.getLoginUserUrl,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    )
    .subscribe((response: GetLoginUserResponse) => {
      Const.loginUser = response.result.login_user;
      if (Const.loginUser.user_type === 0) {
        this.router.navigate(['company-management']);
      }
      Const.mjitUser = Number(Const.loginUser.user_type) === 0
      Const.adminUser = Number(Const.loginUser.user_type) === 1

      this.loginUser = Const.loginUser;
      this.siteArray = Const.loginUser.sites
      this.displayedColumns = ['site'];
      this.getUserFinished = true;
    },
    err => {
      if(err.error.code == "AccountLockError"){
        alert('アカウントがロックされました。管理者までお問合せください');
      }else{
        alert('ログイン情報が取得できませんでした。再度ログインしてください。');
      }
      this.onClickLogout();
    });
  }

  onClickLogout() {
    Const.release()
    this.auth.signOut();
  }

  onClick(event: LoginUserSite) {
    Const.site_id = event.id
    localStorage.setItem('siteId', event.id);
    this.router.navigate(['meter-card-list']);
  }
}
