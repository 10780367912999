import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, OnInit, Inject, Input } from "@angular/core";
import { LoginUser } from "../entity/login-user";
import { FloorConfirmDialogInfo } from "../entity/floor-confirm-dialog-info";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments/environment";
import { LoginUserSite } from "../entity/login-user-site";
import { Router } from "@angular/router";
import { FloorInfo } from "../entity/floor-info";
import { Const } from "../const/const";

@Component({
  selector: "app-floor-add-dialog",
  templateUrl: "./floor-add-dialog.component.html",
  styleUrls: ["./floor-add-dialog.component.scss"],
})
export class FloorAddDialogComponent implements OnInit {
  idToken: string;
  floorName: string;
  floorNumber: number;

  loginUser: LoginUser;
  site: LoginUserSite;
  floorsInfo: FloorInfo[];
  errorFloorNameMsg: string;
  errorFloorNumberMsg: string;

  constructor(
    public floorConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FloorAddDialogComponent>,
    private router: Router,
    public auth: AuthService,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.site = this.data.site;
    this.floorsInfo = this.data.floorsInfo;
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }

  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    if (this.site == undefined) {
      alert("施設情報の取得に失敗しました。");
      return;
    }

    const messageInfo = new FloorConfirmDialogInfo(
      "登録確認",
      "フロアを新規登録します。よろしいですか。"
    );
    const confirmDialog = this.floorConfirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "400px",
        data: messageInfo,
      }
    );

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === "Yes") {
        // APIコールして登録処理
        const postFloorUrl = `${environment.apiUrl}/floors/floor_info`;
        let params = new HttpParams();
        params = params.set("name", this.floorName);
        params = params.set("no", this.floorNumber.toString());
        params = params.set("site_id", this.site.id);
        this.httpClient
          .post(postFloorUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
            }),
          })
          .subscribe(
            (response) => {
              this.dialogRef.close("close");
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("lockedAccount");
              } else {
                alert("フロア情報の登録に失敗しました。");
              }
            }
          );
      }
    });
  }

  checkIfFormDirty() {
    let hasNameChanged = false;

    if (
      this.floorName == undefined ||
      this.floorName == null ||
      this.floorName == "" ||
      this.floorName == "未登録" ||
      this.floorNumber == undefined ||
      this.floorNumber == null
    ) {
      return true;
    }

    for (var i = 0; i < this.floorsInfo.length; i++) {
      if (
        this.floorName == this.floorsInfo[i].name ||
        this.floorNumber.toString() == this.floorsInfo[i].no
      ) {
        hasNameChanged = true;
        break;
      }
    }

    return hasNameChanged;
  }

  changeValue() {
    this.errorFloorNameMsg = "";
    this.errorFloorNumberMsg = "";

    if (
      this.floorName == undefined ||
      this.floorName == null ||
      this.floorName == "" ||
      this.floorNumber === undefined ||
      this.floorNumber == null
    ) {
      this.errorFloorNameMsg = "フロア名と表示順を入力してください";
    } else if (this.floorName == "未登録") {
      this.errorFloorNameMsg = "フロア名が「未登録」のフロアは登録できません";
    } else {
      for (var i = 0; i < this.floorsInfo.length; i++) {
        if (this.floorName == this.floorsInfo[i].name) {
          this.errorFloorNameMsg = "入力したフロア名はすでに存在します";
        }

        if (this.floorNumber.toString() == this.floorsInfo[i].no) {
          this.errorFloorNumberMsg = "入力した表示順はすでに存在します";
        }

        if (this.errorFloorNameMsg != "" && this.errorFloorNumberMsg != "") {
          break;
        }
      }
    }
  }
}
