import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appForbiddenCharacters]",
})
export class ForbiddenCharactersDirective {
  private forbiddenChars = ["\\", ",", '"', "“"];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("input", ["$event"]) onInput(event: Event): void {
    //let inputValue: string = this.el.nativeElement.value;
    //画面から項目の値を取得
    const input = event.target as HTMLInputElement;
    let inputValue: string = input.value;
    const initialLength = inputValue.length;
    // Remove forbidden characters
    this.forbiddenChars.forEach((char) => {
      inputValue = inputValue.split(char).join("");
    });

    // If any forbidden characters were removed, update the input value
    if (inputValue.length !== initialLength) {
      //this.renderer.setProperty(this.el.nativeElement, "value", inputValue);
      input.value = inputValue;
      input.dispatchEvent(new Event("input"));
    }
  }
}
