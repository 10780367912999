import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MeterInfo } from '../entity/get-meter-info';
import { PRINT_STYLES } from './print-styles';

@Component({
  selector: 'app-meter-qrcode-dialog',
  templateUrl: './meter-qrcode-dialog.component.html',
  styleUrls: ['./meter-qrcode-dialog.component.scss']
})

export class MeterQrcodeDialogComponent implements OnInit {
  public myAngularxQrCode: string = null;
  qrcodeTitle: string;
  printStyles = PRINT_STYLES;

  constructor(
    @Inject(MAT_DIALOG_DATA) public meter: MeterInfo,
    public printDialog: MatDialog
  ) {
    this.myAngularxQrCode = "$"+ `${this.meter.id}`
    this.qrcodeTitle = this.meter.partName + "_" + this.meter.name
   }

  ngOnInit() {
    this.myAngularxQrCode = "$"+ `${this.meter.id}`
  }
}
