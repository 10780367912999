import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

class Partition {
  id: string;
  name: string;
  no: number;
  is_card_display: number;
  report_type: number;
  floor_id: string;
  floor_name: string;
  floor_no: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

@Component({
  selector: 'app-partition-detail-dialog',
  templateUrl: './partition-detail-dialog.component.html',
  styleUrls: ['./partition-detail-dialog.component.scss']
})

export class PartitionDetailDialogComponent implements OnInit {
  meterUnitName: string
  idToken: string;

  constructor(   
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PartitionDetailDialogComponent>,
    public auth: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public partitionInfo: Partition
  ) {
  }

  ngOnInit() {
    this.auth.getIdToken()
    .subscribe(
        result => {
          if (result) {
            this.idToken = result;
          } else {
            // idトークンがnullの場合はログイン画面へ遷移
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
    );
  }
}
