import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { MeterType } from "../entity/get-meter-type";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MeterTypeService {

    public token: string = null
    private baseURL = environment.apiUrl

    constructor(private httpClient: HttpClient,public auth: AuthService,private router: Router) { 
      this.getToken()

    }

    getToken(){
      this.auth.getIdToken()
      .subscribe(
        result => {
          if (result){
            this.token = result
          }else{
            alert('セッションが切れています。再度ログインしてください。');
            this.router.navigate(['login']);
          }
        }
      )
    }


    getAllMeterType(): Observable<MeterType[]>{

      const url = this.baseURL + "/meter_types/meter_types_info_all"
      return this.httpClient.get<MeterType[]>(url,this.getHeader()).pipe(
        map(x => JSON.stringify(x)),
        map(x => JSON.parse(x).result.metertypes),
        catchError(this.handleError<MeterType[]>('getAllMeterType', []))
      )
    }

    addNewMeterType(name: string): Observable<MeterType>{
      name = name.trim();
      if (!name) { return; }

      let params = new HttpParams();
      params.set("name",name)

      const url = this.baseURL + "/meter_types/meter_types_info"
      return this.httpClient.post<MeterType>(url,params, this.getHeader()).pipe(
        tap(x =>{this.log("addNewMeterType begin")}),
        map(x => JSON.stringify(x)),
        map(x => JSON.parse(x).result.metertypes),
        catchError(this.handleError<MeterType>("addNewMeterType error"))
      )
        
    }

    updateMeterType(id: string,name: string): Observable<any>{
      name = name.trim();
      if (!name) { return; }
      let params = new HttpParams();
      params.set("name",name)

      const url = `${this.baseURL}/meter_types/${id}/1/meter_types_info`;
      const token = this.token

      let option = {
        headers: new HttpHeaders({
          Authorization: token
        })
      }

      return this.httpClient.patch(url,params,option).pipe(
        map(x => JSON.stringify(x)),
        map(x => JSON.parse(x).message),
        catchError(this.handleError<MeterType>("updateMeterType error"))
      )
    }


    deleteMeterType(id: string): Observable<any>{

      let params = new HttpParams();
      params.set("name","")

      const url = `${this.baseURL}/meter_types/${id}/0/meter_types_info`;
      return this.httpClient.patch(url, params,this.getHeader()).pipe(
        map(x => JSON.stringify(x)),
        map(x => JSON.parse(x).message),
        catchError(this.handleError<MeterType>("deleteMeterType error"))
      )
    }


    getHeader(){
      let httpOptions = {
        headers: new HttpHeaders({Authorization: this.token }),
      };
      return httpOptions
    }

    getPatchHeader(){
      let httpOptions = {
        headers: new HttpHeaders({Authorization: this.token,
          "Content-Type":"application/x-www-form-urlencoded"
          }),
      };
      return httpOptions
    }

    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
  
    /** Log a HeroService message with the MessageService */
    private log(message: string) {
      this.log(message)
    }
}
