import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { FloorManagementListComponent } from "./floor-management-list/floor-management-list.component";
import { LoginComponent } from './login/login.component';
import { MasterEditComponent } from './master-edit/master-edit.component';
import { MeterCardListComponent } from './meter-card-list/meter-card-list.component';
import { MeterManagementListComponent } from "./meter-management-list/meter-management-list.component";
import { MeterReportComponent } from './meter-report/meter-report.component';
import { MeterTypeManagementListComponent } from "./meter-type-management-list/meter-type-management-list.component";
import { MeterUnitComponent } from "./meter-unit/meter-unit.component";
import { PartitionManagementListComponent } from "./partition-management-list/partition-management-list.component";
import { SiteManagementComponent } from "./site-management/site-management.component";
import { SiteSelectComponent } from './site-select/site-select.component';
import { UserManagementComponent } from "./user-management/user-management.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'site-select', component: SiteSelectComponent, canActivate: [AuthGuard] },
  { path: 'meter-card-list', component: MeterCardListComponent, canActivate: [AuthGuard] },
  { path: 'meter-report', component: MeterReportComponent, canActivate: [AuthGuard] },
  { path: 'meter-report-2', component: MeterReportComponent, canActivate: [AuthGuard] },
  { path: 'floor-management',component: FloorManagementListComponent,canActivate: [AuthGuard] },
  { path: 'meter-management',component: MeterManagementListComponent,canActivate: [AuthGuard]},
  { path: 'meter-type', component: MeterTypeManagementListComponent,canActivate:[AuthGuard] },
  { path: 'meter-unit', component: MeterUnitComponent,canActivate:[AuthGuard] },
  { path: 'master-edit', component: MasterEditComponent, canActivate: [AuthGuard] },
  { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
  { path: 'partition-management',component: PartitionManagementListComponent,canActivate: [AuthGuard] },
  { path: 'site-management', component: SiteManagementComponent, canActivate: [AuthGuard] },
  { path: 'company-management', component: CompanyManagementComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule { }

