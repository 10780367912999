import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { CompanyModel, SiteWithUserInfo, UserForSiteModel, UserWithSiteInfo } from '../site-management/site-management.component';
import { SiteUserSelectionDialogComponent } from '../site-user-selection-dialog/site-user-selection-dialog.component';
import {  SiteInfo } from '../entity/get-site-info';
import { Const } from '../const/const';

@Component({
  selector: 'app-site-add-dialog',
  templateUrl: './site-add-edit-dialog.component.html',
  styleUrls: ['./site-add-edit-dialog.component.scss']
})

export class SiteAddOrEditDialogComponent implements OnInit {
  idToken: string;

  companyList: CompanyModel[];
  selectedCompany = new UntypedFormControl();

  siteName: string;
  siteAddress: string;
  userSelectionTitle: string="ユーザー選択";

  userSiteData: UserWithSiteInfo[] = []
  siteUserData: SiteWithUserInfo[] = [] 

  userSelectionData: UserForSiteModel[] = [];
  isSiteUserDataReady: boolean = false

  usingCompanyUserMap = new Map();
  originCompanyUserMap = new Map();

  waitForAddUser:UserForSiteModel[] = []
  waitForDeleteUser:UserForSiteModel[] = []

  @Input() public companyData: CompanyModel[];
  @Input() public allUserData: UserForSiteModel[];
  @Input() public siteModel:  SiteInfo;
  
  constructor(
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<SiteAddOrEditDialogComponent>,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public isEdit: boolean = false) {}

  ngOnInit() {
    this.companyList = this.companyData
    this.auth.getIdToken()
      .subscribe(
          result => {
            if (result) {
              this.idToken = result;
              this.getAllSitesWithUsers()
            } else {
              // idトークンがnullの場合はログイン画面へ遷移
              this.onClickLogout()
              alert('セッションが切れています。再度ログインしてください。');
            }
          }
      );
  }

  getAllSitesWithUsers(){
    if(this.isEdit && this.siteModel != undefined){
      this.siteName = this.siteModel.name
      this.siteAddress = this.siteModel.address
      this.selectedCompany.setValue(this.siteModel.company_id)
    }
    this.isSiteUserDataReady = false
    this.userSiteData = []
    const url = `${environment.apiUrl}/sites/user_site_links_info_all/all`;
    this.httpClient.get(url, {
      headers: new HttpHeaders({
        Authorization: this.idToken
      })
    }).subscribe( res => {
      const jsonStr = JSON.stringify(res);
      const jsonObj = JSON.parse(jsonStr);
      const data = jsonObj.result.sites;
      this.siteUserData = data
      this.isSiteUserDataReady = true

      this.makeCompanyUserMapData()

      if(this.isEdit && this.siteModel != undefined){
        this.doChangeCompany()
      }
    },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
          Const.release()
          this.auth.signOut()
          this.dialogRef.close('lockedAccount');
        }else{
          alert(err.message)
        }
    });
  }

  makeCompanyUserMapData(){
    this.usingCompanyUserMap = new Map()
    this.originCompanyUserMap = new Map()

    for (let index = 0; index < this.companyList.length; index++) {
      const company = this.companyList[index];

      // all user under each company
      let userSelections =this.allUserData.filter(item => {
        if(item.company){
          return item.company.id === company.id
        }
        return false
      })

      // all selectable user data under each company
      let siteUserSelection = this.siteUserData.filter(item => {
          return item.company_id === company.id
      })

      // filter by the siteID if exists
      if (this.siteModel) {
        let siteId = this.siteModel.id 

        let siteWithUserArray: UserWithSiteInfo[] = []
        siteUserSelection.forEach( site => {
          if(site.id === siteId){
            if(site.users){
              site.users.forEach(user => {
                siteWithUserArray.push(user)
              })
            }
          }
        })

        userSelections.forEach( item => {
          item.is_selected = false
          siteWithUserArray.forEach(siteUser => {
              if(siteUser.id === item.id){
                item.is_selected = true
              }
          })
        })
      }else{
        userSelections.forEach( item => {
          item.is_selected = false
        })
      }

      // save to the map
      this.usingCompanyUserMap.set(company.id, userSelections)
    }

    // deep clone map for comparision
    let deepCloneMap = new Map(JSON.parse(JSON.stringify([...this.usingCompanyUserMap])));
    this.originCompanyUserMap = deepCloneMap
  }

  doChangeCompany(){

    this.userSelectionData = []
    // get data from map
    let selectedCompanyID = this.selectedCompany.value
    let userSelectionData = this.usingCompanyUserMap.get(selectedCompanyID)

    this.userSelectionTitle = "ユーザー選択"
    let count = userSelectionData.filter(item => item.is_selected).length
    if (count > 0) {
        this.userSelectionTitle = `ユーザー選択(${count})`
    }

    this.userSelectionData = userSelectionData
    this.checkForUserToSiteData()
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  // メーター管理登録
  onClickConfirm() {
    if(!navigator.onLine){
      alert("ネットワーク接続を確認してください")
      return
    }

    if(this.isEdit){
      this.updateSite()
    }else{
      this.addSite()
    }
  }

  addSite(){
    const messageInfo = new MeterConfirmDialogInfo('新規登録確認', '施設を新規登録します。よろしいですか。');

    const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent, {
      width: '400px',
      data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === 'Yes') {

        // 施設の登録処理
        const postSiteUrl = `${environment.apiUrl}/sites/sites_info`;
        let params = new HttpParams();
        params = params.set('name', this.siteName);
        params = params.set('address', this.siteAddress);
        params = params.set('company_id', this.selectedCompany.value);
        this.httpClient.post(postSiteUrl, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe((response) => {
          // ユーザー施設紐付登録処理
          if(this.hasUserToSiteChanged){
            const jsonStr = JSON.stringify(response);
            const jsonObj = JSON.parse(jsonStr);
            const siteId = jsonObj.result.site.id;
            this.updateUserToSiteData(siteId)
          }else{
            this.dialogRef.close('close');
          }
        },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
            this.dialogRef.close('lockedAccount');
          }else{
            alert('施設情報の登録に失敗しました。');
          }
        });
      }
    });
  }

  updateSite(){
    const messageInfo = new MeterConfirmDialogInfo('施設更新', '施設を更新します。よろしいですか。');
    const confirmDialog = this.confirmDialog.open(CommonConfirmDialogComponent, {
      width: '400px',
      data: messageInfo
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === 'Yes') {

        // 施設の更新処理
        const url = `${environment.apiUrl}/sites/${this.siteModel.id}/1/sites_info`;
        let params = new HttpParams();
        params = params.set('name', this.siteName);
        params = params.set('address', this.siteAddress);
        params = params.set('company_id', this.selectedCompany.value);
        this.httpClient.patch(url, params,
          {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }
        )
        .subscribe((response) => {
          if(this.hasUserToSiteChanged){
            this.updateUserToSiteData(this.siteModel.id)
          }else{
            this.dialogRef.close('close');
          }
        },
        err => {
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            Const.release()
            this.auth.signOut()
            this.dialogRef.close('lockedAccount');
          }else{
            alert('施設情報の更新に失敗しました。');
          }
        });
      }
    });
  }

  onClickSelectUser(){

    const userDialog = this.dialog.open(SiteUserSelectionDialogComponent,{
      width: '800px',
      data: this.isEdit,
    })

    userDialog.componentInstance.allUserData = this.userSelectionData
    userDialog.afterClosed().subscribe(result => {

      if(result === "yes"){
          let newUserSelection = userDialog.componentInstance.allUserData
          this.userSelectionData = newUserSelection
          this.usingCompanyUserMap.set(this.selectedCompany.value, this.userSelectionData)
          let count = newUserSelection.filter(user => user.is_selected).length

          this.userSelectionTitle = "ユーザー選択"
          if(count > 0) {
            this.userSelectionTitle = `ユーザー選択(${count})`  
           }
          this.checkForUserToSiteData()
      }else{
          this.doChangeCompany()
      }
    })
}


  checkForUserToSiteData(){

    let waitForAdd:UserForSiteModel[] = []
    let waitForDelete:UserForSiteModel[]  = []

    this.usingCompanyUserMap.forEach((_, key ) => {
      let currentUserSelections: UserForSiteModel[] = this.usingCompanyUserMap.get(key) as UserForSiteModel[]
      let originUserSelections: UserForSiteModel[] = this.originCompanyUserMap.get(key) as UserForSiteModel[]
      if (key === this.selectedCompany.value) {
          currentUserSelections.forEach(currentUser => {
            originUserSelections.forEach(originUser => {
              if (currentUser.id === originUser.id) {
                if(currentUser.is_selected && originUser.is_selected == false){
                  waitForAdd.push(currentUser)
                }else if(currentUser.is_selected == false && originUser.is_selected){
                  waitForDelete.push(currentUser)
                }
              }
          })
        })
      }else{
          originUserSelections.forEach(originUser => {              
            if(originUser.is_selected){
              waitForDelete.push(originUser)
            }
          })
      }
    })

    // filter the company id
    waitForAdd = waitForAdd.filter(user => user.company_id === this.selectedCompany.value)

    this.waitForAddUser = waitForAdd
    this.waitForDeleteUser = waitForDelete

  }

 // ユーザー施設紐付登録処理
  async updateUserToSiteData(siteId: string = ""){

    let addResults:any[] = []
    if (this.waitForAddUser.length > 0) {
      for await (const user of this.waitForAddUser) {
        const res = await this.addNewUserToSite(user, siteId)
        addResults.push(res)
      }
    }

    let deleteResults:any[] = []
    if (this.waitForDeleteUser.length > 0) {
      for await (const user of this.waitForDeleteUser) {
        const res = await this.deleteUserFromSite(user)
        deleteResults.push(res)
      }
    }

    let addStatusResults = addResults.map( result => result.status).filter(status => status === 200)
    let deleteStatusResults = deleteResults.map( result => result.status).filter(status => status === 200)

    let addErrorMsg = ""
    let isAddSuccess = true
    if (addStatusResults.length == this.waitForAddUser.length) {
      isAddSuccess = true
    }else{
      isAddSuccess = false
      addResults.map(result => {
        if(result.status != 200){
          addErrorMsg = result.message
        }
      })
    }

    let deleteErrorMsg = ""
    let isDeleteSuccess = true
    if (deleteStatusResults.length == this.waitForDeleteUser.length) {
      isDeleteSuccess = true
    }else{
      isDeleteSuccess = false
      deleteResults.map(result => {
        if(result.status != 200){
          deleteErrorMsg = result.message
        }
      })
    }

    if(isAddSuccess && isDeleteSuccess){
      this.dialogRef.close('close');
    }else{
      alert('ユーザー施設紐付に失敗しました。');
    }
  }

  // ユーザー施設紐付登録処理
  async addNewUserToSite(user: UserForSiteModel,siteId: string){
    const postUserSiteLinksUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
    let postUserSiteLinksParams = new HttpParams();
    postUserSiteLinksParams = postUserSiteLinksParams.set('user_id', user.id);
    postUserSiteLinksParams = postUserSiteLinksParams.set('site_id', siteId);
    return this.httpClient.post(postUserSiteLinksUrl, postUserSiteLinksParams,
      {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }
    ).toPromise()
  }

  // ユーザー施設紐付削除処理
  async deleteUserFromSite(user: UserForSiteModel){ 
   const postUserSiteLinksUrl = `${environment.apiUrl}/user_site_links/1/${user.id}`;
   return this.httpClient.patch(postUserSiteLinksUrl,"",
     {
      headers: new HttpHeaders({
        Authorization: this.idToken,
        "content-type": "application/x-www-form-urlencoded"
      }) 
    }
   ).toPromise()
  }

  isUserSelectionDisable(){
    let isDisabled = this.selectedCompany.value == "" || 
    this.selectedCompany.value == undefined || 
    this.selectedCompany.value == null || 
    this.selectedCompany.value == "00000000-0000-0000-0000-000000000000" || //未登録のcompanyID
    !this.isSiteUserDataReady
    return isDisabled
  }

  hasUserToSiteChanged(){
    return this.waitForAddUser.length > 0 || this.waitForDeleteUser.length > 0
  }

  isFormReadyToGo(){
    let siteNameReady = this.siteName != undefined && this.siteName != ""
    let siteAddressReady = this.siteAddress != undefined && this.siteAddress != ""
    let siteCompanyReady= this.selectedCompany.value != undefined && this.selectedCompany.value != ""  && this.selectedCompany.value != null 
    return siteNameReady && siteAddressReady && siteCompanyReady
  }

  isFormChanged(){
    let hasNameChanged = this.siteName != this.siteModel.name
    let hasAddressChanged = this.siteAddress != this.siteModel.address
    let hasCompanyChanged = this.selectedCompany.value != this.siteModel.company_id
    let hasUserChaned = this.hasUserToSiteChanged()
    return hasNameChanged || hasAddressChanged || hasCompanyChanged || hasUserChaned
  }

  confirmButtonDisabled(){
    if(this.isEdit){
      return this.isFormReadyToGo() && this.isFormChanged()
    }
    return this.isFormReadyToGo()
  }
}
