// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  white-space: pre-line;
  text-align-last: center;
}`, "",{"version":3,"sources":["webpack://./src/app/site-complete-dialog/site-complete-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,uBAAA;AACJ","sourcesContent":[".message {\n    white-space: pre-line;\n    text-align-last: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
