// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_component {
  width: 100%;
  height: 100%;
}

.icon_image {
  width: 300px;
  height: 200px;
}

.matError_style {
  width: 120%;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".login_component {\n  width: 100%;\n  height: 100%;\n}\n\n.icon_image {\n  width: 300px;\n  height: 200px;\n}\n\n.matError_style {\n  width: 120%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
