// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  padding: 50px;
}

.confirm-button {
  background-color: #90CAFC;
}

.error {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-unit-add-dialog/meter-unit-add-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ","sourcesContent":["\ntable {\n    padding: 50px ;\n}\n\n.confirm-button {\n    background-color: #90CAFC;\n}\n\n.error {\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
