// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

h2 {
  text-align: center;
}

.example-container {
  height: 480px;
  background-color: white;
  box-shadow: none;
  overflow: scroll;
}

.footerArea {
  margin-top: 20px;
  padding-bottom: 12px;
  align-self: end;
}

.floor-name-header,
.floor-name {
  text-align: center;
  background-color: whitesmoke;
  width: 20%;
}

.partition-name-header,
.partition-name {
  text-align: center;
  width: 20%;
}

.tenant-name-header,
.tenant-name {
  text-align: center;
  background-color: whitesmoke;
  width: 20%;
}

.meter-name-header,
.meter-name {
  text-align: center;
  width: 20%;
}

.meter-label-header,
.meter-label {
  text-align: center;
  background-color: whitesmoke;
  width: 20%;
}

.expiry-header,
.expiry {
  text-align: center;
  width: 20%;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-expiration-warning-dialog/meter-expiration-warning-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AAGJ;;AADA;EACI,gBAAA;EACA,oBAAA;EACA,eAAA;AAIJ;;AAFA;;EAEI,kBAAA;EACA,4BAAA;EACA,UAAA;AAKJ;;AAHA;;EAEI,kBAAA;EACA,UAAA;AAMJ;;AAJA;;EAEI,kBAAA;EACA,4BAAA;EACA,UAAA;AAOJ;;AALA;;EAEI,kBAAA;EACA,UAAA;AAQJ;;AANA;;EAEI,kBAAA;EACA,4BAAA;EACA,UAAA;AASJ;;AAPA;;EAEI,kBAAA;EACA,UAAA;AAUJ","sourcesContent":["table{\n    width: 100%;\n}\nh2 {\n    text-align: center;\n}\n.example-container {\n    height: 480px;\n    background-color:white;\n    box-shadow: none;\n    overflow: scroll;\n  }\n.footerArea{\n    margin-top: 20px;\n    padding-bottom: 12px;\n    align-self: end;\n}\n.floor-name-header,\n.floor-name {\n    text-align: center;\n    background-color: whitesmoke;\n    width: 20%;\n}\n.partition-name-header,\n.partition-name{\n    text-align: center;\n    width: 20%;\n}\n.tenant-name-header,\n.tenant-name{\n    text-align: center;\n    background-color: whitesmoke;\n    width: 20%;\n}\n.meter-name-header,\n.meter-name{\n    text-align: center;\n    width: 20%;\n}\n.meter-label-header,\n.meter-label{\n    text-align: center;\n    background-color: whitesmoke;\n    width: 20%;\n}\n.expiry-header,\n.expiry{\n    text-align: center;\n    width: 20%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
