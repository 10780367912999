import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { LoginUser } from "../entity/login-user";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments/environment";
import { UntypedFormControl } from "@angular/forms";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUserSite } from "../entity/login-user-site";
import { GetPartitionsResponse } from "../entity/get-partitions-response";
import { GetPartitionsResult } from "../entity/get-partitions-result";
import { GetPartitionsSite } from "../entity/get-partitions-site";
import { MatDialog } from "@angular/material/dialog";
import { PartitionDetailDialogComponent } from "../partittion-detail-dialog/partition-detail-dialog.component";
import { PartitionAddDialogComponent } from "../partittion-add-dialog/partition-add-dialog.component";
import { PartitionEditDialogComponent } from "../partittion-edit-dialog/partition-edit-dialog.component";
import { PartitionDeleteDialogComponent } from "../partittion-delete-dialog/partition-delete-dialog.component";
import { GetFloorsResponse } from "../entity/get-floors-response";
import { FloorInfo } from "../entity/floor-info";
import { MessageInfo } from "../entity/message-info";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { Const } from "../const/const";
import { idName } from "../entity/id-name";
import { SiteInfo } from "../entity/get-site-info";
import { LoginUserSiteCompany } from "../entity/login-user-site-company";

class Partition {
  id: string;
  name: string;
  no: number;
  is_card_display: number;
  report_type: number;
  floor_id: string;
  floor_name: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

@Component({
  selector: "app-partition-management-list",
  templateUrl: "./partition-management-list.component.html",
  styleUrls: ["./partition-management-list.component.scss"],
})
export class PartitionManagementListComponent implements OnInit {
  selectedSite = new UntypedFormControl();
  loginUser: LoginUser;
  siteArray: LoginUserSite[];
  idToken: string;
  site = new LoginUserSite();
  sitename: string;

  // テーブル生成用
  displayedColumns: string[];
  partition: Partition;
  partitionsInfo: Partition[];

  // APIのURL
  getLoginUserUrl: string;
  getPartitionsFloorUrl: string;
  getFloorsUrl: string;

  // APIのレスポンス
  getPartitionsResponse: GetPartitionsResponse;

  getPartitionsResult: GetPartitionsResult;
  siteInfo: GetPartitionsSite;
  floorsInfo: FloorInfo[];

  // フラグ類
  getUserFinished: boolean;
  getPartitionFinished: boolean;
  NumberingFlag: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;
  userTypeSysAdmin = 0;
  userTypeAdmin = 1;
  userTypeUser = 2;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public partitionDialog: MatDialog
  ) {
    localStorage.setItem("path", router.url);
  }

  ngOnInit() {
    this.loginUser = new LoginUser();
    this.siteArray = [];
    this.getPartitionsResponse = new GetPartitionsResponse();
    this.floorsInfo = [];
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        if (Const.loginUser === null) {
          this.doGetLoginUser();
        } else {
          this.doSetValue();
        }
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.onClickLogout();
      }
    });
  }

  doSetValue() {
    this.loginUser = Const.loginUser;
    if (Number(this.loginUser.user_type) === this.userTypeSysAdmin) {
      if (Const.siteInfo.length > 0) {
        this.siteArray = Const.siteInfo;
      } else {
        this.getAllSiteData();
      }
    } else {
      for (const site of this.loginUser.sites) {
        this.siteArray.push(site);
      }
    }
    this.selectedSite = new UntypedFormControl(Const.site_id);
    this.mjitUserFlag = Const.mjitUser;
    this.adminUserFlag = Const.adminUser;

    this.getFloorsUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors`;
    this.doGetFloors();
  }

  // 施設情報の取得（UserType:0はシステム管理者として登録されている施設を全件取得する。）
  getAllSiteData() {
    Const.siteInfo.splice(0);
    const url = `${environment.apiUrl}/sites/sites_info_all/all`;
    this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (res) => {
          const jsonStr = JSON.stringify(res);
          const jsonObj = JSON.parse(jsonStr);
          for (const site of jsonObj.result.sites as SiteInfo[]) {
            const siteItem = new LoginUserSite();
            siteItem.id = site.id;
            siteItem.name = site.name;
            siteItem.address = site.address;
            siteItem.updated_at = site.updated_at;
            siteItem.created_at = site.created_at;
            siteItem.company = new LoginUserSiteCompany();
            siteItem.company.id = site.company_id;
            siteItem.company.name = site.company_name;
            Const.siteInfo.push(siteItem);
          }
          this.siteArray = Const.siteInfo;
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("施設情報が取得できませんでした。");
          }
        }
      );
  }

  doGetFloors() {
    this.getPartitionFinished = false;
    this.selectedSite.disable();
    this.NumberingFlag = false;
    this.partitionsInfo = [];

    this.httpClient
      .get(this.getFloorsUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetFloorsResponse) => {
          this.floorsInfo = response.result.floors;

          this.getPartitionsFloorUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors/partitions`;
          this.doGetPartitionsFloor();
        },
        () => {
          alert("フロア情報が存在しません。\nフロアを登録してください");
          this.floorsInfo = [];
          this.getPartitionFinished = true;
          this.selectedSite.enable();
        }
      );
  }

  doGetPartitionsFloor() {
    this.httpClient
      .get(this.getPartitionsFloorUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetPartitionsResponse) => {
          this.getPartitionsResult = response.result;

          for (
            var partitionNumber = 0;
            partitionNumber < this.getPartitionsResult.partitions.length;
            partitionNumber++
          ) {
            this.partition = new Partition();
            this.partition.id =
              this.getPartitionsResult.partitions[partitionNumber].id;
            this.partition.name =
              this.getPartitionsResult.partitions[partitionNumber].name;
            this.partition.no =
              this.getPartitionsResult.partitions[partitionNumber].no;
            this.partition.floor_id =
              this.getPartitionsResult.partitions[partitionNumber].floor.id;
            this.partition.floor_name =
              this.getPartitionsResult.partitions[partitionNumber].floor.name;
            this.partition.is_card_display =
              this.getPartitionsResult.partitions[
                partitionNumber
              ].is_card_display;
            this.partition.report_type =
              this.getPartitionsResult.partitions[partitionNumber].report_type;
            this.partition.created_at =
              this.getPartitionsResult.partitions[partitionNumber].created_at;
            this.partition.updated_at =
              this.getPartitionsResult.partitions[partitionNumber].updated_at;
            if (
              this.getPartitionsResult.partitions[partitionNumber]
                .created_by !== null
            ) {
              this.partition.created_by =
                this.getPartitionsResult.partitions[partitionNumber].created_by;
            }
            if (
              this.getPartitionsResult.partitions[partitionNumber]
                .updated_by !== null
            ) {
              this.partition.updated_by =
                this.getPartitionsResult.partitions[partitionNumber].updated_by;
            }
            this.partitionsInfo.push(this.partition);
          }

          if (this.partitionsInfo.length !== 0) {
            this.NumberingFlag = true;
          }

          this.displayedColumns = [
            "detailButton",
            "partitionName",
            "floorName",
            "editButton",
            "deleteButton",
          ];
          this.getPartitionFinished = true;
          this.selectedSite.enable();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
          } else {
            alert("区画情報が存在しません。");
            this.getPartitionFinished = true;
            this.selectedSite.enable();
          }
        }
      );
  }

  doChangeSite() {
    Const.site_id = this.selectedSite.value;
    this.getFloorsUrl = `${environment.apiUrl}/sites/${Const.site_id}/floors`;
    this.doGetFloors();
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;

    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          Const.loginUser = response.result.login_user;
          Const.mjitUser = Number(Const.loginUser.user_type) === 0;
          Const.adminUser = Number(Const.loginUser.user_type) === 1;
          if (Const.mjitUser) {
            Const.site_id = localStorage.getItem("siteId");
          } else {
            for (const site of response.result.login_user.sites) {
              if (site.id === localStorage.getItem("siteId")) {
                Const.site_id = site.id;
              }
            }
          }

          if (Const.site_id === null) {
            alert("前回選択した施設が見つかりませんでした。");
            Const.site_id = response.result.login_user.sites[0].id;
          }

          this.doSetValue();
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
          } else {
            alert(
              "ログイン情報が取得できませんでした。再度ログインしてください。"
            );
          }
          this.onClickLogout();
        }
      );
  }

  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }

  onClickPartitionDetail(partitionInfo: Partition) {
    const dialogRef = this.partitionDialog.open(
      PartitionDetailDialogComponent,
      {
        width: "650px",
        data: partitionInfo,
      }
    );
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onClickForPartitionCreate() {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.partitionDialog.open(PartitionAddDialogComponent, {
      width: "650px",
      data: {
        partitionsInfo: this.partitionsInfo,
        siteId: this.selectedSite.value,
        site: this.site,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onClickPartitionEdit(partitionInfo) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.partitionDialog.open(PartitionEditDialogComponent, {
      width: "650px",
      data: {
        partitionInfo: partitionInfo,
        partitionsInfo: this.partitionsInfo,
        siteId: this.selectedSite.value,
        site: this.site,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
  }

  onClickPartitionDelete(partitionInfo) {
    Const.site_id = this.selectedSite.value;

    localStorage.setItem("siteId", this.selectedSite.value);
    for (var i = 0; i < this.siteArray.length; i++) {
      if (Const.site_id == this.siteArray[i].id) {
        this.site = this.siteArray[i];
        break;
      }
    }
    const dialogRef = this.partitionDialog.open(
      PartitionDeleteDialogComponent,
      {
        width: "650px",
        data: { partitionInfo: partitionInfo, site: this.site },
      }
    );
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === "close") {
        // TODO: 最小限の更新になるように要改良
        this.doGetFloors();
      }
    });
    //console.log(partitionInfo);
  }
  checkIfReadyToGo() {
    if (!this.floorsInfo.length || !this.getPartitionFinished) {
      return true;
    } else {
      return false;
    }
  }

  onNumbering() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    this.getPartitionFinished = false;
    this.selectedSite.disable();
    this.NumberingFlag = false;
    // APIコールして登録処理
    const patchNumberingPartitionUrl = `${environment.apiUrl}/sites/${this.selectedSite.value}/floors/partitions/no`;
    const params = new HttpParams();
    this.httpClient
      .patch(patchNumberingPartitionUrl, params, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          const successMessageInfo = new MessageInfo(
            "自動採番を行いました",
            ""
          );
          const dialogRef = this.partitionDialog.open(MessageDialogComponent, {
            width: "400px",
            data: successMessageInfo,
          });
          dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult === "close") {
              this.doGetFloors();
            }
          });
        },
        (err) => {
          this.getPartitionFinished = true;
          this.selectedSite.enable();
          this.NumberingFlag = true;
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.onClickLogout();
          } else {
            alert("自動採番に失敗しました");
            this.doGetFloors();
          }
        }
      );
  }
}
