import { Component, Input, OnChanges, OnInit, } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnChanges {
  showDisplayForMJIT = false
  showDisplayForAdmin = false
  @Input() displayMjitFlag
  @Input() displayAdminFlag
  @Input() currentIndex: number = 0;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.showDisplayForMJIT != this.displayMjitFlag) {
      this.showDisplayForMJIT = this.displayMjitFlag
    }

    if (this.showDisplayForAdmin != this.displayAdminFlag) {
      this.showDisplayForAdmin = this.displayAdminFlag
    }
  }

  // レポート画面遷移処理
  onClickReport() {
    this.router.navigate(['meter-report']);
  }

  // 区画管理画面遷移処理
  onClickPartitionManagement() {
    this.router.navigate(['partition-management']);
  }

  // 施設管理画面遷移処理
  onClickSiteEdit() {
    this.router.navigate(['site-management']);
  }

  // テナント情報変更画面遷移処理
  onClickMasterEdit() {
    this.router.navigate(['master-edit']);
  }

  // カード一覧画面遷移処理
  onClickCardList() {
    this.router.navigate(['meter-card-list']);
  }

  // フロア管理画面遷移処理
  onClickFloorManagement() {
    this.router.navigate(['floor-management']);
  }

  // メーター管理画面遷移処理
  onClickMeterManagement() {
    this.router.navigate(['meter-management']);
  }

  // メータータイプ管理画面遷移処理
  onClickMeterTypeManagement() {
    this.router.navigate(['meter-type']);
  }

  // メーター単位管理画面遷移処理
  onClickMeterUnitManagement() {
    this.router.navigate(['meter-unit']);
  }

  // ユーザー管理画面遷移処理
  onClickUseranagement() {
    this.router.navigate(['user-management']);
  }

   // 会社管理画面遷移処理
  onClickCompanyEdit() {
    this.router.navigate(['company-management']);
  }
}
