import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-value-correct-dialog',
  templateUrl: './value-correct-dialog.component.html',
  styleUrls: ['./value-correct-dialog.component.scss']
})
export class ValueCorrectDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ValueCorrectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  onClickClose() {
    this.dialogRef.close('dialog close');
  }
}
