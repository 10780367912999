import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantConfirmDialogInfo } from '../entity/tenant-confirm-dialog-info';

@Component({
  selector: 'app-tenant-confirm-dialog',
  templateUrl: './tenant-confirm-dialog.component.html',
  styleUrls: ['./tenant-confirm-dialog.component.scss']
})
export class TenantConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TenantConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: TenantConfirmDialogInfo
  ) { }

  ngOnInit() {
  }

  onClickYes() {
    this.dialogRef.close('Yes');
  }
}
