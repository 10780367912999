export const PRINT_STYLES =
{
    '.container':{
        'display':'flex',
        'align-items':'flex-start'
    },
    '.rectangle':
    {'width':'130px', 'height': '180px', 'border': '2px solid black','flex-direction': 'column', 'text-align':'center'},
    table: 
    {
    'font-size':'10px'
    },
    th:
    {
        'width':'120px'
    },
    td:
    {
        'width':'300px'
    }
} 