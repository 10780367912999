// import { Tenant } from './tenant';
import { Meter } from './meter';
import { PartitionsTenant } from './partitions-tenant';

export class Partition {
    constructor(id: string, name: string, created_at: string, updated_at: string,
        deleted_at: string, partitions_tenants: PartitionsTenant[]) {
        this.id = id;
        this.name = name;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
        // this.tenants = tenants;
        this.partitions_tenants = partitions_tenants;
    }
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    // tenants: Tenant[];
    partitions_tenants: PartitionsTenant[];
    meters: Meter[];
}

// コンストラクタは後ほど調整
// export class Partition {
//     constructor(
//         private id: string: string,
//         private name: string: string,
//         private created_at: string: string,
//         private updated_at: string: string,
//         private deleted_at: string: string,
//         private tenants: Tenant[],
//         ) {
//     }
//     // meterだけは後からコンストラクタでなく後から生成
//     meters: Meter[];
// }
