import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserConfirmDialogInfo } from '../entity/user-confirm-dialog-info';

@Component({
  selector: 'app-user-confirm-dialog',
  templateUrl: './user-confirm-dialog.component.html',
  styleUrls: ['./user-confirm-dialog.component.scss']
})
export class UserConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInfo: UserConfirmDialogInfo
  ) { }

  ngOnInit() {
  }

  onClickYes() {
    this.dialogRef.close('Yes');
  }
}
