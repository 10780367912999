import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormControl } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { GetLoginUserResponse } from "../entity/get-login-user-response";
import { LoginUser } from "../entity/login-user";
import { UserConfirmDialogInfo } from "../entity/user-confirm-dialog-info";
import { CommonConfirmDialogComponent } from "../common-confirm-dialog/common-confirm-dialog.component";
import { GetTenantsPartition } from "../entity/get-tenants-partition";
import { CompanyInfo } from "../entity/get-company-info";
import { SiteInfo } from "../entity/get-site-info";
import { UserSiteLinksSelectDialogComponent } from "../user-site-links-select-dialog/user-site-links-select-dialog.component";
import {
  GetUserWithSitesLinksInfo,
  UserSite,
} from "../entity/get-user-with-sites-links-info";
import { Auth } from "aws-amplify";
import { Subscriber } from "rxjs";
import { Const } from "../const/const";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { isNull } from "util";
import { Directive, ElementRef } from "@angular/core";
import { NgControl } from "@angular/forms";
import { ForbiddenCharactersDirective } from "../common-confirm-dialog/forbidden-characters.directive";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { Dialog } from "@angular/cdk/dialog";

interface UserType {
  value: string;
  viewValue: string;
}

export class CheckSiteInfo {
  checked = false;

  siteId: string;
  siteName: string;
  siteAddress: string;
  companyName: string;
}

@Component({
  selector: "app-user-add-dialog",
  templateUrl: "./user-add-dialog.component.html",
  styleUrls: ["./user-add-dialog.component.scss"],
})
export class UserAddDialogComponent implements OnInit {
  idToken: string;
  getLoginUserResponse: GetLoginUserResponse;
  getLoginUserUrl: string;
  loginUser: LoginUser;
  companyList: CompanyInfo[];
  userTypeList: UserType[] = [
    { value: "1", viewValue: "管理者" },
    { value: "2", viewValue: "一般" },
  ];

  // 施設情報
  siteData: SiteInfo[];

  // 施設選択情報
  selectSite: string;
  selectSiteInfoList: UserSite[] = new Array();
  siteInfoList: UserSite[] = new Array();
  checkSiteInfoList: CheckSiteInfo[] = new Array();

  // 入力項目
  loginId: string;
  password: string;
  confirmPassword: string;
  userName: string;
  selectedCompnay = new UntypedFormControl();
  selectedUserType = new UntypedFormControl();

  userTypeSysAdmin = "0";
  userTypeAdmin = "1";
  userTypeUser = "2";

  errorMessage: string;
  hasError = false;
  isFormReady = false;

  @Input() public company: CompanyInfo[];
  @Input() public siteInfo: SiteInfo[];
  @Input() public userInfo: GetUserWithSitesLinksInfo[];

  constructor(
    private httpClient: HttpClient,
    private el: ElementRef,
    private router: Router,
    public dialogRef: MatDialogRef<UserAddDialogComponent>,
    public userConfirmDialog: MatDialog,
    public dialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public partitionTenant: GetTenantsPartition[]
  ) {}

  ngOnInit() {
    this.selectSite = "施設選択";
    this.selectSiteInfoList = [];
    this.checkSiteInfoList = [];
    this.companyList = [];
    this.companyList = this.company;
    this.siteCompnayLinks();
    this.hasError = false;
    this.errorMessage = "";
    this.auth.getIdToken().subscribe((result) => {
      if (result) {
        this.idToken = result;
        this.doGetLoginUser();
      } else {
        // idトークンがnullの場合はログイン画面へ遷移
        alert("セッションが切れています。再度ログインしてください。");
        this.router.navigate(["login"]);
      }
    });
  }

  doGetLoginUser() {
    this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
    this.httpClient
      .get(this.getLoginUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response: GetLoginUserResponse) => {
          this.loginUser = response.result.login_user;
          if (response.result.login_user.user_type == this.userTypeSysAdmin) {
            this.userTypeList.push({ value: "0", viewValue: "MJIT" });
          }
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            this.dialogRef.close("lockedAccount");
          } else {
            alert(
              "ログイン名が取得できませんでした。再度ログインしてください。"
            );
            this.dialogRef.close("failed");
          }
          this.onClickLogout();
        }
      );
  }

  // ログアウト
  onClickLogout() {
    Const.release();
    this.auth.signOut();
  }
  // 会社選択
  changeCompanySelection() {
    this.selectSite = "施設選択";
    this.selectSiteInfoList = [];
    this.checkSiteInfoList = [];
  }

  // 施設選択
  onClickSelectSite() {
    const sites = this.siteInfo;

    if (this.selectedCompnay.value !== null) {
      this.siteInfo = this.siteInfo.filter((item) => {
        return item.company_id === this.selectedCompnay.value;
      });
      this.siteCompnayLinks();
    }

    const dialogRef = this.dialog.open(UserSiteLinksSelectDialogComponent, {
      width: "1000px",
      data: this.siteInfo,
    });
    dialogRef.componentInstance.checkSiteInfoList = this.checkSiteInfoList;
    dialogRef.componentInstance.siteInfo = this.siteInfoList;
    dialogRef.componentInstance.company = this.company;
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (this.siteInfo !== sites) {
          this.siteInfo = sites;
          this.siteCompnayLinks();
        }

        // 閉じるボタンの処理
        if (result === "") {
          return;
        }
        // 選択した施設を表示
        if (result.length === 1) {
          // 表示施設
          this.selectSite = result[0].siteName;
          this.selectSiteInfoList = result as UserSite[];
          this.checkSiteInfoList = result;
        }
        if (result.length > 1) {
          // 表示施設
          this.selectSite =
            result[0].siteName + "他(" + (result.length - 1) + ")";
          this.selectSiteInfoList = result as UserSite[];
          this.checkSiteInfoList = result;
        }
        if (result.length === 0) {
          // 表示施設
          this.selectSite = "施設選択";
          this.selectSiteInfoList.splice(0);
          this.checkSiteInfoList.splice(0);
        }
      }
    });
  }

  siteCompnayLinks() {
    this.siteInfoList.splice(0);
    for (const site of this.siteInfo) {
      const siteInfo = new UserSite();
      const companyItem = this.company.filter((item) => {
        return item.id === site.company_id;
      });
      siteInfo.siteId = site.id;
      siteInfo.siteName = site.name;
      siteInfo.siteAddress = site.address;
      siteInfo.companyName = companyItem[0].name;
      this.siteInfoList.push(siteInfo);
    }
  }

  // 登録ボタンの活性化制御
  checkIfReadyToGo() {
    this.isFormReady =
      this.loginId !== undefined &&
      this.loginId !== "" &&
      this.password !== undefined &&
      this.password !== "" &&
      this.confirmPassword !== undefined &&
      this.confirmPassword !== "" &&
      this.userName !== undefined &&
      this.userName !== "" &&
      this.selectedCompnay.value !== null &&
      this.selectedUserType.value !== null;
    return this.isFormReady;
  }

  // ユーザー登録
  onClickConfirm() {
    if (!navigator.onLine) {
      alert("ネットワーク接続を確認してください");
      return;
    }
    // 入力チェック
    this.errorMessage = this.checkValue();
    if (this.errorMessage.length > 0) {
      return;
    }

    // 施設選択をしていない場合エラー
    if (this.selectSite === "施設選択") {
      alert("施設は１つ以上選択してください。");
      return;
    }

    // 確認ダイアログ
    const messageInfo = new UserConfirmDialogInfo(
      "新規登録確認",
      "ユーザーを新規登録します。よろしいですか。"
    );
    const confirmDialog = this.userConfirmDialog.open(
      CommonConfirmDialogComponent,
      {
        width: "400px",
        data: messageInfo,
      }
    );

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result === "Yes") {
        // ユーザー作成
        await this.createCognitoUser();
      }
    });
  }

  // ユーザー施設紐付け情報の登録
  postUserSitelinks(user: any) {
    const posthUserUrl = `${environment.apiUrl}/user_site_links/user_site_links_info`;
    for (const site of this.selectSiteInfoList) {
      let params = new HttpParams();
      params = params.set("user_id", user.id);
      params = params.set("site_id", site.siteId);
      this.httpClient
        .post(posthUserUrl, params, {
          headers: new HttpHeaders({
            Authorization: this.idToken,
          }),
        })
        .subscribe(
          (response) => {},
          (err) => {
            if (err.error.code == "AccountLockError") {
              alert("アカウントがロックされました。管理者までお問合せください");
              Const.release();
              this.auth.signOut();
              this.dialogRef.close("close");
            } else {
              alert("施設の紐付けに失敗しました");
            }
          }
        );
    }
  }

  // 入力チェック
  checkValue(): string {
    let errorMessage = "を入力してください";
    if (this.loginId === undefined || this.loginId === "") {
      errorMessage = "ユーザーID" + errorMessage;
      return errorMessage;
    }
    const loginIdPolicy = new RegExp(/^([a-zA-Z0-9!-/:-@¥[-`{-~]{8,32})+$/);
    const passwordPolicy = new RegExp(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,99}$/
    );

    // ログインIDポリシーチェック
    if (this.loginId.match(loginIdPolicy) === null) {
      return (errorMessage =
        "ログインIDは半角英数記号を含む8文字以上32文字以内で入力してください");
    }
    // 空白チェック
    if (this.loginId.match(/\s+/g) !== null) {
      return (errorMessage =
        "ログインIDは半角英数記号を含む8文字以上32文字以内で入力してください");
    }

    if (this.password === undefined || this.password === "") {
      errorMessage = "パスワード" + errorMessage;
      return errorMessage;
    }
    // パスワードポリシーチェック
    if (this.password.match(passwordPolicy) === null) {
      return (errorMessage =
        "パスワードは、半角英数の大文字と小文字、記号を含む12文字以上99文字以内で入力してください");
    }
    // 空白チェック
    if (this.password.match(/\s+/g) !== null) {
      return (errorMessage =
        "パスワードは、半角英数の大文字と小文字、記号を含む12文字以上99文字以内で入力してください");
    }

    if (this.confirmPassword === undefined || this.confirmPassword === "") {
      errorMessage = "パスワード（確認）" + errorMessage;
      return errorMessage;
    }

    if (this.password !== this.confirmPassword) {
      errorMessage = "パスワードとパスワード（確認）が異なっています";
      return errorMessage;
    }

    if (this.userName === undefined || this.userName === "") {
      errorMessage = "ユーザー名" + errorMessage;
      return errorMessage;
    }
    if (this.selectedCompnay.value === null) {
      errorMessage = "所属会社" + errorMessage;
      return errorMessage;
    }
    if (this.selectedUserType.value === null) {
      errorMessage = "ユーザー権限" + errorMessage;
      return errorMessage;
    }

    // すべて通り抜けたら空文字をリターン
    return ""
  }

  //  forbiddenCharactersValidator(inputValue: string): ValidatorFn {
  //    return (control: AbstractControl): { [key: string]: any } | null => {
  //      const forbiddenCharacters = ["¥", '"', ","];
  //      //const value:string = control.value;
  //      const forbidden = forbiddenCharacters.some((char) =>
  //        inputValue.includes(char)
  //      );
  //      return forbidden
  //        ? {
  //            forbiddenCharacters: { value: control.value },
  //          }
  //        : null;
  //    };
  //  }

  //  onInput(event: Event): void {
  //    const value: string = this.el.nativeElement.value;
  //    if (this.forbiddenCharactersValidator(value)) {
  //      const errorMessage = "forbidden ";
  //      alert(errorMessage);
  //    }
  //  }

  // Cognitoへの登録
  async createCognitoUser() {
    try {
      await Auth.signUp({
        username: this.loginId,
        password: this.password,
      }).then(() => {
        // Cognitoユーザーの確認ステータスを確認済に変更
        const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/activate_cognito_user`;
        let params = new HttpParams();
        params = params.set(
          "user_pool_id",
          environment.amplify.Auth.aws_user_pools_id
        );
        params = params.set("user_name", this.loginId);
        params = params.set("password", this.password);
        this.httpClient
          .post(postActivateCognitoUserUrl, params, {
            headers: new HttpHeaders({
              Authorization: this.idToken,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          })
          .subscribe(
            async (response: GetLoginUserResponse) => {
              await this.createUser();
            },
            (err) => {
              if (err.error.code == "AccountLockError") {
                alert(
                  "アカウントがロックされました。管理者までお問合せください"
                );
                Const.release();
                this.auth.signOut();
                this.dialogRef.close("close");
              } else {
                alert("ユーザー情報の登録に失敗しました。");
              }
            }
          );
      });
    } catch (error) {
      if (error.code == "UsernameExistsException") {
        alert("ログインIDが既に存在します。");
      }
    }
  }

  // 作成したユーザーのユーザーIDを取得しユーザー情報を登録する
  async createUser() {
    // Cognitoユーザーを取得
    const postActivateCognitoUserUrl = `${environment.apiUrl}/cognito/get_cognito_user/${environment.amplify.Auth.aws_user_pools_id}/${this.loginId}`;
    this.httpClient
      .get(postActivateCognitoUserUrl, {
        headers: new HttpHeaders({
          Authorization: this.idToken,
        }),
      })
      .subscribe(
        (response) => {
          const jsonStr = JSON.stringify(response);
          const jsonObj = JSON.parse(jsonStr);
          const data = jsonObj.result.cognito;
          // APIコールして登録処理
          const postUserUrl = `${environment.apiUrl}/users/users_info`;
          let params = new HttpParams();
          params = params.set("id", data.UserAttributes[0].Value);
          params = params.set("login_id", this.loginId);
          params = params.set("name", this.userName);
          params = params.set("company_id", this.selectedCompnay.value);
          params = params.set("user_type", this.selectedUserType.value);
          this.httpClient
            .post(postUserUrl, params, {
              headers: new HttpHeaders({
                Authorization: this.idToken,
              }),
            })
            .subscribe(
              (res) => {
                const jsonUserStr = JSON.stringify(res);
                const jsonUserObj = JSON.parse(jsonUserStr);
                const userData = jsonUserObj.result.user;
                if (this.selectSiteInfoList.length > 0) {
                  // ユーザー施設紐付けを行う
                  this.postUserSitelinks(userData);
                }
                this.dialogRef.close("close");
              },
              (err) => {
                if (err.error.code == "AccountLockError") {
                  alert(
                    "アカウントがロックされました。管理者までお問合せください"
                  );
                  Const.release();
                  this.auth.signOut();
                  this.dialogRef.close("close");
                }
              }
            );
        },
        (err) => {
          if (err.error.code == "AccountLockError") {
            alert("アカウントがロックされました。管理者までお問合せください");
            Const.release();
            this.auth.signOut();
            this.dialogRef.close("close");
          }
        }
      );
  }
}
