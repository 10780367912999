import { SiteWithUserInfo } from '../site-management/site-management.component';

export class CsvSiteInfo {
    siteId: string;
    siteName: string;
    siteAddress: string;
    companyId: string;
    companyName: string;
    userIdList: string[];
    dbSiteData: SiteWithUserInfo;
}
