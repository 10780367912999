import { SiteInfo } from "../entity/get-site-info"

export const Const = {
    site_id: null,
    loginUser: null,
    mjitUser: false,
    adminUser: false,
    siteInfo : [],
    release() {
        this.site_id = ''
        this.loginUser = null
        this.mjitUser = false
        this.adminUser = false
        this.siteInfo = []
    }
}
