// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: medium;
  text-align: right;
}

td {
  text-align: left;
  height: 45px;
}

textarea {
  font-size: medium;
}

input {
  font-size: medium;
}

.confirm-button {
  background-color: #90CAFC;
}`, "",{"version":3,"sources":["webpack://./src/app/tenant-edit-dialog/tenant-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":["table{\n    font-size: medium;\n    text-align: right\n}\n\ntd{\n    text-align: left;\n    height: 45px\n}\n\ntextarea{\n    font-size: medium;\n}\n\ninput{\n    font-size: medium;\n}\n\n.confirm-button{\n    background-color: #90CAFC;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
