import { Injectable } from "@angular/core";
import { ReportMeters } from "src/app/entity/report-meters";
import { ReportPartitions } from "src/app/entity/report-partitions";
import { ValueDetection } from "src/app/entity/value-detection";

/**
 * サービスクラスの共通処理
 */
@Injectable()
export class MeterCardListCommonService {
  constructor() {}

  /**
   * カード表示用データの対象かどうかのチェック
   * @param partition(ReportPartitions): API結果のpartitions以下
   * @returns 対象かどうかの結果: boolean
   */
  shouldCreateCardData(partition: ReportPartitions): boolean {
    // メーター情報が1つでもあれば対象
    return partition.meters.length > 0;
  }

  /**
   * 今月確定値のオブジェクトを作成する
   * @param tenantId(string): テナントID
   * @param meter(ReportMeters): メーター
   * @returns 今月確定値: ValueDetection[]
   * - カード表示側では先頭しか表示しないので、1つしか作らなくてOK
   */
  getValueDetections(tenantId: string, meter: ReportMeters): ValueDetection[] {
    let result: ValueDetection[] = [];

    // value_detectionsが無ければ空のリストを返却
    if (meter.value_detections.length == 0){
      return [];
    }

    // 確定値は降順でソート
    const valueDetections = meter.value_detections;
    valueDetections.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)));

    valueDetections.some(detection => {
      if (detection.tenant_id == tenantId && detection.value_confirmation){
          result.push( new ValueDetection(
            detection.id,
            detection.detected_value,
            detection.picture_url,
            detection.picture_date,
            detection.tenant_id,
            detection.created_at,
            detection.value_confirmation
          ));
        return true;
      } else {
        return false;
      }
    });

    return result;
  }


}
