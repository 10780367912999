import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-list-dialog',
  templateUrl: './report-list-dialog.component.html',
  styleUrls: ['./report-list-dialog.component.scss']
})
export class ReportListDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReportListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onClickReportButton(month: number, isConfirmed: boolean) {
      // 定義してやった方がいい
      const info = {'month': month, 'isConfirmed': isConfirmed};
      this.dialogRef.close(info);
  }

  // 「yyyymm」形式の月情報を受け取って「yyyy年mm月」形式に変換する処理
  monthStringFormat(yearMonth: number) {
    const formatedMonth = String(yearMonth).substr(0, 4) + '年' + String(yearMonth).substr(4) + '月';
    return (formatedMonth);
  }

  // 「yyyy-mm-dd hh:mm:ss」形式に変換する　かつ　＋９時間（日本時間にする）
  timeStringFormat(time: number) {
    let newD = new Date(new Date(time).setHours(new Date(time).getHours() + 9));

    const yyyy = String(newD.getFullYear());
    const mm = String(newD.getMonth() + 1).padStart(2, "0");
    const dd = String(newD.getDate()).padStart(2, "0");
    const hh = String(newD.getHours()).padStart(2, "0");
    const ms = String(newD.getMinutes()).padStart(2, "0");
    const s = String(newD.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${ms}:${s}`;
  }

}
