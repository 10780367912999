import { Component, OnInit, Inject, LOCALE_ID} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetLoginUserResponse } from '../entity/get-login-user-response';
import { LoginUser } from '../entity/login-user';
import { MatDialog } from '@angular/material/dialog';
import { Unit } from "../entity/unit";  
import { MeterConfirmDialogInfo } from '../entity/meter-confirm-dialog-info';
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';
import { MeterUnitAddDialogComponent } from '../meter-unit-add-dialog/meter-unit-add-dialog.component';
import { MeterUnitEditDialogComponent } from '../meter-unit-edit-dialog/meter-unit-edit-dialog.component';
import { Const } from '../const/const';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-meter-unit',
  templateUrl: './meter-unit.component.html',
  styleUrls: ['./meter-unit.component.scss']
})
export class MeterUnitComponent implements OnInit {
  loginUser: LoginUser;
  getLoginUserUrl: string;
  getLoginUserResponse: GetLoginUserResponse;
  getTenantDataUrl: string;
  idToken: string;

  // テーブル生成用
  displayedColumns: string[];
  meterUnitData: Unit[];
  filteredMeterUnitData: Unit[];

  searchKeyString: string="";
  searchKeyChanged: Subject<string> = new Subject<string>();

  // フラグ類
  getUserFinished: boolean;
  getMeterUnitFinished: boolean;
  mjitUserFlag = false;
  adminUserFlag = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public auth: AuthService,
    private route: ActivatedRoute,
    public meterUnitDialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
    ) {
      localStorage.setItem("path", router.url)
    }

    ngOnInit() {
      this.loginUser = new LoginUser()
      this.meterUnitData = []
      this.getMeterUnitFinished = false

      this.searchKeyChanged
      .debounceTime(300) // wait 300ms after the last event before emitting last event
      .subscribe(str => {this.searchKeyString = str; this.filterResult()});

      this.auth.getIdToken()
        .subscribe(
            result => {
              if (result) {
                this.idToken = result;
                if (Const.loginUser === null) {
                  this.doGetLoginUser()
                } else {
                  this.loginUser = Const.loginUser
                  this.mjitUserFlag = Const.mjitUser;
                  this.adminUserFlag = Const.adminUser;
                  this.onGetMeterUnitData();
                }
              } else {
                // idトークンがnullの場合はログイン画面へ遷移
                alert('セッションが切れています。再度ログインしてください。');
                this.onClickLogout()
              }
            }
        );
    }
  
  
    filterResult(){
      if(this.searchKeyString === ""){
        this.filteredMeterUnitData = this.meterUnitData
        return
      }
  
      this.filteredMeterUnitData = this.meterUnitData.filter(item => item.type.includes(this.searchKeyString))
    }
  
    search(text: string){
      this.searchKeyChanged.next(text);
    }
  
    onGetMeterUnitData(){

      this.getMeterUnitFinished = false
      const url = `${environment.apiUrl}/units/units_info_all`
      this.httpClient.get(url, {
        headers: new HttpHeaders({
          Authorization: this.idToken
        })
      }).subscribe( res =>{
        let jsonStr = JSON.stringify(res)
        let jsonObj = JSON.parse(jsonStr)
        let data = jsonObj.result.units

        this.getMeterUnitFinished = true

        this.meterUnitData = data.sort(function(a: Unit,b: Unit){
          let left = new Date(a.updated_at)
          let right = new Date(b.updated_at)
          return right.getDate() - left.getDate()
        })
        this.filteredMeterUnitData = this.meterUnitData
        this.displayedColumns = ["unit","createdBy","createdAt","updatedBy","updatedAt","editButton","deleteButton"]
        this.getMeterUnitFinished = true      
      },
        err=>{
          if(err.error.code == "AccountLockError"){
            alert('アカウントがロックされました。管理者までお問合せください');
            this.onClickLogout()
          }else{
            alert("単位情報が取得できませんでした。")
          }
      })
    }

    doGetLoginUser() {
      this.getLoginUserUrl = `${environment.apiUrl}/login_user`;
  
      this.httpClient.get(this.getLoginUserUrl,
        {
          headers: new HttpHeaders({
            Authorization: this.idToken
          })
        }
      )
      .subscribe((response: GetLoginUserResponse) => {
        Const.loginUser = response.result.login_user;
        Const.mjitUser = Number(Const.loginUser.user_type) === 0
        Const.adminUser = Number(Const.loginUser.user_type) === 1
        if (Const.mjitUser) {
          Const.site_id = localStorage.getItem("siteId")
        } else {
          for (const site of response.result.login_user.sites) {
            if (site.id === localStorage.getItem("siteId")) {
              Const.site_id = site.id
            }
          }
        }
  
        if (Const.site_id === null) {
          alert('前回選択した施設が見つかりませんでした。');
          Const.site_id = response.result.login_user.sites[0].id;
        }
  
        this.loginUser = Const.loginUser
        this.mjitUserFlag = Const.mjitUser
        this.adminUserFlag = Const.adminUser
        this.onGetMeterUnitData();
      },
      err => {
        if(err.error.code == "AccountLockError"){
          alert('アカウントがロックされました。管理者までお問合せください');
        }else{
          alert('ログイン情報が取得できませんでした。再度ログインしてください。');
        }
        this.onClickLogout();
      });
    }
  
    // ログアウト
    onClickLogout() {
      Const.release()
      this.auth.signOut();
    }


    onClickAddMeterUnit(){
      const dialogRef = this.meterUnitDialog.open(MeterUnitAddDialogComponent, {
        width: '650px',
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === 'close') {
          // 最小限の更新になるように要改良

            this.onGetMeterUnitData()
        }
      });
    }


    onClickUpdateMeterUnit(unit: Unit){
      const dialogRef = this.meterUnitDialog.open(MeterUnitEditDialogComponent, {
        width: '650px',
        data: unit
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === 'close') {
          // 最小限の更新になるように要改良
          this.onGetMeterUnitData()
        }
      });
    }

    onClickDeleteMeterUnit(unit: Unit){
      let contentMsg =  unit.type + "を削除します。よろしいですか。"
      const messageInfo = new MeterConfirmDialogInfo('メーター単位削除確認', contentMsg);
      const confirmDialog = this.meterUnitDialog.open(CommonConfirmDialogComponent,{
        width: '400px',
        data: messageInfo
      })

      confirmDialog.afterClosed().subscribe(result => {
        if(result === "Yes"){

          const url = `${environment.apiUrl}/units/${unit.id}/0/units_info`
          let params = new HttpParams();
          params.set("type","")

          this.httpClient.patch(url,params, {
            headers: new HttpHeaders({
              Authorization: this.idToken
            })
          }).subscribe(res => {

            let jsonStr = JSON.stringify(res)
            let jsonObj = JSON.parse(jsonStr)

            if(jsonObj.status == 200){
                this.onGetMeterUnitData()
            }
          }, err => {
            if(err.error.code == "AccountLockError"){
              alert('アカウントがロックされました。管理者までお問合せください');
              Const.release()
              this.auth.signOut()
            }else{
              alert("単位削除に失敗しました")
            }
          })
        }
      })
    }


}
